// https://techdocs.akamai.com/bot-manager/docs/set-up-inline-telemetry
import { Components, Events } from '@/common/types/tracking';
import { isClientSide } from '@/common/utils/environment/environment';
import { trackingMessageError } from '@/common/utils/tracking/tracking';
import { sendErrorsTrackingEvent } from '@/helpers/trackingErrors/trackingErrors';

export const generateTelemetryHeader = (showTelemetry: boolean | undefined) => {
  const telemetryAkamai = {
    'Akamai-BM-Telemetry':
      isClientSide() && showTelemetry ? window.bmak?.get_telemetry() : '',
  };

  if (isClientSide() && !window.bmak?.get_telemetry) {
    trackingMessageError({
      message: 'Miss Telemetry',
      flow: 'akamai_flow',
      checkpoint: 'akamai_error',
      namespace: 'akamai()',
    });

    sendErrorsTrackingEvent({
      component: Components.Request,
      originEvent: Events.Akamai,
      errorMessage: 'non_sdk',
      errorCode: 0,
    });
  }

  return isClientSide() && window.bmak && showTelemetry ? telemetryAkamai : {};
};
