import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { LabelsType } from '@/common/patterns/Header/Header.types';
import { useDebouncedFn } from '@/hooks/useDebouncedFn';

type MenuContextData = {
  isHovering: boolean;
  categoryStack: string[];
  pushCategoryStack: (category: string) => void;
  popCategoryStack: () => void;
  setHoverCategory: (isHover: boolean, category: string) => void;
  clearStack: () => void;
  labels?: LabelsType;
};

export type MenuContextProviderProps = {
  children: ReactNode;
  labels?: LabelsType;
};

export const MenuContext = createContext({} as MenuContextData);

export function MenuContextProvider({
  children,
  labels,
}: MenuContextProviderProps) {
  const [categoryStack, setCategoryStack] = useState<string[]>([]);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const clearStack = useCallback(() => {
    if (!isHovering) {
      setCategoryStack([]);
    }
  }, [isHovering]);

  const debounceShiftCategory = useDebouncedFn(clearStack, 400);

  /**
   *  This method simulate the hover action on DesktopMenu.
   *  The CategoryPanel isn't a child of CategoryMenu, so we can't use
   * the css hover selector to open or close the CategoryPanel
   *
   * @param isHover The boolean triggered on onMouseOver/ onMouseOut / blur / focusactions;
   * @param category The hovered in / out category;
   */
  const setHoverCategory = useCallback(
    (isHover, category) => {
      setIsHovering(isHover);
      if (!isHover) {
        debounceShiftCategory();
      } else {
        setCategoryStack(() => [category]);
      }
    },
    [debounceShiftCategory],
  );

  const pushCategoryStack = useCallback((category) => {
    setCategoryStack((previousCategoryStack) => {
      const newCategoryStack = [...previousCategoryStack];
      newCategoryStack.push(category);
      return newCategoryStack;
    });
  }, []);

  const popCategoryStack = useCallback(() => {
    setCategoryStack((previousCategoryStack) => {
      const newCategoryStack = [...previousCategoryStack];
      newCategoryStack.pop();
      return newCategoryStack;
    });
  }, []);

  return (
    <MenuContext.Provider
      value={{
        clearStack,
        isHovering,
        categoryStack,
        pushCategoryStack,
        popCategoryStack,
        setHoverCategory,
        labels,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}

export const useMenu = () => {
  return useContext(MenuContext);
};
