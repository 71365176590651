import { media } from '@sbf/fairplay-utils';
import styled, { css } from 'styled-components';

type SectionLinkProps = {
  padded?: boolean;
};

export const StyledNav = styled.nav`
  ${({ theme }) => css`
    list-style-type: none;
    background-color: ${theme.colors.neutral[100]};
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    ${media.greaterThan('desktop')} {
      flex-direction: column;
      align-items: start;
      margin: ${theme.spaces[0]} auto;
      flex-direction: row;
      align-items: center;
    }

    ${media.lessThan('desktop')} {
      padding: ${theme.spaces[0]} ${theme.spaces[400]};
    }
  `}
`;

export const StyledMenu = styled.li`
  ${({ theme }) => css`
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: ${theme.spaces[200]} ${theme.spaces[300]};
    white-space: nowrap;

    &.active {
      border-bottom: ${theme.borderWidth.thin} solid ${theme.colors.primary};
    }

    ${media.greaterThan('desktop')} {
      box-sizing: border-box;
      height: 60rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      background: ${theme.colors.neutral[100]};
      border-bottom: ${theme.borderWidth.thin} solid transparent;
      margin-top: ${theme.spaces[0]};
      padding: ${theme.spaces[400]} ${theme.spaces[300]};

      &:first-child {
        padding: ${theme.spaces[400]} ${theme.spaces[300]};
      }

      :hover {
        border-bottom: ${theme.borderWidth.thin} solid ${theme.colors.primary};
      }
    }
  `}
`;

export const StyledLink = styled.a<SectionLinkProps>`
  ${({ theme, padded }) => css`
    font-weight: ${theme.fontWeights.medium};
    text-decoration: none;
    font-size: ${theme.fontSizes.large};

    &,
    &:active,
    &:hover,
    &:visited {
      color: ${theme.colors.neutral[700]};
    }

    &.focus-visible {
      border-radius: ${theme.borderRadius.none};
      z-index: ${theme.zIndex[100]};
    }

    ${!padded &&
    css`
      padding: ${theme.spaces[0]};
    `}
  `}
`;
