import { CustomizationType } from '@/modules/guestCheckout/types/cart';

export type CartSummaryValues = {
  promocode: string;
  cartValue: number;
  discount: number;
  freight: {
    zipCode?: string;
    value?: number;
  };
};

export type SelectedSize = {
  sku: string;
  name: string;
  customization?: CustomizationType;
};

export const CHECKOUT_PLATFORM_LABEL = 'checkout_platform';
