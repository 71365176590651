import { ArrowLeft, ArrowRight } from '@sbf/fairplay-icons';
import Link from 'next/link';
import StaticImage from '@/components/Image/StaticImage';
import { useTrackingContext } from '@/contexts/TrackingContext';
import {
  type SelectContentObject,
  sendSelectContentEvents,
} from '@/helpers/analytics';
import {
  Arrow,
  HighlightText,
  MainSection,
  Slide,
  SlideTextContent,
  Slider,
  SliderWrapper,
  StyledLink,
  Text,
} from './PitchBar.styled';
import { PitchBarContent } from './PitchBar.types';
import { useSlider } from './useSlider';

interface PitchBarProps {
  content: PitchBarContent;
}

export const PitchBar = ({ content }: PitchBarProps) => {
  const { handleEvents } = useTrackingContext();
  const {
    currentSlideIndex,
    goToNextSlide,
    goToPreviousSlide,
    startInterval,
    stopInterval,
  } = useSlider({ numberOfSlides: content.length });

  return (
    <SliderWrapper>
      <Slider>
        <Arrow
          $left
          title="Voltar para o item anterior"
          icon={<ArrowLeft />}
          onClick={() => {
            handleEvents<SelectContentObject>(sendSelectContentEvents, {
              component: 'pitchbar',
              microComponent: 'seta anterior',
              anchorText: `posição: 0`,
              title: `${content[0].highlight} ${content[0].text} ${content[0].cta}`,
              url: '',
              contentType: 'arrow_pitchbar',
            });
            goToPreviousSlide();
          }}
        />

        <MainSection $translate={-currentSlideIndex * 100}>
          {content.map((c, index) => (
            <Slide
              key={index}
              onFocus={() => stopInterval()}
              onBlur={() => startInterval()}
              data-testid="pitchbar-slide"
            >
              <StaticImage
                src={c.icon}
                alt="Nike"
                width="30rem"
                height="30rem"
                disablePlaceholder
              />

              <SlideTextContent>
                <HighlightText>{c.highlight}</HighlightText>
                <Text dangerouslySetInnerHTML={{ __html: c.text }}></Text>
                <Link href={c.href} prefetch={false} passHref legacyBehavior>
                  <StyledLink tabIndex={index === currentSlideIndex ? 0 : -1}>
                    {c.cta}
                  </StyledLink>
                </Link>
              </SlideTextContent>
            </Slide>
          ))}
        </MainSection>

        <Arrow
          $right
          title="Ir para o próximo item"
          icon={<ArrowRight />}
          onClick={() => {
            handleEvents<SelectContentObject>(sendSelectContentEvents, {
              component: 'pitchbar',
              microComponent: 'seta posterior',
              anchorText: `posição: 0`,
              title: `${content[0].highlight} ${content[0].text} ${content[0].cta}`,
              url: '',
              contentType: 'arrow_pitchbar',
            });
            goToNextSlide();
          }}
        ></Arrow>
      </Slider>
    </SliderWrapper>
  );
};
