import { clientBFF } from '@/common/services/api/clientBFF/clientBFF';
import { CouponLink } from '../types';

const COUPON_LINK_BFF = '/coupon/pricing';

export const getCouponPrices = async (
  products: string[],
  couponId?: string,
) => {
  if (!couponId) {
    return null;
  }

  const response = await clientBFF.get<CouponLink[] | null>(COUPON_LINK_BFF, {
    params: { couponId, skus: products.join(',') },
  });

  return response.data;
};
