import configPino, { Logger } from 'pino';
import logLevelData from './log-level';

const logLevels = new Map<string, string>(Object.entries(logLevelData));

export function getLogLevel(logger: string): string {
  return logLevels.get(logger) || logLevels.get('*') || 'info';
}

export function getLogger(name: string): Logger {
  return configPino({ name, level: getLogLevel(name) });
}
