import { LogLevelEnum } from '@grupo-sbf/experiment-react';
import { Components, Events } from '@/common/types/tracking';
import { sendErrorEvent } from '@/modules/checkout/tracking/error/error';

export const LogLevelMap = {
  NOTSET: 0,
  DEBUG: 1,
  INFO: 2,
  WARN: 3,
  ERROR: 4,
};

export const customLogger = (level: LogLevelEnum, message: string) => {
  if (level === LogLevelMap.ERROR) {
    sendErrorEvent({
      component: Components.Experiment,
      errorMessage: message,
      originEvent: Events.Experiment,
      errorCode: 0,
    });
  }
};
