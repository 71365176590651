import { isServerSide } from '@/common/utils/environment/environment';
import { trackingEvent } from '@/common/utils/tracking/tracking';
import {
  TrackingUserData,
  TrackingUtmObject,
} from '@/contexts/TrackingContext';
import { push } from './dataLayer';
import { getUrlType } from './urlType';
type GTagEvent = {
  action: string;
  category: string;
  label: string;
  value?: number;
  nonInteraction?: boolean;
};

export type LoggedUserData = {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: {
    fixo: {
      ddd: string;
      number: string;
    };
    mobile: {
      ddd: string;
      number: string;
    };
  };
  home_address: {
    street: string;
    postal_code: string;
    country: string;
    city: string;
    region: string;
  };
};

export type SelectContentObject = {
  component: string;
  microComponent: string;
  anchorText: string;
  title?: string;
  url: string;
  contentType: string;
  hierarchy?: string;
  menuOrder?: number;
};

export type PageViewObject = {
  url: string;
  referrer: string;
  user: TrackingUserData;
  isMobile: boolean;
  utmValues: TrackingUtmObject | undefined;
  anonymousId: string | null;
};

export type VideoEventProps = {
  component: string;
  microComponent: string;
  interactionType: string;
  attribute?: string;
  homePosition?: string;
};

export const sendEvent = ({
  action,
  category,
  label,
  value = 0,
  nonInteraction = false,
}: GTagEvent) => {
  if (isServerSide() || !window.gtag) {
    return;
  }

  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
    non_interaction: nonInteraction,
  });
};

export const formatToSnakeCase = (text: string | number | null = '') => {
  if (!text || typeof text === 'number') {
    return '';
  }
  const formatedText = text?.replaceAll(/ /g, '_').toLowerCase() ?? '';
  return formatedText;
};

export const sendPageViewEvent = ({
  url,
  referrer,
  user,
  isMobile,
  utmValues,
  anonymousId,
}: PageViewObject) => {
  const transformedUser = {
    ...user,
    id: user.encryptedUserId || undefined,
  };
  delete transformedUser.encryptedUserId;

  const eventObj = {
    event: 'pageView',
    page_type: getUrlType(url),
    timestamp: new Date(),
    referrer: referrer,
    platform: isMobile ? 'web_mobile' : 'web_browser',
    anonymous_id: anonymousId ? anonymousId : '',
    site_version: process.env.NEXT_PUBLIC_APP_VERSION || 'development',
    user: transformedUser,
    ...utmValues,
  };

  push(eventObj);
};

export const sendSelectContentEvents = ({
  component,
  microComponent,
  anchorText,
  title = anchorText,
  url,
  contentType,
  hierarchy,
  menuOrder,
}: SelectContentObject) => {
  const eventObj = {
    event_type: 'click',
    timestamp: new Date(),
    event: 'select_content',
    component: component,
    micro_component: microComponent,
    link: {
      anchor_text: formatToSnakeCase(anchorText),
      rel: '',
      title: formatToSnakeCase(title),
      href: url,
      ...(menuOrder && hierarchy
        ? { hierarchy: formatToSnakeCase(hierarchy), menu_order: menuOrder }
        : undefined),
    },
    content_type: contentType,
    content_id: formatToSnakeCase(
      `${component}_${microComponent}_${anchorText}`,
    ),
  };
  trackingEvent({ link: null });
  trackingEvent(eventObj);
};

export const sendInteractionVideo = ({
  component,
  microComponent,
  interactionType,
  attribute,
  homePosition,
}: VideoEventProps) => {
  const eventObj = {
    event: 'video_interaction',
    component,
    micro_component: microComponent,
    homePosition,
    interaction_type: interactionType,
    attribute,
  };
  push(eventObj);
};
