import {
  DesktopView,
  MobileView,
} from '@/common/components/Breakpoints/Breakpoints';
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import { useMenuData } from './provider/MenuDataProvider';

type MenuProps = {
  onClick?: () => void;
};

const Menu = ({ onClick }: MenuProps) => {
  const menuData = useMenuData();

  return (
    <>
      <DesktopView shouldRenderOnServer>
        <MenuDesktop content={menuData} />
      </DesktopView>
      <MobileView>
        <MenuMobile onClick={onClick} content={menuData} />
      </MobileView>
    </>
  );
};

export default Menu;
