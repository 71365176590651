import { Menu } from '../types';

export const newMenuMock: Menu = {
  content: [
    {
      active: true,
      order: 1,
      title: 'Lançamentos',
      link: '/nav?sorting=DescReleaseDate',
      subMenu: [
        {
          active: true,
          order: 1,
          title: 'Destaques',
          link: '/nav?sorting=DescReleaseDate',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Corinthians',
              link: '/sc/futebol-clube-corinthians-camisa-oficial',
            },
            {
              active: true,
              order: 2,
              title: 'Infinity 4',
              link: '/hotsite/infinity-4',
            },
            {
              active: true,
              order: 3,
              title: 'Metcon 9',
              link: '/hotsite/metcon-9',
            },
            {
              active: true,
              order: 4,
              title: 'Seleção Brasileira',
              link: '/sc/futebol-selecao-brasileira-feminina',
            },
            {
              active: true,
              order: 5,
              title: 'Luminous Pack',
              link: '/hotsite/luminous-pack',
            },
            {
              active: true,
              order: 6,
              title: 'Pegasus 40',
              link: '/sc/pegasus40',
            },
            {
              active: true,
              order: 7,
              title: 'Gamma Force',
              link: '/hotsite/gammaforce',
            },
            {
              active: true,
              order: 8,
              title: 'SNKRS',
              link: '/snkrs/estoque',
            },
            {
              active: true,
              order: 9,
              title: 'Air Jordan 1',
              link: '/hotsite/airjordan1',
            },
            {
              active: true,
              order: 10,
              title: 'Air Force 1',
              link: '/hotsite/airforce1',
            },
            {
              active: true,
              order: 11,
              title: 'Dunk',
              link: '/hotsite/dunks',
            },
            {
              active: true,
              order: 12,
              title: 'Air Max',
              link: '/sc/sportswear-tenis-nike-airmax',
            },
          ],
        },
        {
          active: true,
          order: 2,
          title: 'Feminino',
          link: '/nav/genero/feminino/idade/adulto?sorting=DescReleaseDate',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Calçados',
              link: '/nav/genero/feminino/idade/adulto/tipodeproduto/calcados?sorting=DescReleaseDate',
            },
            {
              active: true,
              order: 2,
              title: 'Roupas',
              link: '/nav/genero/feminino/idade/adulto/tipodeproduto/roupas?sorting=DescReleaseDate',
            },
            {
              active: true,
              order: 3,
              title: 'Acessórios',
              link: '/nav/genero/feminino/idade/adulto/tipodeproduto/acessorios?sorting=DescReleaseDate',
            },
          ],
        },
        {
          active: true,
          order: 3,
          title: 'Masculino',
          link: '/nav/genero/masculino/idade/adulto?sorting=DescReleaseDate',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Calçados',
              link: '/nav/genero/masculino/idade/adulto/tipodeproduto/calcados?sorting=DescReleaseDate',
            },
            {
              active: true,
              order: 2,
              title: 'Roupas',
              link: '/nav/genero/masculino/idade/adulto/tipodeproduto/roupas?sorting=DescReleaseDate',
            },
            {
              active: true,
              order: 3,
              title: 'Acessórios',
              link: '/nav/genero/masculino/idade/adulto/tipodeproduto/acessorios?sorting=DescReleaseDate',
            },
          ],
        },
        {
          active: true,
          order: 4,
          title: 'Infantil',
          link: '/nav/idade/infantil?sorting=DescReleaseDate',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Calçados',
              link: '/nav/idade/infantil/tipodeproduto/calcados?sorting=DescReleaseDate',
            },
            {
              active: true,
              order: 2,
              title: 'Roupas',
              link: '/nav/idade/infantil/tipodeproduto/roupas?sorting=DescReleaseDate',
            },
            {
              active: true,
              order: 3,
              title: 'Acessórios',
              link: '/nav/idade/infantil/tipodeproduto/acessorios?sorting=DescReleaseDate',
            },
          ],
        },
      ],
    },
    {
      active: true,
      order: 2,
      title: 'Ofertas',
      link: '/nav/ofertas/emoferta',
      subMenu: [
        {
          active: true,
          order: 1,
          title: 'Masculino',
          link: '/nav/genero/masculino/ofertas/emoferta',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Roupas',
              link: '/nav/genero/masculino/ofertas/emoferta/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 2,
              title: 'Calçados',
              link: '/nav/genero/masculino/ofertas/emoferta/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 3,
              title: 'Acessórios',
              link: '/nav/genero/masculino/ofertas/emoferta/tipodeproduto/acessorios',
            },
          ],
        },
        {
          active: true,
          order: 2,
          title: 'Feminino',
          link: '/nav/genero/feminino/ofertas/emoferta',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Roupas',
              link: '/nav/genero/feminino/ofertas/emoferta/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 2,
              title: 'Calçados',
              link: '/nav/genero/feminino/ofertas/emoferta/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 3,
              title: 'Acessórios',
              link: '/nav/genero/feminino/ofertas/emoferta/tipodeproduto/acessorios',
            },
          ],
        },
        {
          active: true,
          order: 3,
          title: 'Infantil',
          link: '/nav/idade/infantil/ofertas/emoferta',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Roupas',
              link: '/nav/idade/infantil/ofertas/emoferta/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 2,
              title: 'Calçados',
              link: '/nav/idade/infantil/ofertas/emoferta/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 3,
              title: 'Acessórios',
              link: '/nav/idade/infantil/ofertas/emoferta/tipodeproduto/acessorios',
            },
          ],
        },
        {
          active: true,
          order: 4,
          title: 'Navegue por Preço',
          link: '/nav/ofertas/emoferta',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Ofertas até R$99,99',
              link: '/nav/ofertas/emoferta/preco/ater100',
            },
            {
              active: true,
              order: 2,
              title: 'Ofertas de R$100 a R$299,99',
              link: '/nav/ofertas/emoferta/preco/r100r200/preco/r200r300',
            },
            {
              active: true,
              order: 3,
              title: 'Ofertas de R$300 a R$499,99',
              link: '/nav/ofertas/emoferta/preco/r300r400/preco/r400r500',
            },
            {
              active: true,
              order: 4,
              title: 'Ofertas acima de R$500,00',
              link: '/nav/ofertas/emoferta/preco/acimader500',
            },
          ],
        },
      ],
    },
    {
      active: true,
      order: 3,
      title: 'Masculino',
      link: '/sc/masculino-1',
      subMenu: [
        {
          active: true,
          order: 1,
          title: 'Calçados',
          link: '/nav/genero/masculino/idade/adulto/tipodeproduto/calcados',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Tênis',
              link: '/nav/categorias/tenis/genero/masculino/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 2,
              title: 'Casual',
              link: '/nav/esportes/casual/genero/masculino/idade/adulto/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 3,
              title: 'Corrida',
              link: '/nav/esportes/corrida/genero/masculino/idade/adulto/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 4,
              title: 'Chuteiras',
              link: '/nav/categorias/chuteiras/genero/masculino/idade/adulto/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 5,
              title: 'Basquete',
              link: '/nav/esportes/basquete/genero/masculino/idade/adulto/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 6,
              title: 'Treino & Academia',
              link: '/nav/esportes/treinoacademia/genero/masculino/idade/adulto/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 7,
              title: 'Skateboarding',
              link: '/nav/esportes/skateboarding/genero/masculino/idade/adulto/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 8,
              title: 'Para Jogar Tênis',
              link: '/nav/esportes/parajogartenis/genero/masculino/idade/adulto/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 9,
              title: 'Encontre seu Tênis de Corrida',
              link: '/sc/encontre-seu-tenis',
            },
          ],
        },
        {
          active: true,
          order: 2,
          title: 'Roupas',
          link: '/nav/genero/masculino/idade/adulto/tipodeproduto/roupas',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Camisetas',
              link: '/nav/categorias/camisetas/genero/masculino/idade/adulto/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 2,
              title: 'Shorts',
              link: '/nav/categorias/shorts/genero/masculino/idade/adulto/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 3,
              title: 'Camisas de Times',
              link: '/nav/genero/masculino/idade/adulto/tipodecamiseta/camisasdetimes/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 4,
              title: 'Jaquetas & Moletons',
              link: '/nav/categorias/jaquetasmoletons/genero/masculino/idade/adulto/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 5,
              title: 'Corta Vento',
              link: '/nav/genero/masculino/idade/adulto/tipodejaquetasmoletons/cortavento/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 6,
              title: 'Calças & Leggings',
              link: '/nav/categorias/calcasleggings/genero/masculino/idade/adulto/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 7,
              title: 'Polos',
              link: '/nav/genero/masculino/idade/adulto/tipodecamiseta/polos/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 8,
              title: 'Segunda Pele & Compressão',
              link: '/nav/categorias/segundapelecompressao/genero/masculino/idade/adulto/tipodeproduto/roupas',
            },
          ],
        },
        {
          active: true,
          order: 3,
          title: 'Acessórios',
          link: '/nav/genero/masculino/idade/adulto/tipodeproduto/acessorios',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Bonés & Viseiras',
              link: '/nav/categorias/bonesviseiras/genero/masculino/idade/adulto/tipodeproduto/acessorios',
            },
            {
              active: true,
              order: 2,
              title: 'Bolsas & Mochilas',
              link: '/nav/categorias/bolsasmochilas/genero/masculino/idade/adulto/tipodeproduto/acessorios',
            },
            {
              active: true,
              order: 3,
              title: 'Bolas',
              link: '/nav/categorias/bolas/tipodeproduto/acessorios',
            },
            {
              active: true,
              order: 4,
              title: 'Meias',
              link: '/nav/categorias/meias/genero/masculino/idade/adulto/tipodeproduto/acessorios',
            },
          ],
        },
        {
          active: true,
          order: 4,
          title: 'Esportes',
          link: '/nav/genero/masculino/idade/adulto/',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Corrida',
              link: '/sc/corrida',
            },
            {
              active: true,
              order: 2,
              title: 'Futebol',
              link: '/sc/futebol',
            },
            {
              active: true,
              order: 3,
              title: 'Treino & Academia',
              link: '/nav/esportes/treinoacademia/genero/masculino/idade/adulto/',
            },
            {
              active: true,
              order: 4,
              title: 'Basquete',
              link: '/nav/esportes/basquete/genero/masculino/idade/adulto/',
            },
            {
              active: true,
              order: 5,
              title: 'Skateboarding',
              link: '/nav/esportes/skateboarding/genero/masculino/idade/adulto/',
            },
            {
              active: true,
              order: 6,
              title: 'Natação',
              link: '/nav/esportes/natacaopraia/genero/masculino/idade/adulto/',
            },
            {
              active: true,
              order: 7,
              title: 'Para Jogar Tênis',
              link: '/nav/esportes/parajogartenis/genero/masculino/idade/adulto/',
            },
            {
              active: true,
              order: 8,
              title: 'Yoga',
              link: '/nav/esportes/yoga/genero/masculino/idade/adulto/',
            },
          ],
        },
        {
          active: true,
          order: 5,
          title: 'Marcas',
          link: '/nav/genero/masculino/idade/adulto/',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Nike Sportswear',
              link: '/nav/genero/masculino/idade/adulto/marca/nikesportswear',
            },
            {
              active: true,
              order: 2,
              title: 'Jordan',
              link: '/nav/genero/masculino/idade/adulto/marca/jordan',
            },
          ],
        },
        {
          active: true,
          order: 6,
          title: 'Guias',
          link: '/sc/encontre-seu-tenis',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Encontre seu Tênis de Corrida',
              link: '/sc/encontre-seu-tenis',
            },
          ],
        },
        {
          active: true,
          order: 7,
          title: 'Coleções',
          link: '#',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Corrida em Trilha',
              link: '/hotsite/trail-165',
            },
            {
              active: true,
              order: 2,
              title: 'NBA',
              link: '/hotsite/nba',
            },
            {
              active: true,
              order: 3,
              title: 'ACG',
              link: '/hotsite/acg-221',
            },
            {
              active: true,
              order: 4,
              title: 'Air Max',
              link: '/sc/sportswear-tenis-nike-airmax',
            },
            {
              active: true,
              order: 5,
              title: 'Seleções Nike',
              link: '/hotsite/selecoes?fields=genero%3Amasculino',
            },
          ],
        },
        {
          active: true,
          order: 8,
          title: 'Ofertas',
          link: '/nav/genero/masculino/idade/adulto/ofertas/emoferta',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Calçados',
              link: '/nav/genero/masculino/idade/adulto/ofertas/emoferta/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 2,
              title: 'Roupas',
              link: '/nav/genero/masculino/idade/adulto/ofertas/emoferta/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 3,
              title: 'Acessórios',
              link: '/nav/genero/masculino/idade/adulto/ofertas/emoferta/tipodeproduto/acessorios',
            },
          ],
        },
      ],
    },
    {
      active: true,
      order: 4,
      title: 'Feminino',
      link: '/sc/feminino-1',
      subMenu: [
        {
          active: true,
          order: 1,
          title: 'Calçados',
          link: '/nav/genero/feminino/idade/adulto/tipodeproduto/calcados',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Tênis',
              link: '/nav/categorias/tenis/genero/feminino/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 2,
              title: 'Casual',
              link: '/nav/esportes/casual/genero/feminino/idade/adulto/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 3,
              title: 'Corrida',
              link: '/nav/esportes/corrida/genero/feminino/idade/adulto/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 4,
              title: 'Treino & Academia',
              link: '/nav/esportes/treinoacademia/genero/feminino/idade/adulto/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 5,
              title: 'Chuteiras',
              link: '/nav/categorias/chuteiras/genero/feminino/idade/adulto/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 6,
              title: 'Para Jogar Tênis',
              link: '/nav/esportes/parajogartenis/genero/feminino/idade/adulto/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 7,
              title: 'Skateboarding',
              link: '/nav/esportes/skateboarding/genero/feminino/idade/adulto/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 8,
              title: 'Encontre seu Tênis de Corrida',
              link: '/sc/encontre-seu-tenis',
            },
          ],
        },
        {
          active: true,
          order: 2,
          title: 'Roupas',
          link: '/nav/genero/feminino/idade/adulto/tipodeproduto/roupas',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Conjuntos para treinar',
              link: '/hotsite/conjuntos',
            },
            {
              active: true,
              order: 2,
              title: 'Tops',
              link: '/nav/categorias/tops/genero/feminino/idade/adulto/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 3,
              title: 'Camisetas',
              link: '/nav/categorias/camisetas/genero/feminino/idade/adulto/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 4,
              title: 'Camisas de Times',
              link: '/nav/genero/feminino/idade/adulto/tipodecamiseta/camisasdetimes/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 5,
              title: 'Calças & Leggings',
              link: '/nav/categorias/calcasleggings/genero/feminino/idade/adulto/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 6,
              title: 'Shorts',
              link: '/nav/categorias/shorts/genero/feminino/idade/adulto/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 7,
              title: 'Jaquetas & Moletons',
              link: '/nav/categorias/jaquetasmoletons/genero/feminino/idade/adulto/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 8,
              title: 'Saias & Vestidos & Macacões',
              link: '/nav/categorias/saiasvestidosmacacoes/genero/feminino/idade/adulto/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 9,
              title: 'Segunda Pele & Compressão',
              link: '/nav/categorias/segundapelecompressao/genero/feminino/idade/adulto/tipodeproduto/roupas',
            },
          ],
        },
        {
          active: true,
          order: 3,
          title: 'Acessórios',
          link: '/nav/genero/feminino/idade/adulto/tipodeproduto/acessorios',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Bonés & Viseiras',
              link: '/nav/categorias/bonesviseiras/genero/feminino/idade/adulto/tipodeproduto/acessorios',
            },
            {
              active: true,
              order: 2,
              title: 'Bolsas & Mochilas',
              link: '/nav/categorias/bolsasmochilas/genero/feminino/idade/adulto/tipodeproduto/acessorios',
            },
            {
              active: true,
              order: 3,
              title: 'Bolas',
              link: '/nav/categorias/bolas/tipodeproduto/acessorios',
            },
            {
              active: true,
              order: 4,
              title: 'Meias',
              link: '/nav/categorias/meias/genero/feminino/idade/adulto/tipodeproduto/acessorios',
            },
          ],
        },
        {
          active: true,
          order: 4,
          title: 'Esportes',
          link: '/nav/genero/feminino/idade/adulto',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Corrida',
              link: '/sc/corrida',
            },
            {
              active: true,
              order: 2,
              title: 'Treino & Academia',
              link: '/nav/esportes/treinoacademia/genero/feminino/idade/adulto',
            },
            {
              active: true,
              order: 3,
              title: 'Yoga',
              link: '/nav/esportes/yoga/genero/feminino/idade/adulto',
            },
            {
              active: true,
              order: 4,
              title: 'Futebol',
              link: '/sc/futebol',
            },
            {
              active: true,
              order: 5,
              title: 'Para Jogar Tênis',
              link: '/nav/esportes/parajogartenis/genero/feminino/idade/adulto',
            },
            {
              active: true,
              order: 6,
              title: 'Basquete',
              link: '/nav/esportes/basquete/genero/feminino/idade/adulto',
            },
            {
              active: true,
              order: 7,
              title: 'Skateboarding',
              link: '/nav/esportes/skateboarding/genero/feminino/idade/adulto',
            },
            {
              active: true,
              order: 8,
              title: 'Natação',
              link: '/nav/esportes/natacaopraia/genero/feminino/idade/adulto',
            },
          ],
        },
        {
          active: true,
          order: 5,
          title: 'Marcas',
          link: '/nav/genero/feminino/idade/adulto',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Nike Sportswear',
              link: '/nav/genero/feminino/idade/adulto/marca/nikesportswear',
            },
            {
              active: true,
              order: 2,
              title: 'Jordan',
              link: '/nav/genero/feminino/idade/adulto/marca/jordan',
            },
          ],
        },
        {
          active: true,
          order: 6,
          title: 'Guias',
          link: '/sc/encontre-seu-tenis',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Encontre seu Tênis de Corrida',
              link: '/sc/encontre-seu-tenis',
            },
            {
              active: true,
              order: 2,
              title: 'Guia de Tops',
              link: '/sc/guia-de-tops',
            },
          ],
        },
        {
          active: true,
          order: 7,
          title: 'Coleções',
          link: '/nav/genero/feminino/idade/adulto',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'NBA',
              link: '/hotsite/nba?fields=genero%3Afeminino',
            },
            {
              active: true,
              order: 2,
              title: 'ACG',
              link: '/hotsite/acg-221?fields=genero%3Aunissex&fields=genero%3Afeminino',
            },
            {
              active: true,
              order: 3,
              title: 'Air Max',
              link: '/sc/sportswear-tenis-nike-airmax',
            },
            {
              active: true,
              order: 4,
              title: 'Seleções Nike',
              link: '/hotsite/selecoes?fields=genero%3Afeminino',
            },
          ],
        },
        {
          active: true,
          order: 8,
          title: 'Ofertas',
          link: '/nav/genero/feminino/idade/adulto/ofertas/emoferta',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Calçados',
              link: '/nav/genero/feminino/idade/adulto/ofertas/emoferta/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 2,
              title: 'Roupas',
              link: '/nav/genero/feminino/idade/adulto/ofertas/emoferta/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 3,
              title: 'Acessórios',
              link: '/nav/genero/feminino/idade/adulto/ofertas/emoferta/tipodeproduto/acessorios',
            },
          ],
        },
      ],
    },
    {
      active: true,
      order: 5,
      title: 'Infantil',
      link: '/nav/idade/infantil',
      subMenu: [
        {
          active: true,
          order: 1,
          title: 'Calçados',
          link: '/nav/idade/infantil/tipodeproduto/calcados',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Pré-Adolescentes (34-38)',
              link: '/nav/idade/infantil/tamanho/34/tamanho/3436/tamanho/345/tamanho/34536/tamanho/35/tamanho/355/tamanho/36/tamanho/3639/tamanho/36539/tamanho/37/tamanho/375/tamanho/38/tamanho/f34/tamanho/f345/tamanho/f355/tamanho/f36/tamanho/f37/tamanho/f375/tamanho/f38/tamanho/m34/tamanho/m345/tamanho/m35/tamanho/m355/tamanho/m36/tamanho/m37/tamanho/m375/tamanho/m38/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 2,
              title: 'Crianças (24-33)',
              link: '/nav/idade/infantil/tamanho/24/tamanho/25/tamanho/26/tamanho/27/tamanho/28/tamanho/285/tamanho/29/tamanho/30/tamanho/31/tamanho/32/tamanho/325/tamanho/33/tamanho/335/tamanho/f33/tamanho/f335/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 3,
              title: 'Bebês (16-26)',
              link: '/nav/idade/infantil/tamanho/16/tamanho/175/tamanho/185/tamanho/20/tamanho/21/tamanho/22/tamanho/225/tamanho/24/tamanho/25/tamanho/26/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 4,
              title: 'Casual',
              link: '/nav/esportes/casual/idade/infantil/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 5,
              title: 'Corrida',
              link: '/nav/esportes/corrida/idade/infantil/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 6,
              title: 'Chuteiras',
              link: '/nav/categorias/chuteiras/idade/infantil/tipodeproduto/calcados',
            },
          ],
        },
        {
          active: true,
          order: 2,
          title: 'Roupas',
          link: '/nav/idade/infantil/tipodeproduto/roupas',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Camisetas',
              link: '/nav/categorias/camisetas/idade/infantil/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 2,
              title: 'Camisas de Times',
              link: '/nav/idade/infantil/tipodecamiseta/camisasdetimes/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 3,
              title: 'Shorts',
              link: '/nav/categorias/shorts/idade/infantil/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 4,
              title: 'Calças & Leggings',
              link: '/nav/categorias/calcasleggings/idade/infantil/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 5,
              title: 'Jaquetas & Moletons',
              link: '/nav/categorias/jaquetasmoletons/idade/infantil/tipodeproduto/roupas',
            },
          ],
        },
        {
          active: true,
          order: 3,
          title: 'Acessórios',
          link: '/nav/idade/infantil/tipodeproduto/acessorios',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Bolsas & Mochilas',
              link: '/nav/categorias/bolsasmochilas/idade/infantil/tipodeproduto/acessorios',
            },
            {
              active: true,
              order: 2,
              title: 'Meias',
              link: '/nav/categorias/meias/idade/infantil/tipodeproduto/acessorios',
            },
            {
              active: true,
              order: 3,
              title: 'Bonés & Viseiras',
              link: '/nav/categorias/bonesviseiras/idade/infantil/tipodeproduto/acessorios',
            },
            {
              active: true,
              order: 4,
              title: 'Bolas',
              link: '/nav/categorias/bolas/tipodeproduto/acessorios',
            },
          ],
        },
        {
          active: true,
          order: 4,
          title: 'Esportes',
          link: '/nav/idade/infantil',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Futebol',
              link: '/nav/esportes/futebol/idade/infantil',
            },
            {
              active: true,
              order: 2,
              title: 'Corrida',
              link: '/nav/esportes/corrida/idade/infantil',
            },
            {
              active: true,
              order: 3,
              title: 'Casual',
              link: '/nav/esportes/casual/idade/infantil',
            },
            {
              active: true,
              order: 4,
              title: 'Treino & Academia',
              link: '/nav/esportes/treinoacademia/idade/infantil',
            },
            {
              active: true,
              order: 5,
              title: 'Basquete',
              link: '/nav/esportes/basquete/idade/infantil?sorting=DescReleaseDate',
            },
            {
              active: true,
              order: 6,
              title: 'Natação',
              link: '/nav/esportes/natacaopraia/idade/infantil',
            },
            {
              active: true,
              order: 7,
              title: 'Para Jogar Tênis',
              link: '/nav/esportes/parajogartenis/idade/infantil',
            },
          ],
        },
        {
          active: true,
          order: 5,
          title: 'Ofertas',
          link: '/nav/idade/infantil/ofertas/emoferta',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Calçados',
              link: '/nav/idade/infantil/ofertas/emoferta/tipodeproduto/calcados',
            },
            {
              active: true,
              order: 2,
              title: 'Roupas',
              link: '/nav/idade/infantil/ofertas/emoferta/tipodeproduto/roupas',
            },
            {
              active: true,
              order: 3,
              title: 'Acessórios',
              link: '/nav/idade/infantil/ofertas/emoferta/tipodeproduto/acessorios',
            },
          ],
        },
        {
          active: true,
          order: 6,
          title: 'Nikeland',
          link: '/sc/infantil-nikeland-roblox',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Roblox',
              link: '/sc/infantil-nikeland-roblox',
            },
          ],
        },
      ],
    },
    {
      active: true,
      order: 6,
      title: 'SNKRS',
      link: '/snkrs',
      subMenu: [
        {
          active: true,
          order: 1,
          title: 'SNKRS',
          link: '/snkrs',
          subMenu: [
            {
              active: true,
              order: 1,
              title: 'Feed',
              link: '/snkrs',
            },
            {
              active: true,
              order: 2,
              title: 'Em estoque',
              link: '/snkrs/estoque',
            },
            {
              active: true,
              order: 3,
              title: 'Calendário',
              link: '/snkrs/calendario',
            },
            {
              active: true,
              order: 4,
              title: 'O que é SNKRS?',
              link: '/sc/sneakers',
            },
          ],
        },
      ],
    },
  ],
};
