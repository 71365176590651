import { useMemo } from 'react';
import { useCart } from '@/modules/guestCheckout/services/cart/cart';
import { itemsMapper } from '@/modules/guestCheckout/transformers/product';
export const useCartItems = () => {
  const { data: cartData, isLoading } = useCart();
  const items = useMemo(() => {
    return itemsMapper(cartData?.items || []);
  }, [cartData]);

  return { items, isLoading };
};

export default useCartItems;
