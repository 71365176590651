var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"v10-447-0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/v10-447-0";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import {
  captureConsoleIntegration,
  extraErrorDataIntegration,
} from '@sentry/integrations';
import {
  BrowserProfilingIntegration,
  browserTracingIntegration,
  init,
  replayIntegration,
} from '@sentry/nextjs';
import { isProdEnv } from '@/common/utils/environment/environment';
import SentryConfig from './sentry.default.config';

const {
  ignoreErrors,
  denyUrls,
  tracesSampler,
  beforeSend,
  release,
  environment,
} = SentryConfig;

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

init({
  dsn: SENTRY_DSN,
  tracesSampleRate: isProdEnv ? 0.02 : 1.0,
  environment,
  ignoreErrors,
  beforeSend,
  release,
  denyUrls,
  tracesSampler,
  replaysSessionSampleRate: isProdEnv ? 0 : 1.0,
  replaysOnErrorSampleRate: 0.01,
  tracePropagationTargets: ['localhost', /^https:\/\/nike\.com.br/],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  integrations: [
    browserTracingIntegration({
      enableInp: true,
      shouldCreateSpanForRequest: (url) => {
        return !url.match(/linximpulse/);
      },
    }),
    extraErrorDataIntegration(),
    BrowserProfilingIntegration(),
    captureConsoleIntegration({
      levels: ['error'],
    }),
    replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});
