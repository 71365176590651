import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { encrypt } from '@/helpers/Cryptography';
import { Facet, Pagination } from '@/modules/grid/services/search/search.types';
import { GetRegistrationResponse } from '@/modules/myAccount/components/Registration/Registration.types';

export const TrackingUserDataEmptyState = {
  id: undefined,
  email: undefined,
  nome: undefined,
  tipoCliente: undefined,
  sobrenome: undefined,
  endereco: {
    logradouro: undefined,
    cep: undefined,
    pais: undefined,
    cidade: undefined,
    telefoneFixo: {
      ddd: undefined,
      numero: undefined,
    },
    telefoneAdicional: {
      ddd: undefined,
      numero: undefined,
    },
  },
};

const STORAGE_UTM_KEY = 'tracking-utm';

export type TrackingUserData = {
  id?: string;
  encryptedUserId?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  home_address: {
    street?: string;
    postal_code?: string;
    country?: string;
    city?: string;
    region?: string;
  };
  phone_number?: string;
};

export type TrackingUtmObject = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  utm_id?: string;
  utm_referrer?: string;
  gclid?: string;
  fbclid?: string;
};

export type TrackingPagination = Omit<Pagination, 'current'> & {
  current: number;
};
export type NavTrackingData = {
  pagination: TrackingPagination;
  filters: Facet[];
};

export type GenericFunction<T> = (arg: T) => void;

type TrackingContextProps = {
  userData?: TrackingUserData;
  setUserData: (data?: GetRegistrationResponse) => void;
  utmValues?: TrackingUtmObject;
  getUtmValues: () => TrackingUtmObject;
  setUtmValues: (utms?: TrackingUtmObject) => void;
  viewCount?: number;
  setViewCount: (value: number) => void;
  labelPwh?: string;
  setLabelPwh: (value: string | undefined) => void;
  handleEvents: <ParamType>(
    functionToSend: GenericFunction<ParamType>,
    dataObject: ParamType,
  ) => void;
  navData?: NavTrackingData;
  setNavData: (pagination: TrackingPagination, filters: Facet[]) => void;
  originSideBarCartClick: string;
  setOriginSideBarCartClick: (origin: string, pathname: string) => void;
  setSearchId: (uuid: string) => void;
  searchId?: string;
  searchList: string[];
  cleanSearchIdList: () => void;
};

export type TrackingContextPropsProviderProps = {
  children: ReactNode;
};

export const TrackingContext = createContext({} as TrackingContextProps);

const TrackingContextProvider = ({
  children,
}: TrackingContextPropsProviderProps) => {
  const [userData, setUserData] = useState<TrackingUserData>();
  const [utmValues, setUtmValues] = useState<TrackingUtmObject>();
  const [viewCount, setViewCount] = useState<number>();
  const [searchId, setSearchId] = useState<string>();
  const [searchList, setSearchList] = useState<string[]>([]);
  const [labelPwh, setLabelPwh] = useState<string>();
  const [navData, setNavData] = useState<NavTrackingData>();
  const [originSideBarCartClick, setOriginSideBarCartClick] =
    useState<string>('');

  const getPhoneNumber = (
    dddPhone?: string,
    numberPhone?: string,
    dddMobile?: string,
    numberMobile?: string,
  ) => {
    if (dddPhone && numberPhone) {
      return `${dddPhone}${numberPhone}`;
    }

    if (dddMobile && numberMobile) {
      return `${dddMobile}${numberMobile}`;
    }

    return undefined;
  };

  const handleUserData = useCallback(async (data?: GetRegistrationResponse) => {
    const { endereco, nome, sobrenome, email, id } =
      data || TrackingUserDataEmptyState;

    const encryptedUserId = id ? await encrypt(id) : undefined;

    setUserData({
      id: id ? String(id) : undefined,
      encryptedUserId,
      email: email,
      first_name: nome,
      last_name: sobrenome,
      home_address: {
        street: endereco?.logradouro,
        postal_code: endereco?.cep,
        country: endereco?.pais,
        city: endereco?.cidade,
        region: undefined,
      },
      phone_number: getPhoneNumber(
        endereco?.telefoneFixo?.ddd,
        endereco?.telefoneFixo?.numero,
        endereco?.telefoneAdicional?.ddd,
        endereco?.telefoneAdicional?.numero,
      ),
    });
  }, []);

  const getUtmValues = useCallback(
    () => JSON.parse(window.sessionStorage.getItem(STORAGE_UTM_KEY) || '{}'),
    [],
  );

  const handleSearchId = useCallback(
    (uuid: string) => {
      setSearchId(uuid);

      setSearchList([...searchList, uuid]);
    },
    [searchList],
  );

  const handleCleanSearchId = () => {
    setSearchList([]);
  };

  const handleUtmValues = useCallback((utms?: TrackingUtmObject) => {
    const {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
      utm_id,
      utm_referrer,
      gclid,
      fbclid,
    } = utms || {};

    const data = {
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
      utm_term: utm_term,
      utm_content: utm_content,
      utm_id: utm_id,
      utm_referrer: utm_referrer,
      gclid: gclid,
      fbclid: fbclid,
    };

    window.sessionStorage.setItem(STORAGE_UTM_KEY, JSON.stringify(data));
    setUtmValues(data);
  }, []);

  const handleViewCount = useCallback((value: number) => {
    const viewCount = value + 1 || 0;

    setViewCount(viewCount);
  }, []);

  const handleOriginSideBartCart = useCallback(
    (origin: string, pathname: string) => {
      pathname === '/'
        ? setOriginSideBarCartClick('menu')
        : setOriginSideBarCartClick(origin);
    },
    [],
  );

  const handleLabelPwh = useCallback((value?: string) => {
    setLabelPwh(value);
  }, []);

  const handleNavData = useCallback(
    (pagination: TrackingPagination, filters: Facet[]) => {
      const paginationObj = {
        first: pagination.first,
        next: pagination.next,
        last: pagination.last,
        previous: pagination.previous,
        current: pagination.current || 0,
      };

      const filtersObj = filters || [];

      setNavData({ filters: filtersObj, pagination: { ...paginationObj } });
    },
    [],
  );

  const handleEvents = useCallback((functionToSend, dataObject) => {
    functionToSend({
      ...dataObject,
    });
  }, []);

  return (
    <TrackingContext.Provider
      value={{
        userData,
        setUserData: handleUserData,
        utmValues,
        getUtmValues,
        setUtmValues: handleUtmValues,
        viewCount,
        setViewCount: handleViewCount,
        handleEvents,
        navData,
        setNavData: handleNavData,
        labelPwh,
        setLabelPwh: handleLabelPwh,
        originSideBarCartClick,
        setOriginSideBarCartClick: handleOriginSideBartCart,
        searchId,
        searchList,
        setSearchId: handleSearchId,
        cleanSearchIdList: handleCleanSearchId,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

const useTrackingContext = () => {
  return useContext(TrackingContext);
};

export { TrackingContextProvider, useTrackingContext };
