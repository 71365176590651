import { Product as CartProduct } from '@/modules/checkout/services/cart/cart.types';
import { Product as ProductPDP } from '@/modules/product/types/product';

export const KEY_CURRENT_EVENT = 'tracking-current-event';
export const KEY_MPARTICLE = 'tracking-mparticle';
export const KEY_CART_DATA = 'tracking-card-data';
export const KEY_PRODUCT = 'tracking-current-product';
export const KEY_CATEGORY = 'tracking-categories-product-list';
export const KEY_SNEAKER_FLAG = 'tracking-sneakers-flag';

export enum CheckoutEvents {
  AddToCart = 'add_to_cart',
  RemoveFromCart = 'remove_from_cart',
  BeginCheckout = 'begin_checkout',
  AddShippingInfo = 'add_shipping_info',
  AddPaymentInfo = 'add_payment_info',
  Purchase = 'purchase',
  AddCoupon = 'add_coupon',
}

export type ProductStorage = { removeAll?: boolean; selectedSku: string };
export type ProductEvent = CartProduct | ProductStorage;
export type AllProductTrackingEvent = Partial<
  CartProduct & ProductPDP & ProductStorage
>;
export type EventVariation = ProductPDP | CartProduct;

export type Categories = {
  sku: string;
  category: string | undefined;
};

export type SneakerFlag = {
  sku: string;
  exclusive: boolean | undefined;
};

export enum SNEAKER_FLAG {
  DEFAULT = 'default',
  NIKE = 'Nike',
  SNKRS = 'snkrs',
}
