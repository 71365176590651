import { getCoreConfig } from '@/common/config/config';
import cachedAxios from '@/common/services/cachedAxios/cachedAxios';
import { isProdReleaseEnv } from '@/common/utils/environment/environment';
import { trackingMessageError } from '@/common/utils/tracking/tracking';
import { newMenuMock } from '../mock/newMenuMock';
import { Menu } from '../types';
export const getMenuCdnUrl = (isProdEnv: boolean) => {
  const { publicRuntimeConfig } = getCoreConfig();
  const env = isProdEnv ? 'prd' : 'dev';

  return `${publicRuntimeConfig.pitchbarCdnPath.basePath}/web/${env}/menu/menu.json`;
};

export const getMenuData = async () => {
  const menuCdnUrl = getMenuCdnUrl(isProdReleaseEnv);

  try {
    const response = await cachedAxios.get<Menu>(menuCdnUrl);

    return response.data || newMenuMock;
  } catch {
    trackingMessageError({
      checkpoint: 'intitial_load',
      flow: 'menu()',
      message: 'failed to fetch menu data',
      namespace: 'menu',
    });
    return newMenuMock;
  }
};
