import axios, { AxiosRequestConfig } from 'axios';
import { parseCookies } from 'nookies';
import { getCoreConfig } from '@/common/config/config';
import {
  clientResponseErrors,
  handleEventsErrorFromSuccess,
  handlePreconditionRequiredError,
} from '@/common/services/api/client/errors/errors';
import { getClientHeaders } from '@/common/services/api/client/headers/headers';
import { createHttpAgentConfig } from '@/common/services/api/client/httpAgent/httpAgent';
import {
  isClientSide,
  isDevEnv,
  isProdReleaseEnv,
  isServerSide,
} from '@/common/utils/environment/environment';
import errLogObject from '@/logging/log-format';
import { getLogger } from '@/logging/log-utils';
import { getCouponLinkCookie } from '@/modules/couponLink/hooks/useAsyncCouponLink';
import { ATHLETE_ID } from '@/modules/vouchers/athleteVoucher/services/athleteVoucher/athleteVoucher';

/**
 * For the non production environment we concat a string
 * which bypasses the CORS of the API gateway
 *
 *
 * @param config the axios request config object
 * @returns the new axios request config with force cors parameter
 */
const corsRequestConfig = (config: AxiosRequestConfig) => {
  const newConfig = { ...config };

  /**
   * When the request is executed on server-side we can't includes the force-cors parameter
   * because the API gateway returns status code 400
   */

  if (isDevEnv && isClientSide()) {
    const initialCaracter = newConfig.url?.includes('?') ? '&' : '?';

    newConfig.url += `${initialCaracter}force-cors=-zY8TnWPyUU6nvQj2ABKz^3K%A@*NkbM!Q6Y83^bh9TTnHL5t@mam!$BUTn=eaZ_`;
  }

  return newConfig;
};

const { publicRuntimeConfig } = getCoreConfig();

const httpAgentConfig = createHttpAgentConfig();

export const clientGateway = axios.create({
  baseURL: publicRuntimeConfig.publicApi.core,
  ...httpAgentConfig,
});

if (isProdReleaseEnv) {
  clientGateway.defaults.withCredentials = true;
}

clientGateway.interceptors.request.use(
  async function (config) {
    const coreHeaders = await getClientHeaders();
    const athleteId = parseCookies()[ATHLETE_ID];
    const couponLinkId = getCouponLinkCookie();

    const headers = {
      ...(athleteId ? { [ATHLETE_ID]: athleteId } : {}),
      ...(couponLinkId ? { 'x-coupon-token': couponLinkId } : {}),
      ...config.headers,
      ...coreHeaders,
    };

    config.headers = headers;

    const newConfig = corsRequestConfig(config);
    return newConfig;
  },
  function (error) {
    return Promise.reject(error);
  },
);

clientGateway.interceptors.response.use(
  function (response) {
    handleEventsErrorFromSuccess(response);
    return response;
  },
  function (error) {
    handlePreconditionRequiredError(error);

    if (isServerSide()) {
      const errorLogger = getLogger('*');
      errorLogger.error(errLogObject(error));
    }

    return clientResponseErrors(error);
  },
);
