import styled, { css } from 'styled-components';
import { StyledSectionLinkProps } from './SectionLink.types';

export const SectionLink = styled.a<StyledSectionLinkProps>`
  ${({ theme, type = 'primary' }) => css`
    font-family: ${theme.fonts.primary};
    font-weight: ${theme.fontWeights.medium};
    font-size: ${theme.fontSizes.medium};
    color: ${theme.colors[type]};
    text-decoration: none;
    outline: none;
    line-height: ${theme.lineHeights.large};
    text-transform: capitalize;
    cursor: pointer;

    &.focus-visible {
      border-radius: ${theme.borderRadius.medium};
      box-shadow: ${theme.shadow.focus};
    }
  `}
`;
