import { parseCookies, setCookie } from 'nookies';
import { v4 as uuid } from 'uuid';

export const EXPERIMENT_COOKIE = 'EXPERIMENT_USER_ID';

export type ExperimentEnv = 'local' | 'prd' | 'dev' | 'test' | 'e2e';

export const experimentMap: Record<string, ExperimentEnv> = {
  local: 'local',
  development: 'dev',
  production: 'prd',
};

export const getExperimentCookie = () => {
  const userId = parseCookies()[EXPERIMENT_COOKIE] ?? uuid();
  setCookie(null, EXPERIMENT_COOKIE, userId);
  return userId;
};

export const getExperimentEnv = () => {
  const env = process.env.NEXT_PUBLIC_STORE_ENV ?? 'local';

  return experimentMap[env] ?? 'local';
};
