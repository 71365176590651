import { parseCookies } from 'nookies';

const GUEST_CHECKOUT_ID = 'enableGuest';

export const checkGuestCheckoutFlag = (showLog?: boolean) => {
  const hasGuestCheckout = parseCookies()[GUEST_CHECKOUT_ID];
  const enabledGuest = hasGuestCheckout === btoa('marcinhodavilla');
  if (enabledGuest && showLog) {
    // eslint-disable-next-line no-console
    console.log(
      `%c
      ----------------   guest checkout enabled   ---------------- `,
      'background: #222; color: #bada55; font-size: 16px;font-family: american typewriter',
    );
  }

  return enabledGuest;
};
