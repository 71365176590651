import { useEffect } from 'react';
import { isClientSide } from '@/common/utils/environment/environment';

declare global {
  interface Window {
    insider_object?: Record<string, unknown>;
    Insider?: {
      eventManager?: {
        dispatch?: (event: string) => void;
      };
    };
  }
}

/**
 * Hook that cleans up the confirmation object set by insider
 * in any page that is not the confirmation page.
 *
 * @param asPath string content from useRouter().asPath
 */
export const useInsider = (asPath: string) => {
  useEffect(() => {
    const isConfirmationPage = asPath.includes('confirmacao');

    if (isClientSide() && !isConfirmationPage) {
      window.insider_object = {
        page: {
          type: 'Other',
        },
      };
    }
  }, [asPath]);
  useEffect(() => {
    window?.Insider?.eventManager?.dispatch?.('init-manager:re-initialize');
  }, []);
};
