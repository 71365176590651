import { MouseEvent } from 'react';
import { trackingEvent } from '@/common/utils/tracking/tracking';

export const sendClickEvent = (event: MouseEvent) => {
  const text = event?.currentTarget?.textContent;
  const origin = window?.location?.pathname;

  trackingEvent({
    event: 'login',
    event_type: 'click',
    category: 'UI - Login',
    action: `Click - Botão ${text} do menu`,
    label: `Clicou no botão ${text} dentro do popover menu`,
    origin,
  });
};
