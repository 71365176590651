import { Typography } from '@sbf/fairplay-nike';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';
import styled from 'styled-components';
import Container from '@/common/components/Container/Container';
import { MAIN_CONTENT_MAX_WIDTH } from '@/common/constants/constants';
import Footer from '@/common/patterns/Footer/Footer';
import Header from '@/common/patterns/Header/Header';
import { PreviewHeader } from '@/common/patterns/Header/Header.styled';
import { HEADER_LAYOUT_ENUM } from '@/common/patterns/Header/Header.types';
import { PitchBar } from '@/common/patterns/PitchBar/PitchBar';
import { PitchBarContent } from '@/common/patterns/PitchBar/PitchBar.types';
import { assistedSaleLabels } from '@/modules/assistedSale/config/assistedSaleLabels';
import TopHeader from '@/modules/assistedSale/patterns/TopHeader/TopHeader';
import useSessionSeller from '@/modules/assistedSale/utils/useSessionSeller';
type MainLayoutPropsTypes = {
  children: ReactNode;
  fullHeight?: boolean;
  fullWidth?: boolean;
  maxWidth?: string;
  searchFilters?: boolean;
  contentOnly?: boolean;
  preview?: boolean;
  pitchBarData: PitchBarContent;
};

const LayoutContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  min-height: 100vh;
`;

const FooterWithHydrationOnDemand = withHydrationOnDemand({
  on: ['visible'],
})(Footer);

const MainLayout = ({
  children,
  maxWidth = MAIN_CONTENT_MAX_WIDTH,
  searchFilters = false,
  fullHeight = true,
  fullWidth = false,
  contentOnly = false,
  preview = false,
  pitchBarData,
}: MainLayoutPropsTypes) => {
  const { hasSessionStore } = useSessionSeller();

  const headerAssistedSalesProps = {
    prepend: <TopHeader />,
    layout: HEADER_LAYOUT_ENUM.ASSISTED_SALES,
    labels: assistedSaleLabels,
  };
  const headerPropsBase = {
    searchFilters: searchFilters,
  };

  const headerProps = hasSessionStore
    ? {
        ...headerPropsBase,
        ...headerAssistedSalesProps,
      }
    : {
        ...headerPropsBase,
      };

  const { query } = useRouter();
  const isWebview = query.source === 'app';

  const isContentOnly = isWebview || contentOnly;

  return (
    <LayoutContainer>
      {preview && (
        <PreviewHeader>
          <Typography variant="paragraph" size="medium">
            Preview mode
          </Typography>
        </PreviewHeader>
      )}
      {!isContentOnly && <Header {...headerProps} />}
      {!isContentOnly && <PitchBar content={pitchBarData} />}
      <Container
        fullHeight={fullHeight}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
      >
        {children}
      </Container>
      {!isContentOnly && <FooterWithHydrationOnDemand />}
    </LayoutContainer>
  );
};

export default MainLayout;
