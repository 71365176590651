import { Minus, Plus } from '@sbf/fairplay-icons';
import { Divider, Typography } from '@sbf/fairplay-nike';
import { media } from '@sbf/fairplay-utils';
import styled, { css } from 'styled-components';
import SectionLink from '@/components/SectionLink';
import { variant } from '@/helpers/styles';

export const Container = styled.footer`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral[700]};
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    max-width: 1280rem;
    padding: ${theme.spaces[500]} ${theme.spaces[300]};

    ${media.greaterThan('desktop')} {
      padding: ${theme.spaces[600]};
    }
  `}
`;

export const MainFooter = styled.div`
  ${({ theme }) => css`
    ${media.greaterThan('desktop')} {
      display: grid;
      grid-template-columns: repeat(4, 198rem);
      grid-gap: ${theme.spaces[400]};
      justify-content: space-between;
      align-items: flex-start;
    }
  `}
`;

type VerticalGridProps = {
  size: 'small' | 'large';
};

export const VerticalGrid = styled.div<VerticalGridProps>`
  ${({ theme, size }) => css`
    display: grid;
    grid-gap: ${variant(size, {
      small: theme.spaces[100],
      large: theme.spaces[200],
    })};
    ${media.lessThan('desktop')} {
      margin: ${theme.spaces[300]} 0 ${theme.spaces[500]};
    }
  `}
`;

export const StyledSectionLink = styled(SectionLink)`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.regular};
    color: ${theme.colors.neutral[200]};
    text-transform: none;
  `}
`;

// TODO: some section titles are not links, because
// of that I had to duplicate SectionLink component behavior
//
// Maybe we need the SectionLink component to be more generic
// to the point where it doesn't return a link
export const SectionTitle = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fonts.primary};
    font-weight: ${theme.fontWeights.regular};
    font-size: ${theme.fontSizes.medium};
    color: ${theme.colors.neutral[200]};
    line-height: ${theme.lineHeights.large};
    padding-bottom: ${theme.spaces[300]};
  `}
`;

export const AccordionTitle = styled(Typography).attrs({
  variant: 'paragraph',
  size: 'small',
})`
  ${({ theme }) => css`
    text-transform: uppercase;
    color: ${theme.colors.neutral[100]};
  `}
`;

export const ExpandedIcon = styled(Minus)`
  ${({ theme }) => css`
    color: ${theme.colors.neutral[100]};
  `}
`;

export const ClosedIcon = styled(Plus)`
  ${({ theme }) => css`
    color: ${theme.colors.neutral[100]};
  `}
`;

export const CustomDivider = styled(Divider).attrs({
  mobileMargin: { m: [400, 0] },
  desktopMargin: { m: [500, 0] },
  variant: 'secondary',
  type: 'soft',
  orientation: 'horizontal',
})``;

export const SubFooter = styled.div`
  display: grid;
  place-items: center;
  ${media.greaterThan('desktop')} {
    grid-template-columns: 400rem 1fr;
    grid-gap: 150rem;
  }
`;

export const SubFooterLinks = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 400rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: ${theme.spaces[100]};
  `}
`;

export const Copyright = styled(Typography).attrs({
  variant: 'paragraph',
  size: 'tiny',
})`
  ${({ theme }) => css`
    text-align: center;
    margin-top: 24rem;

    && {
      color: ${theme.colors.neutral[300]};
    }

    ${media.greaterThan('desktop')} {
      text-align: right;
      margin: 0;
    }
  `}
`;
