import { Link as Anchor } from '@sbf/fairplay-nike';
import Link from 'next/link';
import { MouseEvent, memo } from 'react';
import Container from '@/common/components/Container/Container';
import { getCoreRoutes } from '@/common/config/routes';
import { useNewCallbackLogin } from '@/common/hooks/useNewCallbackLogin/useNewCallbackLogin';
import useUnleash from '@/common/hooks/useUnleash/useUnleash';
import { useTrackingContext } from '@/contexts/TrackingContext';
import {
  SelectContentObject,
  sendSelectContentEvents,
} from '@/helpers/analytics';
import { AuthEventType, handleSignIn } from '@/helpers/auth';
import { useAuthSession } from '@/hooks/useAuthSession';
import { checkGuestCheckoutFlag } from '@/modules/guestCheckout/utils/guestCheckoutEnabled';
import {
  Copyright,
  CustomDivider,
  Container as FooterContainer,
  MainFooter,
  StyledSectionLink,
  SubFooter,
  SubFooterLinks,
  VerticalGrid,
  Wrapper,
} from './Footer.styled';
import AppStores from './components/AppStores/AppStores';
import CollapsibleLinks from './components/CollapsibleLinks/CollapsibleLinks';
import Payments from './components/Payments/Payments';
import Social from './components/Social/Social';
import { FOOTER_LINKS } from './constants';
import { sendContractEvent } from './gaEvents';

const PRIVACY_POLICY = 'Política de Privacidade';
const COOKIES_POLICY = 'Política de Cookies';
const TERMS_OF_SERVICE = 'Termos de Uso';
const NEWSLETTER_TEXT = 'Cadastre-se para receber novidades';
const GIFT_CARD_TEXT = 'Cartão Presente';
const FIND_STORE_TEXT = 'Encontre Uma Loja Nike';
const SITE_MAP_TEXT = 'Mapa do Site';
const JOURNAL_TEXT = 'Nike Journal';
const CORINTHIANS_TEXT = 'Corinthians';
const TRACK_ORDER_TEXT = 'Acompanhe seu Pedido';
const coreRoutes = getCoreRoutes();
export const getYear = () => new Date().getFullYear();

export type FooterProps = {
  layout?: 'default' | 'snkrs';
};

const authEvent: AuthEventType = {
  action: 'Click - Newsletter Login',
  label: `Clicou no link ${NEWSLETTER_TEXT} para fazer o login`,
};

const Footer = ({ layout = 'default' }: FooterProps) => {
  const isNewCallbackLogin = useNewCallbackLogin();

  const { handleEvents } = useTrackingContext();

  const year = getYear();

  const { isAuthenticated } = useAuthSession();

  const newsletterHandler = (event: MouseEvent) => {
    event.preventDefault();
    handleSignIn(authEvent, isNewCallbackLogin);
  };

  const isOrderDetailsGuestCheckoutActive = useUnleash(
    'nike-front.order-details-guest-checkout',
  );

  const isGuestCheckoutReady = checkGuestCheckoutFlag();

  return (
    <FooterContainer data-testid="footer">
      <Container>
        <Wrapper>
          <MainFooter>
            <VerticalGrid size="large">
              <StyledSectionLink
                type="secondary"
                text={FIND_STORE_TEXT}
                href={coreRoutes.findStore}
                onClick={() => {
                  handleEvents<SelectContentObject>(sendSelectContentEvents, {
                    component: 'footer',
                    microComponent: 'main_footer_content',
                    anchorText: FIND_STORE_TEXT,
                    title: '',
                    url: coreRoutes.findStore,
                    contentType: 'navigation_link',
                  });
                }}
              />
              {isAuthenticated ? (
                <StyledSectionLink
                  type="secondary"
                  text={NEWSLETTER_TEXT}
                  href={coreRoutes.nikeProfile}
                  onClick={() => {
                    handleEvents<SelectContentObject>(sendSelectContentEvents, {
                      component: 'footer',
                      microComponent: 'main_footer_content',
                      anchorText: NEWSLETTER_TEXT,
                      title: '',
                      url: coreRoutes.nikeProfile,
                      contentType: 'navigation_link',
                    });
                  }}
                />
              ) : (
                <StyledSectionLink
                  type="secondary"
                  text={NEWSLETTER_TEXT}
                  href="#"
                  onClick={(event: MouseEvent) => {
                    newsletterHandler(event);
                    handleEvents<SelectContentObject>(sendSelectContentEvents, {
                      component: 'footer',
                      microComponent: 'main_footer_content',
                      anchorText: NEWSLETTER_TEXT,
                      title: '',
                      url: 'https://www.nike.com.br/',
                      contentType: 'navigation_link',
                    });
                  }}
                />
              )}
              <StyledSectionLink
                type="secondary"
                text={GIFT_CARD_TEXT}
                href={coreRoutes.giftCard}
                onClick={() => {
                  handleEvents<SelectContentObject>(sendSelectContentEvents, {
                    component: 'footer',
                    microComponent: 'main_footer_content',
                    anchorText: GIFT_CARD_TEXT,
                    title: '',
                    url: coreRoutes.giftCard,
                    contentType: 'navigation_link',
                  });
                }}
              />
              <StyledSectionLink
                type="secondary"
                text={SITE_MAP_TEXT}
                href={coreRoutes.siteMap}
                onClick={() => {
                  handleEvents<SelectContentObject>(sendSelectContentEvents, {
                    component: 'footer',
                    microComponent: 'main_footer_content',
                    anchorText: SITE_MAP_TEXT,
                    title: '',
                    url: coreRoutes.siteMap,
                    contentType: 'navigation_link',
                  });
                }}
              />

              <StyledSectionLink
                type="secondary"
                text={JOURNAL_TEXT}
                href={coreRoutes.journal}
                onClick={() => {
                  handleEvents<SelectContentObject>(sendSelectContentEvents, {
                    component: 'footer',
                    microComponent: 'main_footer_content',
                    anchorText: JOURNAL_TEXT,
                    title: '',
                    url: coreRoutes.journal,
                    contentType: 'navigation_link',
                  });
                }}
              />

              <StyledSectionLink
                type="secondary"
                text={CORINTHIANS_TEXT}
                href={coreRoutes.corinthians}
                onClick={() => {
                  handleEvents<SelectContentObject>(sendSelectContentEvents, {
                    component: 'footer',
                    microComponent: 'main_footer_content',
                    anchorText: CORINTHIANS_TEXT,
                    title: '',
                    url: coreRoutes.corinthians,
                    contentType: 'navigation_link',
                  });
                }}
              />
              {isOrderDetailsGuestCheckoutActive && isGuestCheckoutReady && (
                <StyledSectionLink
                  type="secondary"
                  text={TRACK_ORDER_TEXT}
                  href={coreRoutes.trackOrder}
                  onClick={() => {
                    handleEvents<SelectContentObject>(sendSelectContentEvents, {
                      component: 'footer',
                      microComponent: 'main_footer_content',
                      anchorText: TRACK_ORDER_TEXT,
                      title: '',
                      url: coreRoutes.trackOrder,
                      contentType: 'navigation_link',
                    });
                  }}
                />
              )}
            </VerticalGrid>

            <CollapsibleLinks
              id="footer-help"
              title="Ajuda"
              links={FOOTER_LINKS.help}
            />

            <CollapsibleLinks
              id="footer-about"
              title="Sobre a Nike"
              links={FOOTER_LINKS.about}
            />

            <div>
              <Social />

              <Payments layout={layout} />

              <AppStores />
            </div>
          </MainFooter>

          <CustomDivider />

          <SubFooter>
            <SubFooterLinks>
              <Link
                href={coreRoutes.geoSelectionLanguage}
                passHref
                legacyBehavior
              >
                <Anchor
                  variant="secondary"
                  size="tiny"
                  type="quiet"
                  text="Brasil"
                  onClick={() => {
                    handleEvents<SelectContentObject>(sendSelectContentEvents, {
                      component: 'footer',
                      microComponent: 'bottom_footer',
                      anchorText: 'brasil',
                      title: '',
                      url: coreRoutes.geoSelectionLanguage,
                      contentType: 'navigation_link',
                    });
                  }}
                />
              </Link>

              <Link href={coreRoutes.privacyPolicy} passHref legacyBehavior>
                <Anchor
                  variant="secondary"
                  size="tiny"
                  type="quiet"
                  text={PRIVACY_POLICY}
                  onClick={() => {
                    sendContractEvent(PRIVACY_POLICY);
                    handleEvents<SelectContentObject>(sendSelectContentEvents, {
                      component: 'footer',
                      microComponent: 'bottom_footer',
                      anchorText: PRIVACY_POLICY,
                      title: '',
                      url: coreRoutes.privacyPolicy,
                      contentType: 'navigation_link',
                    });
                  }}
                />
              </Link>

              <Link href={coreRoutes.cookiesPolicy} passHref legacyBehavior>
                <Anchor
                  variant="secondary"
                  size="tiny"
                  type="quiet"
                  text={COOKIES_POLICY}
                  onClick={() => {
                    sendContractEvent(COOKIES_POLICY);
                    handleEvents<SelectContentObject>(sendSelectContentEvents, {
                      component: 'footer',
                      microComponent: 'bottom_footer',
                      anchorText: PRIVACY_POLICY,
                      title: '',
                      url: coreRoutes.cookiesPolicy,
                      contentType: 'navigation_link',
                    });
                  }}
                />
              </Link>

              <Link href={coreRoutes.termsOfService} passHref legacyBehavior>
                <Anchor
                  variant="secondary"
                  size="tiny"
                  type="quiet"
                  text={TERMS_OF_SERVICE}
                  onClick={() => {
                    sendContractEvent(TERMS_OF_SERVICE);
                    handleEvents<SelectContentObject>(sendSelectContentEvents, {
                      component: 'footer',
                      microComponent: 'bottom_footer',
                      anchorText: TERMS_OF_SERVICE,
                      title: '',
                      url: coreRoutes.termsOfService,
                      contentType: 'navigation_link',
                    });
                  }}
                />
              </Link>
            </SubFooterLinks>

            <Copyright>
              © {year} Nike. Todos os direitos reservados. Fisia Comércio de
              Produtos Esportivos Ltda - CNPJ: 59.546.515/0045-55 Rodovia Fernão
              Dias, S/N Km 947.5 - Galpão Modulo 3640 - CEP 37640-903 - Extrema
              - MG
            </Copyright>
          </SubFooter>
        </Wrapper>
      </Container>
    </FooterContainer>
  );
};

export default memo(Footer);
