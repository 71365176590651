import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { CartDataResponseApi, ProductPlaceOrder } from '../types/cart';
import {
  CheckoutErrorDetail,
  CheckoutItemError,
  CheckoutItemErrorDetail,
} from '../types/checkoutErrorResponse';

const CartContext = createContext<CartContextProviderState>(
  {} as CartContextProviderState,
);

type FormattedError = {
  formattedMessage: {
    message: string;
    skuDetail?: ProductPlaceOrder;
    shoppingCart?: CartDataResponseApi;
  };
};

type CheckoutErrorDetailFormatted = CheckoutItemError & FormattedError;

export interface CartContextProviderState {
  setClearSaleSessionId: (sessionId: string) => void;
  clearSaleSessionIdData: string;
  setErrorCartData: (error: CheckoutErrorDetail) => void;
  cleanErrorCartData: () => void;
  cartErrorData: Partial<CheckoutErrorDetail>;
  getErrorCartData: CheckoutErrorDetailFormatted[] | [];
  findProductError: (skuId: string) => CheckoutErrorDetailFormatted | undefined;
  isThereSomeErrorOnCart: boolean;
}

type ProviderProps = {
  children: ReactNode;
};

const CheckoutGuestContext = ({ children }: ProviderProps) => {
  const defaultErrorCartData = {};
  const [clearSaleSessionIdData, setClearSaleSessionIdData] =
    useState<string>('');
  const [errorCart, setErrorCart] =
    useState<Partial<CheckoutErrorDetail>>(defaultErrorCartData);

  const setClearSaleSessionId = useCallback((sessionId: string) => {
    setClearSaleSessionIdData(sessionId);
  }, []);

  const setErrorCartData = (error: CheckoutErrorDetail) => {
    setErrorCart(error);
  };
  const cleanErrorCartData = () => {
    setErrorCart(defaultErrorCartData);
  };

  const getSkuDetail = useCallback(
    (skuId: string, shoppingCart: CartDataResponseApi) => {
      return shoppingCart.items.find((res) => res.sku.id === skuId);
    },
    [],
  );

  const getErrorCode = useCallback(
    (
      code: string,
      detail: CheckoutItemErrorDetail,
      shoppingCart: CartDataResponseApi,
    ) => {
      const skuDetail = getSkuDetail(detail.sku, shoppingCart);
      const errorMapper = {
        CHK082: {
          message: `Limite máximo de ${detail?.maxItemQuantity} por pedido excedido`,
          skuDetail,
        },
        CHK002: {
          message: `Produto indisponível`,
          skuDetail,
        },
      }[code];

      return (
        errorMapper || {
          message: 'Ocorreu um erro inesperado - ' + code,
          skuDetail,
        }
      );
    },
    [getSkuDetail],
  );

  const getErrorCartData = useMemo(() => {
    if (!errorCart?.items || !errorCart?.shoppingCart) {
      return [];
    }
    const shoppingCart = errorCart?.shoppingCart;
    const items = errorCart?.items.map((item) => {
      return {
        ...item,
        formattedMessage: getErrorCode(
          item.code,
          item.detail as CheckoutItemErrorDetail,
          shoppingCart,
        ),
      };
    });
    return items;
  }, [errorCart, getErrorCode]);

  const isThereSomeErrorOnCart = useMemo(() => {
    return Boolean(errorCart?.items && errorCart?.items.length > 0);
  }, [errorCart]);

  const findProductError = useCallback(
    (skuId: string) => {
      return getErrorCartData?.find((res) => res.detail.sku === skuId);
    },
    [getErrorCartData],
  );

  return (
    <CartContext.Provider
      value={{
        setClearSaleSessionId,
        clearSaleSessionIdData,
        setErrorCartData,
        cleanErrorCartData,
        cartErrorData: errorCart,
        getErrorCartData,
        findProductError,
        isThereSomeErrorOnCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

function useCartContext(): CartContextProviderState {
  return useContext(CartContext);
}

export { CheckoutGuestContext, useCartContext };
