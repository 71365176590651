export enum KEY_CODE {
  BACKSPACE = 8,
  TAB = 9,
  ENTER = 13,
  SHIFT = 16,
  CTRL = 17,
  ALT = 18,
  PAUSE_BREAK = 19,
  CAPS_LOCK = 20,
  ESCAPE = 27,
  SPACE = 32,
  PAGE_UP = 33,
  PAGE_DOWN = 34,
  END = 35,
  HOME = 36,

  Left_Arrow = 37,
  Up_Arrow = 38,
  Right_Arrow = 39,
  Down_Arrow = 40,

  INSERT = 45,
  DELETE = 46,

  ZERO = 48,
  CLOSED_PAREN = ZERO,
  ONE = 49,
  EXCLAMATION_MARK = ONE,
  TWO = 50,
  AT_SIGN = TWO,
  THREE = 51,
  POUND_SIGN = THREE,
  HASH = POUND_SIGN,
  FOUR = 52,
  DOLLAR_SIGN = FOUR,
  FIVE = 53,
  PERCENT_SIGN = FIVE,
  SIX = 54,
  CARET = SIX,
  HAT = CARET,
  SEVEN = 55,
  AMPERSAND = SEVEN,
  EIGHT = 56,
  STAR = EIGHT,
  ASTERIK = STAR,
  NINE = 57,
  OPEN_PAREN = NINE,

  A = 65,
  B = 66,
  C = 67,
  D = 68,
  E = 69,
  F = 70,
  G = 71,
  H = 72,
  I = 73,
  J = 74,
  K = 75,
  L = 76,
  M = 77,
  N = 78,
  O = 79,
  P = 80,
  Q = 81,
  R = 82,
  S = 83,
  T = 84,
  U = 85,
  V = 86,
  W = 87,
  X = 88,
  Y = 89,
  Z = 90,

  LEFT_WINDOWKEY = 91,
  RIGHT_WINDOWKEY = 92,
  SELECT_KEY = 93,

  NUMPAD0 = 96,
  NUMPAD1 = 97,
  NUMPAD2 = 98,
  NUMPAD3 = 99,
  NUMPAD4 = 100,
  NUMPAD5 = 101,
  NUMPAD6 = 102,
  NUMPAD7 = 103,
  NUMPAD8 = 104,
  NUMPAD9 = 105,

  MULTIPLY = 106,
  ADD = 107,
  SUBTRACT = 109,
  DECIMAL_POINT = 110,
  DIVIDE = 111,

  F1 = 112,
  F2 = 113,
  F3 = 114,
  F4 = 115,
  F5 = 116,
  F6 = 117,
  F7 = 118,
  F8 = 119,
  F9 = 120,
  F10 = 121,
  F11 = 122,
  F12 = 123,

  NUM_LOCK = 144,
  SCROLL_LOCK = 145,

  SEMI_COLON = 186,
  EQUALS = 187,
  COMMA = 188,
  DASH = 189,
  PERIOD = 190,
  UNDER_SCORE = DASH,
  PLUS_SIGN = EQUALS,
  FORWARD_SLASH = 191,
  TILDE = 192,
  GRAVE_ACCENT = TILDE,

  OPEN_BRACKET = 219,
  CLOSED_BRACKET = 221,
  QUOTE = 222,
}
