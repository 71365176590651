import { AxiosError } from 'axios';
const errLogObject = (error) => {
  return error instanceof AxiosError
    ? {
        status: error.status,
        code: error.code,
        path: error.config.url,
        method: error.config.method,
        message: error.message || null,
        data: error.response?.data,
        stack: error.stack,
        response: {
          status: error.response?.status,
          data: error.response?.data,
        },
      }
    : {
        exception: error,
      };
};
export default errLogObject;
