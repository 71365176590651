import { AxiosError } from 'axios';
import {
  isClientSide,
  isTestEnv,
} from '@/common/utils/environment/environment';
import { STATUS_CODE } from '@/common/utils/http/statusCode/statusCode';

enum RETRY_DELAY {
  PRECONDITION_REQUIRED = 30100,
  DEFAULT = 5000,
}

enum RETRY_QUANTITY {
  PRECONDITION_REQUIRED = 2,
  DEFAULT = 3,
}

const shouldRetry = (error: AxiosError, statusCode: number) => {
  return Number(error?.response?.status) === statusCode && isClientSide();
};
export const retryRequestByConditionalQueries = () => {
  return {
    retryDelay: (_: number, error: AxiosError | unknown) => {
      const shouldRetryError =
        error instanceof AxiosError &&
        shouldRetry(error, STATUS_CODE.PRECONDITION_REQUIRED);
      if (isTestEnv) {
        return 0;
      }
      return !shouldRetryError ? 0 : RETRY_DELAY.PRECONDITION_REQUIRED;
    },
    retry: (count: number, error: AxiosError | unknown) => {
      const retry =
        error instanceof AxiosError &&
        shouldRetry(error, STATUS_CODE.PRECONDITION_REQUIRED);
      if (isTestEnv) {
        return false;
      }
      return retry && count <= RETRY_QUANTITY.PRECONDITION_REQUIRED;
    },
  };
};

export default retryRequestByConditionalQueries;
