import { NikeProfile } from '@sbf/fairplay-icons';
import { Typography } from '@sbf/fairplay-nike';
import { media } from '@sbf/fairplay-utils';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import SectionLink from '@/components/SectionLink';

const MOBILE_HEADER_SIZE = '88rem';
const MOBILE_CATEGORY_TITLE_SIZE = '43rem';
const DESKTOP_HEADER_SIZE = '140rem';

type SectionLinkProps = {
  padded: boolean;
};

export const LinkMobileMenu = styled(Link)`
  text-decoration: none;

  -webkit-tap-highlight-color: transparent;
`;

export const Item = styled.li`
  ${({ theme }) => css`
    padding: ${theme.spaces[300]} ${theme.spaces[0]};

    ${media.lessThan('desktop')} {
      & a {
        font-size: ${theme.fontSizes.medium};
        color: ${theme.colors.neutral[600]};
      }
    }

    ${media.greaterThan('desktop')} {
      padding: ${theme.spaces[100]} ${theme.spaces[0]};
    }
  `}
`;

export const SeeMoreItem = styled(Item)`
  ${({ theme }) => css`
    ${media.lessThan('desktop')} {
      & a {
        font-size: ${theme.fontSizes.small};
        color: ${theme.colors.neutral[600]};
        text-decoration: none;
        height: 16rem;
      }
    }
  `}
`;

export const ListItems = styled.ul`
  ${({ theme }) => css`
    list-style-type: none;
    ${media.lessThan('desktop')} {
      padding: ${theme.spaces[0]} ${theme.spaces[400]};
    }
    ${media.greaterThan('desktop')} {
      margin-top: ${theme.spaces[300]};
      ${Item} :first-child {
        margin-top: ${theme.spaces[0]};
      }
    }
  `}
`;

export const SubCategoryList = styled.li`
  ${({ theme }) => css`
    padding: ${theme.spaces[300]} ${theme.spaces[0]};
    ${CategoryButton} {
      font-size: ${theme.fontSizes.small};
      color: ${theme.colors.neutral[600]};
    }
  `}
`;

export const SubCategoryContentWrapper = styled.div`
  ${({ theme }) => css`
    max-height: calc(90vh - ${DESKTOP_HEADER_SIZE});
    overflow-y: auto;
    width: 100%;

    ::-webkit-scrollbar {
      width: 4rem;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.neutral[300]};
    }
  `}
`;

export const SubCategoryContent = styled.ul`
  ${({ theme }) => css`
    list-style-type: none;
    padding: ${theme.spaces[0]} ${theme.spaces[400]};

    ${media.greaterThan('desktop')} {
      column-count: 4;
      padding: ${theme.spaces[500]} ${theme.spaces[500]} ${theme.spaces[0]};
      width: calc(1024rem - 2 * ${theme.spaces[500]});
      margin: ${theme.spaces[0]} auto;
    }
  `}
`;

export const SubCategoryListItem = styled.li`
  ${({ theme }) => css`
    break-inside: avoid;
    padding-bottom: ${theme.spaces[300]};
    width: 100%;
    overflow: hidden;
  `}
`;

export const CategoryContentContainer = styled.div`
  height: calc(100vh - ${MOBILE_CATEGORY_TITLE_SIZE} - ${MOBILE_HEADER_SIZE});
  overflow-y: auto;
`;

export const CategoryContentContainerAccount = styled(CategoryContentContainer)`
  display: flex;
  flex-direction: column;
`;

export const CategoryTitle = styled(Typography).attrs({
  variant: 'heading',
  size: 'h1',
  forwardedAs: 'span',
})`
  ${({ theme }) => css`
    display: block;
    padding: ${theme.spaces[0]} ${theme.spaces[0]} ${theme.spaces[300]}
      ${theme.spaces[400]};
  `}
`;

export const CategoryPanel = styled.div`
  ${({ theme }) => css`
    position: absolute;
    background-color: ${theme.colors.neutral[100]};
    top: 0;
    left: 100%;
    width: 100%;
    padding-bottom: ${theme.spaces[500]};
    &[aria-expanded='true'] {
      left: 0;
    }

    ${media.greaterThan('desktop')} {
      top: 100%;
      justify-content: center;
      height: auto;
      display: none;
      left: 0;

      &[aria-expanded='true'] {
        display: flex;
      }

      :hover,
      :focus-within {
        display: flex;
      }
    }
  `}
`;

export const StyledSectionLink = styled(SectionLink)<SectionLinkProps>`
  ${({ theme, padded }) => css`
    box-sizing: border-box;
    font-weight: ${theme.fontWeights.medium};

    &.focus-visible {
      z-index: ${theme.zIndex[100]};
    }

    ${padded &&
    css`
      padding: ${theme.spaces[400]} ${theme.spaces[300]};
      height: 80rem;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  `}
`;

export const CategoryButton = styled.button`
  ${({ theme }) => css`
    && {
      color: ${theme.colors.neutral[700]};
      background: ${theme.colors.neutral[100]};
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: ${theme.fontSizes.xlarge} !important;
      font-weight: ${theme.fontWeights.regular};
      border: ${theme.borderWidth.none};
      outline: none;
      font-family: ${theme.fonts.primary};
      text-align: left;

      &.focus-visible {
        box-shadow: ${theme.shadow.focus};
      }
    }
  `}
`;

export const CategoryMenu = styled.li`
  ${({ theme }) => css`
    padding: ${theme.spaces[300]} ${theme.spaces[0]};
    width: 100%;

    ${media.greaterThan('desktop')} {
      box-sizing: border-box;
      width: auto;
      height: 80rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${theme.colors.neutral[100]};
      border-bottom: ${theme.borderWidth.thin} solid transparent;
      margin-top: ${theme.spaces[0]};
      padding: ${theme.spaces[0]};

      :hover {
        border-bottom: ${theme.borderWidth.thin} solid ${theme.colors.primary};
      }
    }
  `}
`;

export const CategoryMenuAccount = styled(CategoryMenu)`
  ${({ theme }) => css`
    margin-top: ${theme.spaces[300]};
    margin-bottom: ${theme.spaces[100]};
  `}
`;

export const Categories = styled.nav`
  ${({ theme }) => css`
    list-style-type: none;
    background-color: ${theme.colors.neutral[100]};
    display: flex;
    flex-direction: column;
    align-items: start;

    ${media.greaterThan('desktop')} {
      margin: ${theme.spaces[0]} auto;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    ${media.lessThan('desktop')} {
      padding: ${theme.spaces[0]} ${theme.spaces[400]};
    }
  `}
`;

export const NavMenu = styled.nav`
  position: relative;
  contain-intrinsic-size: 9rem 18rem;
`;

export const UserLogged = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-size: ${theme.fontSizes.small};
  `}
`;

export const NikeProfileAccount = styled(NikeProfile)`
  ${({ theme }) => css`
    margin-right: ${theme.spaces[200]};
    top: -3rem;
    position: relative;
  `}
`;

export const SecondaryMenuContainer = styled.li`
  ${({ theme }) => css`
    max-width: fit-content;
    background: ${theme.colors.neutral[100]};
    padding: ${theme.spaces[600]} 0 ${theme.spaces[300]};
  `}
`;

export const SecondaryListItems = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

export const SecondaryItem = styled.li`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.spaces[100]} ${theme.spaces[0]};
  `}
`;

export const SecondaryItemLink = styled(LinkMobileMenu)`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spaces[200]};
    font-size: ${theme.fontSizes.medium};
    font-weight: ${theme.fontWeights.medium};
    color: ${theme.colors.neutral[700]};
    align-items: center;
  `}
`;
