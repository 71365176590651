/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * The isEmpty is a function to return if the value is empty
 *
 * @param {any} value - The value you want to know is empty or not
 *
 * @example
 * import { isEmpty } from '@/common/utils/isEmpty';
 *
 * isEmpty('string')
 *
 * //return false
 */

const isEmpty = (value: any) => {
  const isBool = typeof value === 'boolean';
  const isNumber = typeof value === 'number';
  const isObject = value === Object(value);
  const isArray = Array.isArray(value);

  if (isBool) {
    return !value;
  }

  if (isArray) {
    return !value.length;
  }

  if (isObject) {
    return Object.getOwnPropertyNames(value).length === 0;
  }

  return isNumber ? false : !value || !value.trim().length;
};

export default isEmpty;
