import { NikeProfile } from '@sbf/fairplay-icons';
import { Link, PopoverContent, Typography } from '@sbf/fairplay-nike';
import { media } from '@sbf/fairplay-utils';
import styled, { css } from 'styled-components';

type MenuUserType = {
  isVisible: boolean;
};

export const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40rem;
`;

export const SubHeaderBG = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral[200]};
    padding: ${theme.spaces[200]} ${theme.spaces[0]};
  `}
`;

export const LinksBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    button,
    a {
      color: ${theme.colors.neutral[700]};
      :hover {
        color: ${theme.colors.neutral[500]};
      }
    }
  `}
`;

export const BrandBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spaces[400]};
  `}
`;

export const PopoverContentStyled = styled(PopoverContent)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;

  &:focus-visible {
    outline: 0;
  }
`;

export const MenuUserLogged = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuUserName = styled(Typography).attrs({
  variant: 'paragraph',
  size: 'tiny',
  weight: 'regular',
  color: '#737373',
})<MenuUserType>`
  ${({ theme, isVisible }) => css`
    line-height: 1.9;
    margin-right: 10rem;
    ${isVisible &&
    `
      color: ${theme.colors.neutral[700]};
    `}
    :hover {
      color: ${theme.colors.neutral[700]};
    }
  `}
`;

export const MenuUserIcon = styled(NikeProfile).attrs({
  size: '18px',
  color: '#737373',
})<MenuUserType>`
  ${({ theme, isVisible }) => css`
    position: relative;
    top: -2rem;
    ${isVisible &&
    `
      color: ${theme.colors.neutral[700]};
    `}
  `}
`;

export const MenuItem = styled(Link)`
  ${({ theme }) => css`
    padding-left: ${theme.spaces[400]};
    padding-right: ${theme.spaces[400]};
    padding-bottom: ${theme.spaces[300]};
    padding-top: ${theme.spaces[300]};
    font-size: ${theme.fontSizes.medium};

    ${media.greaterThan('desktop')} {
      padding-left: ${theme.spaces[400]};
      padding-right: ${theme.spaces[400]};
      padding-bottom: ${theme.spaces[200]};
      padding-top: ${theme.spaces[200]};
    }

    :hover {
      color: ${theme.colors.neutral[700]};
    }
  `}
`;

export const Wrapper = styled.button`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: ${theme.lineHeights.tight};
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;

    &.focus-visible {
      box-shadow: ${theme.shadow.focus};
    }
  `}
`;

export const SubHeaderIcon = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    svg {
      font-size: ${theme.fontSizes.small};
      margin-right: ${theme.spaces[100]};
      opacity: ${theme.opacity[700]};
    }
  `}
`;
