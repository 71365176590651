import { UnleashClient, useFlag } from '@unleash/proxy-client-react';
import { UNLEASH_CONFIG } from '@/common/config/unleash';

const useUnleash = (toggleName: string) => {
  if (toggleName === '') {
    throw new Error('Feature Toggle name passed to useToggle is invalid');
  }

  return useFlag(toggleName);
};

export const getUnleashVariantSSR = async (
  experimentName: string,
  userId?: string,
) => {
  const unleash = new UnleashClient(UNLEASH_CONFIG);
  await unleash.start();

  if (userId) {
    await unleash.updateContext({ userId });
  }

  const featureToggle = unleash.getVariant(experimentName);
  unleash.stop();

  return featureToggle;
};

export default useUnleash;
