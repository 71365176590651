import Link from 'next/link';
import { StaticImage } from '@/components/Image';
import { LogoAnchorStyled } from './Logo.styled';

type LogoPropsType = {
  src: string;
  href: string;
  alt: string;
  title: string;
  width: string;
  height: string;
  imgTitle?: string;
  onClick?: () => void;
};

const Logo = ({
  src,
  href,
  alt,
  title,
  width,
  height,
  imgTitle,
  onClick,
}: LogoPropsType) => {
  return (
    <Link href={href} passHref legacyBehavior>
      <LogoAnchorStyled title={title}>
        <StaticImage
          src={src}
          alt={alt}
          width={width}
          height={height}
          disablePlaceholder
          priority
          title={imgTitle}
          onClick={onClick}
        />
      </LogoAnchorStyled>
    </Link>
  );
};

export default Logo;
