import { signIn } from 'next-auth/react';
import { setCookie } from 'nookies';
import { getStorageItem } from '@/helpers/localStorage';
import { sendClickEventSignIn } from './trackingEvents';
import { AuthEventType } from './types';

export const handleSignIn = async (
  authEvent: AuthEventType | null = null,
  isNewCallbackLogin: boolean,
) => {
  const clienteAnonimoToken = getStorageItem<string>('x-client-token');

  if (clienteAnonimoToken) {
    setCookie(null, 'token-cliente-anonimo', clienteAnonimoToken, {
      maxAge: 84600,
      path: '/',
    });
  }

  setCookie(
    null,
    'additionalAuthParams',
    JSON.stringify({ newCallbackLogin: String(isNewCallbackLogin) }),
    {
      maxAge: 84600,
      path: '/',
    },
  );

  if (window.location.pathname === '/auth/error') {
    const urlParams = new URLSearchParams(window.location.search);
    const callbackUrl = urlParams.get('callbackUrl') || window.location.origin;
    signIn('keycloak', { callbackUrl: callbackUrl });
  } else {
    signIn('keycloak');
  }
  if (authEvent) {
    sendClickEventSignIn(authEvent.action, authEvent.label);
  }
};
