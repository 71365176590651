export enum HEADER_LAYOUT_ENUM {
  DEFAULT = 'default',
  SNKRS = 'snkrs',
  ASSISTED_SALES = 'assistedSale',
}

export type HeaderLayoutProps = {
  layout?: `${HEADER_LAYOUT_ENUM}`;
  searchFilters?: boolean;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  labels?: LabelsType;
};

export type LabelsType = {
  signInButtonLabel: string;
  myOrdersLabel: string;
  myAccountLabel: string;
  myFavorites: string;
  helloLabel: string;
};
