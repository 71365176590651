import { cpf } from 'cpf-cnpj-validator';

export const ALREADY_EXISTS = 'ALREADY_EXISTS';

export const NAME_ERROR_MESSAGES = {
  REQUIRED: 'Insira seu nome',
  MINLENGTH: 'Por favor, digite pelo menos 2 caracteres',
  ISLATIN: 'Escreva seu nome utilizando apenas caracteres latinos.',
};

export const SURNAME_ERROR_MESSAGES = {
  REQUIRED: 'Insira seu sobrenome',
  MINLENGTH: 'Por favor, digite pelo menos 2 caracteres',
  ISLATIN: 'Escreva seu sobrenome utilizando apenas caracteres latinos.',
};

export const CPF_ERROR_MESSAGES = {
  REQUIRED: 'Digite um CPF para continuar.',
  INVALID: 'CPF inválido.',
  INTERNALERROR:
    'Tivemos um problema no nosso sistema, tente digitar o CPF novamente.',
  VERIFYCPF: 'Verifique o campo CPF.',
};

export const BIRTHDATE_ERROR_MESSAGES = {
  REQUIRED: 'Digite uma data para continuar.',
  MINLENGTH:
    'Data inválida, a data deve conter 8 dígitos e estar no formato: DD/MM/AAAA.',
  ISVALID:
    'Data inválida, a data deve conter 8 dígitos e estar no formato: DD/MM/AAAA.',
  ISOLDEREIGHTEEN: 'É preciso ser maior de idade para se cadastrar.',
  ISOLDERHUNDRED: 'maxAgePlaceholder',
  ISDOCCHECKERVALID: 'Data inválida.',
};

export const CNPJ_ERROR_MESSAGES = {
  REQUIRED: 'Digite um CNPJ para continuar.',
  MINLENGTH: 'CNPJ inválido.',
};

export const CORPORATE_NAME_ERROR_MESSAGES = {
  REQUIRED: 'Digite a razão social para continuar.',
};

export const STATE_REGISTRATION_ERROR_MESSAGES = {
  REQUIRED: 'Digite a inscrição estadual para continuar.',
};

export const PHONE_ERROR_MESSAGES = {
  REQUIRED: 'Digite um número de telefone para continuar.',
  MINLENGTH: 'Número de telefone inválido.',
};

export const ZIPCODE_ERROR_MESSAGES = {
  DOESNT_EXIST: 'CEP não existe.',
  INVALID: 'Digite um CEP válido.',
};

export const ADDRESS_ERROR_MESSAGES = {
  REQUIRED: 'Digite um endereço para continuar.',
};

export const NUMBER_ERROR_MESSAGES = {
  REQUIRED: 'Digite um número para continuar.',
};

export const DISTRICT_ERROR_MESSAGES = {
  REQUIRED: 'Digite um bairro para continuar.',
};

export const GENDER_PREFERENCE_ERROR_MESSAGES = {
  REQUIRED: 'Selecione uma das opções acima para continuar.',
};

export const checkValidDate = (value: string) => {
  const date = convertToDateObject(value);
  return date instanceof Date && !isNaN(date.getTime());
};

export const convertToDateObject = (value: string) => {
  const [day, month, year] = value.split('/');
  return new Date(`${year}/${month}/${day}`);
};

export const checkMinAge = (value: string) => {
  const date = convertToDateObject(value);
  const age = getAge(date);
  return age >= 18;
};

export const checkMaxAge = (value: string) => {
  const date = convertToDateObject(value);
  const age = getAge(date);
  return age <= 100;
};

export const getAge = (dateString: Date) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();

  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

export const checkLatinChars = (value: string | undefined) => {
  if (value) {
    const regex = /^[a-záâãéêíïóôõöúüçñ ]+$/i;
    const test = regex.test(value);
    return test;
  }
  return true;
};

export const cpfValidator = (value = '', alreadyExists = false) => {
  if (alreadyExists) {
    return true;
  }
  const formattedValue = cpf.strip(value);
  return cpf.isValid(formattedValue);
};
