import { isProdEnv } from '@/common/utils/environment/environment';
const errorsTransactions = ['/sc/[staticPageSlug]'];

export const getAppVersion = (value) => {
  const version = value.replaceAll('-', '.');
  const path = value.replaceAll('.', '-');

  return {
    version,
    path,
  };
};

const SentryConfig = {
  release: getAppVersion(process.env.NEXT_PUBLIC_APP_VERSION).path,
  environment: process.env.NODE_ENV,

  beforeSend: (event, hint) => {
    if (!isProdEnv) {
      return event;
    }
    if (errorsTransactions.includes(event.tags.transaction)) {
      return null;
    }

    if (!hint) {
      return event;
    }
    // const { response, config } = hint.originalException;
    // const statusCode = Number(response?.status || 0);

    // We've decided to log just 500 and 403 errors to sentry
    // To first of all get just the essencial errors from the NEXT_PUBLIC_API_URL
    // if (
    //   statusCode < 500 &&
    //   statusCode !== 403 &&
    //   config?.baseURL === process.env.NEXT_PUBLIC_API_URL
    // ) {
    //   return null;
    // }
    // We've decided to ignore errors from static pages.
    // They are created by third-party company and we have no control over them.
    // To effectively track and address our actual errors, we have chosen to ignore the errors on static pages

    return event;
  },
  tracesSampler: (samplingContext) => {
    if (!isProdEnv) {
      return 1;
    }

    if (samplingContext.transactionContext.name === 'GET /api/healthcheck') {
      return 0;
    }

    // We have a lot of transactions with status 0, so we decided to sample them even more
    if (samplingContext.transactionContext.status === '0') {
      return 0.001;
    }

    return 0.01;
  },
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // Avast extension error
    '_avast_submit',
  ],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Third
    /s\.go-mpulse\.net/i,
    /googletagmanager\.com/i,
    /graph\.facebook\.com/i,
    /connect\.facebook\.net\/en_US\/all\.js/i,
    /(https:\/\/)vfr-v3-production\.sizebay\.technology\/\w*/i,
    /(https:\/\/)s\.salecycle\.com\w*/i,
  ],
};

export default SentryConfig;
