import { DesktopView } from '@/common/components/Breakpoints/Breakpoints';
import Container from '@/common/components/Container/Container';
import { HEADER_MAX_WIDTH } from '@/common/constants/constants';
import {
  FixedHeader,
  Header as StyledHeader,
  SubHeaderBG,
} from './Header.styled';
import { HeaderLayoutProps } from './Header.types';
import MainHeader from './components/MainHeader/MainHeader';
import SubHeader from './components/SubHeader/SubHeader';

const Header = ({
  layout = 'default',
  searchFilters,
  append,
  prepend,
  labels = {
    signInButtonLabel: 'Entrar',
    helloLabel: 'Olá,',
    myAccountLabel: 'Minha conta',
    myOrdersLabel: 'Meus pedidos',
    myFavorites: 'Meus Favoritos',
  },
}: HeaderLayoutProps) => {
  return (
    <StyledHeader data-testid="header" id="navigationHeader" layout={layout}>
      <FixedHeader layout={layout}>
        {prepend}
        <DesktopView shouldRenderOnServer>
          <SubHeaderBG>
            <Container maxWidth={HEADER_MAX_WIDTH}>
              <SubHeader layout={layout} labels={labels} />
            </Container>
          </SubHeaderBG>
        </DesktopView>
        <Container maxWidth={HEADER_MAX_WIDTH}>
          <MainHeader
            layout={layout}
            searchFilters={searchFilters}
            labels={labels}
          />
        </Container>
        {append}
      </FixedHeader>
    </StyledHeader>
  );
};

export default Header;
