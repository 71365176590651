import useUnleash from '@/common/hooks/useUnleash/useUnleash';
import { StaticImage } from '@/components/Image';
import { FooterProps } from '../../Footer';
import { SectionTitle } from '../../Footer.styled';
import {
  PaymentMethod,
  PaymentsGrid,
  PaymentsWrapper,
} from './Payments.styled';

const Payments = ({ layout = 'default' }: FooterProps) => {
  const isBankSlipActive = useUnleash('nike-front.boleto');
  return (
    <PaymentsWrapper data-testid="footer-payments">
      <SectionTitle>Formas de pagamento</SectionTitle>

      <PaymentsGrid>
        <PaymentMethod>
          <StaticImage
            src="/images/paymentMethods/mastercard.svg"
            alt="Mastercard"
            width="35rem"
            height="22rem"
            disablePlaceholder
          />
        </PaymentMethod>

        <PaymentMethod>
          <StaticImage
            src="/images/paymentMethods/visa.svg"
            alt="Visa"
            width="40rem"
            height="16rem"
            disablePlaceholder
          />
        </PaymentMethod>

        <PaymentMethod>
          <StaticImage
            src="/images/paymentMethods/amex.svg"
            alt="Amex"
            width="27rem"
            height="20rem"
            disablePlaceholder
          />
        </PaymentMethod>

        <PaymentMethod>
          <StaticImage
            src="/images/paymentMethods/elo.svg"
            alt="Elo"
            width="22rem"
            height="22rem"
            disablePlaceholder
          />
        </PaymentMethod>

        <PaymentMethod>
          <StaticImage
            src="/images/paymentMethods/hipercard.svg"
            alt="Hipercard"
            width="38rem"
            height="16rem"
            disablePlaceholder
          />
        </PaymentMethod>

        <PaymentMethod>
          <StaticImage
            src="/images/paymentMethods/discover.svg"
            alt="Discover"
            width="37rem"
            height="10rem"
            disablePlaceholder
          />
        </PaymentMethod>
        {!layout.includes('snkrs') && (
          <>
            {isBankSlipActive && (
              <PaymentMethod>
                <StaticImage
                  src="/images/paymentMethods/boleto.svg"
                  alt="Boleto"
                  width="32rem"
                  height="16rem"
                  disablePlaceholder
                />
              </PaymentMethod>
            )}
            <PaymentMethod>
              <StaticImage
                src="/images/paymentMethods/pix.svg"
                alt="Pix"
                width="22rem"
                height="22rem"
                disablePlaceholder
              />
            </PaymentMethod>
          </>
        )}
      </PaymentsGrid>
    </PaymentsWrapper>
  );
};

export default Payments;
