import { useMenu } from '@/common/patterns/Menu/menuContext';
import {
  HeaderMenuMobileContainer,
  MobileDrawerContentContainer,
} from '../../Header.styled';
import BackButton from './BackButton/BackButton';

type DrawerContentMobileProps = {
  children: React.ReactNode;
};

const DrawerContentMobile = ({ children }: DrawerContentMobileProps) => {
  const { categoryStack, popCategoryStack } = useMenu();

  // The category name will be shown after the second level of the stack
  const category = categoryStack[categoryStack.length - 2];
  const hasCategoryStack = !categoryStack.length;

  // TODO: Create a login spacer component
  return (
    <>
      {!hasCategoryStack && (
        <HeaderMenuMobileContainer>
          <BackButton text={category ?? 'Voltar'} action={popCategoryStack} />
        </HeaderMenuMobileContainer>
      )}
      <MobileDrawerContentContainer canScroll={hasCategoryStack}>
        {children}
      </MobileDrawerContentContainer>
    </>
  );
};

export default DrawerContentMobile;
