import { Link as Anchor } from '@sbf/fairplay-nike';
import Link from 'next/link';
import { AnchorHTMLAttributes, memo } from 'react';
import {
  DesktopView,
  MobileView,
} from '@/common/components/Breakpoints/Breakpoints';
import { SectionTitle, VerticalGrid } from '../../Footer.styled';
import {
  AccordionDetails,
  AccordionDiv,
  AccordionSummary,
} from './CollapsibleLinks.styled';

type Link = {
  text: string;
  href: string;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

type CollapsibleLinksProps = {
  id: string;
  title: string;
  links: Link[];
};

const CollapsibleLinks = ({ id, title, links }: CollapsibleLinksProps) => {
  const grid = (
    <VerticalGrid size="small">
      {links.map(({ text, href, ...linkProps }) => (
        <Link key={text} href={href} passHref legacyBehavior>
          <Anchor
            {...linkProps}
            variant="secondary"
            size="tiny"
            type="quiet"
            text={text}
          />
        </Link>
      ))}
    </VerticalGrid>
  );

  return (
    <div data-testid={id}>
      <MobileView>
        <AccordionDiv>
          <AccordionDetails>
            <AccordionSummary id={`${id}-header`}>{title}</AccordionSummary>
            {grid}
          </AccordionDetails>
        </AccordionDiv>
      </MobileView>
      <DesktopView>
        <SectionTitle>{title}</SectionTitle>
        {grid}
      </DesktopView>
    </div>
  );
};

export default memo(CollapsibleLinks);
