import { Button } from '@sbf/fairplay-core';
import { NikeCloseLarge } from '@sbf/fairplay-icons';
import { nike } from '@sbf/fairplay-tokens';
import styled, { css } from 'styled-components';

export const StyledBox = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 30rem;
    bottom: 0;
    left: -20%;
    right: 0;
    z-index: ${theme.zIndex[400]};
    height: ${theme.spaces[700]};
    width: ${theme.spaces[700]};
  `}
`;

export type CloseButtonProps = {
  onClick: () => void;
};

const CloseButton = ({ onClick }: CloseButtonProps) => {
  return (
    <StyledBox>
      <Button.Icon
        shape="rounded"
        icon={<NikeCloseLarge color={nike.colors.secondary} />}
        title="Fechar menu"
        onClick={onClick}
      />
    </StyledBox>
  );
};

export default CloseButton;
