import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getCoreConfig } from '@/common/config/config';
import { client } from '@/common/services/api/client/client';
import { type ClientError } from '@/common/services/api/types';
import { getStorageItem, setStorageItem } from '@/helpers/localStorage';
import {
  PostSignInSellerResponse,
  SellerProps,
  SignInSellerProps,
  StoreProps,
} from '../../types/LoginSeller.types';
import { OptionsProps } from '../../types/StoresList.types';

export const POST_LOGIN_SELLER = '/v4/assistentes/login';

export const storeBucket = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_STORE_API}`,
});

export const postSignInSeller = async (
  gateway: string,
  login: string,
  password: string,
) => {
  return await client.post<PostSignInSellerResponse>(POST_LOGIN_SELLER, {
    Matricula: login,
    CPF: password,
    GatewayIp: gateway,
  });
};

export const getStores = async () => {
  const { data } = await storeBucket.get<[]>('', {
    params: {
      alt: 'media',
    },
  });

  return data;
};

export const useStoresQuery = () => {
  return useQuery<Array<OptionsProps>>(
    ['stores'],
    async () => {
      const data = await getStores();

      const parsedStores = data.map((data: StoreProps) => {
        const [prefix] = data.name.split(' ');
        const newDescription = `${prefix}${data.establishmentId} - ${data.name}`;

        return {
          id: data.establishmentId,
          description: newDescription,
          selected: false,
          gateway: process.env.NEXT_PUBLIC_STORE_GATEWAY,
        };
      });

      return parsedStores as Array<OptionsProps>;
    },
    {
      refetchOnMount: true,
      retryOnMount: false,
    },
  );
};

export const useSellerQuery = () => {
  const context = useQuery<SellerProps | undefined>(
    ['seller'],
    async () => {
      const seller = getStorageItem<string>('assistedSaleStore') as unknown;
      if (!seller) {
        return;
      }

      const response = seller as SellerProps;
      return response;
    },
    {
      refetchOnMount: true,
      retryOnMount: false,
    },
  );

  return context;
};

export const usePostSignInSellerMutation = () => {
  const queryClient = useQueryClient();
  const { publicRuntimeConfig } = getCoreConfig();

  return useMutation<PostSignInSellerResponse, ClientError, SignInSellerProps>(
    async (item) => {
      const response = await postSignInSeller(
        item.gateway,
        item.login,
        item.password,
      );
      return response.data;
    },
    {
      onSuccess: (data, item) => {
        setStorageItem('assistedSaleStore', {
          name: data.nome,
          sellerId: data.matricula,
          store: item.store,
          description: item.description,
          token: publicRuntimeConfig.assistedSale.teleSalesToken,
          xNikeWaf: publicRuntimeConfig.assistedSale.storeHeader,
        });

        queryClient.invalidateQueries('seller');
      },
    },
  );
};
