import { isFuture, isPast } from 'date-fns';

/**
 * Evaluates if today is between the first and the second date.
 * If first date or second date are falsy values, it returns true
 *
 * @param firstDate string
 * @param secondDate string
 * @returns boolean
 */
export const isDateBetween = (firstDate = '', secondDate = '') => {
  if (!firstDate && !secondDate) {
    return true;
  }

  const startDate = new Date(firstDate || 0);
  const endDate = new Date(secondDate || Date.now() + 10000);

  return isPast(startDate) && isFuture(endDate);
};
