import { PRODUCT_IMAGE_FALLBACK } from '@/common/components/Img/constants';
import { getCoreConfig } from '@/common/config/config';

type LoaderType = {
  src: string;
  width: number;
};

type ImageLoader = (resolverProps: LoaderType) => string;

const { publicRuntimeConfig } = getCoreConfig();

export const akamaiLoader = ({ src, width }: LoaderType) => {
  if (src === PRODUCT_IMAGE_FALLBACK) {
    return src;
  }

  const urlImage = src
    ? `https://imgnike-a.akamaihd.net/${width}x${width}/${src}.jpg`
    : PRODUCT_IMAGE_FALLBACK;

  return urlImage;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const staticLoader: ImageLoader = ({ src, width }) => {
  return `${publicRuntimeConfig.cdnPaths.staticPath}${src}`;
};

export type EnumLoaderType = 'akamai' | 'static';

export const LOADER_NAME_AKAMAI = 'akamai';
export const LOADER_NAME_STATIC = 'static';

export const LOADER_MAPPER: { [key in EnumLoaderType]?: ImageLoader } = {
  [LOADER_NAME_AKAMAI]: akamaiLoader,
  [LOADER_NAME_STATIC]: staticLoader,
};

export const getLoader = (name: EnumLoaderType) => LOADER_MAPPER[name];
