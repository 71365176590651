import { createMedia } from '@artsy/fresnel';
import styled from 'styled-components';
import { isClientSide } from '@/common/utils/environment/environment';

const fairplayBreakpoints = {
  xs: 0,
  sm: 768,
  md: 900,
  lg: 1024,
  xl: 1192,
};

export const MediaContainer = styled.div`
  width: auto;
`;

const NikeMedia = createMedia({
  breakpoints: fairplayBreakpoints,
  interactions: {
    hover: '(pointer: coarse), (-moz-touch-enabled: 1)',
    notHover:
      'not all and (pointer: coarse), not all and (-moz-touch-enabled: 1)',
  },
});

type MediaViewTypeProps = {
  children: React.ReactNode;
  shouldRenderOnServer?: boolean;
};

export const DesktopView = ({
  children,
  shouldRenderOnServer,
}: MediaViewTypeProps) => {
  return (
    <Media greaterThanOrEqual="lg">
      {(className, renderChildren) => {
        return (
          <MediaContainer className={className}>
            {renderChildren && (isClientSide() || shouldRenderOnServer)
              ? children
              : null}
          </MediaContainer>
        );
      }}
    </Media>
  );
};

export const MobileView = ({ children }: MediaViewTypeProps) => {
  return (
    <Media lessThan="lg">
      {(className, renderChildren) => {
        return (
          <MediaContainer className={className}>
            {renderChildren ? children : null}
          </MediaContainer>
        );
      }}
    </Media>
  );
};

// Make styles for injection into the header of the page
export const mediaStyles = NikeMedia.createMediaStyle();

const { Media, MediaContextProvider: MediaProvider } = NikeMedia;

const MediaContextProvider = ({ children }: { children: React.ReactNode }) => (
  <MediaProvider>{children}</MediaProvider>
);

export { Media, MediaContextProvider };
