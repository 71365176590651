import axios from 'axios';
import { getCanonicalNavValue } from '@/common/utils/metadata/metadata';
import { CompleteOrderDetails } from '@/modules/checkout/services/completeOrder/completeOrder.types';
import {
  getTrackingAddToCartUrl,
  getTrackingCompleteOrdertUrl,
} from '@/modules/sizebay/transformers/sizebayUrl/sizebay';
import {
  AddToCartProduct,
  AddToCartType,
  CompleteOrder,
} from './sizebay.types';

const TENANT_ID = '1683';

export const trackAddToCart = (permalinks: string[]) => {
  const url = getTrackingAddToCartUrl();

  if (permalinks.length == 0) {
    return;
  }

  const addToCart = { tenantId: TENANT_ID } as AddToCartType;

  const addToCartProducts = [] as AddToCartProduct[];
  permalinks.forEach((permalink) =>
    addToCartProducts.push({ permalink: permalink }),
  );

  addToCart.products = addToCartProducts;

  if (url && permalinks.length > 0) {
    return axios.post(url, addToCart);
  }
};

export const trackCompleteOrder = (order: CompleteOrderDetails) => {
  const url = getTrackingCompleteOrdertUrl();

  if (!order.id) {
    return;
  }

  const completeOrder = {
    tenantId: TENANT_ID,
    orderId: order.id.toString(),
  } as CompleteOrder;

  completeOrder.items = order.itens.map(
    ({ urlModeloReal, valorTotal, quantidade, tamanho }) => ({
      permalink: getCanonicalNavValue(urlModeloReal),
      price: parseFloat(valorTotal),
      quantity: quantidade,
      size: tamanho,
    }),
  );

  if (url && completeOrder.items.length > 0) {
    return axios.post(url, completeOrder);
  }
};

export const sendTrackToSizebay = (hrefs: (string | undefined)[]) => {
  const filteredHrefs = hrefs.filter((href) => href) as string[];
  if (filteredHrefs.length > 0) {
    trackAddToCart(filteredHrefs);
  }
};
