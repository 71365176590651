import { Button } from '@sbf/fairplay-core';
import { media } from '@sbf/fairplay-utils';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.decorative.running};
    width: 100%;
    height: 32rem;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: ${theme.spaces[0]} ${theme.spaces[600]};

    ${media.lessThan('desktop')} {
      padding: ${theme.spaces[0]} ${theme.spaces[300]};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    height: 100%;
    width: 100%;
    max-width: 1920rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: ${theme.spaces[200]} ${theme.spaces[0]};

    > p {
      color: ${theme.colors.secondary};
      padding: ${theme.spaces[0]};
      font-weight: ${theme.fontWeights.medium};
    }
  `}
`;

export const LogoutButton = styled(Button.Outline)`
  ${({ theme }) => css`
    border: none;
    background-color: ${theme.colors.decorative.running};
    box-sizing: border-box;
    color: ${theme.colors.secondary};
    font-size: ${theme.fontSizes.tiny};
    font-weight: ${theme.fontWeights.medium};
    padding: ${theme.spaces[0]};
  `}
`;
