import Script from 'next/script';

const OnePixelScripts = () => {
  return (
    <Script
      id="onepixel-script"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `var i=new Image,u="https://s3-sa-east-1.amazonaws.com/frame-image-br/bg.png?x-id=46b1257f0fe8e65c31aadb007200b2c2&x-r="+document.referrer+"&x-s="+window.location.href;i.src=u;
    `,
      }}
    />
  );
};

export default OnePixelScripts;
