import { push as _push } from '@/common/utils/tracking/thirdParty/gtm/gtm';

/**
 * This function abstract the data layer calls
 *
 * @param prop information to populate the data layer
 * @deprecated Use push from '@/common/utils/tracking/thirdParty/gtm' instead
 */
export const push = _push; // export for backwards compatibility

/**
 * This function pushed the page information in data layer
 *
 * @param pageName
 * @param prop information to populate the data layer
 */
export const pushPageInfos = (pageName: string, prop: unknown) => {
  _push({
    [pageName]: prop,
  });
};

/**
 * This function was create to replace the GTM Page View
 *
 * CAUTION: This function can only be called once on first page load
 */
export const virtualPageView = () => {
  _push({
    event: 'virtualPageView',
  });
};

/**
 * This function is a custom event to represente change route between pages
 *
 * CAUTION: This function need to run when user navigate between pages but
 * can't be called on first page load but we need
 *
 */
export const changeRoute = () => {
  _push({
    event: 'changeRoute',
  });
};
