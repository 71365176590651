import uniqBy from 'lodash/uniqBy';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { getCartInfo } from '@/helpers/checkout';
import { Cart as CartType } from '@/modules/checkout/services/cart/cart.types';
import {
  Product,
  ShippingModalityTypesProps,
} from '@/modules/freight/types/ShippingModalityTypes.types';

export enum DELIVERY_MODES {
  Normal = 1,
  Agendada = 2,
  Expressa = 9,
}

export type CheckoutModalityType = {
  deliveryType: DELIVERY_MODES;
  label: string;
  selectedPeriod: number;
  startDate: Date;
};

export type ContextTracker = {
  zipCode: string;
  itens: Product[];
  mensagensCarrinhoTodos: string | string[] | null;
  deliveryGroup: Array<string | number>;
  deliveryType:
    | {
        id: number | undefined;
        type: string;
      }[]
    | undefined;
};

export type ObjectData = {
  address: string;
  addressFullData: ShippingModalityTypesProps;
  clearSale: {
    sessionId: string;
  };
  goToPayment: boolean;
  isCartAPILoading: boolean;
  modalityTypes: {
    [key: string]: CheckoutModalityType;
  };
  purchaseSummary: { [k: string]: unknown };
  isFastBuy: boolean;
  getContextToTracking: ContextTracker;
};

type CheckoutContextData = {
  checkoutValues: ObjectData;
  setCheckoutStepValues: (values: Partial<ObjectData>) => void;
  setPurchaseSummary: (cartData: CartType) => void;
  setModalityTypes: (
    modality: CheckoutModalityType,
    distributionCenter: string | number,
  ) => void;
  initModalityTypes: (distributionCenters: (string | number)[]) => void;
  getContextToTracking: () => ContextTracker;
};

export type CheckoutProviderProps = {
  children: ReactNode;
};

export const initialState: ObjectData = {
  address: '',
  addressFullData: {} as ShippingModalityTypesProps,
  modalityTypes: {},
  goToPayment: false,
  isCartAPILoading: false,
  clearSale: {
    sessionId: '',
  },
  purchaseSummary: {},
  isFastBuy: false,
  getContextToTracking: {} as ContextTracker,
};

export const CheckoutContext = createContext({
  checkoutValues: initialState,
} as CheckoutContextData);

function CheckoutProvider({ children }: CheckoutProviderProps) {
  const [checkoutValues, setCheckoutValues] =
    useState<ObjectData>(initialState);

  const handleCheckout: CheckoutContextData['setCheckoutStepValues'] =
    useCallback((values) => {
      setCheckoutValues((prevState) => ({
        ...prevState,
        ...values,
      }));
    }, []);

  const setPurchaseSummary = (cartData: CartType) => {
    setCheckoutValues((prevState) => ({
      ...prevState,
      purchaseSummary: getCartInfo(cartData),
    }));
  };

  const setModalityTypes = (
    modality: CheckoutModalityType,
    distributionCenter: string | number,
  ) => {
    setCheckoutValues((prevState) => ({
      ...prevState,
      modalityTypes: {
        ...prevState.modalityTypes,
        [distributionCenter]: modality,
      },
    }));
  };

  const getContextToTracking = () => {
    const { addressFullData } = checkoutValues;
    const { mensagensCarrinhoTodos = '', enderecos = [] } =
      addressFullData || {};
    const deliveryType = (
      addressFullData?.enderecos?.[0].grupoEntrega || []
    ).flatMap((res) => {
      return res.tiposDeEntrega;
    });
    const deliveryGroup = enderecos.flatMap((res) => res.grupoEntrega);
    const itens = (deliveryType || []).flatMap((res) => res.itens);
    return {
      zipCode: enderecos?.[0]?.cep || '',
      itens: itens && uniqBy(itens, 'itemCarrinhoId'),
      mensagensCarrinhoTodos,
      deliveryGroup: (deliveryGroup || []).flatMap(
        (res) => res.centroDistribuicaoId,
      ),
      deliveryType: (deliveryType || []).flatMap((res) => {
        return {
          id: res.centroDistribuicao,
          type: res.tituloEntrega,
        };
      }),
    };
  };

  const initModalityTypes = useCallback(
    (distributionCenters: (string | number)[]) => {
      const initialModalityTypes = distributionCenters.reduce<{
        [k: string]: CheckoutModalityType;
      }>((acc, dc) => {
        acc[dc] = {
          deliveryType: 1,
          label: '',
          selectedPeriod: 0,
          startDate: new Date(),
        };
        return acc;
      }, {});
      setCheckoutValues((prevState) => ({
        ...prevState,
        modalityTypes: initialModalityTypes,
      }));
    },
    [],
  );

  return (
    <CheckoutContext.Provider
      value={{
        checkoutValues,
        setCheckoutStepValues: handleCheckout,
        setPurchaseSummary,
        setModalityTypes,
        initModalityTypes,
        getContextToTracking,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
}

export const useCheckout = () => {
  return useContext(CheckoutContext);
};

export default CheckoutProvider;
