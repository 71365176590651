import {
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useRef,
  useState,
} from 'react';

type InitialState = {
  searchFilters: boolean;
};

type UIContext = 'searchFilters';

export const initialState = {
  searchFilters: false,
};

type WebsiteUIContextProps = {
  isOpen: InitialState;
  searchGridColumns: number;
  setSearchValue: Dispatch<SetStateAction<string>>;
  searchValue: string;
  isEmailFlagReady: MutableRefObject<boolean>;
  isCookieIdFlagReady: MutableRefObject<boolean>;
  setSearchGridColumns: Dispatch<SetStateAction<number>>;
  setOpen: (context: UIContext) => void;
  setClose: (context: UIContext) => void;
};

export type WebsiteUIContextPropsProviderProps = {
  children: ReactNode;
};

export const WebsiteUIContext = createContext({
  isOpen: initialState,
} as WebsiteUIContextProps);

export function WebsiteUIContextProvider({
  children,
}: WebsiteUIContextPropsProviderProps) {
  const [isOpen, setIsOpen] = useState(initialState);
  const [searchGridColumns, setSearchGridColumns] = useState(3);
  const [searchValue, setSearchValue] = useState('');
  const isEmailFlagReady = useRef(false);
  const isCookieIdFlagReady = useRef(false);

  const setOpen: WebsiteUIContextProps['setOpen'] = (context) =>
    setIsOpen({ ...isOpen, [context]: true });
  const setClose: WebsiteUIContextProps['setClose'] = (context) =>
    setIsOpen({ ...isOpen, [context]: false });

  return (
    <WebsiteUIContext.Provider
      value={{
        setSearchValue,
        searchValue,
        isOpen,
        setOpen,
        setClose,
        searchGridColumns,
        setSearchGridColumns,
        isEmailFlagReady,
        isCookieIdFlagReady,
      }}
    >
      {children}
    </WebsiteUIContext.Provider>
  );
}

export const useWebsiteUIContext = () => {
  return useContext(WebsiteUIContext);
};
