import { Link } from '@sbf/fairplay-nike';
import { media } from '@sbf/fairplay-utils';
import styled, { css } from 'styled-components';

export const StyledDrawer = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    list-style-type: none;
    background-color: ${theme.colors.neutral[100]};

    ${media.lessThan('desktop')} {
      padding: ${theme.spaces[0]} ${theme.spaces[400]};
    }
  `}
`;

export const StyledDrawerItem = styled.li`
  ${({ theme }) => css`
    display: flex;
    align-items: start;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    padding: ${theme.spaces[300]} ${theme.spaces[0]};

    &:first-child {
      padding: ${theme.spaces[0]};
    }
  `}
`;

export const StyledLink = styled(Link)`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.medium};
    text-decoration: none;
    font-size: ${theme.fontSizes.large};
    padding: ${theme.spaces[0]};

    &.focus-visible {
      border-radius: ${theme.borderRadius.none};
      z-index: ${theme.zIndex[100]};
    }
  `}
`;
