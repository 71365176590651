import { parseCookies, setCookie } from 'nookies';
import { v4 as uuidv4 } from 'uuid';
import { UnknownObject } from '@/common/types/objects';
import { sendEventGtag } from './thirdParty/gtag/gtag';
import { pushPageInfos, sendEventGtm } from './thirdParty/gtm/gtm';
import {
  ExceptionErrorTypes,
  MessageErrorTypes,
  sentryExceptionError,
  sentryMessageError,
} from './thirdParty/sentry/sentry';

export type TrackingExceptionErrorType = ExceptionErrorTypes;
export type TrackingMessageErrorType = MessageErrorTypes;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trackingEventOLD = (trackingData: any): void => {
  sendEventGtag({ ...trackingData });
};

export const trackingEvent = (trackingData: UnknownObject): void => {
  sendEventGtm({ ...trackingData });
};

export const trackingPageInfos = (
  pageName: string,
  trackingData: UnknownObject,
): void => {
  pushPageInfos(pageName, { ...trackingData });
};

export const trackingExceptionError = (
  trackingErrorData: TrackingExceptionErrorType,
): void => {
  sentryExceptionError(trackingErrorData);
};

export const trackingMessageError = (
  trackingErrorData: TrackingMessageErrorType,
): void => {
  sentryMessageError(trackingErrorData);
};

export const setAnonymousId = () => {
  if (getAnonymousId()) {
    return;
  }
  const anonymousid = uuidv4().replace(/^["'](.+(?=["']$))["']$/, '$1');
  setCookie(null, 'anonymousid', anonymousid, {
    path: '/',
  });
};

export const getAnonymousId = () => {
  return parseCookies()['anonymousid'];
};
