/**
 * The sale channel origin
 * @readonly
 * @enum {number}
 * @property {number} Desktop origin.
 * @property {number} Mobile origin.
 */
export enum SALES_CHANNEL {
  Desktop = 1,
  Mobile = 7,
  AssistedSale = 29,
}
