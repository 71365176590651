import { media } from '@sbf/fairplay-utils';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

type Props<C extends React.ElementType> = {
  title: string;
  icon: React.ReactNode;
  as?: C;
};

type ButtonProps<C extends React.ElementType> = Props<C> &
  Omit<React.ComponentPropsWithRef<C>, keyof Props<C>>;

type StyledButtonProps<C extends React.ElementType> = Omit<
  ButtonProps<C>,
  'icon' | 'title'
>;

const StyledButton = styled.button<StyledButtonProps<React.ElementType>>`
  ${({ theme }) => css`
    line-height: ${theme.lineHeights.tight};
    cursor: pointer;
    outline: none;
    box-sizing: border-box;
    border: none;
    display: inline-block;
    border-radius: ${theme.borderRadius.circular};
    background: ${theme.colors.neutral[100]};
    color: ${theme.colors.neutral[700]};

    ${media.greaterThan('desktop')} {
      padding: ${theme.spaces[200]};
    }

    :hover {
      opacity: ${theme.opacity[700]};
    }

    &.focus-visible {
      box-shadow: ${theme.shadow.focus};
    }

    svg {
      vertical-align: top;
      font-size: ${theme.fontSizes.xlarge};
    }
  `}
`;

const ButtonWrapper = <C extends React.ElementType = 'button'>(
  { title, icon, ...props }: ButtonProps<C>,
  ref: React.Ref<HTMLButtonElement | HTMLAnchorElement>,
) => {
  return (
    <StyledButton {...props} ref={ref} aria-label={title}>
      {icon}
    </StyledButton>
  );
};

const HeaderButtonIcon = forwardRef(ButtonWrapper) as <
  C extends React.ElementType = 'button',
>(
  props: ButtonProps<C> & {
    ref?: React.Ref<HTMLButtonElement | HTMLAnchorElement>;
  },
) => React.ReactElement;

export default HeaderButtonIcon;
