import Image, { type ImgProps } from '@/common/components/Img/Img';
import { GetObjSameKeys } from '@/common/types/keys';
import { isProdReleaseEnv } from '@/common/utils/environment/environment';
import { EnumLoaderType, getLoader } from './loaders';

type srcImgPropsType = Pick<ImgProps, 'src'>;
type srcProductImageType = { src: undefined };

type srcImgType = GetObjSameKeys<srcImgPropsType, srcProductImageType>;

type StaticImageProps = ImgProps &
  srcImgType & {
    loader?: EnumLoaderType;
    disablePlaceholder?: boolean;
  };

const StaticImage = ({
  src,
  alt,
  width,
  height,
  disablePlaceholder = false,
  ...props
}: StaticImageProps) => {
  const isPuclicImage = (src as string)?.startsWith('/');

  const turnOnLoader =
    isPuclicImage && isProdReleaseEnv
      ? { loader: getLoader('static') }
      : { unoptimized: true };

  if (disablePlaceholder) {
    return (
      <Image
        src={src}
        alt={alt}
        width={width}
        height={height}
        {...turnOnLoader}
        {...props}
      />
    );
  }

  return (
    <Image
      src={src}
      alt={alt}
      width={width}
      height={height}
      placeholder="blur"
      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPstAcAAVcAyrKrMM4AAAAASUVORK5CYII="
      {...turnOnLoader}
      {...props}
    />
  );
};

export default StaticImage;
