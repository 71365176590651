import { newMenuMock } from '@/common/patterns/Menu/mock/newMenuMock';
import { Menu, MenuItem } from '@/common/patterns/Menu/types';
import { trackingMessageError } from '@/common/utils/tracking/tracking';

export const isValidMenuItem = (menuItem: MenuItem): boolean => {
  const keys: (keyof MenuItem)[] = ['active', 'order', 'title', 'link'];
  const isSubmenuValid = menuItem.subMenu?.every(isValidMenuItem) ?? true;

  const isItemValid = keys.every(
    (key) => menuItem[key] !== undefined && menuItem[key] !== null,
  );

  return isItemValid && isSubmenuValid;
};

export const validateMenu = (menuData: Menu): Menu => {
  try {
    const isRootValid = Boolean(menuData.content);

    if (!isRootValid) {
      return newMenuMock;
    }

    const isContentValid = menuData.content?.every?.(isValidMenuItem);
    const isMenuValid = isRootValid && isContentValid;

    if (!isMenuValid) {
      trackingMessageError({
        checkpoint: 'intitial_load',
        flow: 'menu()',
        message: 'invalid menu data provided',
        namespace: 'menu',
      });
      return newMenuMock;
    }
    return menuData;
  } catch (e) {
    trackingMessageError({
      checkpoint: 'intitial_load',
      flow: 'menu()',
      message: 'error during menu validation',
      extraDetails: {
        errorMessage: e.message,
      },
      namespace: 'menu',
    });
    return newMenuMock;
  }
};
