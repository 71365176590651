import { currencyFormatter } from '@/helpers/formatter';
import {
  Cart,
  CartDataType,
} from '@/modules/checkout/services/cart/cart.types';
import {
  CompleteOrderDetails,
  DetailsOrderStatusType,
  DetailsOrderType,
  PAYMENT_STATUS,
} from '@/modules/checkout/services/completeOrder/completeOrder.types';

/**
 * return cart info
 */
export const getCartInfo = (cart: Cart | undefined): CartDataType => {
  const items = cart?.itens || [];
  const quantity = cart?.quantidadeTotalItens ?? 0;
  const maxQuantityPerProduct = cart?.quantidadeMaximaDeItensPorProduto ?? 10;
  const discount = cart?.desconto ?? '0,00';
  const totalPrice = cart?.total ?? '0,00';
  const subTotalPrice = cart?.subTotal ?? '0,00';
  const giftCardValue = cart?.valorCartaoPresente ?? '0,00';
  const totalSavings = cart?.valorEconomizado ?? '0,00';
  const codigoValeTroca = cart?.codigoValeTroca ?? null;
  const freeFreight = cart?.freteGratis;
  const promocode = cart?.promocode ?? '';
  const messagesCartAll = cart?.mensagensCarrinhoTodos ?? [''];
  const cep = cart?.cep ?? undefined;
  const itemsWithoutDiscount = cart?.subTotalItensSemDesconto || '0,00';
  const subTotalWithoutDiscount = cart?.subTotalSemDesconto || '0,00';

  let freightValue = '';
  if (!freeFreight) {
    freightValue = cart?.frete !== '0' ? cart?.frete ?? '0,00' : 'A calcular';
  } else {
    freightValue = 'Grátis';
  }

  return {
    items,
    quantity,
    maxQuantityPerProduct,
    discount,
    freightValue,
    totalPrice,
    totalSavings,
    subTotalPrice,
    giftCardValue,
    codigoValeTroca,
    promocode,
    messagesCartAll,
    cep,
    itemsWithoutDiscount,
    subTotalWithoutDiscount,
  };
};

export const getDetailsOrderInfo = (
  detailsOrder: CompleteOrderDetails | undefined,
): DetailsOrderType => {
  const bankSlipCode = detailsOrder?.codigoBoleto ?? null;
  const bankSlipUrl = detailsOrder?.urlBoleto ?? null;
  const number = detailsOrder?.id;
  const paymentType = detailsOrder?.codigoEspeciePagamento;
  const totalValue = currencyFormatter(Number(detailsOrder?.valorTotal));
  const installmentValue = currencyFormatter(
    Number(detailsOrder?.valorParcela),
  );
  const pixCode = detailsOrder?.pix?.codigo;
  const pixDataVencimento = detailsOrder?.pix?.dataVencimento;
  const paymentLinkUrl = detailsOrder?.urlLinkDePagamento;
  const status = (detailsOrder?.status ||
    PAYMENT_STATUS.WAITING) as DetailsOrderStatusType;
  const addressState = detailsOrder?.enderecoDeEntrega.uf;

  return {
    bankSlipCode,
    bankSlipUrl,
    number,
    paymentType,
    totalValue,
    installmentValue,
    pixCode,
    pixDataVencimento,
    paymentLinkUrl,
    status,
    addressState,
  };
};
