import { AppSeoProps } from '@/common/components/AppSeo/AppSeo';
import { getCoreConfig } from '@/common/config/config';
import { isProdReleaseEnv } from '../utils/environment/environment';

const {
  publicRuntimeConfig: {
    cdnPaths,
    seo: { facebookId, googleSiteVerification },
  },
} = getCoreConfig();

const DEFAULT_TITLE = 'Nike.com';
const URL = 'https://nike.com.br';
const THEME_COLOR = '#ffffff';
const META_IMAGES_PATH = '/images/meta';
const META_IMAGES_URL = isProdReleaseEnv
  ? `${cdnPaths.basePath}${META_IMAGES_PATH}`
  : META_IMAGES_PATH;

export const getSeoConfig = (): AppSeoProps => ({
  seoData: {
    defaultSeo: {
      defaultTitle: DEFAULT_TITLE,
      facebook: {
        appId: facebookId,
      },
      openGraph: {
        type: 'website',
        locale: 'pt_BR',
        site_name: DEFAULT_TITLE,
      },
      twitter: {
        site: '@nike',
        handle: '@nike',
        cardType: 'summary',
      },
      additionalMetaTags: [
        {
          name: 'google-site-verification',
          content: googleSiteVerification,
        },
        {
          name: 'msapplication-TileColor',
          content: THEME_COLOR,
        },
        {
          name: 'msapplication-TileImage',
          content: `${META_IMAGES_URL}/ms-icon-144x144.png`,
        },
        {
          name: 'msapplication-config',
          content: `${META_IMAGES_URL}/browserconfig.xml`,
        },
        {
          name: 'theme-color',
          content: THEME_COLOR,
        },
      ],
      additionalLinkTags: [
        {
          rel: 'apple-touch-icon',
          sizes: '57x57',
          href: `${META_IMAGES_URL}/apple-icon-57x57.png`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '60x60',
          href: `${META_IMAGES_URL}/apple-icon-60x60.png`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '72x72',
          href: `${META_IMAGES_URL}/apple-icon-72x72.png`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '76x76',
          href: `${META_IMAGES_URL}/apple-icon-76x76.png`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '114x114',
          href: `${META_IMAGES_URL}/apple-icon-114x114.png`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '120x120',
          href: `${META_IMAGES_URL}/apple-icon-120x120.png`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '144x144',
          href: `${META_IMAGES_URL}/apple-icon-144x144.png`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '152x152',
          href: `${META_IMAGES_URL}/apple-icon-152x152.png`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: `${META_IMAGES_URL}/apple-icon-180x180.png`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '192x192',
          href: `${META_IMAGES_URL}/android-icon-192x192.png`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: `${META_IMAGES_URL}/favicon-32x32.png`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '96x96',
          href: `${META_IMAGES_URL}/favicon-96x96.png`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: `${META_IMAGES_URL}/favicon-16x16.png`,
        },
        {
          rel: 'manifest',
          href: `${META_IMAGES_URL}/manifest.json`,
        },
      ],
    },
    corporateContact: {
      url: URL,
      logo: `${META_IMAGES_URL}/swoosh-logo-black.png`,
      contactPoint: [
        {
          telephone: '+55 (11) 4935-2323',
          contactType: 'Fale conosco',
          areaServed: 'BR',
          availableLanguage: ['Brazilian Portuguese'],
        },
      ],
    },
    socialProfile: {
      type: 'Organization',
      name: DEFAULT_TITLE,
      url: URL,
      sameAs: [
        'https://www.facebook.com/nike',
        'https://www.youtube.com/user/nike',
        'https://www.instagram.com/nike/',
        'https://www.twitter.com/Nike/',
      ],
    },
    siteLinksSearchBox: {
      url: URL,
      potentialActions: [
        {
          target: `${URL}/busca?term`,
          queryInput: 'search_term_string',
        },
      ],
    },
  },
});
