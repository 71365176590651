import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from 'react-query';
import { getCoreConfig } from '@/common/config/config';
import { getCorePaths } from '@/common/config/paths';
import { client } from '@/common/services/api/client/client';
import { clientBFF } from '@/common/services/api/clientBFF/clientBFF';
import { type ClientError } from '@/common/services/api/types';
import { setUserOnSentryContext } from '@/common/utils/tracking/thirdParty/sentry/sentry';
import {
  DocumentResponse,
  ErrorResponse,
  GetAccountResponse,
  GetAddressResponse,
  GetKeycloakTokenResponse,
  GetRegistrationResponse,
  GetZipCodeResponse,
  PostAddressBody,
  PostDocumentCheckerError,
  PostDocumentCheckerParams,
  PostDocumentCheckerResponse,
  PostKeycloakTokenResponse,
  PutAddressBody,
  PutRegistrationBody,
  PutRegistrationBodyBFF,
} from '@/modules/myAccount/components/Registration/Registration.types';
import {
  clientRegistrationDocumentChecker,
  clientRegistrationKeycloak,
  serverSideRegistrationKeycloak,
} from '@/modules/myAccount/services/registration/api/client';
import { PutAdditionalInfoBody } from '../../components/Registration/EditPage/AdditionalData/AdditionalData.types';

export const GET_REGISTRATION_QUERY = 'registration';
export const GET_ADDRESS_QUERY = 'address';
export const GET_ACCOUNT_QUERY = 'account';

const { customers } = getCorePaths();
const {
  publicRuntimeConfig: { registration },
} = getCoreConfig();

export const getZipCode = async (zipCode: string) => {
  const response = await client.get(`${customers.zipCode}/${zipCode}`);
  return response.data;
};

export const postCpf = async (cpf: string) => {
  const response = await client.post(customers.cpf, { cpf });
  return response.data;
};

export const postCnpj = async (cnpj: string) => {
  const response = await client.post(customers.cnpj, { cnpj });
  return response.data;
};

export const getRegistration = async () => {
  const response = await client.get(customers.registration);

  return response.data;
};

export const putRegistration = async (data: PutRegistrationBody) => {
  const response = await client.put(customers.registrationPut, data);
  return response.data;
};

export const putRegistrationCPF = async (data: PutRegistrationBodyBFF) => {
  const response = await clientBFF.put(
    customers.registrationPutBFFPhysical,
    data,
  );
  return response.data;
};

export const putRegistrationCNPJ = async (data: PutRegistrationBodyBFF) => {
  const response = await clientBFF.put(
    customers.registrationPutBFFJuridical,
    data,
  );
  return response.data;
};

export const putAdditionalInfo = async (data: PutAdditionalInfoBody) => {
  const response = await clientBFF.put(customers.myAccountInfoBFF, data);
  return response.data;
};

export const getAccountData = async () => {
  const response = await clientBFF.get<GetAccountResponse>(
    customers.myAccountInfoBFF,
  );
  return response.data;
};

export const getAddress = async () => {
  const response = await client.get<GetAddressResponse[]>(customers.address);

  return response.data;
};

export const deleteAddress = async (id: number) => {
  const response = await client.delete(`${customers.address}/${id}`);

  return response.data;
};

export const postAddress = async (data: PostAddressBody) => {
  const response = await client.post(customers.address, data);

  return response.data;
};

export const putAddress = async (data: PutAddressBody) => {
  const response = await client.put(customers.address, data);

  return response.data;
};

export const postKeycloakToken = async () => {
  const params = new URLSearchParams();
  params.append('client_id', 'service-now-cli');
  params.append('client_secret', registration.keycloakClientSecret);
  params.append('grant_type', 'client_credentials');
  const response = await clientRegistrationKeycloak.post(
    customers.keycloakToken,
    params,
  );
  return response.data;
};

export const getKeycloakToken = async () => {
  const response = await serverSideRegistrationKeycloak.get(
    customers.serverSideKeycloakToken,
  );
  return response.data;
};

export const postDocumentChecker = async ({
  documentNumber,
  birthDate,
  token,
}: PostDocumentCheckerParams) => {
  const params = new URLSearchParams();
  params.append('document', documentNumber);
  params.append('birth_date', birthDate);
  const config = {
    headers: {
      'x-access-token': token,
    },
  };
  const response = await clientRegistrationDocumentChecker.post(
    customers.documentChecker,
    params,
    config,
  );
  return response.data;
};

export const useGetZipCodeMutation = (
  options?: UseMutationOptions<GetZipCodeResponse, ErrorResponse, string>,
) => {
  return useMutation(getZipCode, options);
};

export const useGetKeycloakTokenMutation = (
  options?: UseMutationOptions<GetKeycloakTokenResponse, ErrorResponse, void>,
) => {
  return useMutation(getKeycloakToken, options);
};

export const usePostCpfMutation = (
  options?: UseMutationOptions<DocumentResponse, ErrorResponse, string>,
) => {
  return useMutation(postCpf, options);
};

export const usePostCnpjMutation = (
  options?: UseMutationOptions<DocumentResponse, ErrorResponse, string>,
) => {
  return useMutation(postCnpj, options);
};

export const usePutRegistrationMutationCPF = (
  options?: UseMutationOptions<void, ErrorResponse, PutRegistrationBodyBFF>,
) => {
  return useMutation(putRegistrationCPF, options);
};

export const usePutRegistrationMutationCNPJ = (
  options?: UseMutationOptions<void, ErrorResponse, PutRegistrationBodyBFF>,
) => {
  return useMutation(putRegistrationCNPJ, options);
};

export const usePutAdditionalInfoMutation = (
  options?: UseMutationOptions<void, ErrorResponse, PutAdditionalInfoBody>,
) => {
  return useMutation(putAdditionalInfo, options);
};

export const useGetAccountDataQuery = (
  options?: UseQueryOptions<GetAccountResponse, ClientError<ErrorResponse>>,
) => {
  return useQuery<GetAccountResponse, ClientError<ErrorResponse>>(
    GET_ACCOUNT_QUERY,
    getAccountData,
    options,
  );
};

export const usePutRegistrationMutation = (
  options?: UseMutationOptions<void, ErrorResponse, PutRegistrationBody>,
) => {
  return useMutation(putRegistration, options);
};

export const useGetRegistrationQuery = (
  options?: UseQueryOptions<
    GetRegistrationResponse,
    ClientError<ErrorResponse>
  >,
) => {
  return useQuery<GetRegistrationResponse, ClientError<ErrorResponse>>(
    [GET_REGISTRATION_QUERY],
    () => getRegistration(),
    {
      onSuccess(data) {
        setUserOnSentryContext(data);
      },
      enabled: options?.enabled ?? true,
      refetchOnMount: false,
      retryOnMount: false,
      ...options,
    },
  );
};

export const useGetAddressQuery = (
  options?: UseQueryOptions<GetAddressResponse[], ClientError<ErrorResponse>>,
) => {
  return useQuery<GetAddressResponse[], ClientError<ErrorResponse>>(
    GET_ADDRESS_QUERY,
    async () => {
      const response = await getAddress();
      return response;
    },
    {
      refetchOnMount: false,
      retryOnMount: false,
      ...options,
    },
  );
};

export const useDeleteAddressMutation = (
  options?: UseMutationOptions<void, ErrorResponse, number>,
) => {
  return useMutation(deleteAddress, options);
};

export const usePostAddressMutation = (
  options?: UseMutationOptions<void, ErrorResponse, PostAddressBody>,
) => {
  return useMutation(postAddress, options);
};

export const usePutAddressMutation = (
  options?: UseMutationOptions<void, ErrorResponse, PutAddressBody>,
) => {
  return useMutation(putAddress, options);
};

export const usePostKeycloakTokenMutation = (
  options?: UseMutationOptions<PostKeycloakTokenResponse, ErrorResponse, null>,
) => {
  return useMutation(postKeycloakToken, options);
};

export const usePostDocumentCheckerMutation = (
  options?: UseMutationOptions<
    PostDocumentCheckerResponse,
    PostDocumentCheckerError,
    PostDocumentCheckerParams
  >,
) => {
  return useMutation(postDocumentChecker, options);
};
