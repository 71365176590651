export const adoptCustomStyles = {
  styles: {
    web: {
      card: {
        container: {
          bottom: 'calc(50% - 110px)',
          left: 'calc(50% - 240px)',
          width: '480px',
          margin: '0',
          padding: '12px',
        },
        textContainer: {
          marginBottom: '32px',
        },
        titleRow: {
          alignItems: 'center',
          marginBottom: '24px',
          maxWidth: '100%',
        },
        title: {
          fontSize: '20px',
          lineHeight: '100%',
        },
        textsRow: {
          marginBottom: '16px',
        },
        textRowContent: {
          fontSize: '16px',
          lineHeight: '140%',
          marginBottom: '16px',
        },
        textRowLinks: {
          display: 'flex',
          fontSize: '14px',
          lineHeight: '100%',
          gap: '8px',
          fontWeight: 'normal',
        },
        buttonsContainer: {
          justifyContent: 'flex-end',
          gap: '12px',
        },
        rejectAllButton: {
          fontSize: '14px !important',
          height: '48px',
          lineHeight: '100%',
          borderRadius: '1000px',
          border: '1px solid #ccc',
          padding: '0 20px',
        },
        preferencesButton: {
          marginRight: 'auto',
          fontSize: '14px !important',
        },
        acceptAllButton: {
          fontSize: '14px !important',
          fontWeight: 'bold',
          height: '48px',
          lineHeight: '100%',
          borderRadius: '1000px',
          padding: '0 28px',
        },
      },
    },
    mobile: {
      card: {
        container: {
          bottom: 'calc(50% - 143px)',
          left: 'calc(50% - 175px)',
          width: '350px',
          margin: '0',
          padding: '8px',
        },
        textContainer: {
          marginBottom: '32px',
        },
        titleRow: {
          alignItems: 'center',
          marginBottom: '24px',
          maxWidth: '100%',
        },
        title: {
          fontSize: '18px',
          lineHeight: '100%',
        },
        textsRow: {
          marginBottom: '14px',
        },
        textRowContent: {
          fontSize: '14px',
          lineHeight: '140%',
          marginBottom: '16px',
        },
        textRowLinks: {
          display: 'flex',
          fontSize: '14px',
          lineHeight: '100%',
          gap: '8px',
          fontWeight: 'normal',
        },
        buttonsContainer: {
          justifyContent: 'flex-end',
          gap: '12px',
        },
        rejectAllButton: {
          fontSize: '14px !important',
          height: '48px',
          lineHeight: '100%',
          borderRadius: '1000px',
          border: '1px solid #ccc',
          padding: '0 20px',
        },
        preferencesButton: {
          marginRight: 'auto',
          fontSize: '14px !important',
        },
        acceptAllButton: {
          fontSize: '14px !important',
          fontWeight: 'bold',
          height: '48px',
          lineHeight: '100%',
          borderRadius: '1000px',
          padding: '0 28px',
        },
      },
    },
  },
  hasImportant: true,
  theme: 'light',
};
