// eslint-disable-next-line import/no-duplicates
import { addDays, format, getDay, parse } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptBR from 'date-fns/locale/pt-BR';

/**
 *
 * The Intl.NumberFormat object is a constructor for objects that enables
 * language-sensitive number formatting
 *
 * The format style "currency" for currency format, if the style is "currency",
 * the currency property must be entered.
 *
 * @param {number} number - The value of the number you want to format
 *
 * @example
 * import { currencyFormatter } from '@/helpers/fomatter';
 *
 * currencyFormatter(number)
 *
 * //return R$ *number*
 */
export const currencyFormatter = (number: number) => {
  const validNumber = typeof number === 'number' && !isNaN(number) ? number : 0;
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(validNumber);
};

export const currencyFormatterWithoutSymbol = (number: number) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currencyDisplay: 'code',
    currency: 'BRL',
  })
    .format(number)
    .replace('BRL', '')
    .trim();
};

/**
 *
 * Format "currency" string to number
 *
 * @param {currency} string - The value of the currency string you want to format
 *
 * @example
 * import { currencyToNumber } from '@/helpers/fomatter';
 *
 * currencyToNumber(currency)
 *
 * //return number
 */
export const currencyToNumber = (currency: string) => {
  return Number(currency.replace(/[^0-9\\,]+/g, '').replace(/,/g, '.'));
};

/**
 *
 * Format "date" string to date
 *
 * @param {date} string - The value of the date string you want to format
 * @param {formating} string - Data and time formatting "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
 *
 * @example
 * import { dateFormatter } from '@/helpers/fomatter';
 *
 * dateFormatter(date, 'yyyy-MM-dd')
 * dateFormatter(new Date(), 'yyyy-MM-dd')
 *
 * //return string like '16/11/2021 01:28:45' or just '16/11/2021', depends of formatting
 */
export const dateFormatter = (
  date: string | Date,
  formatting = 'dd/MM/yyyy',
) => {
  return format(new Date(date), formatting, { locale: ptBR });
};

/**
 *
 * @param {startDate} Date - The value of the date you want to format
 *
 * @example
 * import { verboseDateFormatter } from '@/helpers/fomatter';
 *
 * verboseDateFormatter(startDate)
 *
 * //return string like 'ter 16/11/2021'
 *
 */

export const verboseDateFormatter = (startDate: Date) => {
  return format(startDate, 'cccccc P', { locale: ptBR });
};

/**
 *
 * @param {currentDate} Date - The current date value
 * @param {daysToSum} number - The value of days to add
 *
 * @example
 * import { addDay } from '@/helpers/fomatter';
 *
 * addDay(currentDate, daysToSum)
 *
 * //return Date
 *
 */

export const addDay = (currentDate: Date, daysToSum: number) => {
  return addDays(currentDate, daysToSum);
};

/**
 *
 * @param {date} Date - The current date value
 *
 * @example
 * import { getDayFromDate } from '@/helpers/fomatter';
 *
 * getDayFromDate(date)
 *
 * //return *weekday abbreviation* number from 0 to 6 the day of week, 0 represents Sunday
 *
 */

export const getDayFromDate = (date: Date) => {
  return getDay(date);
};

/**
 *
 * @param {date} string - The date string to be parsed
 * @param {format} string - The date string format
 *
 * @example
 * import { parseDate } from '@/helpers/formatter';
 *
 * parseDate(date, format)
 *
 * //return Date
 *
 */

export const parseDate = (date: string, format: string) => {
  return parse(date, format, new Date(), { locale: ptBR });
};
