import { Facebook, Instagram, Youtube } from '@sbf/fairplay-icons';
import Link from 'next/link';
import { getCoreRoutes } from '@/common/config/routes';
import { SectionTitle } from '../../Footer.styled';
import { sendSocialMediaEvent } from '../../gaEvents';
import { SocialGrid, SocialWrapper } from './Social.styled';

const coreRoutes = getCoreRoutes();

const socialMedia = {
  facebook: 'Facebook',
  instagram: 'Instagram',
  youtube: 'Youtube',
};

const Social = () => {
  return (
    <SocialWrapper data-testid="footer-social">
      <SectionTitle>Redes sociais</SectionTitle>

      <SocialGrid>
        <Link
          href={coreRoutes.facebook}
          passHref
          target="_blank"
          rel="noopener noreferrer"
          title={socialMedia.facebook}
          onClick={() => sendSocialMediaEvent(socialMedia.facebook)}
        >
          <Facebook />
        </Link>

        <Link
          href={coreRoutes.instagram}
          passHref
          target="_blank"
          rel="noopener noreferrer"
          title={socialMedia.instagram}
          onClick={() => sendSocialMediaEvent(socialMedia.instagram)}
        >
          <Instagram />
        </Link>

        <Link
          href={coreRoutes.youTube}
          passHref
          target="_blank"
          rel="noopener noreferrer"
          title={socialMedia.youtube}
          onClick={() => sendSocialMediaEvent(socialMedia.youtube)}
        >
          <Youtube />
        </Link>
      </SocialGrid>
    </SocialWrapper>
  );
};

export default Social;
