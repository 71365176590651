type CreditCardBody =
  | {
      MesCartao?: string;
      AnoCartao?: string;
      NomeCartao?: string;
      NumeroCartao?: string;
      TitularCartao?: string;
      CodigoSegurancaCartao?: string;
      UtilizarCartao: true;
      tokenCartao?: string;
    }
  | {
      MesCartao?: never;
      AnoCartao?: never;
      NomeCartao?: never;
      NumeroCartao?: never;
      TitularCartao?: never;
      CodigoSegurancaCartao?: never;
      UtilizarCartao: false;
      tokenCartao?: never;
    };

export type CompleteOrderBody = {
  TesteAbDescontoBoleto?: boolean;
  Newsletter?: boolean;
  UtilizarPagamentoOnline?: boolean;
  ParceiroId?: number;
  OpcaoPagamento?: string;
  CodigoEspecie?: string;
  UtilizarCartaoCompraRapida: boolean;
  numeroTentativas?: number;
  sessionId: string;
  testeAbHabilitaDescontoPix?: boolean;
} & CreditCardBody;

export type CompleteOrder = {
  mensagensOneClickBuy: unknown[];
  numeroPedido: number;
  parceiroPedidoIdB2W: null | unknown;
  pedidoTemporarioId: number;
  numeroTentativa: number;
  clienteId: number;
  confirmacaoPagamentoOnline: boolean;
  mensagemClientePagamentoOnline: null | unknown;
  urlBoleto: string;
  boletoLinhaDigitavel: string;
  retornoGateway: boolean;
};

export type DetailsOrderStatusType =
  | 'PagamentoEfetuado'
  | 'NovoPedido'
  | 'AguardandoPagamento'
  | 'PagamentoCancelado';

export type DetailsOrderType = {
  bankSlipCode: string | null;
  bankSlipUrl: string | null;
  paymentType: string | undefined;
  number: number | undefined;
  totalValue: string | undefined;
  installmentValue: string | undefined;
  pixCode: string | undefined;
  pixDataVencimento: string | undefined;
  paymentLinkUrl: string | undefined;
  status: DetailsOrderStatusType;
  addressState: string | undefined;
};

export type CompleteOrderDetails = {
  id: number;
  parceiroPedidoIdB2W?: null | unknown;
  codigo: string;
  dataCompra: string;
  idCliente: number;
  clienteId: string;
  documento: string;
  formaDePagamento: string;
  nomeAdministradoraCartao?: null | unknown;
  urlBoleto: string;
  codigoBoleto: string;
  pagamentoBoleto: boolean;
  operacao: string;
  formaDeVendas: string;
  canalDeVendas: string;
  vendedor?: null | unknown;
  status: string;
  valorTotalFrete: number;
  valorTotalDesconto: number;
  valorTotalDescontoRetira: number;
  valorTotalValeTroca: number;
  enderecoEhNovoDoValeTroca: boolean;
  valorTotal: number;
  valorSubtotal: number;
  valorTotalCartaoPresente: number;
  cuponsDescontoIds: unknown[];
  valesTrocaIds: unknown[];
  gruposDeEntregaIds: number[];
  tipoEntrega: DeliveryType[];
  pix: {
    codigo: string;
    dataVencimento: string;
  };
  enderecoDeEntrega: DeliveryAddress;
  itens: Item[];
  numeroDeParcelas: number;
  codigoEspeciePagamento: string;
  numeroCartaoMascarado?: null | unknown;
  titularCartao?: null | unknown;
  descricaoParcelamento?: null | unknown;
  primeiraCompra: boolean;
  valorParcela: number;
  tipoCliente: string;
  atributos: Attribute[];
  cartaoPresente?: null | unknown;
  pagoEm?: null | unknown;
  urlLinkDePagamento?: string | undefined;
  urlLinkDePagamentoExpiraEm?: null | unknown;
  canalDeVendaId: string;
};

interface DeliveryType {
  grupoEntregaId: number;
  tipoEntrega: string;
  nomeLojaFisica?: null | unknown;
  codigoLojaReserva: string;
  codigoTipoEntrega: number;
  descricaoEnvioEntrega: string;
  flagMktp: boolean;
  flagOms: boolean;
}

interface Telephone {
  ddd: string;
  numero: string;
}

interface CellPhone {
  ddd: string;
  numero: string;
}

interface DeliveryAddress {
  local: string;
  logradouro: string;
  numero: string;
  complemento?: null | unknown;
  bairro: string;
  referencia?: null | unknown;
  cep: string;
  cidade: string;
  uf: string;
  pais: string;
  telefone: Telephone;
  celular: CellPhone;
  nomeCompletoDeQuemIraRececeber: string;
  nomeDeQuemIraReceber: string;
  sobrenomeDeQuemIraReceber: string;
  email: string;
}

interface PreSale {
  estaEmPreVenda: boolean;
  inicioDisponibilidade?: null | unknown;
  periodoPrazoEntrega?: null | unknown;
}

export type Item = {
  id: number;
  sku: string;
  integratorId?: null | unknown;
  skuExterno?: null | unknown;
  sellerSkuId?: null | unknown;
  pedidoId: number;
  tamanho: string;
  tamanhosDisponiveis?: null | unknown;
  cor: string;
  nome: string;
  desconto: number;
  descontoRetira?: null | unknown;
  valorUnitarioDe: string;
  valorUnitarioPor: string;
  valorTotal: string;
  pesoUnitario: number;
  pesoTotal: number;
  quantidade: number;
  quantidadeEmEstoque: number;
  centroDistribuicao: number;
  freteReceita: number;
  personalizacao: boolean;
  codigoModeloVirtual?: null | unknown;
  presente: boolean;
  pedidoCriadoEm: string;
  href: string;
  bundle?: null | unknown;
  tipoBundle?: null | unknown;
  prazoDoItemDescricao?: null | unknown;
  tipoPersonalizacao?: null | unknown;
  precoPersonalizacao?: null | unknown;
  modeloMarca: string;
  qtdeItensTrocados?: null | unknown;
  nomeModeloReal: string;
  urlModeloReal: string;
  prazoDoItem: number;
  prazoMaximo: number;
  prazoExpedicao?: null | unknown;
  dataExpedicao?: null | unknown;
  centroDistribuicaoId: number;
  descricaoPrazoEntrega: string;
  tipoDoGrupoDeEntrega: number;
  codigoDaTransportadora: number;
  urlTransportadora?: null | unknown;
  codigoRastreioCorreios?: null | unknown;
  dataExibicaoTransportadora?: null | unknown;
  statusEntregaID?: null | unknown;
  statusItem?: null | unknown;
  motivoCancelTrocaDevolId?: null | unknown;
  cancelado: boolean;
  motivoCancelamento: string;
  trackingCriadoEm?: null | unknown;
  trackingAtualizadoEm?: null | unknown;
  itemUnitarioEComReservaEstoque?: null | unknown;
  trackingDataAtualizacaoStatusSeparacao?: null | unknown;
  agrupador: string;
  indiceBundle?: null | unknown;
  imagem: string;
  imagemBundle?: null | unknown;
  embrulhoPresente: boolean;
  comissaoSeller?: null | unknown;
  comissaoLinhagem?: null | unknown;
  personalizacaoChuteira?: null | unknown;
  codigoSeller: string;
  nomeSeller: string;
  mktpEntreguePorCentauro: boolean;
  mktpModalidadeCentauroEnvios: number;
  grupoEntregaId: number;
  tipoDeEntrega: number;
  formaEntrega?: null | unknown;
  tracking: unknown[];
  preVenda: PreSale;
  dataPrevisaoEntrega?: null | unknown;
  entregueEm?: null | unknown;
  tokenIntelipost: string;
  isRetiraLoja: boolean;
  hubPedidoId?: null | unknown;
  cepOrigem?: null | unknown;
  codigoDoServico: number;
  altura: number;
  largura: number;
  profundidade: number;
  codigoEntregaExterno?: null | unknown;
  dna: string;
  retiraLoja?: null | unknown;
  insumo: boolean;
  categoria: string;
  flagMarketplace: boolean;
  tipoId: number;
  situacao: number;
  quantidadeDisponivelTroca: number;
  boleto: boolean;
};
interface Attribute {
  chave: string;
  valor: string;
}

export enum PAYMENT_STATUS {
  PAID = 'PagamentoEfetuado',
  NEW_ORDER = 'NovoPedido',
  WAITING = 'AguardandoPagamento',
  CANCELED = 'PagamentoCancelado',
}
