import axios from 'axios';
import { parseCookies } from 'nookies';
import { getCoreConfig } from '@/common/config/config';
import { SALES_CHANNEL } from '@/common/constants/constants';
import { createHttpAgentConfig } from '@/common/services/api/client/httpAgent/httpAgent';
import {
  isClientSide,
  isDevEnv,
  isProdReleaseEnv,
  isServerSide,
} from '@/common/utils/environment/environment';
import { generateTelemetryHeader } from '@/helpers/akamai/akamai';
import { ACCESS_TOKEN_COOKIE } from '@/hooks/useAuthSession';
import errLogObject from '@/logging/log-format';
import { getLogger } from '@/logging/log-utils';
import { ATHLETE_ID } from '@/modules/vouchers/athleteVoucher/services/athleteVoucher/athleteVoucher';
import {
  handlePreconditionRequiredError,
  handleUnauthorizedError,
} from '../client/errors/errors';
import { getAccessToken } from '../client/headers/headers';

const { publicRuntimeConfig } = getCoreConfig();

const httpAgentConfig = createHttpAgentConfig({
  timeout: 20000,
});

export const clientCheckout = axios.create({
  baseURL: publicRuntimeConfig.publicApi.checkout,
  ...httpAgentConfig,
});

if (isProdReleaseEnv) {
  clientCheckout.defaults.withCredentials = true;
}

const getSandBoxHeader = () => {
  return {
    'x-sandbox': isDevEnv,
  };
};

const getRealmHeader = () => {
  return {
    Realm: 'nike',
  };
};

export const getSalesChannel = () => {
  /**
   * When run at server-side we can't identify the user's screen size
   */
  const isDesktop =
    isClientSide() && window.matchMedia('(min-width: 1024px)').matches;

  if (isDesktop) {
    return SALES_CHANNEL.Desktop;
  }

  return SALES_CHANNEL.Mobile;
};

clientCheckout.interceptors.request.use(
  async function (config) {
    const { data = {}, params = {} } = config;

    const coreHeaders =
      data.sandbox || params.sandbox ? getSandBoxHeader() : {};
    const realmHeader = getRealmHeader();
    const isLogged = parseCookies()[ACCESS_TOKEN_COOKIE];
    const athleteId = parseCookies()[ATHLETE_ID];

    const accessToken = isLogged ? await getAccessToken() : null;
    const headers = {
      ...config.headers,
      ...coreHeaders,
      ...realmHeader,
      ...generateTelemetryHeader(true),
      ...(athleteId ? { [ATHLETE_ID]: athleteId } : {}),
      SalesChannelID: getSalesChannel(),
      // We need to use some parameters to prevent cart guest checkout with authentication.
      ...(accessToken && !params.noAuth
        ? {
            Authorization: `Bearer ${accessToken as string}`,
          }
        : {}),
    };

    config.headers = headers;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

clientCheckout.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    handlePreconditionRequiredError(error);

    if (isServerSide()) {
      const errorLogger = getLogger('*');
      errorLogger.error(errLogObject(error));
    }
    handleUnauthorizedError(error);

    return Promise.reject(error);
  },
);
