import {
  CorporateContactJsonLd,
  CorporateContactJsonLdProps,
  DefaultSeo,
  DefaultSeoProps,
  SiteLinksSearchBoxJsonLd,
  SiteLinksSearchBoxJsonLdProps,
  SocialProfileJsonLd,
  SocialProfileJsonLdProps,
} from 'next-seo';

export type AppSeoProps = {
  seoData: Readonly<{
    defaultSeo: Readonly<DefaultSeoProps>;
    corporateContact: Readonly<CorporateContactJsonLdProps>;
    socialProfile: Readonly<SocialProfileJsonLdProps>;
    siteLinksSearchBox: Readonly<SiteLinksSearchBoxJsonLdProps>;
  }>;
};

const AppSeo = ({
  seoData: { defaultSeo, corporateContact, socialProfile, siteLinksSearchBox },
}: AppSeoProps) => (
  <>
    <DefaultSeo {...defaultSeo} />
    <SocialProfileJsonLd {...socialProfile} />
    <CorporateContactJsonLd {...corporateContact} />
    <SiteLinksSearchBoxJsonLd {...siteLinksSearchBox} />
  </>
);

export default AppSeo;
