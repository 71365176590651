import { parseCookies } from 'nookies';
import { UnknownObject } from '@/common/types/objects';
import {
  isServerSide,
  isTestEnv,
} from '@/common/utils/environment/environment';
import { isType } from '@/common/utils/funcs/isType/isType';
import { waitForEvent } from '@/modules/tracking/utils/waitForEvent/waitForEvent';

export const isPageViewEvent = (prop: unknown) => {
  return isType<UnknownObject>('object', prop) && prop?.event === 'pageView';
};

/**
 * This function abstract the data layer calls
 *
 * @param prop information to populate the data layer
 */
export const push = (prop: unknown) => {
  const _push = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(prop);
  };

  if (isPageViewEvent(prop)) {
    _push();
    return;
  }

  waitForEvent('pageView', _push);
};

/**
 * This function pushed the page information in data layer
 *
 * @param pageName
 * @param prop information to populate the data layer
 */
export const pushPageInfos = (pageName: string, prop: unknown) => {
  push({
    [pageName]: prop,
  });
};

export function sendEventGtm(event: UnknownObject) {
  const willNotHaveDataLayerDefined = isServerSide() && !isTestEnv;
  if (willNotHaveDataLayerDefined) {
    // eslint-disable-next-line no-console
    console.error('[GTM] dataLayer is undefined');
    return;
  }

  push(event);
}

export const getGaUserId = () => {
  const gaIdValue = parseCookies()['_ga'];
  return gaIdValue && gaIdValue.substring(6);
};
