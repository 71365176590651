import Head from 'next/head';
import Script from 'next/script';
import { useEffect } from 'react';
import { isClientSide } from '@/common/utils/environment/environment';
import { adoptCustomStyles } from './AdoptCustomStyles';

const AdoptScript = () => {
  useEffect(() => {
    if (isClientSide()) {
      window.adoptChangeClasses = adoptCustomStyles;
    }

    return () => {
      window.adoptChangeClasses = undefined;
    };
  }, []);

  return (
    <>
      <Head>
        <meta
          name="adopt-website-id"
          content="466a4126-c655-44e3-8979-823121f2a1cc"
        />
      </Head>
      <style>{`
        #adopt-controller-button + div {
          font-size: 12px;
        }
      `}</style>
      <Script
        src={`//tag.goadopt.io/injector.js?website_code=466a4126-c655-44e3-8979-823121f2a1cc`}
        strategy="lazyOnload"
      />
    </>
  );
};

export default AdoptScript;
