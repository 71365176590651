import { parseISO } from 'date-fns';
import { ChangeEvent } from 'react';
import {
  maskCnpj,
  maskCpf,
  validateCnpj,
  validateCpf,
} from '@/common/utils/form/mask';
import { dateFormatter } from '@/helpers/formatter';
import { AddressFormData } from '@/modules/guestCheckout/types/address';
import {
  AdditionalDataPhonePUT,
  Phone,
  PhonePUT,
} from '@/modules/myAccount/components/Registration/Registration.types';

export const formatPhone = (
  phone: Phone | AdditionalDataPhonePUT | PhonePUT | undefined,
) => {
  let areaCode = '';
  let number = '';
  if (phone) {
    Object.values(phone).forEach((item) => {
      if (item) {
        item.length == 2 ? (areaCode = item) : (number = item);
      }
    });

    if (areaCode && number) {
      const finalNumber =
        number.length === 8
          ? `${number.substring(0, 4)}-${number.substring(4, 8)}`
          : `${number.substring(0, 5)}-${number.substring(5, 9)}`;
      return `(${areaCode}) ${finalNumber}`;
    }
  }
  return '';
};

export const formatZipCode = (zipCode: string | undefined) =>
  zipCode ? `${zipCode?.substring(0, 5)}-${zipCode?.substring(5, 8)}` : '';

export const maskDocument = (document: ChangeEvent<HTMLInputElement>) => {
  return document.target.value.length <= 14
    ? maskCpf(document)
    : maskCnpj(document);
};

export const getNumberPhone = (numberPhone: string) => {
  return numberPhone?.replace('+55', '');
};

export const getMaskDate = (date: string) => {
  return dateFormatter(date, 'dd/MM/yyyy');
};

export const getIsoStringDate = (date: string) => {
  const convertDate = date.split('/').reverse().join('-');

  return new Date(parseISO(convertDate)).toISOString();
};

export const getMaskDocument = (document: string) => {
  return document.length <= 11 ? validateCpf(document) : validateCnpj(document);
};

export const removeMaskDocument = (document: string) => {
  return document.replace(/\/|\.|-/g, '');
};

export const removeMaskPhone = (numberPhone: string) => {
  return `+55${numberPhone.replace(/[^0-9]+/g, '')}`;
};

export const removeMaskZipCode = (zipCode: string) => {
  return zipCode.replace(/\.|-/g, '');
};

export const formatFormToApiData = (data: AddressFormData) => {
  const cleanedNumber = data.mobilePhoneNumber.replace(/[^\d]/g, '');
  const convertMobilePhone = cleanedNumber.match(/^(\d{2})(\d{8,9})$/);
  const parsedMobilePhoneNumber = {
    areaCode: convertMobilePhone?.[1] || '',
    number: convertMobilePhone?.[2] || '',
  };

  return {
    ...data,
    mobilePhoneNumber: parsedMobilePhoneNumber,
  };
};
