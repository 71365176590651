import { NikeBag as Bag } from '@sbf/fairplay-icons';
import { useMemo } from 'react';
import { getCartInfo } from '@/helpers/checkout';
import { useCart } from '@/modules/checkout/services/cart/cart';
import { ButtonWrapper, CounterItems } from './CartIconButton.styled';

export type CartIconButtonProps = {
  onClick: () => void;
};

const CartIconButton = ({ onClick }: CartIconButtonProps) => {
  const { data } = useCart();

  const cartData = useMemo(() => {
    return getCartInfo(data);
  }, [data]);

  const qntItems = useMemo(() => {
    if (cartData.quantity === 0) {
      return '';
    }
    if (cartData.quantity > 0 && cartData.quantity <= 9) {
      return String(cartData.quantity);
    }
    return '9+';
  }, [cartData]);

  const quantityLabel =
    cartData.quantity > 1
      ? `Sacola com ${cartData.quantity} itens`
      : `Sacola com 1 item`;

  return (
    <ButtonWrapper onClick={onClick}>
      <CounterItems>{qntItems}</CounterItems>
      <Bag
        titleAccess={cartData.quantity == 0 ? 'Sacola vazia' : quantityLabel}
      />
    </ButtonWrapper>
  );
};

export default CartIconButton;
