export type TrackingErrors = {
  component: Components;
  microComponent?: MicroComponents;
  originEvent: Events;
  errorMessage: string | undefined;
  extraDetail?: {
    errorCode?: string | undefined;
    errorMessageUser: string | undefined;
    errorMessageSystem: string | undefined;
  };
  errorCode: number | undefined;
};

export enum Events {
  AddToCart = 'add_to_cart',
  SubtractItemOnCart = 'subtract_item_on_cart',
  TrashItem = 'trash_item_on_cart',
  RemoveVoucherBackground = 'remove_voucher_on_background',
  RemoveCoupon = 'remove_coupon',
  RemoveCardSaved = 'remove_card_saved',
  AddCoupon = 'add_coupon',
  CartItemErrors = 'cart_item_errors',
  CheckoutGenericErrors = 'checkout_generic_errors',
  FreightCalculate = 'freight_calculate',
  AddPersonalization = 'add_personalization',
  AddFavorites = 'add_favorite',
  CompleteOrder = 'complete_order',
  CompleteOrderPCI = 'complete_order_pci',
  AddDeliveryModality = 'delivery_modality',
  SelectAddress = 'select_address',
  ChangeAddress = 'change_address',
  ResponseRequest = 'response_request',
  NotifyMe = 'notify_me',
  Akamai = 'akamai',
  PlaceOrder = 'place_order',
  Experiment = 'ab_test_error',
  SyncCart = 'sync_cart',
  RevalidateCart = 'revalidate_cart',
  CreateEmptyCart = 'create_empty_cart',
}

export enum Components {
  Cart = 'cart_page',
  ProductDetailsPage = 'product_details_page',
  ProductDetailsPageSnkrs = 'product_details_page_snkrs',
  CheckoutPage = 'checkout_page',
  MyCardsPage = 'mycards_page',
  PaymentPage = 'payment_page',
  OrderPage = 'order_page',
  IdentificationPage = 'identification_page',
  Request = 'request',
  Experiment = 'ab_test',
  GuestCheckout = 'guest_checkout',
}

export enum MicroComponents {
  SummaryCartItem = 'summary_cart_item',
  RemoveVoucherBackground = 'remove_voucher_on_background',
  CouponDiscount = 'coupon_discount',
  RemoveCouponBackground = 'remove_coupon_on_background',
  CouponComponent = 'coupon_component',
  FreightCalculatorComponent = 'freight_calculator_component',
  PaymentStepComponent = 'payment_step',
  AddToCartButtonCTA = 'add_to_cart_button_cta',
  FastBuyCTA = 'fast_buy_cta',
  PersonalizationButton = 'personalization_button',
  FavoriteButton = 'favorite_button',
  ConfirmAddress = 'confirm_address',
  SelectAddress = 'select_address',
  ChangeAddress = 'change_address',
  SendNotifyMe = 'send_notify_me',
  PlaceOrder = 'place_order',
  ViewOrder = 'view_order',
  IdentificationStep1 = 'identification_step_1',
  IdentificationStep2 = 'identification_step_2',
  IdentificationStep3 = 'identification_step_3',
  IdentificationStep4 = 'identification_step_4',
}
