import { parseCookies } from 'nookies';
import { getStorageItem } from '@/helpers/localStorage';
import { SALES_CHANNEL } from '@/helpers/salesChannel';
import { SELLER_KEY } from '../constants';
import { AssistedSaleHeaders } from '../types/AssistedSaleHeaders.types';

export const isAssistedSaleSession = (): boolean => {
  return !!getStorageItem(SELLER_KEY);
};

export const isAssistedSalePaymentMethod = (
  storesToggle: string | undefined,
): boolean => {
  if (!isAssistedSaleSession()) {
    return false;
  }

  const store = getAssistedSaleHeaders()?.['x-lj-id'];

  const useStoreToggle = storesToggle?.split(',');
  const releaseLinkPayment = useStoreToggle?.find(
    (element) => element === store,
  );

  const hasCookiePaymentLink = parseCookies()['hasCookiePaymentLink'];
  const hasStoreToggle = hasCookiePaymentLink || releaseLinkPayment;
  const enablePaymentLink = Boolean(hasStoreToggle);

  return enablePaymentLink;
};

export const isAssistedSalePhysicalPayment = (isPhysicalPayment: boolean) => {
  return isAssistedSaleSession() && isPhysicalPayment;
};

export const getAssistedSaleHeaders = (): AssistedSaleHeaders | undefined => {
  if (!isAssistedSaleSession()) {
    return undefined;
  }

  const teleSales = {
    teleSalesName: 'Encomenda Expressa',
    campaign: 9,
    freeShipping: false,
  };

  const seller = getStorageItem(SELLER_KEY) as {
    store: string;
    sellerId: string;
    token: string;
    xNikeWaf: string;
  };

  return {
    'x-lj-id': seller.store,
    'x-matricula': seller.sellerId,
    'x-cv-id': SALES_CHANNEL.AssistedSale,
    'x-nike-waf': seller.xNikeWaf,
    'x-televendas': `codigo=${seller.token}&nome=${teleSales.teleSalesName}&campanha=${teleSales.campaign}&aplicarFreteGratis=${teleSales.freeShipping}`,
  };
};
