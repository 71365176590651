import { useSession } from 'next-auth/react';
import { setCookie } from 'nookies';
import { UseQueryOptions, useQuery } from 'react-query';
import { getCorePaths } from '@/common/config/paths';
import { clientGateway } from '@/common/services/api/clientGateway/clientGateway';
import { type ClientError } from '@/common/services/api/types';
import { getStorageItem } from '@/common/utils/localStorage/localStorage';
import { encrypt } from '@/helpers/Cryptography';
import {
  AthleteVoucherEligibleResponse,
  AthleteVoucherResponse,
} from './athleteVoucher.types';
export const ATHLETE_ID = 'athlete-ck';
export const ATHELETE_VOUCHER = 'athleteVoucher';

const { checkout } = getCorePaths();

type ExchangeVoucherOptions = UseQueryOptions<
  AthleteVoucherResponse,
  ClientError
>;

export const getAthleteVoucher = async () => {
  const response = await clientGateway.get<AthleteVoucherResponse>(
    checkout.exchangeVoucherAthleteExtract,
  );
  return response.data;
};

export const useAthleteVoucher = (options?: ExchangeVoucherOptions) => {
  return useQuery<AthleteVoucherResponse, ClientError>(
    'athleteVoucher',
    getAthleteVoucher,
    options,
  );
};

export const getAthleteVoucherEligible = async () => {
  const response = await clientGateway.get<AthleteVoucherEligibleResponse>(
    checkout.exchangeVoucherAthleteEligible,
  );
  return response.data;
};

export const useAthleteVoucherEligible = () => {
  const { status } = useSession();

  const userToken = getStorageItem<string>('x-client-token');
  return useQuery<AthleteVoucherEligibleResponse, ClientError>(
    ['useAthleteEligibleContext', userToken, status],
    getAthleteVoucherEligible,
    {
      refetchOnMount: false,
      retryOnMount: false,
      enabled: status === 'authenticated' && Boolean(userToken),
      async onSuccess(data) {
        const hash = await encrypt(ATHELETE_VOUCHER);

        if (data?.customerEligibleAthleteVoucher) {
          setCookie(null, ATHLETE_ID, hash, {
            path: '/',
          });
        }
        return data;
      },
    },
  );
};
