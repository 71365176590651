import {
  ActivateFn,
  ExperimentConfig,
  ExperimentType,
  TestOptions,
  TrackFn,
} from '@grupo-sbf/experiment-react';
import { LogLevelMap, customLogger } from '../utils/experiment/logger';
import { getCoreConfig } from './config';

export type ExperimentEvents = {
  events?: {
    primary?: string;
    secondary?: string;
  };
};

const {
  publicRuntimeConfig: {
    experiment: { sdkKey, accountId },
  },
} = getCoreConfig();

export type ExperimentWithEvent = ExperimentType & ExperimentEvents;

export const experimentConfig: ExperimentConfig = {
  accountId,
  sdkKey,
  logging: {
    level: LogLevelMap.ERROR,
    logger: {
      log: customLogger,
    },
  },
};

export const securePurchaseMessage: ExperimentWithEvent = {
  id: 'secure-purchase-message',
  name: 'Secure Purchase Message',
  variants: {
    original: 'original',
    showMessage: 'show-message',
  },
  events: {
    primary: 'purchase',
  },
};

export const experiments = { securePurchaseMessage };

// These two functions replace the live call to VWO
// in test environments so we know what will be passed to VWO in production
const mockActivate: ActivateFn = (campaignId) =>
  // eslint-disable-next-line no-console
  console.log(`Activate -> ${campaignId}`);

const mockTrack: TrackFn = (campaignId, goalId) => {
  // eslint-disable-next-line no-console
  console.log(`Track -> ${campaignId} > ${goalId}`);
  return {};
};

export const testOptions: TestOptions = {
  experiments,
  activate: mockActivate,
  track: mockTrack,
};
