import { isClientSide } from '@/common/utils/environment/environment';

export const waitForEvent = (
  event: string,
  callback: () => void,
  timeout = 500,
  tries = Infinity,
) => {
  if (!isClientSide() || tries === 0) {
    return;
  }

  const isEventDispatched = (
    window.dataLayer as Array<{ event: string }>
  )?.find?.((e: { event: string }) => e?.event === event);

  if (isEventDispatched) {
    callback();
  } else {
    setTimeout(
      () => waitForEvent(event, callback, timeout, tries - 1),
      timeout,
    );
  }
};
