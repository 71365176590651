import { Link } from '@sbf/fairplay-nike';
import { media } from '@sbf/fairplay-utils';
import styled, { css } from 'styled-components';
import { HeaderLayoutProps } from './Header.types';

type DrawerContentProps = {
  canScroll: boolean;
};

interface FixedHeaderProps {
  layout: string;
}

export const HeaderLogoWrapper = styled.span``;

export const Header = styled.header<HeaderLayoutProps>`
  ${({ theme, layout }) => css`
    box-sizing: border-box;
    position: relative;
    padding-bottom: ${layout === 'default' ? '112rem' : '0'};
    z-index: ${theme.zIndex[300]};
    transition: opacity 0.1s;

    ${media.greaterThan('desktop')} {
      padding-bottom: 120rem;
    }
  `}
`;

export const PreviewHeader = styled.div`
  ${({ theme }) => css`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${theme.zIndex[500]};
    background: ${theme.colors.informative[100]};
    height: 60px;
    width: 60px;
    margin: 10px;
    font-size: 16px;
    opacity: 1;
    text-align: center;
    border-width: 1px;
    border-style: solid;
    border-color: ${theme.colors.informative[300]};
    align-items: center;
    justify-content: center;
    display: flex;
  `}
`;

export const FixedHeader = styled.div<FixedHeaderProps>`
  ${({ theme, layout }) => css`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: ${theme.zIndex[100]};
    background: ${theme.colors.neutral[100]};

    ${media.lessThan('desktop')} {
      height: 112rem;

      ${layout === 'snkrs' &&
      css`
        height: 97rem;
      `}
    }
  `}
`;

export const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40rem;
`;

export const SubHeaderBG = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral[200]};
  `}
`;

export const MainHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.colors.neutral[100]};
    flex-direction: column;
    padding: ${theme.spaces[200]} ${theme.spaces[0]} ${theme.spaces[0]};

    ${media.greaterThan('desktop')} {
      display: grid;
      grid-template-columns: auto 1fr auto;
      padding: ${theme.spaces[0]};
    }

    ${media.greaterThan('desktopLarge')} {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
    }
  `}
`;

export const NikeLogoAnchor = styled.a`
  ${media.greaterThan('desktopLarge')} {
    width: fit-content;
  }
`;

export const RightContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spaces[400]};

    ${media.greaterThan('desktopLarge')} {
      justify-content: flex-end;
    }
  `}
`;

export const LinksBox = styled.div`
  display: flex;
  align-items: center;
`;

export const BrandBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spaces[400]};
  `}
`;

export const SearchBarContainer = styled.div`
  ${media.greaterThan('desktop')} {
    max-width: 176rem;
  }
`;

export const MobileTopContent = styled.div<HeaderLayoutProps>`
  ${({ theme, layout }) => css`
    width: ${layout === 'default' ? '100%' : '90%'};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${theme.spaces[300]};
  `}
`;

export const MobileDrawerContentContainer = styled.div<DrawerContentProps>`
  ${({ canScroll }) => css`
    height: 100vh;

    ${canScroll &&
    css`
      overflow-y: auto;
      overflow-x: hidden;
    `}
  `}
`;

export const HeaderMenuMobileContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spaces[400]};
  `}
`;

export const MenuPopoverContent = styled.div`
  display: grid;
`;

export const MenuItem = styled(Link)`
  ${({ theme }) => css`
    padding-left: ${theme.spaces[400]};
    padding-right: ${theme.spaces[400]};
    padding-bottom: ${theme.spaces[300]};
    padding-top: ${theme.spaces[300]};
    font-size: ${theme.fontSizes.medium};

    ${media.greaterThan('desktop')} {
      padding-left: ${theme.spaces[400]};
      padding-right: ${theme.spaces[400]};
      padding-bottom: ${theme.spaces[200]};
      padding-top: ${theme.spaces[200]};
    }
  `}
`;

export const MenuUserLogged = styled.div`
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.button`
  ${({ theme }) => css`
    line-height: ${theme.lineHeights.tight};
    cursor: pointer;
    outline: none;
    border: none;
    background: ${theme.colors.neutral[100]};
    position: relative;
    right: ${theme.spaces[400]};

    ${media.greaterThan('desktop')} {
      left: ${theme.spaces[300]};
    }

    :hover {
      opacity: ${theme.opacity[700]};
      background-color: ${theme.colors.neutral[300]};
      border-radius: ${theme.borderRadius.circular};
    }

    &.focus-visible {
      box-shadow: ${theme.shadow.focus};
    }
  `}
`;

export const BoxSearchMobile = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    grid-gap: ${theme.spaces[100]};
  `}
`;
