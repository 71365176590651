import { useEffect, useRef } from 'react';

/**
 *  This hook implements the debounce effect and returns a function.
 *  The callback will execute after a delay time.
 *
 * @example
 * import { useDebouncedFn } from '@/hooks/useDebouncedFn';
 *
 * const SomeComponent = () => {
 *
 *   const debounceFn = useDebouncedFn(() => {}, 100);
 *
 *   return (<div onClick={debounceFn}>Dialog Content</div>);
 * }
 *
 * @param callback the method that will be called after the delay time;
 * @param delay the waiting time in milliseconds;
 */
export const useDebouncedFn = (callback: () => void, delay: number) => {
  const latestCallback = useRef<() => void>();
  const latestTimeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    latestCallback.current = callback;
  }, [callback]);

  return () => {
    if (latestTimeout.current) {
      clearTimeout(latestTimeout.current);
    }

    latestTimeout.current = setTimeout(() => {
      latestCallback.current?.();
    }, delay);
  };
};
