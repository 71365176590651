import React, { Fragment } from 'react';

const PRE_CONNECT_URL_MAX = 8;
const ERROR_MESSAGE = `The web application should use maximum ${PRE_CONNECT_URL_MAX} pre-connect URLs for best performance. Please verify https://rigor.com/blog/preconnect-resource-hints/#:~:text=Using%20an%20excessive%20number%20of,6%2D8%20preconnect%20resource%20hints`;

type PreconnectUrlsPropsType = {
  urlsList: string[];
};

const Preconnect = ({ urlsList }: PreconnectUrlsPropsType) => {
  const hasExceededMaximumAmountUrl = urlsList.length > PRE_CONNECT_URL_MAX;

  if (hasExceededMaximumAmountUrl) {
    // eslint-disable-next-line no-console
    console.warn(ERROR_MESSAGE);
  }

  return (
    <>
      {urlsList.map((url) => (
        <Fragment key={url}>
          <link rel="preconnect" href={url} />
          <link rel="dns-prefetch" href={url} />
        </Fragment>
      ))}
    </>
  );
};

export default Preconnect;
