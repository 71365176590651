import { Typography } from '@sbf/fairplay-nike';
import styled, { css } from 'styled-components';

export const AppsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16rem;
`;

export const AppStoreText = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.colors.secondary};
    font-size: ${theme.fontSizes.medium};
  `}
`;

export const AppStoresImages = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16rem;
  gap: 8rem;
`;
