import Link from 'next/link';
import { useRouter } from 'next/router';
import { getCoreRoutes } from '@/common/config/routes';
import { StyledLink, StyledMenu, StyledNav } from './Menu.styled';

const coreRoutes = getCoreRoutes();

const Menu = () => {
  const router = useRouter();

  const active = (ref: string) => (router.pathname === ref ? 'active' : '');

  return (
    <StyledNav data-testid="Menu" id="navigationMenu" role="menu">
      <StyledMenu role="menuitem" className={active(coreRoutes.snkrs)}>
        <Link href={coreRoutes.snkrs} passHref legacyBehavior>
          <StyledLink>Feed</StyledLink>
        </Link>
      </StyledMenu>
      <StyledMenu role="menuitem" className={active(coreRoutes.snkrsStock)}>
        <Link href={coreRoutes.snkrsStock} passHref legacyBehavior>
          <StyledLink>Em Estoque</StyledLink>
        </Link>
      </StyledMenu>
      <StyledMenu role="menuitem" className={active(coreRoutes.snkrsCalendar)}>
        <Link href={coreRoutes.snkrsCalendar} passHref legacyBehavior>
          <StyledLink>Calendário</StyledLink>
        </Link>
      </StyledMenu>
    </StyledNav>
  );
};

export default Menu;
