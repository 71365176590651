import { Components, MicroComponents } from '@/common/types/tracking';
import { trackingEvent } from '@/common/utils/tracking/tracking';
import { push } from '@/helpers/dataLayer';
import { getStorageItem } from '../localStorage';

type ExtraEvents = {
  microComponent: MicroComponents;
  component: Components;
};

export const sendClickEventSignIn = (
  action: string,
  label: string,
  extraEvent?: ExtraEvents,
) => {
  const origin = window?.location?.pathname;
  const isDesktop = window.matchMedia('(min-width: 1024px)').matches;
  trackingEvent({
    event: 'login',
    event_type: 'click',
    method: 'nike_account',
    category: `UI - Login - Nike Account`,
    action: `${action}` + ' - ' + `${isDesktop ? 'desktop' : 'mobile'}`,
    component: extraEvent?.component,
    micro_component: extraEvent?.microComponent,
    label,
    origin,
  });
  trackingEvent({
    event: 'start_login',
    action,
    label,
    origin,
  });
};

export const sendLoginAndSingUpEvent = (event: string) => {
  const origin = getStorageItem('clickedOnLogin');

  trackingEvent({
    event: event,
    event_type: 'click',
    method: 'Email',
    origin: origin ? 'menu' : 'cart',
  });
};

export const sendClickEventSignOut = (action: string, label: string) => {
  const origin = window?.location?.pathname;
  push({
    event: 'login',
    event_type: 'click',
    category: 'UI - Login',
    action,
    label,
    origin,
  });
};
