import { useMenu } from '@/common/patterns/Menu/menuContext';
import {
  MenuUserIcon,
  MenuUserName,
  Wrapper,
} from '../SubHeader/SubHeader.styled';

interface PropsUserLogged {
  username: string;
  isVisible: boolean;
}

const UserLogged = ({ username, isVisible }: PropsUserLogged) => {
  const { labels } = useMenu();

  return (
    <>
      <Wrapper data-testid={'user-menu-btn'}>
        <MenuUserName isVisible={isVisible}>
          {labels?.helloLabel} {username}
        </MenuUserName>
        {/* TODO: verificar se o profile se mantém o mesmo*/}
        <MenuUserIcon isVisible={isVisible} />
      </Wrapper>
    </>
  );
};

export default UserLogged;
