import { trackingEvent } from '@/common/utils/tracking/tracking';
import {
  ErrorResponse,
  onChangeFavoriteProps,
  sendSubmitErrorsEventProps,
} from '../Registration.types';

const REGISTER_FORM = 'cadastro';

export const sendChangePersonTypeEvent = (value: string) => {
  trackingEvent({
    event: 'select_person_type',
    form: REGISTER_FORM,
    action: 'clique no select de tipo de pessoa',
    select: `${value}`,
  });
};

export const sendClickSubmitEvent = (
  personType: string,
  favoriteproducts: Array<string>,
) => {
  trackingEvent({
    event: 'select_registration',
    form: REGISTER_FORM,
    action: 'clique no botão para finalizar o cadastro',
    account_type: `${personType}`,
    favorite_products: `${favoriteproducts}`,
  });
};

export const sendCloseButtonEvent = () => {
  trackingEvent({
    event: 'select_close_registration',
    form: REGISTER_FORM,
    action: 'clique no botão para fechar o modal de cadastro',
  });
};

export const sendChangeFavoriteEvent = ({
  type,
  checked,
}: onChangeFavoriteProps) => {
  trackingEvent({
    event: 'select_favorites_checkbox',
    form: REGISTER_FORM,
    action: 'clique no checkbox de produtos favoritos',
    checkbox: `${checked ? 'habilitou' : 'desabilitou'}`,
    product: `${type}`,
  });
};

export const sendClickCepEvent = () => {
  trackingEvent({
    event: 'select_zipcode_link',
    form: REGISTER_FORM,
    action: 'clique no link não sei meu cep',
  });
};

export const sendSubmitFormErrorEvent = ({
  field,
  message,
}: sendSubmitErrorsEventProps) => {
  trackingEvent({
    event: 'form_validation_error',
    form: REGISTER_FORM,
    field_name: `${field}`,
    message: message || '',
  });
};

export const sendApiSuccessEvent = (personType: string) => {
  trackingEvent({
    event: 'api_success_registration',
    account_type: `${personType}`,
  });
};

export const sendApiErrorEvent = (personType: string, error: ErrorResponse) => {
  trackingEvent({
    event: 'api_error_registration',
    account_type: `${personType}`,
    error_message: `${error.response.data}`,
    error_code: `${error.response.status}`,
  });
};

export const sendStateRegistrationExemptEvent = (checked: boolean) => {
  trackingEvent({
    event: 'select_state_exempt_checkbox',
    form: REGISTER_FORM,
    action: 'clique no checkbox isento',
    checkbox: `${checked ? 'Marcou' : 'Desmarcou'}`,
  });
};

export const setNewNameOrSurnameEvent = (personType: string, value: string) => {
  trackingEvent({
    event: 'api_success_name_surname',
    form: REGISTER_FORM,
    account_type: `${personType}`,
    field_name: `${value}`,
  });
};

export const setLatinNameOrSurnameEvent = (
  personType: string,
  value: string,
) => {
  trackingEvent({
    event: 'api_success_latin_chars',
    form: REGISTER_FORM,
    account_type: `${personType}`,
    field_name: `${value}`,
  });
};

export const setNameOrSurnameMinimumCharsEvent = (
  personType: string,
  value: string,
) => {
  trackingEvent({
    event: 'api_success_minimum_chars',
    form: REGISTER_FORM,
    account_type: `${personType}`,
    field_name: `${value}`,
  });
};

export const setRigthBirthdateEvent = (value: string) => {
  trackingEvent({
    event: 'api_success_birthdate',
    form: REGISTER_FORM,
    birthdate: `${value}`,
  });
};

export const sendKeyCloakTokenErrorEvent = () => {
  trackingEvent({
    event: 'api_error_keycloak',
    form: REGISTER_FORM,
  });
};

export const sendDocumentCheckerSuccessEvent = () => {
  trackingEvent({
    event: 'api_success_document_check',
    form: REGISTER_FORM,
  });
};

export const sendDocumentCheckerErrorEvent = (code = '', message = '') => {
  if (code && message) {
    trackingEvent({
      event: 'api_error_document_check',
      form: REGISTER_FORM,
      error_message: `${message}`,
      error_code: `${code}`,
    });
  } else {
    trackingEvent({
      event: 'api_error_document_check',
      form: REGISTER_FORM,
      error_message: '',
      error_code: '',
    });
  }
};

export const setHelpCenterEvent = () => {
  trackingEvent({
    event: 'select_help_center_link',
    form: REGISTER_FORM,
    action: 'clique no link central de ajuda',
  });
};

export const setMaxAgeEvent = () => {
  trackingEvent({
    event: 'max_age_validation_error',
    action: 'digitou data de nascimento acima de 100 anos',
    form: REGISTER_FORM,
  });
};

export const sendNewsletterRegisterChangeEvent = (
  personType: string,
  newsletter: boolean,
) => {
  trackingEvent({
    event: 'select_newsletter_register',
    account_type: `${personType}`,
    form: REGISTER_FORM,
    newsletter: `${newsletter ? 'Marcada' : 'Desmarcada'}`,
  });
};

export const setNewsletterOptinEvent = (personType: string) => {
  trackingEvent({
    event: 'api_success_newsletter_registration',
    form: REGISTER_FORM,
    account_type: `${personType}`,
  });
};
