import {
  currencyFormatter,
  currencyFormatterWithoutSymbol,
} from '@/helpers/formatter';
import { SummaryProductInfoProps } from '@/modules/checkout/components/SummaryProductInfo/SummaryProductInfo';
import {
  ItemInfoRequestBody,
  Product,
} from '@/modules/checkout/services/cart/cart.types';
import {
  CartDataResponseApi,
  ProductPlaceOrder,
} from '@/modules/guestCheckout/types/cart';

export const getItemFromCart = (
  item: ItemInfoRequestBody,
  itemCart: ProductPlaceOrder[] | undefined,
): ProductPlaceOrder | undefined => {
  return itemCart?.find((res) => {
    return res.sku.id === item.sku;
  });
};

export const addQuantity = (item: ProductPlaceOrder) => {
  return item.quantity + 1;
};

export const mapperProduct = (
  item: ProductPlaceOrder,
): SummaryProductInfoProps => {
  const {
    image,
    name,
    url = '',
    price: { listPrice, price },
    quantity,
    sku: { id: skuId, customization, color, size },
  } = item;

  return {
    id: 0, // Just for tests (we have to send the correct ID (uuid))
    img: image.url,
    title: name,
    href: url,
    price: currencyFormatter(price),
    oldPrice: currencyFormatter(listPrice),
    color: color.description,
    size: size.description,
    totalPrice: currencyFormatter(price ?? 0),
    quantity,
    personalizationPrice: currencyFormatter(customization?.price?.price ?? 0),
    sku: skuId,
  };
};

export const productSummary = (
  product: ProductPlaceOrder[] | undefined,
): SummaryProductInfoProps[] | [] => {
  if (!product) {
    return [];
  }

  return product.map(mapperProduct);
};

export const itemsMapper = (items: ProductPlaceOrder[]) => {
  return items.map((item) => {
    return {
      ...item,
      priceFormatted: {
        listPrice: currencyFormatter(item.price.listPrice),
        price: currencyFormatter(item.price.price),
        total: currencyFormatter(item.total),
        priceAfterDiscount: currencyFormatter(item.price.priceAfterDiscount),
      },
    };
  });
};

export const mapperProductService = (data: ProductPlaceOrder[]) => {
  return data.map((res: ProductPlaceOrder): Product => {
    const { quantity, sku, name, image, price, url } = res;
    return {
      id: 123, // We have to do a diff from shopping-cart and carrinhos. Both have Id's: string and number :/
      sku: sku.id,
      href: url,
      modelo: name,
      limiteModeloCor: 6,
      imagem: image?.url,
      cor: sku.color?.description,
      tamanho: sku.size?.description,
      quantidade: quantity,
      valorUnitarioDe: currencyFormatterWithoutSymbol(price.listPrice),
      valorUnitarioPor: currencyFormatterWithoutSymbol(price.price),
      total: currencyFormatterWithoutSymbol(price.price),
      precoPersonalizacao: currencyFormatterWithoutSymbol(price.price),
      customId: '',
      customizacoesFisia: [],
      personalizado: false,
      tipoPersonalizacao: 0,
      codigoEstilo: '123',
      possuiEstoque: true,
    };
  });
};

export const isSNKRS = (cartData: CartDataResponseApi | undefined) => {
  if (!cartData || cartData.items.length === 0) {
    return false;
  }

  return cartData.items.some((item) => item.exclusive === true);
};
