import { useSession } from 'next-auth/react';
import { destroyCookie, setCookie } from 'nookies';
import { useEffect } from 'react';
import { sendLoginAndSingUpEvent } from '@/helpers/auth/trackingEvents';
import { push } from '@/helpers/dataLayer';
import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from '../helpers/localStorage';

export const ACCESS_TOKEN_COOKIE = 'hdr-tk';
export const EXPIRATION_COOKIE = 'hdr-tk-exp';

export const useAuthSession = () => {
  const { data: session, status } = useSession({ required: false });
  const isAuthenticated = status === 'authenticated';
  const isLoading = status === 'loading';
  const accessToken = session?.accessToken as string;
  const idToken = session?.idToken as string;
  useEffect(() => {
    if (session?.x_client_token) {
      setStorageItem('x-client-token', session?.x_client_token);
    }
    if (session?.accessToken && session?.accessTokenExpires) {
      setCookie(null, ACCESS_TOKEN_COOKIE, session.accessToken);
      setCookie(null, EXPIRATION_COOKIE, session.accessTokenExpires);
    }

    if (!isAuthenticated && !isLoading) {
      destroyCookie(null, ACCESS_TOKEN_COOKIE);
      destroyCookie(null, EXPIRATION_COOKIE);
    }

    const userJustLoggedIn = getStorageItem('userJustLoggedIn');

    if (isAuthenticated && userJustLoggedIn !== 'true') {
      setStorageItem('userJustLoggedIn', 'true');
      push({
        event: 'login',
        event_type: 'login com sucesso',
        method: 'Nike',
        category: 'UI - Login - Nike Account',
        action: 'Login Sucesso',
        label: 'Login feito com sucesso',
        origin,
      });
      sendLoginAndSingUpEvent('login');
      removeStorageItem('clickedOnLogin');
    }
  }, [session, isAuthenticated, isLoading]);

  return {
    isAuthenticated,
    isLoading,
    session,
    accessToken,
    idToken,
  };
};

export default useAuthSession;
