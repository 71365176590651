import { signOut } from 'next-auth/react';
import { destroyCookie } from 'nookies';
import { isClientSide } from '@/common/utils/environment/environment';
import { clearUserOnSentryContext } from '@/common/utils/tracking/thirdParty/sentry/sentry';
import { removeStorageItem } from '@/helpers/localStorage';
import { ACCESS_TOKEN_COOKIE, EXPIRATION_COOKIE } from '@/hooks/useAuthSession';
import { KEY_MPARTICLE } from '@/modules/checkout/utils/trackingEvents.types';
import { sendClickEventSignOut } from './trackingEvents';
import { AuthEventType } from './types';

export const handleSignOut = async (authEvent: AuthEventType | null = null) => {
  removeStorageItem('x-client-token');
  removeStorageItem('userJustLoggedIn');

  removeStorageItem(KEY_MPARTICLE);
  destroyCookie(null, 'token-cliente-anonimo');
  destroyCookie(null, ACCESS_TOKEN_COOKIE);
  destroyCookie(null, EXPIRATION_COOKIE);
  if (authEvent && isClientSide()) {
    sendClickEventSignOut(authEvent.action, authEvent.label);
  }
  clearUserOnSentryContext();
  signOut({ redirect: false });
};
