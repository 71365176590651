import {
  Button,
  Checkbox,
  Radio,
  TextInput,
  Typography,
} from '@sbf/fairplay-nike';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { getCoreRoutes } from '@/common/config/routes';
import { useNewCallbackLogin } from '@/common/hooks/useNewCallbackLogin/useNewCallbackLogin';
import { formatPhone, formatZipCode } from '@/common/utils/form/formatter';
import {
  maskCnpj,
  maskCpf,
  maskDate,
  maskPostCode,
  maskTelephone,
} from '@/common/utils/form/mask';
import { StaticImage } from '@/components/Image';
import { Loader } from '@/components/Loader';
import { useTrackingContext } from '@/contexts/TrackingContext';
import {
  SelectContentObject,
  sendSelectContentEvents,
} from '@/helpers/analytics';
import { AuthEventType, handleSignIn, handleSignOut } from '@/helpers/auth';
import { dateFormatter } from '@/helpers/formatter';
import { setStorageItem } from '@/helpers/localStorage';
import { checkoutPath, homePath, searchCepPath } from '@/helpers/path';
import { CACHE_QUERY } from '@/modules/guestCheckout/services/address/address.type';
import {
  GET_ADDRESS_QUERY,
  GET_REGISTRATION_QUERY,
  useGetKeycloakTokenMutation,
  useGetZipCodeMutation,
  usePostCnpjMutation,
  usePostDocumentCheckerMutation,
  usePutRegistrationMutation,
  usePutRegistrationMutationCNPJ,
  usePutRegistrationMutationCPF,
} from '@/modules/myAccount/services/registration/registration';
import { checkMinimumChars } from '@/modules/myAccount/utils/form/registrationModal';
import {
  ADDRESS_ERROR_MESSAGES,
  ALREADY_EXISTS,
  BIRTHDATE_ERROR_MESSAGES,
  CNPJ_ERROR_MESSAGES,
  CORPORATE_NAME_ERROR_MESSAGES,
  CPF_ERROR_MESSAGES,
  DISTRICT_ERROR_MESSAGES,
  GENDER_PREFERENCE_ERROR_MESSAGES,
  NAME_ERROR_MESSAGES,
  NUMBER_ERROR_MESSAGES,
  PHONE_ERROR_MESSAGES,
  STATE_REGISTRATION_ERROR_MESSAGES,
  SURNAME_ERROR_MESSAGES,
  ZIPCODE_ERROR_MESSAGES,
  checkLatinChars,
  checkMaxAge,
  checkMinAge,
  checkValidDate,
  cpfValidator,
} from '@/modules/myAccount/utils/form/validations';
import {
  CompleteFormData,
  CompleteFormProps,
  Errors,
  PutRegistrationBodyBFF,
} from '../Registration.types';
import {
  ButtonHelpCenter,
  ButtonMargin,
  CheckboxMargin,
  ColumnCenter,
  ColumnStart,
  Content,
  GenderPreferenceRow,
  Grid,
  HelpCenterLink,
  LinkStyled,
  LoginStyled,
  MarginFavorites,
  MarginTitle,
  PersonRow,
  PoliciesBoldParagraph,
  PoliciesContainer,
  PoliciesContent,
  PoliciesErrorIcon,
  PoliciesErrorParagraph,
  PoliciesLink,
  PoliciesParagraph,
  PoliciesText,
} from './CompleteForm.styled';
import {
  sendApiErrorEvent,
  sendApiSuccessEvent,
  sendChangeFavoriteEvent,
  sendChangePersonTypeEvent,
  sendClickCepEvent,
  sendClickSubmitEvent,
  sendCloseButtonEvent,
  sendDocumentCheckerErrorEvent,
  sendDocumentCheckerSuccessEvent,
  sendKeyCloakTokenErrorEvent,
  sendNewsletterRegisterChangeEvent,
  sendStateRegistrationExemptEvent,
  sendSubmitFormErrorEvent,
  setHelpCenterEvent,
  setLatinNameOrSurnameEvent,
  setMaxAgeEvent,
  setNameOrSurnameMinimumCharsEvent,
  setNewNameOrSurnameEvent,
  setNewsletterOptinEvent,
  setRigthBirthdateEvent,
} from './gaEvents';

const coreRoutes = getCoreRoutes();

const NATURAL_PERSON = 'PF';
const JURIDICAL_PERSON = 'PJ';
const BOTH = 'AMBOS';
const FEMALE = 'FEMININO';
const MALE = 'MASCULINO';

const CompleteForm = ({
  onCloseModal,
  onLogout,
  responseData,
}: CompleteFormProps) => {
  const isNewCallbackLogin = useNewCallbackLogin();
  const [personType, setPersonType] = useState(NATURAL_PERSON);
  const [newsletterOptin, setNewsletterOptin] = useState(false);
  const [policiesAccept, setPoliciesAccept] = useState(false);
  const [hasPoliciesError, setHasPoliciesError] = useState(false);
  const [isStateRegistrationEnabled, setStateRegistration] = useState(false);
  const [stateValue, setStateValue] = useState('');
  const [cityValue, setCityValue] = useState('');
  const [genderPreference, setGenderPreference] = useState('');
  const [hasGenderPreferenceError, setHasGenderPreferenceError] =
    useState(false);

  const [documentAlreadyExistsMessage, setDocumentAlreadyExistsMessage] =
    useState(false);
  const { handleEvents } = useTrackingContext();

  const getZipCodeMutation = useGetZipCodeMutation();
  const postCnpjMutation = usePostCnpjMutation();
  const putRegistrationMutation = usePutRegistrationMutation();

  const putRegistrationMutationCPF = usePutRegistrationMutationCPF();
  const putRegistrationMutationCNPJ = usePutRegistrationMutationCNPJ();

  const postDocumentCheckerMutation = usePostDocumentCheckerMutation();
  const getKeycloakTokenMutation = useGetKeycloakTokenMutation();

  const queryClient = useQueryClient();

  const router = useRouter();

  const isLoading = useMemo(
    () =>
      getZipCodeMutation.isLoading ||
      postCnpjMutation.isLoading ||
      putRegistrationMutation.isLoading ||
      putRegistrationMutationCPF.isLoading ||
      putRegistrationMutationCNPJ.isLoading ||
      getKeycloakTokenMutation.isLoading ||
      postDocumentCheckerMutation.isLoading,
    [
      getZipCodeMutation.isLoading,
      postCnpjMutation.isLoading,
      putRegistrationMutation.isLoading,
      putRegistrationMutationCPF.isLoading,
      putRegistrationMutationCNPJ.isLoading,
      getKeycloakTokenMutation.isLoading,
      postDocumentCheckerMutation.isLoading,
    ],
  );

  useEffect(() => {
    if (responseData?.cnpj) {
      setPersonType(JURIDICAL_PERSON);
    }

    if (responseData?.endereco?.cidade) {
      setCityValue(responseData?.endereco?.cidade);
    }

    if (responseData?.endereco?.estado) {
      setStateValue(responseData?.endereco?.estado);
    }
  }, [responseData]);

  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    setError,
  } = useForm<CompleteFormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      cpf: responseData?.cpf || '',
      dataDeNascimento:
        (responseData?.dataDeNascimento &&
          dateFormatter(responseData?.dataDeNascimento)) ||
        '',
      nome: responseData?.nome || '',
      sobrenome: responseData?.sobrenome || '',
      cnpj: responseData?.cnpj || '',
      razaoSocial: responseData?.razaoSocial || '',
      inscricaoEstadual: responseData?.inscricaoEstadual || '',
      cep: formatZipCode(responseData?.endereco?.cep) || '',
      endereco: responseData?.endereco?.logradouro || '',
      numero: responseData?.endereco?.numero || '',
      complemento: responseData?.endereco?.complemento || '',
      bairro: responseData?.endereco?.bairro || '',
      telefone:
        formatPhone(responseData?.endereco?.telefoneAdicional) ||
        formatPhone(responseData?.endereco?.telefoneFixo) ||
        '',
    },
  });

  useEffect(() => {
    if (
      errors.dataDeNascimento?.message ===
      BIRTHDATE_ERROR_MESSAGES.ISOLDERHUNDRED
    ) {
      setMaxAgeEvent();
    }
  }, [errors.dataDeNascimento?.message]);

  const handleSendClickSubmitEvent = () => {
    if (!policiesAccept) {
      setHasPoliciesError(true);
    }
    if (!genderPreference) {
      setHasGenderPreferenceError(true);
    }
    const favoriteproducts = [];
    favoriteproducts.push(genderPreference);
    sendClickSubmitEvent(personType, favoriteproducts);
  };

  const isNameLatin = checkLatinChars(responseData?.nome);
  const isSurnameLatin = checkLatinChars(responseData?.sobrenome);
  const isNameGreaterThanTwoChars = checkMinimumChars(responseData?.nome);
  const isSurnameGreaterThanTwoChars = checkMinimumChars(
    responseData?.sobrenome,
  );
  const isYoungerThanOneHundred = responseData?.dataDeNascimento
    ? checkMaxAge(dateFormatter(responseData?.dataDeNascimento))
    : true;
  const isEighteenYearsOrOlder = responseData?.dataDeNascimento
    ? checkMinAge(dateFormatter(responseData?.dataDeNascimento))
    : true;

  const onInscricaoEstadualChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setStateRegistration(checked);
    if (checked) {
      setValue('inscricaoEstadual', '');
      clearErrors('inscricaoEstadual');
    }
    sendStateRegistrationExemptEvent(checked);
  };

  const checkPostCodeApi = async (value: string) => {
    const postCode = value.replace(/_|-/g, '');

    if (postCode.length === 8) {
      getZipCodeMutation.mutate(postCode, {
        onSuccess: (data) => {
          if (data.logradouro) {
            setValue('endereco', data.logradouro.slice(0, 60));
            clearErrors('endereco');
          }
          if (data.bairro) {
            setValue('bairro', data.bairro.slice(0, 40));
            clearErrors('bairro');
          }
          setCityValue(data.cidade.slice(0, 40));
          setStateValue(data.uf);
        },
        onError: () => {
          setError('cep', {
            message: ZIPCODE_ERROR_MESSAGES.DOESNT_EXIST,
          });
          setValue('endereco', '');
          setValue('numero', '');
          setValue('complemento', '');
          setValue('bairro', '');
          setCityValue('');
          setStateValue('');
        },
      });
    } else {
      setValue('endereco', '');
      setValue('numero', '');
      setValue('complemento', '');
      setValue('bairro', '');
      setCityValue('');
      setStateValue('');
      return ZIPCODE_ERROR_MESSAGES.INVALID;
    }
  };

  const checkCnpj = async (value: string) => {
    const cnpj = value.replace(/\/|\.|-/g, '');

    if (responseData?.cnpj) {
      return true;
    }

    await postCnpjMutation.mutateAsync(cnpj, {
      onSuccess: () => {
        clearErrors('cnpj');
      },
      onError: (error) => {
        setError('cnpj', {
          message: `${error.response.data[0]}.`,
        });
      },
    });
  };

  const onChangePersonType = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPersonType(value);
    sendChangePersonTypeEvent(value);
  };

  const onChangeGenderPreference = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setHasGenderPreferenceError(false);
    setGenderPreference(value);
    sendChangeFavoriteEvent({ type: value, checked: true });
  };

  const onChangeNewsletter = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    sendNewsletterRegisterChangeEvent(personType, checked);
    setNewsletterOptin(checked);
  };

  const onChangePolicies = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    checked && setHasPoliciesError(false);
    setPoliciesAccept(checked);
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('nome', event.target.value.replace(/[0-9]/g, ''));
  };

  const onChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('sobrenome', event.target.value.replace(/[0-9]/g, ''));
  };

  const onSubmit = (formData: CompleteFormData) => {
    if (!policiesAccept || !genderPreference) {
      return;
    }
    const phone = formData.telefone.replace(/ |_|-|\(|\)/g, '');

    const additionalPhone = phone.length === 11;
    const ddd = phone.substring(0, 2);

    let requestBodyBFF: PutRegistrationBodyBFF = {};

    const phoneNumber = additionalPhone
      ? phone.substring(2, 11)
      : phone.substring(2, 10);

    requestBodyBFF = {
      addressInfo: {
        zipCode: formData.cep.replace(/\.|-/g, ''),
        address: formData.endereco,
        houseNumber: formData.numero,
        additionalInfo: formData.complemento,
        neighborhood: formData.bairro,
        city: cityValue,
        state: stateValue,
        contactNumber: {
          areaCode: ddd,
          number: phoneNumber,
        },
      },
      preferedProducts: genderPreference,
      firstName: formData.nome,
      lastName: formData.sobrenome,
      newsletterConsent: newsletterOptin,
      useTermsConsent: policiesAccept,
    };

    const isNameMissing = Boolean(!responseData?.nome);
    const isSurnameMissing = Boolean(!responseData?.sobrenome);

    if (
      personType === NATURAL_PERSON &&
      formData.cpf &&
      formData.dataDeNascimento
    ) {
      const [day, month, year] = formData.dataDeNascimento.split('/');
      if (!responseData?.cpf) {
        requestBodyBFF.documentNumber = formData.cpf.replace(/\.|-/g, '');
      }
      requestBodyBFF.birthdate = `${year}-${month}-${day}`;
    } else {
      if (!responseData?.cnpj) {
        requestBodyBFF.documentNumber = formData.cnpj?.replace(/\/|\.|-/g, '');
      }
      requestBodyBFF.companyNumber = formData.inscricaoEstadual;
      requestBodyBFF.socialReason = formData.razaoSocial;
    }

    const callEvent = (
      check: boolean,
      eventType: (personType: string, eventValue: string) => void,
      eventValue: string,
    ) => {
      if (check) {
        eventType(personType, eventValue);
      }
    };

    const checkValidationEvents = () => {
      callEvent(isNameMissing, setNewNameOrSurnameEvent, 'nome');
      callEvent(isSurnameMissing, setNewNameOrSurnameEvent, 'sobrenome');
      callEvent(!isNameLatin, setLatinNameOrSurnameEvent, 'nome');
      callEvent(!isSurnameLatin, setLatinNameOrSurnameEvent, 'sobrenome');
      callEvent(
        !isNameGreaterThanTwoChars,
        setNameOrSurnameMinimumCharsEvent,
        'nome',
      );
      callEvent(
        !isSurnameGreaterThanTwoChars,
        setNameOrSurnameMinimumCharsEvent,
        'sobrenome',
      );
      if (!isYoungerThanOneHundred) {
        setRigthBirthdateEvent('menor que 100 anos');
      }
      if (!isEighteenYearsOrOlder) {
        setRigthBirthdateEvent('maior que 18 anos');
      }
    };

    const successMutation = () => {
      queryClient.invalidateQueries(GET_ADDRESS_QUERY);
      queryClient.invalidateQueries(GET_REGISTRATION_QUERY);
      queryClient.refetchQueries({
        queryKey: [CACHE_QUERY.ADDRESS, true],
      });
      sendApiSuccessEvent(personType);

      checkValidationEvents();
      if (newsletterOptin) {
        setNewsletterOptinEvent(personType);
      }
      onCloseModal();
    };

    const mutation =
      personType === 'PF'
        ? putRegistrationMutationCPF
        : putRegistrationMutationCNPJ;

    mutation.mutate(requestBodyBFF, {
      onSuccess: () => {
        successMutation();
      },
      onError: (error) => {
        sendApiErrorEvent(personType, error);
      },
    });
  };

  const onSubmitError = (errors: Errors) => {
    Object.keys(errors).forEach((field) => {
      sendSubmitFormErrorEvent({ field, message: errors[field].message });
    });
  };

  const onCloseButton = async () => {
    sendCloseButtonEvent();
    onCloseModal();
    if (router.pathname === checkoutPath) {
      await router.push(homePath);
    }
    onLogout();
  };

  const handleHelpCenterClick = () => {
    setHelpCenterEvent();
    window.location.assign(coreRoutes.attendance);
  };

  const birthDayResponseError = (message: string) => {
    if (message === BIRTHDATE_ERROR_MESSAGES.ISOLDERHUNDRED) {
      return (
        <>
          <span>
            Essa data está correta? Digite novamente. Você tem mais de 100 anos?
            Entre em contato através da nossa{' '}
          </span>
          <ButtonHelpCenter onClick={handleHelpCenterClick}>
            <HelpCenterLink>Central de Ajuda</HelpCenterLink>
          </ButtonHelpCenter>
          <span>.</span>
        </>
      );
    } else {
      return message;
    }
  };

  const authEvent: AuthEventType = {
    action: 'Login via Cadastro Complementar - CPF já cadastrado',
    label: 'Clicou no link Faça seu Login',
  };

  const authEventOnCloseAndLogout: AuthEventType = {
    action: 'Logout via Cadastro Complementar - CPF já cadastrado',
    label: 'Clicou no link Faça seu Login',
  };

  const onClickLogin = () => {
    handleSignOut(authEventOnCloseAndLogout);
    handleSignIn(authEvent, isNewCallbackLogin);
    setStorageItem('clickedOnLogin', 'clickLoginMenu');
    handleEvents<SelectContentObject>(sendSelectContentEvents, {
      component: 'registration',
      microComponent: 'error_link',
      anchorText: 'Faça seu Login',
      title: '',
      url: coreRoutes.login,
      contentType: 'navigation_link',
    });
  };

  const cpfResponseError = (message: string) => {
    if (documentAlreadyExistsMessage) {
      return (
        <span>
          {message}{' '}
          <LoginStyled
            size="tiny"
            onClick={onClickLogin}
            text="Faça seu Login"
          />
        </span>
      );
    } else {
      return message;
    }
  };

  const documentChecker = async (value: string) => {
    setDocumentAlreadyExistsMessage(false);
    const cpf = getValues('cpf') || '';

    if (responseData?.cpf) {
      clearErrors('cpf');
      clearErrors('dataDeNascimento');
      return true;
    }

    if (!cpfValidator(cpf)) {
      return CPF_ERROR_MESSAGES.VERIFYCPF;
    }

    try {
      const response = await getKeycloakTokenMutation.mutateAsync();
      const params = {
        documentNumber: cpf,
        birthDate: value,
        token: response.token,
      };

      try {
        await postDocumentCheckerMutation.mutateAsync(params);
        clearErrors('cpf');
        clearErrors('dataDeNascimento');
        sendDocumentCheckerSuccessEvent();
        return true;
      } catch (error) {
        if (error.response?.data) {
          const { code, message } = error.response.data;

          if (code === ALREADY_EXISTS) {
            setDocumentAlreadyExistsMessage(true);
          }

          setError('cpf', {
            message: message,
          });
          sendDocumentCheckerErrorEvent(code, message);
        } else {
          setError('cpf', {
            message: CPF_ERROR_MESSAGES.INTERNALERROR,
          });
          sendDocumentCheckerErrorEvent();
        }

        return BIRTHDATE_ERROR_MESSAGES.ISDOCCHECKERVALID;
      }
    } catch (error) {
      setError('cpf', {
        message: CPF_ERROR_MESSAGES.INTERNALERROR,
      });
      sendKeyCloakTokenErrorEvent();

      return BIRTHDATE_ERROR_MESSAGES.ISDOCCHECKERVALID;
    }
  };

  return (
    <Content>
      <StaticImage
        src="/images/brands/logo.svg"
        width="40rem"
        height="14rem"
        alt="Logo da Nike"
        disablePlaceholder
      />

      <MarginTitle variant="heading" size="h2">
        COMPLETE SEU CADASTRO
      </MarginTitle>

      <Typography variant="paragraph" size="tiny" weight="regular">
        Os campos marcados com asterisco são de preenchimento obrigatório.
      </Typography>

      <PersonRow>
        <Radio
          control={{
            label: 'Pessoa física',
          }}
          name="tipoPessoa"
          id="pessoaFisica"
          value="PF"
          checked={personType === NATURAL_PERSON}
          onChange={onChangePersonType}
          disabled={Boolean(responseData?.cnpj)}
        />
        <Radio
          control={{
            label: 'Pessoa jurídica',
          }}
          name="tipoPessoa"
          id="pessoaJuridica"
          value="PJ"
          checked={personType === JURIDICAL_PERSON}
          onChange={onChangePersonType}
          disabled={Boolean(responseData?.cpf)}
        />
      </PersonRow>

      <form onSubmit={handleSubmit(onSubmit, onSubmitError)} noValidate>
        <Grid>
          <ColumnStart>
            <TextInput
              id="nome"
              placeholder="Nome*"
              type="text"
              enterKeyHint="next"
              maxLength={19}
              {...register('nome', {
                required: {
                  value: personType === NATURAL_PERSON,
                  message: NAME_ERROR_MESSAGES.REQUIRED,
                },
                minLength: {
                  value: 2,
                  message: NAME_ERROR_MESSAGES.MINLENGTH,
                },
                validate: {
                  isLatin: (value) =>
                    value &&
                    (checkLatinChars(value) || NAME_ERROR_MESSAGES.ISLATIN),
                },
              })}
              control={{
                label: 'Nome*',
                error: Boolean(errors.nome),
                hint: {
                  id: 'nome-hint',
                  errorMessage: errors.nome?.message || '',
                },
              }}
              onChange={onChangeName}
              disabled={
                Boolean(responseData?.nome) &&
                isNameLatin &&
                isNameGreaterThanTwoChars
              }
            />

            <TextInput
              id="sobrenome"
              placeholder="Sobrenome*"
              type="text"
              enterKeyHint="next"
              maxLength={19}
              {...register('sobrenome', {
                required: {
                  value: personType === NATURAL_PERSON,
                  message: SURNAME_ERROR_MESSAGES.REQUIRED,
                },
                minLength: {
                  value: 2,
                  message: SURNAME_ERROR_MESSAGES.MINLENGTH,
                },
                validate: {
                  isLatin: (value) =>
                    value &&
                    (checkLatinChars(value) || SURNAME_ERROR_MESSAGES.ISLATIN),
                },
              })}
              control={{
                label: 'Sobrenome*',
                error: Boolean(errors.sobrenome),
                hint: {
                  id: 'sobrenome-hint',
                  errorMessage: errors.sobrenome?.message || '',
                },
              }}
              onChange={onChangeLastName}
              disabled={
                Boolean(responseData?.sobrenome) &&
                isSurnameLatin &&
                isSurnameGreaterThanTwoChars
              }
            />
          </ColumnStart>
        </Grid>
        {personType === NATURAL_PERSON && (
          <Grid>
            <TextInput
              id="cpf"
              placeholder="CPF*"
              type="tel"
              enterKeyHint="next"
              maxLength={14}
              {...register('cpf', {
                required: {
                  value: personType === NATURAL_PERSON,
                  message: CPF_ERROR_MESSAGES.REQUIRED,
                },
                validate: {
                  isValidDocument: (value) =>
                    value &&
                    (cpfValidator(value, Boolean(responseData?.cpf)) ||
                      CPF_ERROR_MESSAGES.INVALID),
                },
              })}
              control={{
                label: 'CPF*',
                error: Boolean(errors.cpf),
                hint: {
                  id: 'cpf-hint',
                  /* @ts-expect-error design system does not support links on error message */
                  errorMessage: cpfResponseError(errors.cpf?.message || ''),
                },
              }}
              onChange={maskCpf}
              disabled={Boolean(responseData?.cpf)}
            />

            {documentAlreadyExistsMessage && (
              <Typography variant="paragraph" size="tiny" weight="regular">
                Se não tiver acesso ao e-mail, ligue para nossos atletas de
                atendimento (11) 5039-9711
              </Typography>
            )}

            <TextInput
              id="dataDeNascimento"
              placeholder="Data de Nascimento*"
              type="tel"
              enterKeyHint="next"
              maxLength={10}
              {...register('dataDeNascimento', {
                required: {
                  value: personType === NATURAL_PERSON,
                  message: BIRTHDATE_ERROR_MESSAGES.REQUIRED,
                },
                minLength: {
                  value: 10,
                  message: BIRTHDATE_ERROR_MESSAGES.MINLENGTH,
                },
                validate: {
                  isValidDate: (value) =>
                    value &&
                    (checkValidDate(value) || BIRTHDATE_ERROR_MESSAGES.ISVALID),
                  isOlderThanEighteen: (value) =>
                    value &&
                    (checkMinAge(value) ||
                      BIRTHDATE_ERROR_MESSAGES.ISOLDEREIGHTEEN),
                  isOlderThanOneHundred: (value) =>
                    value &&
                    (checkMaxAge(value) ||
                      BIRTHDATE_ERROR_MESSAGES.ISOLDERHUNDRED),
                  isValidDocument: async (value) =>
                    value && (await documentChecker(value)),
                },
              })}
              control={{
                label: 'Data de Nascimento*',
                error: Boolean(errors.dataDeNascimento),
                hint: {
                  id: 'dataDeNascimento-hint',
                  /* @ts-expect-error check with design system team */
                  errorMessage: birthDayResponseError(
                    errors.dataDeNascimento?.message || '',
                  ),
                },
              }}
              onChange={maskDate}
              disabled={
                Boolean(responseData?.dataDeNascimento) &&
                isYoungerThanOneHundred &&
                isEighteenYearsOrOlder
              }
            />
          </Grid>
        )}

        {personType === JURIDICAL_PERSON && (
          <Grid>
            <TextInput
              id="cnpj"
              placeholder="CNPJ*"
              type="tel"
              enterKeyHint="next"
              maxLength={18}
              {...register('cnpj', {
                required: {
                  value: personType === JURIDICAL_PERSON,
                  message: CNPJ_ERROR_MESSAGES.REQUIRED,
                },
                minLength: {
                  value: responseData?.cnpj ? 14 : 18,
                  message: CNPJ_ERROR_MESSAGES.MINLENGTH,
                },
                validate: {
                  checkExistsInDataBase: async (value) =>
                    value && (await checkCnpj(value)),
                },
              })}
              control={{
                label: 'CNPJ*',
                error: Boolean(errors.cnpj),
                hint: {
                  id: 'cnpj-hint',
                  errorMessage: errors.cnpj?.message || '',
                },
              }}
              onChange={maskCnpj}
              disabled={Boolean(responseData?.cnpj)}
            />
            <TextInput
              id="razaoSocial"
              placeholder="Razão Social*"
              type="text"
              enterKeyHint="next"
              {...register('razaoSocial', {
                required: {
                  value: personType === JURIDICAL_PERSON,
                  message: CORPORATE_NAME_ERROR_MESSAGES.REQUIRED,
                },
              })}
              control={{
                label: 'Razão Social*',
                error: Boolean(errors.razaoSocial),
                hint: {
                  id: 'razaoSocial-hint',
                  errorMessage: errors.razaoSocial?.message || '',
                },
              }}
              disabled={Boolean(responseData?.razaoSocial)}
            />
            <ColumnCenter>
              <TextInput
                id="inscricaoEstadual"
                placeholder="Inscrição Estadual*"
                type="tel"
                enterKeyHint="next"
                {...register('inscricaoEstadual', {
                  required: {
                    value:
                      personType === JURIDICAL_PERSON &&
                      !isStateRegistrationEnabled,
                    message: STATE_REGISTRATION_ERROR_MESSAGES.REQUIRED,
                  },
                })}
                maxLength={18}
                control={{
                  label: 'Inscrição Estadual*',
                  error: Boolean(errors.inscricaoEstadual),
                  hint: {
                    id: 'inscricaoEstadual-hint',
                    errorMessage: errors.inscricaoEstadual?.message || '',
                  },
                }}
                disabled={
                  isStateRegistrationEnabled ||
                  Boolean(responseData?.inscricaoEstadual)
                }
              />

              <CheckboxMargin
                marginBottom={errors.inscricaoEstadual ? true : false}
              >
                <Checkbox
                  id="isentoInscricaoEstadual"
                  checked={isStateRegistrationEnabled}
                  onChange={onInscricaoEstadualChange}
                  control={{
                    label: 'Isento',
                  }}
                />
              </CheckboxMargin>
            </ColumnCenter>
          </Grid>
        )}

        <Grid>
          <TextInput
            id="telefone"
            placeholder="Telefone*"
            type="tel"
            enterKeyHint="next"
            maxLength={15}
            {...register('telefone', {
              required: {
                value: true,
                message: PHONE_ERROR_MESSAGES.REQUIRED,
              },
              minLength: {
                value: 14,
                message: PHONE_ERROR_MESSAGES.MINLENGTH,
              },
            })}
            control={{
              label: 'Telefone*',
              error: Boolean(errors.telefone),
              hint: {
                id: 'telefone-hint',
                errorMessage: errors.telefone?.message || '',
              },
            }}
            onChange={maskTelephone}
            disabled={Boolean(
              responseData?.endereco?.telefoneAdicional?.numero ||
                responseData?.endereco?.telefoneFixo?.numero,
            )}
          />

          <ColumnCenter>
            <TextInput
              id="cep"
              placeholder="CEP*"
              type="tel"
              enterKeyHint="next"
              maxLength={9}
              {...register('cep', {
                validate: {
                  checkExists: async (value) => await checkPostCodeApi(value),
                },
              })}
              control={{
                label: 'CEP*',
                error: Boolean(errors.cep),
                hint: {
                  id: 'cep-hint',
                  errorMessage: errors.cep?.message || '',
                },
              }}
              onChange={maskPostCode}
              disabled={Boolean(responseData?.endereco?.cep)}
            />
            <LinkStyled
              onClick={sendClickCepEvent}
              marginBottom={errors.cep}
              text="Não sei meu CEP"
              href={searchCepPath}
              external
            />
          </ColumnCenter>
          <TextInput
            id="endereco"
            placeholder="Endereço*"
            type="text"
            enterKeyHint="next"
            maxLength={60}
            {...register('endereco', {
              required: {
                value: true,
                message: ADDRESS_ERROR_MESSAGES.REQUIRED,
              },
            })}
            control={{
              label: 'Endereço*',
              error: Boolean(errors.endereco),
              hint: {
                id: 'endereco-hint',
                errorMessage: errors.endereco?.message || '',
              },
            }}
            disabled={Boolean(responseData?.endereco?.logradouro)}
          />

          <ColumnStart>
            <TextInput
              id="numero"
              placeholder="Número*"
              type="text"
              enterKeyHint="next"
              maxLength={5}
              {...register('numero', {
                required: {
                  value: true,
                  message: NUMBER_ERROR_MESSAGES.REQUIRED,
                },
              })}
              control={{
                label: 'Número*',
                error: Boolean(errors.numero),
                hint: {
                  id: 'numero-hint',
                  errorMessage: errors.numero?.message || '',
                },
              }}
              disabled={Boolean(responseData?.endereco?.numero)}
            />
            <TextInput
              id="complemento"
              placeholder="Complemento"
              type="text"
              enterKeyHint="next"
              maxLength={40}
              {...register('complemento')}
              control={{
                label: 'Complemento',
              }}
              disabled={Boolean(responseData?.endereco?.complemento)}
            />
          </ColumnStart>

          <TextInput
            id="bairro"
            placeholder="Bairro*"
            type="text"
            enterKeyHint="next"
            maxLength={40}
            {...register('bairro', {
              required: {
                value: true,
                message: DISTRICT_ERROR_MESSAGES.REQUIRED,
              },
            })}
            control={{
              label: 'Bairro*',
              error: Boolean(errors.bairro),
              hint: {
                id: 'bairro-hint',
                errorMessage: errors.bairro?.message || '',
              },
            }}
            disabled={Boolean(responseData?.endereco?.bairro)}
          />
          <TextInput
            id="cidade"
            placeholder="Cidade*"
            type="text"
            maxLength={40}
            value={cityValue}
            control={{
              label: 'Cidade*',
            }}
            disabled
          />

          <TextInput
            id="estado"
            placeholder="Estado*"
            type="text"
            maxLength={2}
            value={stateValue}
            control={{
              label: 'Estado*',
            }}
            disabled
          />
        </Grid>

        <MarginFavorites>
          <Typography variant="paragraph" size="medium" weight="medium">
            Produtos favoritos*
          </Typography>
        </MarginFavorites>

        <GenderPreferenceRow>
          <Radio
            control={{
              label: 'Feminino',
            }}
            name="genderPreference"
            id="female"
            value={FEMALE}
            checked={genderPreference === FEMALE}
            onChange={onChangeGenderPreference}
          />
          <Radio
            control={{
              label: 'Masculino',
            }}
            name="genderPreference"
            id="male"
            value={MALE}
            checked={genderPreference === MALE}
            onChange={onChangeGenderPreference}
          />
          <Radio
            control={{
              label: 'Ambos',
            }}
            name="genderPreference"
            id="both"
            value={BOTH}
            checked={genderPreference === BOTH}
            onChange={onChangeGenderPreference}
          />
        </GenderPreferenceRow>
        {hasGenderPreferenceError && (
          <PoliciesErrorParagraph>
            <PoliciesErrorIcon />
            {GENDER_PREFERENCE_ERROR_MESSAGES.REQUIRED}
          </PoliciesErrorParagraph>
        )}

        <PoliciesContainer>
          <PoliciesContent>
            <Checkbox
              id="newsletterOptin"
              data-testid="newsletterOptin"
              checked={newsletterOptin}
              onChange={onChangeNewsletter}
            />
            <PoliciesText>
              <PoliciesBoldParagraph>
                Inscreva-se para receber comunicações de Fisia, Distribuidora
                Nike no Brasil
              </PoliciesBoldParagraph>
              <PoliciesParagraph>
                Atualizações sobre produtos, ofertas e vantagens de Membro.
              </PoliciesParagraph>
            </PoliciesText>
          </PoliciesContent>
          <PoliciesContent>
            <Checkbox
              id="termsAndPrivacy"
              data-testid="termsAndPrivacy"
              checked={policiesAccept}
              onChange={onChangePolicies}
            />
            <PoliciesText>
              <PoliciesParagraph>
                Concordo com a{' '}
                <Link
                  href={`${coreRoutes.privacyPolicy}?source=app`}
                  passHref
                  target="_blank"
                  legacyBehavior
                  rel="noopener noreferrer"
                >
                  <PoliciesLink size="tiny" text="Política de Privacidade" />
                </Link>{' '}
                e os{' '}
                <Link
                  href={`${coreRoutes.termsOfService}?source=app`}
                  passHref
                  target="_blank"
                  legacyBehavior
                  rel="noopener noreferrer"
                >
                  <PoliciesLink size="tiny" text="Termos de Uso" />
                </Link>{' '}
                de Fisia, Distribuidora Nike do Brasil*
              </PoliciesParagraph>
              {hasPoliciesError && (
                <PoliciesErrorParagraph>
                  <PoliciesErrorIcon />
                  Para continuar você precisa ler e concordar com os termos
                </PoliciesErrorParagraph>
              )}
            </PoliciesText>
          </PoliciesContent>
        </PoliciesContainer>

        <ButtonMargin
          onClick={() => handleSendClickSubmitEvent()}
          type="submit"
          fullWidth
        >
          Finalizar Cadastro
        </ButtonMargin>
      </form>
      <Button.Outline onClick={onCloseButton} fullWidth>
        Sair
      </Button.Outline>

      {isLoading && <Loader />}
    </Content>
  );
};

export default CompleteForm;
