import { useFlagsStatus, useUnleashClient } from '@unleash/proxy-client-react';
import { parseCookies, setCookie } from 'nookies';
import { useEffect } from 'react';
import useUnleash from '@/common/hooks/useUnleash/useUnleash';
import { getStorageItem } from '@/common/utils/localStorage/localStorage';
import { useWebsiteUIContext } from '@/contexts/WebsiteUIContext';
import { useAuthSession } from '@/hooks/useAuthSession';

/**
 *
 * @param toogleName the toogle id for the requested toogle
 * @returns a tuple of 1 boolean, and the first is isFeatureEnabled
 */
const useEmailFeatureToogle = (toogleName: string): [boolean, boolean] => {
  const { isAuthenticated, session } = useAuthSession();

  const { flagsReady, flagsError } = useFlagsStatus();
  const { isEmailFlagReady, isCookieIdFlagReady } = useWebsiteUIContext();
  const isFeatureEnabled = useUnleash(toogleName);
  const useunleashclient = useUnleashClient();
  const cookieId = parseCookies()['unleashId'];
  const userEmail = session?.user?.email;
  const getContextFromUnleash = useunleashclient.getContext();

  const unleashSession = getStorageItem<string>('unleash:repository:sessionId');

  useEffect(() => {
    unleashSession && setCookie(null, 'unleashSession', unleashSession);
  }, [unleashSession]);

  const isCookieFlagReady = cookieId
    ? (isCookieIdFlagReady.current && flagsReady) || flagsError
    : flagsReady;

  useEffect(() => {
    const updateContextBasedOnParam = async () => {
      if (
        flagsReady &&
        !isCookieIdFlagReady.current &&
        getContextFromUnleash?.properties?.cookieId !== cookieId
      ) {
        isCookieIdFlagReady.current = true;
        await useunleashclient.setContextField('cookieId', cookieId);
      }
    };
    const updateContextIfNeeded = async () => {
      if (
        isAuthenticated &&
        userEmail &&
        !isEmailFlagReady?.current &&
        flagsReady &&
        getContextFromUnleash?.userId !== userEmail
      ) {
        isEmailFlagReady.current = true;
        await useunleashclient.setContextField('userId', userEmail);
      } else if (
        !isAuthenticated &&
        getContextFromUnleash?.userId &&
        isEmailFlagReady?.current
      ) {
        isEmailFlagReady.current = false;
        await useunleashclient.setContextField('userId', '');
      }
    };
    if (cookieId) {
      updateContextBasedOnParam();
    } else {
      updateContextIfNeeded();
    }
  }, [
    isCookieIdFlagReady,
    isAuthenticated,
    userEmail,
    cookieId,
    isEmailFlagReady,
    flagsReady,
    useunleashclient,
    getContextFromUnleash,
  ]);

  return [isFeatureEnabled, isCookieFlagReady];
};

export default useEmailFeatureToogle;
