import Link from 'next/link';
import { StaticImage } from '@/components/Image';
import { useTrackingContext } from '@/contexts/TrackingContext';
import {
  SelectContentObject,
  sendSelectContentEvents,
} from '@/helpers/analytics';
import { AppStoreText, AppStoresImages, AppsWrapper } from './AppStore.styled';

const appStores = {
  googlePlay: {
    text: 'Google Play',
    url: 'https://play.google.com/store/apps/details?id=br.com.gruposbf.nike',
  },
  appleStore: {
    text: 'App Store',
    url: 'https://apps.apple.com/br/app/nike/id6447586622',
  },
};

const AppStores = () => {
  const { handleEvents } = useTrackingContext();

  return (
    <AppsWrapper data-testid="footer-stores">
      <AppStoreText variant="paragraph">Baixe o app Nike</AppStoreText>

      <AppStoresImages>
        <Link
          href={appStores.googlePlay.url}
          rel="noopener noreferrer"
          target="_blank"
          onClick={() =>
            handleEvents<SelectContentObject>(sendSelectContentEvents, {
              component: 'footer',
              microComponent: 'main_footer_content',
              anchorText: appStores.googlePlay.text,
              title: '',
              url: appStores.googlePlay.url,
              contentType: 'app_store_link',
            })
          }
        >
          <StaticImage
            src="/images/appsStore/play.svg"
            alt={appStores.googlePlay.text}
            width={110}
            height={40}
          />
        </Link>
        <Link
          href={appStores.appleStore.url}
          rel="noopener noreferrer"
          target="_blank"
          onClick={() =>
            handleEvents<SelectContentObject>(sendSelectContentEvents, {
              component: 'footer',
              microComponent: 'main_footer_content',
              anchorText: appStores.appleStore.text,
              title: '',
              url: appStores.appleStore.url,
              contentType: 'app_store_link',
            })
          }
        >
          <StaticImage
            src="/images/appsStore/apple.svg"
            alt={appStores.appleStore.text}
            width={110}
            height={40}
          />
        </Link>
      </AppStoresImages>
    </AppsWrapper>
  );
};

export default AppStores;
