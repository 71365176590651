import { ChangeEvent } from 'react';

export const validateCpf = (document: string) => {
  return document
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2');
};
export const validateCnpj = (document: string) => {
  return document
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');
};

export const maskCpf = (event: ChangeEvent<HTMLInputElement>) => {
  let value = event.currentTarget.value;
  value = validateCpf(value);
  event.currentTarget.value = value;
  return event;
};

export const maskCnpj = (event: ChangeEvent<HTMLInputElement>) => {
  let value = event.currentTarget.value;
  value = validateCnpj(value);
  event.currentTarget.value = value;
  return event;
};

export const maskDate = (event: ChangeEvent<HTMLInputElement>) => {
  let value = event.currentTarget.value;
  value = value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2');
  event.currentTarget.value = value;
  return event;
};

export const maskExpirationDate = (event: ChangeEvent<HTMLInputElement>) => {
  let value = event.currentTarget.value;
  value = value.replace(/\D/g, '');

  if (value.length > 4) {
    const month = value.slice(0, 2);
    const year = value.slice(-2);
    value = month + year;
  }

  value = value.replace(/^(\d{2})(\d{2})/, '$1/$2');

  event.currentTarget.value = value;
  return event;
};

export const maskPostCode = (event: ChangeEvent<HTMLInputElement>) => {
  let value = event.currentTarget.value;
  value = value.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2');
  event.currentTarget.value = value;
  return event;
};

export const cleanPhoneNumber = (phone: string) => {
  return phone
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4,5})(\d{4})/, '$1-$2');
};

export const maskTelephone = (event: ChangeEvent<HTMLInputElement>) => {
  event.currentTarget.value = cleanPhoneNumber(event.currentTarget.value);
  return event;
};

export const maskCard = (event: ChangeEvent<HTMLInputElement>) => {
  let value = event.currentTarget.value;
  value = value.replace(/\D/g, '').replace(/(\d{4})/g, '$& ');
  event.currentTarget.value = value.trimEnd();
  return event;
};
