import { ReactNode, createContext, useContext, useState } from 'react';

type GridContextProps = {
  visiblePage: number;
  setVisiblePage: (page: number) => void;
  isPageScrolling: boolean;
  setIsPageScrolling: (scrolling: boolean) => void;
};

export type GridContextProviderProps = {
  children: ReactNode;
};

export const GridContext = createContext({} as GridContextProps);

export function GridContextProvider({ children }: GridContextProviderProps) {
  const [visiblePage, setVisiblePage] = useState(1);
  const [isPageScrolling, setIsPageScrolling] = useState(false);

  return (
    <GridContext.Provider
      value={{
        visiblePage,
        setVisiblePage,
        isPageScrolling,
        setIsPageScrolling,
      }}
    >
      {children}
    </GridContext.Provider>
  );
}

export const useGridContext = () => {
  return useContext(GridContext);
};
