import { ArrowLeft, Pin } from '@sbf/fairplay-icons';
import { Link as Anchor, Divider } from '@sbf/fairplay-nike';
import Link from 'next/link';
import { useState } from 'react';
import { getCoreRoutes } from '@/common/config/routes';
import { useNewCallbackLogin } from '@/common/hooks/useNewCallbackLogin/useNewCallbackLogin';
import useUnleash from '@/common/hooks/useUnleash/useUnleash';
import { HeaderLayoutProps } from '@/common/patterns/Header/Header.types';
import MenuPopover from '@/common/patterns/Header/components/MenuPopover/MenuPopover';
import { MenuContextProvider } from '@/common/patterns/Menu/menuContext';
import { StaticImage } from '@/components/Image';
import { useTrackingContext } from '@/contexts/TrackingContext';
import {
  SelectContentObject,
  sendSelectContentEvents,
} from '@/helpers/analytics';
import { AuthEventType, handleSignIn } from '@/helpers/auth';
import { setStorageItem } from '@/helpers/localStorage';
import { useAuthSession } from '@/hooks/useAuthSession';
import { checkGuestCheckoutFlag } from '@/modules/guestCheckout/utils/guestCheckoutEnabled';
import {
  BrandBox,
  LinksBox,
  SubHeader as StyledSubHeader,
  SubHeaderIcon,
} from './SubHeader.styled';
import { sendClickButtonAttendanceEvent } from './gaEvents';

const coreRoutes = getCoreRoutes();

const SubHeader = ({ layout, labels }: HeaderLayoutProps) => {
  const isNewCallbackLogin = useNewCallbackLogin();
  const { session } = useAuthSession();
  const { handleEvents } = useTrackingContext();
  const [visible, setVisible] = useState<boolean>(false);
  const isSnkrsLayout = layout === 'snkrs';
  const isAssistedSaleLayout = layout === 'assistedSale';
  const isDefaultLayout = layout === 'default' || isAssistedSaleLayout;
  const isOrderDetailsGuestCheckoutActive = useUnleash(
    'nike-front.order-details-guest-checkout',
  );

  const isGuestCheckoutReady = checkGuestCheckoutFlag();

  const authEvent: AuthEventType = {
    action: 'Login Header Home Page',
    label: 'Clicou no botão Entrar no header para fazer o login',
  };

  return (
    <StyledSubHeader>
      {isSnkrsLayout && (
        <SubHeaderIcon>
          <ArrowLeft />
          <Link href={coreRoutes.home} passHref legacyBehavior>
            <Anchor
              type="brand"
              size="tiny"
              text="Voltar para nike.com.br"
              onClick={() => {
                handleEvents<SelectContentObject>(sendSelectContentEvents, {
                  component: 'header',
                  microComponent: 'top_bar',
                  anchorText: 'Voltar para nike.com.br',
                  title: '',
                  url: coreRoutes.home,
                  contentType: 'navigation_link',
                });
              }}
            />
          </Link>
        </SubHeaderIcon>
      )}
      {isDefaultLayout && (
        <BrandBox>
          <Link
            href="/nav/marca/jordan"
            passHref
            legacyBehavior
            prefetch={false} // FOR TESTING PURPOSES, THE STATIC PAGES WERE INCREASING THE JSON SIZE
          >
            <Anchor
              title="Ir para página do Jordan"
              icon={
                <StaticImage
                  src="/images/brands/jordan.svg"
                  alt="Jordan"
                  width="19rem"
                  height="18rem"
                  disablePlaceholder
                  priority
                />
              }
              onClick={() => {
                handleEvents<SelectContentObject>(sendSelectContentEvents, {
                  component: 'header',
                  microComponent: 'top_bar',
                  anchorText: 'Ir para página do Jordan',
                  title: '',
                  url: '/nav/marca/jordan',
                  contentType: 'navigation_link',
                });
              }}
            />
          </Link>

          <Link href={coreRoutes.snkrs} passHref legacyBehavior>
            <Anchor
              title="Ir para página do Sneakers"
              icon={
                <StaticImage
                  src="/images/brands/snkrs.svg"
                  alt="Ir para página do Sneakers"
                  width="46rem"
                  height="15rem"
                  disablePlaceholder
                  priority
                />
              }
              onClick={() => {
                handleEvents<SelectContentObject>(sendSelectContentEvents, {
                  component: 'header',
                  microComponent: 'top_bar',
                  anchorText: 'SNKRS',
                  title: 'Ir para página do Sneakers',
                  url: coreRoutes.snkrs,
                  contentType: 'navigation_link',
                });
              }}
            />
          </Link>
        </BrandBox>
      )}

      <LinksBox onMouseLeave={() => setVisible(false)}>
        <>
          <Link
            href={coreRoutes.journal}
            passHref
            legacyBehavior
            prefetch={false} // FOR TESTING PURPOSES, THE STATIC PAGES WERE INCREASING THE JSON SIZE
          >
            <Anchor
              type="brand"
              as="button"
              size="tiny"
              text="Nike Journal"
              onClick={() => {
                handleEvents<SelectContentObject>(sendSelectContentEvents, {
                  component: 'header',
                  microComponent: 'top_bar',
                  anchorText: 'nike journal',
                  title: '',
                  url: coreRoutes.journal,
                  contentType: 'navigation_link',
                });
              }}
            />
          </Link>
          <Divider
            desktopMargin={{ m: [0, 300] }}
            height="12rem"
            orientation="vertical"
          />
        </>
        {isOrderDetailsGuestCheckoutActive && isGuestCheckoutReady && (
          <>
            <Link href={coreRoutes.trackOrder} passHref legacyBehavior>
              <Anchor
                type="brand"
                as="button"
                size="tiny"
                text="Acompanhe seu pedido"
                onClick={() => {
                  sendClickButtonAttendanceEvent();
                  handleEvents<SelectContentObject>(sendSelectContentEvents, {
                    component: 'header',
                    microComponent: 'top_bar',
                    anchorText: 'acompanhe seu pedido',
                    title: '',
                    url: coreRoutes.trackOrder,
                    contentType: 'navigation_link',
                  });
                }}
              />
            </Link>
            <Divider
              desktopMargin={{ m: [0, 300] }}
              height="12rem"
              orientation="vertical"
            />
          </>
        )}

        <Link href={coreRoutes.attendance} passHref legacyBehavior>
          <Anchor
            type="brand"
            as="button"
            size="tiny"
            text="Ajuda"
            onClick={() => {
              sendClickButtonAttendanceEvent();
              handleEvents<SelectContentObject>(sendSelectContentEvents, {
                component: 'header',
                microComponent: 'top_bar',
                anchorText: 'ajuda',
                title: '',
                url: coreRoutes.attendance,
                contentType: 'navigation_link',
              });
            }}
          />
        </Link>

        {isDefaultLayout && (
          <>
            <Divider
              desktopMargin={{ m: [0, 300] }}
              height="12rem"
              orientation="vertical"
            />
            <Link href={coreRoutes.joinUs} passHref legacyBehavior>
              <Anchor
                type="brand"
                size="tiny"
                text="Junte-se a nós"
                onClick={() => {
                  handleEvents<SelectContentObject>(sendSelectContentEvents, {
                    component: 'header',
                    microComponent: 'top_bar',
                    anchorText: 'Junte-se a nós',
                    title: '',
                    url: coreRoutes.joinUs,
                    contentType: 'navigation_link',
                  });
                }}
              />
            </Link>
          </>
        )}
        <Divider
          desktopMargin={{ m: [0, 300] }}
          height="12rem"
          orientation="vertical"
        />

        {session ? (
          <MenuContextProvider labels={labels}>
            <MenuPopover
              visible={visible}
              username={session?.user?.name || ' '}
              setVisible={setVisible}
            />
          </MenuContextProvider>
        ) : (
          <Anchor
            type="brand"
            size="tiny"
            as="button"
            text={labels?.signInButtonLabel || 'Entrar'}
            onClick={() => {
              handleSignIn(authEvent, isNewCallbackLogin);
              setStorageItem('clickedOnLogin', 'clickLoginMenu');
              handleEvents<SelectContentObject>(sendSelectContentEvents, {
                component: 'header',
                microComponent: 'top_bar',
                anchorText: 'entrar',
                title: '',
                url: coreRoutes.login,
                contentType: 'navigation_link',
              });
            }}
          />
        )}
        {isSnkrsLayout && (
          <Divider
            desktopMargin={{ m: [0, 300] }}
            height="12rem"
            orientation="vertical"
          />
        )}
        {isSnkrsLayout && (
          <SubHeaderIcon>
            <Pin />
            <Link
              href={coreRoutes.geoSelectionLanguage}
              passHref
              legacyBehavior
            >
              <Anchor
                type="brand"
                size="tiny"
                text="Brasil"
                onClick={() => {
                  handleEvents<SelectContentObject>(sendSelectContentEvents, {
                    component: 'header',
                    microComponent: 'top_bar',
                    anchorText: 'brasil',
                    title: '',
                    url: coreRoutes.geoSelectionLanguage,
                    contentType: 'navigation_link',
                  });
                }}
              />
            </Link>
          </SubHeaderIcon>
        )}
      </LinksBox>
    </StyledSubHeader>
  );
};

export default SubHeader;
