import { ArrowRight } from '@sbf/fairplay-icons';
import { Button } from '@sbf/fairplay-nike';
import { useQueryClient } from 'react-query';
import { useNewCallbackLogin } from '@/common/hooks/useNewCallbackLogin/useNewCallbackLogin';
import MenuItems from '@/common/patterns/Header/components/MenuItems/MenuItems';
import { useTrackingContext } from '@/contexts/TrackingContext';
import { AuthEventType, handleSignIn, handleSignOut } from '@/helpers/auth';
import { useAuthSession } from '@/hooks/useAuthSession';
import {
  CategoryButton,
  CategoryContentContainerAccount,
  CategoryMenuAccount,
  CategoryPanel,
  CategoryTitle,
  NikeProfileAccount,
  UserLogged,
} from './Menu.styled';
import { useMenu } from './menuContext';

const MenuAccountMobile = () => {
  const isNewCallbackLogin = useNewCallbackLogin();
  const { categoryStack, pushCategoryStack, popCategoryStack, labels } =
    useMenu();
  const { session, isAuthenticated } = useAuthSession();
  const queryClient = useQueryClient();
  const { setUserData } = useTrackingContext();

  const isSubCategoryOpened = (stackPosition: number, stackTitle: string) =>
    categoryStack.length > stackPosition &&
    categoryStack[stackPosition] === stackTitle;

  const handleSignOutAndSendEvent = () => {
    handleSignOut();
    popCategoryStack();
    setUserData();
    queryClient.resetQueries(['cart', isAuthenticated], { exact: true });
  };

  const authEvent: AuthEventType = {
    action: 'Login Header Home Page',
    label: 'Clicou no botão Entrar no header para fazer o login',
  };

  return (
    <CategoryMenuAccount role="menuitem">
      {session ? (
        <CategoryButton
          data-testid={'user-menu-btn-mobile'}
          onClick={() => pushCategoryStack('account')}
        >
          <UserLogged>
            <NikeProfileAccount size="18px" />
            {labels?.helloLabel} {session?.user?.name}
          </UserLogged>
          <ArrowRight size="medium" />
        </CategoryButton>
      ) : (
        <Button.Fill
          data-testid={'signin-button-mobile'}
          onClick={() => handleSignIn(authEvent, isNewCallbackLogin)}
        >
          {labels?.signInButtonLabel}
        </Button.Fill>
      )}
      <CategoryPanel
        data-testid={`category-panel-account`}
        aria-expanded={isSubCategoryOpened(0, 'account')}
      >
        <CategoryTitle>{session?.user?.name}</CategoryTitle>
        <CategoryContentContainerAccount>
          <MenuItems handleSignOut={handleSignOutAndSendEvent} />
        </CategoryContentContainerAccount>
      </CategoryPanel>
    </CategoryMenuAccount>
  );
};

export default MenuAccountMobile;
