import { Link as Anchor } from '@sbf/fairplay-nike';
import debounce from 'lodash/debounce';
import Link from 'next/link';
import { memo, useEffect, useRef, useState } from 'react';
import isEmpty from '@/common/utils/funcs/isEmpty/isEmpty';
import { useTrackingContext } from '@/contexts/TrackingContext';
import {
  SelectContentObject,
  sendSelectContentEvents,
} from '@/helpers/analytics';
import {
  Categories,
  CategoryMenu,
  CategoryPanel,
  Item,
  ListItems,
  StyledSectionLink,
  SubCategoryContent,
  SubCategoryContentWrapper,
  SubCategoryListItem,
} from './Menu.styled';
import { useMenu } from './menuContext';
import { MenuItem } from './types';
import { isItemActive } from './utils/isItemActive/isItemActive';

type MenuProps = {
  content: MenuItem[];
};

const MENU_DELAY = 150;

const MenuDesktop = ({ content }: MenuProps) => {
  const { categoryStack, setHoverCategory } = useMenu();
  const { handleEvents, cleanSearchIdList } = useTrackingContext();
  const [isCategoryVisible, setCategoryVisible] = useState<boolean>(false);

  const debounceHoverCategory = useRef(
    debounce(async (categoryItem) => {
      setHoverCategory(true, categoryItem.title);
    }, MENU_DELAY),
  ).current;

  const handleMouseLeave = () => {
    setHoverCategory(false, '');
    debounceHoverCategory.cancel();
  };

  useEffect(() => {
    return () => {
      debounceHoverCategory.cancel();
    };
  }, [debounceHoverCategory]);

  useEffect(() => {
    if (isEmpty(categoryStack)) {
      setCategoryVisible(false);
    } else {
      setCategoryVisible(true);
    }
  }, [categoryStack]);

  return (
    <>
      <Categories role="menubar">
        {content.filter(isItemActive).map((category) => {
          const hasSubMenu = category.subMenu?.some(isItemActive);
          return (
            <CategoryMenu
              key={category.title}
              role="menuitem"
              onMouseEnter={() => debounceHoverCategory(category)}
              onFocus={() => debounceHoverCategory(category)}
              onMouseLeave={() => handleMouseLeave()}
              onBlur={() => handleMouseLeave()}
            >
              <StyledSectionLink
                padded={true}
                href={`${category.link}`}
                text={category.title}
                onClick={() => {
                  cleanSearchIdList();
                  handleEvents<SelectContentObject>(sendSelectContentEvents, {
                    component: 'header',
                    microComponent: 'main_header_content',
                    anchorText: category.title,
                    url: category.link,
                    contentType: 'navigation_link',
                    menuOrder: category.order,
                    hierarchy: `${category.title}`,
                  });
                }}
              />

              {hasSubMenu && isCategoryVisible && (
                <CategoryPanel
                  data-testid={`category-panel-${category.title}`}
                  aria-expanded={categoryStack[0] === category.title}
                  key={category.title}
                >
                  <SubCategoryContentWrapper>
                    <SubCategoryContent
                      role="menu"
                      data-testid={`subcategory-content-${category.title}`}
                    >
                      {category.subMenu
                        ?.filter(isItemActive)
                        .map((subMenuItem) => (
                          <SubCategoryListItem
                            data-testid={`subcategory-${category.title}-${subMenuItem.title}`}
                            role="menuitem"
                            key={subMenuItem.title}
                          >
                            <StyledSectionLink
                              padded={false}
                              href={`${subMenuItem.link}`}
                              text={subMenuItem.title}
                              onClick={() => {
                                cleanSearchIdList();
                                handleEvents<SelectContentObject>(
                                  sendSelectContentEvents,
                                  {
                                    component: 'header',
                                    microComponent: 'main_header_content',
                                    anchorText: subMenuItem.title,
                                    url: subMenuItem.link,
                                    contentType: 'navigation_link',
                                    menuOrder: subMenuItem.order,
                                    hierarchy: `${category.title}>${subMenuItem.title}`,
                                  },
                                );
                              }}
                            />
                            <ListItems
                              data-testid={`list-${category.title}-${subMenuItem.title}`}
                              role="menu"
                            >
                              {subMenuItem.subMenu
                                ?.filter(isItemActive)
                                .map((item) => (
                                  <Item
                                    data-testid={`item-${category.title}-${subMenuItem.title}-${item.title}`}
                                    role="menuitem"
                                    key={item.title}
                                  >
                                    {/* TODO: There is a warning on mobile related to the accessibility https://github.com/grupo-sbf/nike-front/issues/79 */}
                                    <Link
                                      href={`${item.link}`}
                                      passHref
                                      legacyBehavior
                                    >
                                      <Anchor
                                        onClick={() => {
                                          handleMouseLeave();
                                          cleanSearchIdList();
                                          handleEvents<SelectContentObject>(
                                            sendSelectContentEvents,
                                            {
                                              component: 'header',
                                              microComponent:
                                                'main_header_content',
                                              anchorText: item.title,
                                              title: item.title + '_submenu',
                                              url: item.link,
                                              contentType: 'navigation_link',
                                              menuOrder: item.order,
                                              hierarchy: `${category.title}>${subMenuItem.title}>${item.title}`,
                                            },
                                          );
                                        }}
                                        text={item.title}
                                        size="small"
                                        type="brand"
                                      />
                                    </Link>
                                  </Item>
                                ))}
                            </ListItems>
                          </SubCategoryListItem>
                        ))}
                    </SubCategoryContent>
                  </SubCategoryContentWrapper>
                </CategoryPanel>
              )}
            </CategoryMenu>
          );
        })}
      </Categories>
    </>
  );
};

export default memo(MenuDesktop);
