import axios from 'axios';
import { getCoreConfig } from '@/common/config/config';
import { isProdReleaseEnv } from '@/common/utils/environment/environment';

const {
  publicRuntimeConfig: { registration },
} = getCoreConfig();

export const clientRegistrationDocumentChecker = axios.create({
  baseURL: `${registration.documentChecker}`,
});

export const clientRegistrationKeycloak = axios.create({
  baseURL: `${registration.keycloak}`,
});

export const serverSideRegistrationKeycloak = axios.create();

if (isProdReleaseEnv) {
  clientRegistrationDocumentChecker.defaults.withCredentials = true;
  clientRegistrationKeycloak.defaults.withCredentials = true;
  serverSideRegistrationKeycloak.defaults.withCredentials = true;
}

clientRegistrationKeycloak.interceptors.request.use(
  function (config) {
    const headers = {
      ...config.headers,
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    config.headers = headers;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);
