import { getCoreRoutes } from '@/common/config/routes';

const coreRoutes = getCoreRoutes();

export const FOOTER_LINKS = {
  about: [
    {
      text: 'Propósito',
      href: coreRoutes.purpose,
    },
    {
      text: 'Sustentabilidade',
      href: coreRoutes.sustainability,
    },
    {
      text: 'Sobre a Nike, Inc.',
      href: coreRoutes.aboutNikeInc,
    },
    {
      text: 'Sobre o Grupo SBF',
      href: coreRoutes.aboutSBFGroup,
    },
  ],
  help: [
    {
      text: 'Dúvidas Gerais',
      href: coreRoutes.generalQuestions,
    },
    {
      text: 'Encontre seu Tamanho',
      href: coreRoutes.findYourSize,
    },
    {
      text: 'Entregas',
      href: coreRoutes.attendanceDeliveries,
    },
    {
      text: 'Pedidos',
      href: coreRoutes.attendanceRequests,
    },
    {
      text: 'Devoluções',
      href: coreRoutes.exchangesAndReturns,
    },
    {
      text: 'Pagamentos',
      href: coreRoutes.attendancePayments,
    },
    {
      text: 'Produtos',
      href: coreRoutes.attendanceProducts,
    },
    {
      text: 'Corporativo',
      href: coreRoutes.attendanceCorporate,
    },
    {
      text: 'Fale Conosco',
      href: coreRoutes.attendance,
      target: '_blank',
      rel: 'noreferrer',
    },
  ],
};
