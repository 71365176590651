import { PopoverRoot, PopoverTrigger } from '@sbf/fairplay-nike';
import { Dispatch, SetStateAction } from 'react';
import { useQueryClient } from 'react-query';
import { LabelsType } from '@/common/patterns/Header/Header.types';
import MenuItems from '@/common/patterns/Header/components/MenuItems/MenuItems';
import {
  MenuUserLogged,
  PopoverContentStyled,
} from '@/common/patterns/Header/components/SubHeader/SubHeader.styled';
import UserLogged from '@/common/patterns/Header/components/UserLogged/UserLogged';
import { useTrackingContext } from '@/contexts/TrackingContext';
import { AuthEventType, handleSignOut } from '@/helpers/auth';
import { useAuthSession } from '@/hooks/useAuthSession';

interface PropsMenuPopover {
  visible: boolean;
  username: string;
  setVisible: Dispatch<SetStateAction<boolean>>;
  labels?: LabelsType;
}

const MenuPopover = (props: PropsMenuPopover) => {
  const { visible, username, setVisible } = props;
  const queryClient = useQueryClient();
  const { isAuthenticated } = useAuthSession();
  const { setUserData } = useTrackingContext();

  const handleSignOutAndSendEvent = () => {
    const authEvent: AuthEventType = {
      action: 'Click - Sair Menu Area Logada',
      label: 'Clicou no botão Sair dentro do Menu para fazer logout',
    };
    handleSignOut(authEvent);
    setUserData();
    queryClient.resetQueries(['cart', isAuthenticated], { exact: true });
  };

  return (
    <PopoverRoot open={visible} onOpenChange={setVisible}>
      <PopoverTrigger asChild style={{ display: 'flex' }}>
        <MenuUserLogged onMouseEnter={() => setVisible(true)}>
          <UserLogged isVisible={visible} username={username} />
        </MenuUserLogged>
      </PopoverTrigger>
      <PopoverContentStyled width="180rem" align="end" side="bottom">
        <MenuItems handleSignOut={handleSignOutAndSendEvent} />
      </PopoverContentStyled>
    </PopoverRoot>
  );
};

export default MenuPopover;
