import { captureException, captureMessage, setUser } from '@sentry/nextjs';
import { Scope, SeverityLevel } from '@sentry/types';
import { type ClientError } from '@/common/services/api/types';
import { UnknownObject } from '@/common/types/objects';
import { GetRegistrationResponse } from '@/modules/myAccount/components/Registration/Registration.types';
export type ErrorDataTypes = {
  flow: string;
  checkpoint: string;
  namespace: string;
  code?: string | null;
  level?: SeverityLevel;
  extraDetails?: UnknownObject;
};

export type ExceptionErrorTypes = ErrorDataTypes & {
  error: ClientError;
};

export type MessageErrorTypes = ErrorDataTypes & {
  message: string;
};

export type ErrorsDetailsTypes = ErrorDataTypes & {
  env: string;
  errorMessage: string;
};

export type ErrorsInfosTypes = {
  errorDetails: ErrorsDetailsTypes;
  scope: Scope;
};

export const setUserOnSentryContext = (user: GetRegistrationResponse) => {
  setUser({ email: user.email, id: user.id });
};

export const clearUserOnSentryContext = () => {
  setUser(null);
};

const setErrorsInfo = ({ errorDetails, scope }: ErrorsInfosTypes) => {
  const {
    flow,
    checkpoint,
    namespace,
    code,
    env,
    errorMessage,
    level = 'error',
    extraDetails,
  } = errorDetails;

  scope.setTag('flow', flow);
  scope.setTag('checkpoint', checkpoint);
  scope.setTag('code', code);
  scope.setTag('namespace', namespace);
  scope.setLevel(level);
  scope.setFingerprint([flow, checkpoint, namespace, env, errorMessage]);
  if (extraDetails) {
    Object.keys(extraDetails).forEach((key) => {
      scope.setExtra(key, extraDetails[key]);
    });
  }
  return scope;
};

export const sentryExceptionError = ({
  error,
  flow,
  checkpoint,
  namespace,
  code,
  level = 'error',
  extraDetails,
}: ExceptionErrorTypes) => {
  const errorMessage = error?.message as string;
  const errorDetails = {
    flow,
    checkpoint,
    namespace,
    code,
    env: process.env.NODE_ENV,
    errorMessage,
    level,
    extraDetails,
  };

  return captureException(error, (scope) => {
    return setErrorsInfo({
      errorDetails,
      scope,
    });
  });
};

export const sentryMessageError = ({
  message,
  flow,
  checkpoint,
  namespace,
  level = 'error',
  extraDetails,
}: MessageErrorTypes) => {
  const errorDetails = {
    flow,
    checkpoint,
    namespace,
    env: process.env.NODE_ENV,
    errorMessage: message,
    level,
    extraDetails,
  };

  return captureMessage(message, (scope) => {
    return setErrorsInfo({
      errorDetails,
      scope,
    });
  });
};
