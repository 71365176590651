import { NikeBag as Bag } from '@sbf/fairplay-icons';
import { useCartItems } from '@/modules/guestCheckout/hooks/useCartItems';
import { ButtonWrapper, CounterItems } from './CartIconButton.styled';

export type CartIconButtonProps = {
  onClick: () => void;
};

const GuestCartIconButton = ({ onClick }: CartIconButtonProps) => {
  const { items } = useCartItems();

  const quantityItem = items
    .flatMap((res) => res.quantity)
    .reduce((acc, current) => {
      return acc + current;
    }, 0);

  const quantityLabel =
    items?.length > 1
      ? `Sacola com ${quantityItem} itens`
      : `Sacola com 1 item`;
  const quantityCounterLabel = !quantityItem
    ? ''
    : quantityItem > 9
    ? '+9'
    : quantityItem;
  return (
    <ButtonWrapper onClick={onClick}>
      <CounterItems>{quantityCounterLabel}</CounterItems>
      <Bag titleAccess={!items.length ? 'Sacola vazia' : quantityLabel} />
    </ButtonWrapper>
  );
};

export default GuestCartIconButton;
