import { getCoreConfig } from '@/common/config/config';

const {
  publicRuntimeConfig: { cryptography },
} = getCoreConfig();

const browserAlgorithm = 'AES-CBC'; // @TODO: get from env
const keyAsString = cryptography.cryptoKey as string;
const ivAsString = cryptography.cryptoIv as string;

const encrypt = async (text: string) => {
  // there is no window.crypto.subtle in unsecure environments
  if (!window.crypto.subtle) {
    return 'failed_to_encrypt';
  }

  const key = hexStringToUint8Array(keyAsString);
  const iv = hexStringToUint8Array(ivAsString);
  const encodedText = new TextEncoder().encode(text);

  const importedKey = await window.crypto.subtle.importKey(
    'raw',
    key,
    { name: browserAlgorithm },
    false,
    ['encrypt', 'decrypt'],
  );

  const encryptedBuffer = await window.crypto.subtle.encrypt(
    { name: browserAlgorithm, iv: iv },
    importedKey,
    encodedText,
  );

  const encryptedArray = new Uint8Array(encryptedBuffer);
  const encryptedHex = Array.from(encryptedArray)
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');

  return encryptedHex;
};

function hexStringToUint8Array(hexString: string): Uint8Array {
  const length = hexString.length / 2;
  const uint8Array = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    uint8Array[i] = parseInt(hexString.substr(i * 2, 2), 16);
  }
  return uint8Array;
}

export { encrypt };
