import { Loading } from '@sbf/fairplay-icons';
import { hexToRGBA, media } from '@sbf/fairplay-utils';
import styled, { css } from 'styled-components';
import {
  BoxLoaderProps,
  DirectionType,
  LoaderProps,
} from '@/components/Loader/Loader.types';

const boxLoaderModifiers = {
  column: () => css`
    flex-direction: column;
    top: 0;
  `,

  row: () => css`
    flex-direction: row;
    justify-content: center;
  `,
};

export const AbsoluteLoaderContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background: ${hexToRGBA(theme.colors.neutral[100], theme.opacity[700])};
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: ${theme.zIndex[300]};
  `}
`;

export const PageLoaderContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${theme.zIndex[300]};
    background: ${hexToRGBA(theme.colors.neutral[100], theme.opacity[700])};
  `}
`;

export const ComponentLoaderContainer = styled.div<LoaderProps>`
  ${({ isFullHeight }) => css`
    height: ${isFullHeight ? '100vh' : 'auto'};
    min-height: auto;
    align-items: center;
    display: flex;
    justify-content: center;
  `}
`;

export const BoxLoader = styled.div<BoxLoaderProps>`
  ${({ theme, direction = 'column' }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.neutral[700]};
    position: relative;

    ${direction &&
    boxLoaderModifiers[direction] &&
    boxLoaderModifiers[direction]()}
  `}
`;

export const LoadingStyled = styled(Loading)`
  will-change: transform;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  animation: rotation 1.5s infinite linear;
`;

export const TypographyWrapper = styled.span<DirectionType>`
  ${({ theme, direction = 'column' }) => css`
    color: ${theme.colors.neutral[700]};

    ${direction === 'column' &&
    `
    padding-top: ${theme.spaces[200]};

    ${media.greaterThan('desktop')} {
      padding-top: ${theme.spaces[400]};
    }
  `}
  `}
`;
