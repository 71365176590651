import { parseCookies } from 'nookies';
import useUnleash from '@/common/hooks/useUnleash/useUnleash';

const NEW_CALLBACK_LOGIN_COOKIE = 'testnewcallbacklogin';

export const useNewCallbackLogin = () => {
  const useNewCallbackLoginToggleEnabled = useUnleash(
    'nike-front.new-callback-login',
  );
  const useNewCallbackLoginCookieEnabled =
    parseCookies()[NEW_CALLBACK_LOGIN_COOKIE];

  return (
    useNewCallbackLoginToggleEnabled ||
    Boolean(useNewCallbackLoginCookieEnabled)
  );
};
