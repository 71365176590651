import { Button } from '@sbf/fairplay-nike';
import styled, { css } from 'styled-components';

export const SliderWrapper = styled.section`
  overflow: hidden;
  background-color: #f3f3f3; // not in the design system;

  * {
    box-sizing: border-box;
  }
`;

export const Slider = styled.div`
  min-height: 55px;
  max-width: 900px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
`;

export const MainSection = styled.div<{ $translate: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  gap: 1rem;
  transform: translateX(${({ $translate }) => $translate ?? 0}%);
  transition: transform 0.5s ease-in-out;
`;

export const Arrow = styled(Button.Icon)<{ $left: boolean; $right: boolean }>`
  ${({ theme, $left, $right }) => css`
    position: absolute;
    z-index: ${theme.zIndex[100]};
    width: ${theme.spaces[500]};
    height: 44px;
    border-radius: 0;
    padding: 0;
    background-color: #f3f3f3; // not in the design system;
    left: ${$left ? 0 : 'auto'};
    right: ${$right ? 0 : 'auto'};
    top: 50%;
    transform: translateY(-50%);

    &:hover {
      background-color: #f3f3f3; // not in the design system;
    }

    svg {
      font-size: ${theme.fontSizes.xlarge};
    }
  `}
`;

export const Slide = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 30px auto;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    gap: ${theme.spaces[200]};
    width: 100%;
    padding: 0 ${theme.spaces[600]};
  `};
`;

export const SlideTextContent = styled.div`
  text-align: center;
`;

export const HighlightText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.neutral[700]};
    font-size: ${theme.fontSizes.medium};
    font-weight: bold;
    letter-spacing: -1px;
  `}
`;

export const Text = styled.span`
  ${({ theme }) => css`
    margin: 0 ${theme.spaces[200]};
    color: ${theme.colors.neutral[700]};
    font-size: ${theme.fontSizes.small};
    font-weight: ${theme.fontWeights.regular};
  `}
`;

export const StyledLink = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.neutral[700]};
    font-size: ${theme.fontSizes.small};
  `}
`;
