import { getDateOnCDPFormat } from '@/common/utils/funcs/getDateOnCDPFormat/getDateOnCDPFormat';
import isEmpty from '@/common/utils/funcs/isEmpty/isEmpty';
import {
  trackingEvent,
  trackingMessageError,
} from '@/common/utils/tracking/tracking';
import { currencyToNumber } from '@/helpers/formatter';
import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from '@/helpers/localStorage';
import {
  Cart,
  Product as CartProduct,
} from '@/modules/checkout/services/cart/cart.types';
import { PAYMENT_TABS_VALUES } from '@/modules/checkout/types/payments';
import { hasCouponLinkCookie } from '@/modules/couponLink/hooks/useAsyncCouponLink';
import { getProductTag } from '@/modules/product/utils/tagProduct/tagProduct';
import { getCleanStyleCode } from '@/modules/product/utils/variant/variant';
import {
  AllProductTrackingEvent,
  Categories,
  CheckoutEvents,
  KEY_CART_DATA,
  KEY_CATEGORY,
  KEY_CURRENT_EVENT,
  KEY_MPARTICLE,
  KEY_PRODUCT,
  KEY_SNEAKER_FLAG,
  ProductEvent,
  SNEAKER_FLAG,
  SneakerFlag,
} from './trackingEvents.types';

export const sendAddOrRemoveEvent = () => {
  //TODO: Move this rule of AnalyticsFromStorage to TrackingContext
  const { event, product, cart, categories, mParticle } =
    getAnalyticsInfoFromStorage();
  const hasCouponLink = hasCouponLinkCookie();

  if (!event || !product || isEmpty(product)) {
    return;
  }

  const cartItem = cart.itens?.find((item) => item.sku === product.selectedSku);

  const value = cart?.total || '0';
  const promocode = cart?.promocode;
  const discount = cart?.desconto || '0';
  const name = product?.name || product?.modelo;
  const price = getPriceInfo(product, cartItem);
  const from_price = product.priceInfos
    ? product.priceInfos.price || product.priceInfos.promotionalPrice
    : currencyToNumber(
        product?.valorUnitarioDe || product?.valorUnitarioPor || '0',
      ) + currencyToNumber(cartItem?.precoPersonalizacao || '0');
  const quantity =
    event === CheckoutEvents.AddToCart ||
    (event === CheckoutEvents.RemoveFromCart && !product.removeAll)
      ? 1
      : product.quantidade;
  const id = product?.selectedProduct || product.sku?.substring(0, 8);
  const sku = product?.selectedSku || product.sku;
  const size = cartItem?.tamanho;
  const category = categories?.find(({ sku }) => sku === id)?.category;
  const itemType = id ? isSneaker(id) : undefined;
  const description = product?.description;
  const reviewRating = product?.reviewsSummary?.rating || 0;
  const reviewCount = product?.reviewsSummary?.count || 0;
  const styleCode = getStyleCode(product);
  const brand = product.brand;
  const product_url = product?.url;
  const availableSizes = product.sizes?.map((size) => size.description) || [];
  const extendedContent = product?.extendedContent?.length || 0;

  const urlParams = new URLSearchParams(mParticle?.href);
  const utmMedium = urlParams.get('utm_medium');
  const utmCampaign = urlParams.get('utm_campaign');
  const utmSource = urlParams.get('utm_source');

  trackingEvent({ ecommerce: null });
  trackingEvent({
    event: event,
    timestamp_vuc: getDateOnCDPFormat(),
    origin: mParticle?.currentOrigin,
    source: mParticle?.href,
    utm_medium: utmMedium ? utmMedium : undefined,
    utm_campaign: utmCampaign ? utmCampaign : undefined,
    utm: utmSource ? utmSource : undefined,
    ecommerce: {
      currency: 'BRL',
      value: currencyToNumber(value),
      discount_value: currencyToNumber(discount),
      coupon: promocode || undefined,
      has_coupon_link: Boolean(hasCouponLink),
      items: [
        {
          available_sizes: availableSizes,
          discount_percentage: undefined,
          discount: product.priceInfos?.discount || 0,
          index: 0,
          from_price,
          item_brand: brand,
          item_category: category,
          item_description: `produto ${description ? 'com ' : 'sem '}descrição`,
          item_id: styleCode,
          item_name: name,
          item_sku: sku,
          item_size: size,
          item_scooby_id: id,
          item_type: itemType,
          item_variant: cartItem?.cor,
          price,
          product_url,
          quantity,
          item_tag: getProductTag(product),
          rating_count: reviewCount,
          rating_value: reviewRating,
          item_extended_content: `produto ${
            extendedContent > 0 ? 'com' : 'sem'
          } conteúdo estendido`,
        },
      ],
    },
  });

  removeStorageItem(KEY_CURRENT_EVENT);
};

const getPriceInfo = (
  product: AllProductTrackingEvent,
  cartItem?: CartProduct,
) => {
  if (product.priceInfos) {
    if (product.priceInfos.promotionalPrice) {
      return product.priceInfos.promotionalPrice;
    } else {
      return product.priceInfos.price;
    }
  }

  return (
    currencyToNumber(product?.valorUnitarioPor || '0') +
    currencyToNumber(cartItem?.precoPersonalizacao || '0')
  );
};

export const sendCheckoutFunnelEvent = (paymentType?: string) => {
  const { event, cart, categories, mParticle } = getAnalyticsInfoFromStorage();
  const hasCouponLink = hasCouponLinkCookie();

  if (!event) {
    return;
  }

  const value = cart?.total || '0';
  const promocode = cart?.promocode;
  const discount = cart?.desconto || '0';
  const freightZipcode = cart?.cep;
  const freightValue = cart?.frete;

  const getCategories = (sku: string) => {
    return (
      categories?.find(
        (category: Categories) => category.sku === sku.substring(0, 8),
      )?.category || ''
    );
  };

  const urlParams = new URLSearchParams(mParticle?.href);
  const utmMedium = urlParams.get('utm_medium');
  const utmCampaign = urlParams.get('utm_campaign');
  const utmSource = urlParams.get('utm_source');

  const items =
    !cart || (cart && isEmpty(cart?.itens))
      ? []
      : cart.itens.map(
          (
            {
              cor,
              modelo,
              sku,
              valorUnitarioDe = '0',
              valorUnitarioPor = '0',
              quantidade = 0,
              codigoEstilo,
              tamanho,
              presente = false,
            }: CartProduct,
            index,
          ) => {
            const fromPrice = currencyToNumber(
              valorUnitarioDe || valorUnitarioPor,
            );
            const itemPrice = currencyToNumber(valorUnitarioPor);

            return {
              coupon: promocode || undefined,
              index,
              item_brand: getItemBrand(sku),
              item_category: getCategories(sku),
              item_id: getCleanStyleCode(codigoEstilo),
              from_price: currencyToNumber(valorUnitarioDe || valorUnitarioPor),
              discount: Math.floor((fromPrice - itemPrice) * 100) / 100,
              item_size: tamanho,
              item_sku: sku,
              isGift: presente,
              item_name: modelo,
              item_scooby_id: sku.substring(0, 8),
              item_type: isSneaker(sku),
              item_variant: cor,
              price: currencyToNumber(valorUnitarioPor),
              quantity: quantidade,
            };
          },
        );

  trackingEvent({ ecommerce: null });
  trackingEvent({
    event: event,
    timestamp_vuc: getDateOnCDPFormat(),
    source: mParticle?.href,
    utm_medium: utmMedium ? utmMedium : undefined,
    utm_campaign: utmCampaign ? utmCampaign : undefined,
    utm: utmSource ? utmSource : undefined,
    ecommerce: {
      coupon: promocode || undefined,
      has_coupon_link: Boolean(hasCouponLink),
      currency: 'BRL',
      freight_zipcode: freightZipcode,
      freight_value: freightValue ? currencyToNumber(freightValue) : undefined,
      discount_value: currencyToNumber(discount),
      items,
      value: currencyToNumber(value),
      payment_type: paymentType ? PAYMENT_TABS_VALUES[paymentType] : undefined,
    },
  });

  removeStorageItem(KEY_CURRENT_EVENT);
};

export const sendAnalyticsEvents = (cart: Cart): void => {
  const event = getStorageItem(KEY_CURRENT_EVENT);
  setCardDataOnStorage(cart);

  if (!event) {
    return;
  }

  const isAddOrRemoveEvent =
    event === CheckoutEvents.AddToCart ||
    event === CheckoutEvents.RemoveFromCart;

  const isPurchaseOrAddCoupon =
    event !== CheckoutEvents.Purchase && event !== CheckoutEvents.AddCoupon;
  if (isAddOrRemoveEvent) {
    sendAddOrRemoveEvent();
    return;
  }

  if (isPurchaseOrAddCoupon) {
    sendCheckoutFunnelEvent();
    return;
  }
};

export const setEventTypeOnStorage = (currentEvent: CheckoutEvents): void => {
  removeStorageItem(KEY_CURRENT_EVENT);
  setStorageItem(KEY_CURRENT_EVENT, currentEvent);
};

export type mParticleObj = {
  currentOrigin: string;
  href: string;
};

export const setmParticleObjOnStorage = (mParticleObj: mParticleObj): void => {
  removeStorageItem(KEY_MPARTICLE);
  setStorageItem(KEY_MPARTICLE, mParticleObj);
};

export const setCardDataOnStorage = (cardData: Cart): void => {
  removeStorageItem(KEY_CART_DATA);
  setStorageItem(KEY_CART_DATA, cardData);
};

export const setProductOnStorage = (product: ProductEvent): void => {
  removeStorageItem(KEY_PRODUCT);
  setStorageItem(KEY_PRODUCT, product);
};

export const setSneakerFlagOnStorage = (exclusive: SneakerFlag): void => {
  const sneakersFromStorage = getStorageItem(KEY_SNEAKER_FLAG) as SneakerFlag[];

  if (
    !sneakersFromStorage ||
    (sneakersFromStorage && isEmpty(sneakersFromStorage))
  ) {
    setStorageItem(KEY_SNEAKER_FLAG, [exclusive]);
    return;
  }

  const repeatSneaker = sneakersFromStorage.find(
    ({ sku }: SneakerFlag) => sku === exclusive.sku,
  );

  if (repeatSneaker) {
    return;
  }

  const incrementalInfo = sneakersFromStorage.concat(exclusive);
  setStorageItem(KEY_SNEAKER_FLAG, incrementalInfo);
};

export const setCategoryOnStorage = (category: Categories): void => {
  const categoriesFromStorage = getStorageItem(KEY_CATEGORY) as Categories[];

  if (
    !categoriesFromStorage ||
    (categoriesFromStorage && isEmpty(categoriesFromStorage))
  ) {
    setStorageItem(KEY_CATEGORY, [category]);
    return;
  }

  const repeatCategory = categoriesFromStorage.find(
    ({ sku }: Categories) => sku === category.sku,
  );

  if (repeatCategory) {
    return;
  }

  const incrementalInfo = categoriesFromStorage.concat(category);
  setStorageItem(KEY_CATEGORY, incrementalInfo);
};

export const clearAllAnalyticsDataOnStorage = (): void => {
  removeStorageItem(KEY_PRODUCT);
  removeStorageItem(KEY_CURRENT_EVENT);
  removeStorageItem(KEY_CART_DATA);
  removeStorageItem(KEY_CATEGORY);
  removeStorageItem(KEY_SNEAKER_FLAG);
};

export const getAnalyticsInfoFromStorage = () => {
  const product = getStorageItem(KEY_PRODUCT) as AllProductTrackingEvent;
  const event = getStorageItem(KEY_CURRENT_EVENT) as string;
  const categories = getStorageItem(KEY_CATEGORY) as Categories[];
  const cart = getStorageItem(KEY_CART_DATA) as Cart;
  const exclusive = getStorageItem(KEY_SNEAKER_FLAG) as SneakerFlag[];
  const mParticle = getStorageItem(KEY_MPARTICLE) as mParticleObj;

  return {
    event: event,
    product: product,
    cart: cart,
    categories: categories,
    sneaker: exclusive,
    mParticle: mParticle,
  };
};

export const isSneaker = (sku: string) => {
  const { sneaker } = getAnalyticsInfoFromStorage();
  const flag =
    sneaker?.find(
      (exclusive: SneakerFlag) => exclusive.sku === sku.substring(0, 8),
    )?.exclusive || false;
  return getSneakerFlag(flag);
};

export const getStyleCode = (product: AllProductTrackingEvent) => {
  const styleCode = getCleanStyleCode(
    product?.colorInfo?.styleCode || product?.codigoEstilo || '',
  );

  return styleCode;
};

export const getItemBrand = (sku: string) =>
  isSneaker(sku) === SNEAKER_FLAG.DEFAULT
    ? SNEAKER_FLAG.NIKE
    : SNEAKER_FLAG.SNKRS;

export const getSneakerFlag = (flag: boolean) =>
  flag ? SNEAKER_FLAG.SNKRS : SNEAKER_FLAG.DEFAULT;

type SummaryValuesTracking = {
  subtotal: string;
  discount: string;
  total: string;
  freight: string;
  voucher?: string;
  giftCard?: string;
  hasPixDiscount?: boolean;
  pixDiscountValue?: string;
};

export const sendInconsistentValuesMessage = (
  summaryValues: SummaryValuesTracking,
  step: 'cart' | 'summary',
) => {
  trackingMessageError({
    message: 'Inconsistent checkout summary values',
    extraDetails: summaryValues,
    flow: 'checkout_flow',
    checkpoint: step,
    namespace: 'checkout()',
  });
};
