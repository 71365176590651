export const cartPath = '/carrinho';

export const homePath = '/';

export const loginPath = '/login';

export const searchCepPath =
  'https://buscacepinter.correios.com.br/app/endereco/index.php';

export const snkrsPath = '/snkrs';

export const nikeProfilePath = 'https://www.nike.com/pt/member/settings';

export const registrationDataPath = '/minha-conta/alterar-dados-cadastrais';

export const registrationDataPathCommunication =
  '/minha-conta/alterar-dados-cadastrais?communication';

export const myCardsPath = '/minha-conta/meus-cartoes';

export const checkoutPath = '/checkout';

export const paymentPath = '/checkout/pagamento';

export const paymentConfirmationPath = '/checkout/confirmacao';

export const loginSellerPath = '/login-vendedor';

export const journalPath = '/journal';
