import { ArrowLeft } from '@sbf/fairplay-icons';
import styled, { css } from 'styled-components';

const StyledBackButton = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.neutral[700]};
    background: ${theme.colors.neutral[100]};
    padding: ${theme.spaces[200]} ${theme.spaces[0]};
    display: inline-flex;
    justify-content: start;
    align-items: center;
    font-size: ${theme.fontSizes.medium};
    font-weight: ${theme.fontWeights.medium};
    font-family: ${theme.fonts.primary};
    border: ${theme.borderWidth.none};
    outline: none;
    cursor: pointer;

    svg {
      margin-right: ${theme.spaces[300]};
      font-size: inherit;
    }

    &.focus-visible {
      box-shadow: ${theme.shadow.focus};
    }
  `}
`;

type BackButtonProps = {
  text: string;
  action: () => void;
};

const BackButton = ({ text, action }: BackButtonProps) => (
  <StyledBackButton data-testid="drawer-back-button" onClick={action}>
    <ArrowLeft />
    {text}
  </StyledBackButton>
);

export default BackButton;
