/**
 *  Config options for the implementation of throttle behaviour.
 *
 * @param delay the waiting time in milliseconds;
 * @param leading if it's true, the function will exec on the first call;
 * @param trailing if it's true, the function will exec after last call;
 */
export interface ThrottleOptions {
  delay: number;
  leading?: boolean;
  trailing?: boolean;
}

export const isThrottleOptions = (
  arg: ThrottleOptions | number,
): arg is ThrottleOptions => {
  return (arg as ThrottleOptions).delay !== undefined;
};
