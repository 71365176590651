/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * The isType is a function for return the types of the values
 *
 * @param {any} value - The value you want to know is the type or not
 *
 * @example
 * import { isType } from '@/common/utils/isType';
 *
 * isType('number', 123)
 *
 * //return true
 */

export const isType = <T>(type: string, value: any): value is T =>
  Object.prototype.toString
    .call(value)
    .toLowerCase()
    .replace('[object', '')
    .includes(type);

export default isType;
