import { ParsedUrlQuery } from 'querystring';

export const formatQueryFromUrl = (query: ParsedUrlQuery | undefined) => {
  if (!query) {
    return '';
  }
  const formattedQuery = Object.entries(query).reduce((acc, [key, value]) => {
    if (!value) {
      return acc;
    }
    if (Array.isArray(value)) {
      value.forEach((val) => {
        acc.append(key, val);
      });
    } else {
      acc.append(key, value);
    }
    return acc;
  }, new URLSearchParams());

  return formattedQuery.toString();
};

export const removeQueryParams = (path: string) => {
  const SEPARATOR_CHARACTER = '?';
  const isValidQueryUrl = path?.includes(SEPARATOR_CHARACTER);

  return isValidQueryUrl ? path.split(SEPARATOR_CHARACTER)[0] : path;
};
