import { useEffect, useRef, useState } from 'react';

export const useSlider = ({ numberOfSlides }: { numberOfSlides: number }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const SPEED = 5000;

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      setCurrentSlideIndex(
        (prev) => (prev === numberOfSlides - 1 ? 0 : prev + 1), // do not use "goToNextSlide" here
      );
    }, SPEED);
  };

  const stopInterval = () => {
    intervalRef.current && clearInterval(intervalRef.current);
  };

  const resetInterval = () => {
    stopInterval();
    startInterval();
  };

  const goToPreviousSlide = () => {
    setCurrentSlideIndex((prev) =>
      currentSlideIndex === 0 ? numberOfSlides - 1 : prev - 1,
    );
    resetInterval();
  };

  const goToNextSlide = () => {
    setCurrentSlideIndex((prev) =>
      currentSlideIndex === numberOfSlides - 1 ? 0 : prev + 1,
    );
    resetInterval();
  };

  useEffect(() => {
    startInterval();
    return () =>
      intervalRef.current ? clearInterval(intervalRef.current) : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfSlides]);

  return {
    currentSlideIndex,
    goToNextSlide,
    goToPreviousSlide,
    startInterval,
    stopInterval,
  };
};
