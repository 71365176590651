import { Modal } from '@sbf/fairplay-nike';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { getCoreRoutes } from '@/common/config/routes';
import { useTrackingContext } from '@/contexts/TrackingContext';
import { AuthEventType, handleSignOut } from '@/helpers/auth';
import { sendLoginAndSingUpEvent } from '@/helpers/auth/trackingEvents';
import { dateFormatter } from '@/helpers/formatter';
import { useAuthSession } from '@/hooks/useAuthSession';
import CompleteForm from '@/modules/myAccount/components/Registration/CompleteForm/CompleteForm';
import { useGetRegistrationQuery } from '@/modules/myAccount/services/registration/registration';
import {
  checkMinimumChars,
  verifyAccountData,
  wichPersonType,
} from '@/modules/myAccount/utils/form/registrationModal';
import {
  checkLatinChars,
  checkMaxAge,
  checkMinAge,
} from '@/modules/myAccount/utils/form/validations';
import { useAthleteVoucherEligible } from '@/modules/vouchers/athleteVoucher/services/athleteVoucher/athleteVoucher';
import { sendCompleteFormEvent } from './gaEvents';

const RegistrationModal = () => {
  const [completeFormVisibility, setCompleteFormVisibility] = useState(false);
  const { isLoading, data, isSuccess, refetch } = useGetRegistrationQuery({
    enabled: false,
  });
  const { isLoading: isAuthLoading, isAuthenticated } = useAuthSession();
  const router = useRouter();
  const { home } = getCoreRoutes();
  const { setUserData } = useTrackingContext();

  useAthleteVoucherEligible();

  const onCloseAndLogout = async () => {
    setCompleteFormVisibility(false);
    if (router.pathname !== home) {
      await router.push(home);
    }
    const authEventonCloseAndLogout: AuthEventType = {
      action: 'Click - Fora Modal Cadastro Complementar',
      label: 'Fechou o modal de cadastro complementar e foi deslogado',
    };
    handleSignOut(authEventonCloseAndLogout);
    setUserData();
  };

  useEffect(() => {
    if (isAuthenticated) {
      refetch();
    } else if (!isAuthLoading) {
      setUserData();
    }
  }, [isAuthenticated, refetch, isAuthLoading, setUserData]);

  useEffect(() => {
    if (isSuccess && data) {
      setUserData(data);

      const missingData = verifyAccountData(data);
      const personType = wichPersonType(data);
      const isNameGreaterThanTwochars = checkMinimumChars(data?.nome);
      const isSurnameGreaterThanTwochars = checkMinimumChars(data?.sobrenome);
      const isNameLatin = checkLatinChars(data?.nome);
      const isSurnameLatin = checkLatinChars(data?.sobrenome);
      const isYoungerThanOneHundred = data?.dataDeNascimento
        ? checkMaxAge(dateFormatter(data?.dataDeNascimento))
        : true;
      const isEighteenYearsOrOlder = data?.dataDeNascimento
        ? checkMinAge(dateFormatter(data?.dataDeNascimento))
        : true;

      const dataError = [];
      const missingDataToEvent = [];

      if (
        missingData.length > 0 ||
        !isNameLatin ||
        !isSurnameLatin ||
        !isYoungerThanOneHundred ||
        !isEighteenYearsOrOlder ||
        !isSurnameGreaterThanTwochars ||
        !isNameGreaterThanTwochars
      ) {
        setCompleteFormVisibility(true);
        if (missingData[0] === 'nova conta') {
          missingDataToEvent.push('nova conta');
          sendLoginAndSingUpEvent('sign_up');
        } else {
          missingDataToEvent.push(...missingData);
        }
        if (!isNameLatin) {
          dataError.push({
            field: 'nome',
            error: 'caracter especial',
          });
        }
        if (!isSurnameLatin) {
          dataError.push({
            field: 'sobrenome',
            error: 'caracter especial',
          });
        }
        if (!isNameGreaterThanTwochars) {
          dataError.push({
            field: 'nome',
            error: 'caracteres minimos',
          });
        }
        if (!isSurnameGreaterThanTwochars) {
          dataError.push({
            field: 'sobrenome',
            error: 'caracteres minimos',
          });
        }
        if (!isYoungerThanOneHundred) {
          dataError.push({
            field: 'data de nascimento',
            error: 'maior que 100 anos',
          });
        }
        if (!isEighteenYearsOrOlder) {
          dataError.push({
            field: 'data de nascimento',
            error: 'menor que 18 anos',
          });
        }
        sendCompleteFormEvent(missingDataToEvent, dataError, personType);
      }
    }
  }, [data, isSuccess, setUserData]);

  if (isLoading || !isAuthenticated) {
    return null;
  }

  return (
    <Modal.Blank isOpen={completeFormVisibility} onClose={onCloseAndLogout}>
      <CompleteForm
        responseData={data}
        onCloseModal={() => setCompleteFormVisibility(false)}
        onLogout={() => handleSignOut()}
      />
    </Modal.Blank>
  );
};

export default RegistrationModal;
