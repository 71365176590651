import styled, { css } from 'styled-components';

export const AccordionSummary = styled.summary`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.spaces[300]} 0;
    width: 100%;
    height: ${theme.spaces[400]};
    background: ${theme.colors.neutral[700]};
    font-size: ${theme.fontSizes.small};
    color: ${theme.colors.secondary};
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    cursor: pointer;
    font-weight: ${theme.fontWeights.regular};
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    border-top: ${theme.borderWidth.hairline} solid ${theme.colors.neutral[300]};
    border-bottom: ${theme.borderWidth.hairline} solid
      ${theme.colors.neutral[300]};

    &::-webkit-details-marker {
      display: none;
    }

    &::after {
      content: '+';
      color: ${theme.colors.neutral[200]};
      font-size: ${theme.fontSizes.big};
      width: ${theme.spaces[400]};
      text-align: center;
    }
  `}
`;

export const AccordionDetails = styled.details`
  ${({ theme }) => css`
    display: grid;
    width: auto;
    font-size: ${theme.fontSizes.tiny};
    color: ${theme.colors.neutral[200]};
    text-decoration: none;
    font-weight: ${theme.fontWeights.regular};
  `}
`;

export const AccordionDiv = styled.div`
  ${({ theme }) => css`
    details[open] summary:after {
      content: '-';
      color: ${theme.colors.neutral[200]};
      font-size: ${theme.fontSizes.big};
      width: ${theme.spaces[400]};
      text-align: center;
    }
  `}
`;
