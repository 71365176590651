import { Typography } from '@sbf/fairplay-core';
import {
  AbsoluteLoaderContainer,
  BoxLoader,
  ComponentLoaderContainer,
  LoadingStyled,
  PageLoaderContainer,
  TypographyWrapper,
} from '@/components/Loader/Loader.styled';
import {
  CustomLoaderProps,
  LoaderProps,
} from '@/components/Loader/Loader.types';

export const CustomLoader: React.FC<CustomLoaderProps> = ({
  titleAlly = 'carregando o conteúdo',
  text = 'Carregando',
  variant = 'heading',
  size = 'h1',
  as = 'span',
  direction,
}: CustomLoaderProps) => {
  return (
    <BoxLoader direction={direction}>
      <LoadingStyled titleAccess={titleAlly} />
      <TypographyWrapper direction={direction}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Typography variant={variant} size={size} weight="medium" as={as}>
          {text}
        </Typography>
      </TypographyWrapper>
    </BoxLoader>
  );
};

const Loader = ({
  isFullScreen = true,
  isFullHeight = true,
  isAbsoluteLoader = false,
}: LoaderProps) => {
  const ComponentLoader = () => (
    <ComponentLoaderContainer isFullHeight={isFullHeight}>
      <CustomLoader />
    </ComponentLoaderContainer>
  );

  const PageLoader = () => (
    <PageLoaderContainer>
      <CustomLoader />
    </PageLoaderContainer>
  );

  if (isAbsoluteLoader) {
    return (
      <AbsoluteLoaderContainer>
        <CustomLoader />
      </AbsoluteLoaderContainer>
    );
  }

  const Container = isFullScreen ? PageLoader : ComponentLoader;

  return <Container />;
};

export default Loader;
