import {
  sendEventHotSiteWithoutResult,
  sendEventLoadGridHotsite,
  sendEventLoadGridNav,
  sendEventLoadGridSearch,
  sendEventNavWithoutResult,
  sendEventSearchWithoutResult,
} from '../../screens/Grid/trackingEvents';

const REF_PATH_SEARCH = 'busca';
const REF_PATH_SEARCH_VALUE = `/${REF_PATH_SEARCH}?`;

const REF_PATH_SEARCH_CONTEXT = 'search';
const REF_PATH_SEARCH_CONTEXT_VALUE = `/${REF_PATH_SEARCH}?`;

const REF_PATH_HOTSITE = 'hotsite';
const REF_PATH_HOTSITE_VALUE = `/${REF_PATH_HOTSITE}/`;

const REF_PATH_NAV = 'nav';
const REF_PATH_NAV_VALUE = `/${REF_PATH_NAV}/`;

export type gridRouteQuery = {
  page?: number;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  utm_id?: string;
  utm_referrer?: string;
  gclid?: string;
  fbclid?: string;
};

export const REF_PATH_MAPPER: Partial<{
  [key: string]: string;
}> = {
  [REF_PATH_SEARCH]: REF_PATH_SEARCH_VALUE,
  [REF_PATH_SEARCH_CONTEXT]: REF_PATH_SEARCH_CONTEXT_VALUE,
  [REF_PATH_HOTSITE]: REF_PATH_HOTSITE_VALUE,
  [REF_PATH_NAV]: REF_PATH_NAV_VALUE,
};

/**
 * This will return the matching reference path type
 *
 * @example
 * import { isRefPathType } from '@/modules/grid/utils/url';

 * isRefPathType('/busca?','busca')
 * // true
 *
 * @param refPath - string reference path
 * @param type - string reference type
 */
export const isRefPathType = (refPath: string, type: string): boolean => {
  const refPathType = REF_PATH_MAPPER[type] || undefined;
  return refPath === refPathType;
};

/**
 * This will return the filter value relative path url
 *
 * This method will return relative path if search path need to
 * translated on page for context
 *
 * @example
 * import { refPathType } from '@/modules/grid/utils/url';

 * refPathType('search')
 * // '/busca?'
 *
 * @param refPath - string relative path page
 */
export const refPathType = (refPath: string) => {
  return REF_PATH_MAPPER[refPath];
};

export const searchEvents = (path: string) => {
  if (path === REF_PATH_SEARCH_CONTEXT) {
    return sendEventLoadGridSearch;
  } else if (path === REF_PATH_NAV) {
    return sendEventLoadGridNav;
  }

  return sendEventLoadGridHotsite;
};

export const searchEmptyResultEvents = (path: string) => {
  if (path === REF_PATH_SEARCH_CONTEXT) {
    return sendEventSearchWithoutResult;
  } else if (path === REF_PATH_NAV) {
    return sendEventNavWithoutResult;
  }

  return sendEventHotSiteWithoutResult;
};

export const parseSearchUrl = (searchUrl: string) => {
  searchUrl = searchUrl.replace(/^.*\?/, '');
  const parse = new URLSearchParams(searchUrl);

  return parse;
};

export const getNavigationUrlPairs = (navUrl: string) => {
  const navPathRegexObject = navUrl.match(/\/nav\/(.*?)(\?|$)/);
  if (navPathRegexObject == null) {
    return null;
  }

  const regexGroup = 1;
  const navRoute = navPathRegexObject[regexGroup];

  const navValues = navRoute.split('/');
  const pairs = [];
  for (let i = 0; i < navValues.length; i += 2) {
    pairs.push(navValues[i] + '/' + navValues[i + 1]);
  }
  return pairs;
};

export const getHotsiteUrlInfo = (hotsiteUrl: string) => {
  const hotsiteNameRegexObject = hotsiteUrl.match(/\/hotsite\/(.*?)(\?|$)/);
  if (hotsiteNameRegexObject == null) {
    return null;
  }

  const regexGroup = 1;
  const hotsiteName = hotsiteNameRegexObject[regexGroup];
  const queryParameters = hotsiteUrl.replace(/^.*\?/, '');
  const fields = new URLSearchParams(queryParameters);

  return { hotsiteName, fields };
};

export const getQueryStringsFromPath = (path: string) => {
  const SEPARATOR_CHARACTER = '?';
  let splittedNextPage = [];

  if (path.includes(SEPARATOR_CHARACTER)) {
    splittedNextPage = path.split(SEPARATOR_CHARACTER);
    splittedNextPage.shift();
    path = splittedNextPage.toString();
  }

  return path;
};

export const getQueryWithoutUtmValues = (routeQuery: gridRouteQuery) => {
  const query = { ...routeQuery };

  delete query.utm_source;
  delete query.utm_medium;
  delete query.utm_campaign;
  delete query.utm_term;
  delete query.utm_content;
  delete query.utm_id;
  delete query.utm_referrer;
  delete query.gclid;
  delete query.fbclid;

  return query;
};
