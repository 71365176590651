import { media } from '@sbf/fairplay-utils';
import styled, { css } from 'styled-components';

export const SocialWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spaces[400]};

    ${media.greaterThan('desktop')} {
      margin-top: ${theme.spaces[0]};
    }
  `}
`;

export const SocialGrid = styled.div`
  ${({ theme }) => css`
    width: 200rem;
    display: grid;
    grid-gap: ${theme.spaces[200]};
    grid-auto-flow: column;
    justify-content: start;

    svg {
      font-size: ${theme.fontSizes.big};
      color ${theme.colors.neutral[100]};
      opacity: ${theme.opacity[700]};

      :hover {
        opacity: ${theme.opacity[1000]};
      }
    }
  `}
`;
