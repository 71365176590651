import { trackingEvent } from '@/common/utils/tracking/tracking';

type errorObject = {
  field: string;
  error: string;
};

const formingParamArrays = (
  missingData: string[],
  dataError: Array<errorObject>,
) => {
  const inputErrorArr = [];
  const errorsArr = [];
  const inputArr = [];

  if (dataError.length > 0) {
    const mappedDataErrors = dataError.map((item) => {
      return `${item.field + ' - ' + item.error}`;
    });
    inputErrorArr.push(...mappedDataErrors);

    const inputs = dataError.map((item) => {
      return `${item.field}`;
    });

    inputArr.push(...inputs);

    const errors = dataError.map((item) => {
      return `${item.error}`;
    });

    errorsArr.push(...errors);
  }

  const missingDataErrors = [];
  if (missingData[0] !== 'nova conta') {
    missingDataErrors.push(...missingData);
    const mappedMissingDataErrors = missingDataErrors.map((item) => {
      return `${item + ' - dado faltando'}`;
    });
    inputErrorArr.push(...mappedMissingDataErrors);
    inputArr.push(...missingDataErrors);

    for (let i = 0; i < missingData.length; i++) {
      errorsArr.push('dado faltando');
    }
  }

  return { inputErrorArr, inputArr, errorsArr };
};

export const sendCompleteFormEvent = (
  missingData: string[],
  dataError: Array<errorObject>,
  personType: string,
) => {
  const { inputErrorArr, inputArr, errorsArr } = formingParamArrays(
    missingData,
    dataError,
  );
  trackingEvent({
    event: 'view_complete_form',
    reason: `${
      missingData[0] === 'nova conta' ? 'nova conta' : 'erro de dado'
    }`,
    input_error: `${inputErrorArr}`,
    input: `${inputArr}`,
    error: `${errorsArr}`,
    form: 'cadastro',
    account_type: `${personType}`,
  });
};
