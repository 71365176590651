import { isDateBetween } from '@/common/utils/date/date';

type ActiveItem = {
  startDate?: string;
  endDate?: string;
  active: boolean;
};

export const isItemActive = ({ active, startDate, endDate }: ActiveItem) =>
  active && isDateBetween(startDate, endDate);
