import { ParsedUrlQuery } from 'querystring';
import { NextSeoProps } from 'next-seo';
import { getCoreConfig } from '@/common/config/config';

/**
 *  builds the meta tags of home page
 */
export const getMetadata = (
  pageOrigin = 'default',
  asPath = '',
  query = {},
): NextSeoProps => {
  const titleMeta: { [key: string]: string } = {
    snkrsHome:
      'SNKRS Nike - Sneakers Exclusivos - Tênis e Calçados - Just Do It - Nike.com.br',
    snkrsStock:
      'Últimos SNKRS Nike em Estoque - Sneakers Exclusivos - Just Do It - Nike.com.br',
    snkrsCalendar:
      'Calendário de Lançamento de SNKRS Nike - Sneakers Exclusivos - Just Do It - Nike.com.br',
    default: 'Nike - Produtos e Coleções Exclusivas - Just Do It - Nike.com.br',
  };

  const descriptionMeta: { [key: string]: string } = {
    snkrsHome:
      'Só na Nike você encontra SNKRS com exclusividade e pelos melhores preços. Confira! Calçados e tênis sneakers da Nike para você. Para atletas. Para o mundo.',
    snkrsStock:
      'Corra e aproveite as ofertas dos últimos SNKRS Nike em estoque! Sneakers com exclusividade e pelos melhores preços. Confira! Da Nike para você. Para atletas. Para o mundo. Nike.com.br.',
    snkrsCalendar:
      'Calendário de lançamento dos exclusivos SNKRS Nike! Tênis e calçados sneakers com exclusividade e pelos melhores preços. Confira! Da Nike para você. Para atletas. Para o mundo. Nike.com.br.',
    default:
      'Produtos, coleções, serviços e experiências únicas para te inspirar. Da Nike para você. Para atletas. Para o mundo. Nike.com.br',
  };

  const canonicalValue = getCanonicalNavValue(asPath);
  const { noFollow, noIndex } = getNoIndexNoFollowValue(asPath, query);

  return {
    title: titleMeta[pageOrigin],
    description: descriptionMeta[pageOrigin],
    canonical: canonicalValue,
    nofollow: noFollow,
    noindex: noIndex,
    openGraph: {
      title: titleMeta[pageOrigin],
      description: descriptionMeta[pageOrigin],
      url: canonicalValue,
      images: [
        {
          url: '/images/meta/open-graph-main-image.jpg',
          width: 1920,
          height: 1088,
        },
      ],
    },
  };
};

export const getCanonicalNavValue = (asPath: string) => {
  const {
    publicRuntimeConfig: { domain },
  } = getCoreConfig();

  const path = asPath.split('?')[0];
  const baseUrl = `https://www.${domain}`;

  return `${baseUrl}${path}`;
};

export const getNoIndexNoFollowValue = (
  asPath: string,
  query: ParsedUrlQuery,
) => {
  const FILTERS_LIMIT = 12;
  const [path, queryStrings] = asPath.split('?');
  const filterQuantity = path.split('/').length;
  const objKeys = Object.keys(query);

  let noIndex = false;
  let noFollow = true;

  if (asPath.includes('/busca')) {
    return { noIndex: true, noFollow: false };
  }

  if (!queryStrings && filterQuantity < FILTERS_LIMIT) {
    return { noIndex, noFollow: false };
  }

  if (
    filterQuantity >= FILTERS_LIMIT ||
    (objKeys.length === 2 && objKeys.includes('page'))
  ) {
    noIndex = true;
    noFollow = false;

    return { noIndex, noFollow };
  }

  if (objKeys.length >= 2) {
    noIndex = true;
    noFollow = true;

    return { noIndex, noFollow };
  }

  return { noIndex, noFollow };
};

export interface PostPageQuery extends ParsedUrlQuery {
  page?: string;
  sort_by?: string;
  sort_order?: string;
  slug?: string;
}
