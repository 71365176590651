import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useInView as useInViewFromIntersectionObserver } from 'react-intersection-observer';
import { isType } from '@/common/utils/funcs/isType/isType';
import { isServerSide } from '@/helpers/environment';
import { throttle } from '@/helpers/throttle';
import { ThrottleOptions } from '@/helpers/throttle/types';
import { CustomInViewHookResponse, DefaultUseInViewOptions } from './types';

const defaultOptions: DefaultUseInViewOptions = {
  isClientSide: !isServerSide(),
  throttleLeading: false,
  prefeScrollListener: false,
  throttleDelay: 150,
  offset: 0,
  intersectionOptions: {
    triggerOnce: true,
    rootMargin: '0px',
  },
};

const useElementVisibleOnScreen = <
  T extends Element | HTMLDivElement | HTMLButtonElement | null,
>({
  offset = 0,
  throttleOptions = { delay: 150, leading: false, trailing: false },
}: {
  offset: number;
  throttleOptions: ThrottleOptions;
}): [MutableRefObject<T | null>, boolean] => {
  const ref = useRef<T>(null);
  const [isElementVisible, setElementVisibility] = useState(false);

  const handleElementVisibility = throttle(() => {
    const element = ref.current;

    if (element) {
      const top = element.getBoundingClientRect().top;
      const isElementVisibleOnScreen =
        top + offset >= 0 && top - offset <= window.innerHeight;

      if (isElementVisibleOnScreen) {
        setElementVisibility(true);
        return;
      }

      setElementVisibility(false);
    }
  }, throttleOptions);

  const addElementVisibilityScrollListener = useCallback(() => {
    document.addEventListener('scroll', handleElementVisibility, {
      passive: true,
    });
  }, [handleElementVisibility]);

  const removelElementVisibilityScrolListener = useCallback(() => {
    document.removeEventListener('scroll', handleElementVisibility);
  }, [handleElementVisibility]);

  useEffect(() => {
    addElementVisibilityScrollListener();
    return () => removelElementVisibilityScrolListener();
  }, [
    addElementVisibilityScrollListener,
    removelElementVisibilityScrolListener,
  ]);

  return [ref, isElementVisible];
};

const createUseInViewHook =
  <T extends Element | HTMLDivElement | HTMLButtonElement | null>(
    intersectionHook: typeof useInViewFromIntersectionObserver,
  ) =>
  (
    options: DefaultUseInViewOptions,
  ):
    | CustomInViewHookResponse
    | [MutableRefObject<T | null> | null, boolean] => {
    const config = {
      ...defaultOptions,
      ...options,
    };

    if (!config.isClientSide) {
      return [null, false];
    }

    if (
      config.prefeScrollListener ||
      isType('undefined', window.IntersectionObserver)
    ) {
      return useElementVisibleOnScreen<T>({
        offset: config.offset || 0,
        throttleOptions: {
          delay: config.throttleDelay || 150,
          leading: config.throttleLeading,
        },
      });
    }

    return intersectionHook(config.intersectionOptions);
  };

const useInView = createUseInViewHook;

export { useInView };
