import { Button, DesktopView, MobileView } from '@sbf/fairplay-nike';
import { useFlagsStatus } from '@unleash/proxy-client-react';
import Link from 'next/link';
import { parseCookies, setCookie } from 'nookies';
import { useEffect, useState } from 'react';
import AdoptScript from '@/common/components/ThirdPartyScripts/AdoptScript';
import { getCoreRoutes } from '@/common/config/routes';
import useUnleash from '@/common/hooks/useUnleash/useUnleash';
import {
  Anchor,
  Container,
  MessageDesk,
  MessageMobile,
  TextContainer,
  Title,
} from './BannerCookie.styled';
import {
  sendClickButtonEvent,
  sendPrivacyPolicyEvent,
  sendViewBannerEvent,
} from './gaEvents';

const COOKIE_BANNER = 'aceite-uso-cookie';
const MAX_AGE_ONE_YEAR = 12 * 30 * 24 * 60 * 60;

const BANNER_MESSAGE =
  'Usamos cookies para garantir que você obtenha a melhor experiência em nosso site. Consulte nossa ';
const BUTTON_TEXT = 'Aceitar';
const BANNER_TITLE = 'Política de Cookies';
const PRIVACY_POLICY = 'Política de Privacidade';

const coreRoutes = getCoreRoutes();

const BannerCookie = () => {
  const bannerCookie = parseCookies()[COOKIE_BANNER];
  const [hasCookie, setHasCookie] = useState(true);
  const { flagsReady } = useFlagsStatus();
  const isAdoptEnabled = useUnleash('nike-front.adopt-cookies');

  useEffect(() => {
    setHasCookie(Boolean(bannerCookie));
    if (flagsReady && !hasCookie && !isAdoptEnabled) {
      sendViewBannerEvent();
    }
  }, [hasCookie, bannerCookie, isAdoptEnabled, flagsReady]);

  const clickAcceptHandler = () => {
    sendClickButtonEvent();
    setCookie(null, COOKIE_BANNER, new Date().toDateString(), {
      maxAge: MAX_AGE_ONE_YEAR,
      path: '/',
    });
    setHasCookie(true);
  };

  if (!flagsReady || (hasCookie && !isAdoptEnabled)) {
    return null;
  }

  if (isAdoptEnabled) {
    return <AdoptScript />;
  } else {
    return (
      <Container data-testid="banner-cookies-terms">
        <TextContainer>
          <Title>{BANNER_TITLE}</Title>
          <MobileView>
            <MessageMobile>{BANNER_MESSAGE}</MessageMobile>
            <Link href={coreRoutes.privacyPolicy} passHref legacyBehavior>
              <Anchor text={PRIVACY_POLICY} onClick={sendPrivacyPolicyEvent} />
            </Link>
            <MessageMobile>.</MessageMobile>
          </MobileView>

          <DesktopView>
            <MessageDesk>{BANNER_MESSAGE}</MessageDesk>
            <Link href={coreRoutes.privacyPolicy} passHref legacyBehavior>
              <Anchor text={PRIVACY_POLICY} onClick={sendPrivacyPolicyEvent} />
            </Link>
            <MessageDesk>.</MessageDesk>
          </DesktopView>
        </TextContainer>

        <Button.Fill size="medium" onClick={clickAcceptHandler}>
          {BUTTON_TEXT}
        </Button.Fill>
      </Container>
    );
  }
};

export default BannerCookie;
