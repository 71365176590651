import isEmpty from '@/common/utils/funcs/isEmpty/isEmpty';
import {
  trackingEvent,
  trackingEventOLD,
} from '@/common/utils/tracking/tracking';
import type { SnkrsGridProduct } from '@/domains/snkrs/modules/grid/types/product';
import { SNEAKER_FLAG } from '@/modules/checkout/utils/trackingEvents.types';
import {
  Breadcrumb,
  Details,
  Facet,
  Product,
} from '@/modules/grid/services/search/search.types';
import { getCleanStyleCode } from '@/modules/product/utils/variant/variant';
import {
  getHotsiteUrlInfo,
  getNavigationUrlPairs,
  parseSearchUrl,
} from '../../utils/url/url';

export const getItemlistType = (pathname: string) => {
  let pageType;
  if (pathname?.includes('/nav') || pathname?.includes('/snkrs')) {
    pageType = 'list_results';
  } else if (pathname?.includes('/busca')) {
    pageType = 'search_results';
  } else if (pathname?.includes('/hotsite')) {
    pageType = 'hotsite_results';
  }

  return pageType;
};

const getSearchTerm = () => {
  const { pathname, search } = window.location;
  let searchTerm = '';
  if (pathname.includes('/busca')) {
    const params = new URLSearchParams(search);
    searchTerm = params.get('term') || '';
  }
  return searchTerm;
};

const getItemsOrder = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('sorting') || '';
};

const getSelectedFilters = () => {
  const { pathname } = window.location;
  const getParams = pathname.split('/nav/')[1]?.split('/');
  const selectedFilters = {
    key: getParams?.filter((_, index) => index % 2 === 0) || [],
    value: getParams?.filter((_, index) => index % 2 != 0) || [],
  };

  return selectedFilters;
};

const getFormat = (colums?: number, totalResults?: number) => {
  if (colums && totalResults) {
    const lines = Math.round(totalResults / colums);
    return `${colums}_columns, ${lines}_lines`;
  } else {
    return '';
  }
};

export const sendEventDoSearch = (searchValue: string) => {
  trackingEvent({
    event: 'click_to_search',
    category: 'UI - Header - Search',
    action: 'Fazer uma busca',
    label: `Busca por termo: ${searchValue}`,
  });
};

export const sendEventLoadGridNav = (route: string) => {
  trackingEventOLD({
    category: 'Loading - Nav - Rota de entrada',
    action: 'Carregou - Exibiu página de navegação',
    label: `Nav - URL: ${route}`,
    value: 0,
    nonInteraction: true,
  });
};

export const sendEventLoadGridHotsite = (route: string) => {
  trackingEventOLD({
    category: 'Loading - Hotsite - Rota de entrada',
    action: 'Carregou - Exibiu página de hotsite',
    label: `Hotsite - URL: ${route}`,
    value: 0,
    nonInteraction: true,
  });
};

export const sendEventLoadGridSearch = (route: string) => {
  trackingEventOLD({
    category: 'Loading - Busca - Rota de entrada',
    action: 'Carregou - Exibiu página de busca',
    label: `Busca - URL: ${route}`,
    value: 0,
    nonInteraction: true,
  });
};

export const sendEventClickProductCard = (
  sku: string,
  refPath: string,
  position: number,
) => {
  trackingEvent({
    event: 'click_card_product_grid',
    category: `UI - ${refPath} - Card produtos`,
    action: 'Click - Card Produto',
    label: `Clicou no produto ${sku} na posição ${position}`,
  });
};

export const sendEventClickProductCardPwh = (
  sku: string,
  position: number,
  label?: string,
) => {
  trackingEvent({
    event: 'pwh_click_product_card',
    pwh: {
      items: {
        category: 'pwh_click_product_card',
        action: `Clicou no produto ${sku} na posição ${position}`,
        label,
      },
    },
  });
};

export const sendEventSearchWithoutResult = (searchPath: string) => {
  const parsedSearchPath = parseSearchUrl(searchPath);

  trackingEvent({
    event: 'view_empty_search_results',
    category: 'Busca - Sem Resultados',
    action: 'Acessou search sem resultados',
    label: `busca: ${parsedSearchPath.get(
      'term',
    )}, filtros: ${parsedSearchPath.getAll('fields')}`,
  });
};

export const sendEventNavWithoutResult = (navPath: string) => {
  const navPairs = getNavigationUrlPairs(navPath);
  if (navPairs == null) {
    return;
  }

  trackingEvent({
    event: 'view_empty_nav',
    category: 'Nav - Sem Resultados',
    action: 'Acessou nav sem resultados',
    label: `nav: ${navPairs}`,
  });
};

export const sendEventHotSiteWithoutResult = (hotsitePath: string) => {
  const parsedHotsite = getHotsiteUrlInfo(hotsitePath);
  if (parsedHotsite == null) {
    return;
  }

  trackingEvent({
    event: 'view_empty_hotsite',
    category: 'Hotsite - Sem Resultados',
    action: 'Acessou hotsite sem resultados',
    label: `hotsite: ${
      parsedHotsite.hotsiteName
    }, filtros: ${parsedHotsite.fields.getAll('fields')}`,
  });
};

export type LoadItemListObject = {
  list: Product[] | SnkrsGridProduct[];
  type: SNEAKER_FLAG;
  avaiableFilters?: string[];
  pagination?: {
    current?: number;
    last?: number;
    pageMaxSize?: number;
    pageSize?: number;
  };
  columns?: number;
  pathname: string;
};

export const sendBreadcrumbInsider = (breadcrumbInfo: Breadcrumb[]) => {
  const breadcrumbValue = breadcrumbInfo.map((item) => item.value).join(' / ');
  trackingEvent({
    event: 'breadcrumb_insider',
    breadcrumbInfo,
    breadcrumbValue,
  });
};

export const sendLoadItemListEvent = ({
  list,
  type,
  avaiableFilters,
  pagination,
  columns,
  pathname,
}: LoadItemListObject) => {
  trackingEvent({ ecommerce: null, item_list: null });
  trackingEvent({
    event: 'load_item_list',
    event_type: 'item_load',
    ecommerce: {
      items: isEmpty(list)
        ? list
        : list.map(
            (
              {
                name,
                id,
                details: {
                  categories = [],
                  brand,
                  rate,
                  reviews,
                  modelColor,
                  styleCode,
                },
                oldPrice = 0,
                price = 0,
                installment,
              }: Product | SnkrsGridProduct,
              index,
            ) => ({
              item_name: name,
              item_id: getCleanStyleCode(styleCode),
              quantity: 1,
              item_category: categories ? categories[0] : undefined,
              from_price: oldPrice || price,
              price,
              item_type: type,
              item_brand: brand || undefined,
              item_list_name: getItemlistType(pathname),
              item_list_id: window.location.pathname,
              item_scooby_id: id || undefined,
              item_variant: modelColor,
              rating_value: Number(rate) || 0,
              rating_count: reviews || 0,
              index: index + 1,
              discount_percentage: 0,
              interest_free_installments_count: installment?.quantity || 0,
              interest_free_installments_value: installment?.price || 0,
              avaiable_tags: [],
              avaiable_colors: [],
              avaiable_sizes: [],
              avaiable_appeals: [],
            }),
          ),
    },
    item_list: isEmpty(list)
      ? {}
      : {
          type: getItemlistType(pathname),
          id: window.location.pathname,
          format: getFormat(columns, pagination?.pageMaxSize),
          items_cta: undefined,
          items_order: getItemsOrder(),
          avaiable_filters: avaiableFilters || [],
          selected_filters: getSelectedFilters(),
          algorithm: undefined,
          slot_index: 0,
          search_term: getSearchTerm(),
          pagination: {
            pages_count: pagination?.last || 1,
            current_page: pagination?.current || 1,
            page_max_size: pagination?.pageMaxSize || 0,
            page_size: pagination?.pageSize || 0,
            page_items: isEmpty(list) ? [] : list.map(({ id = '' }) => id),
          },
        },
  });
};

export type ViewItemListObject = {
  list: Product[] | SnkrsGridProduct[];
  type: SNEAKER_FLAG;
  avaiableFilters?: string[];
  pagination?: {
    current?: number;
    last?: number;
    pageMaxSize?: number;
    pageSize?: number;
  };
  columns?: number;
  pathname: string;
  search_id?: string;
};

export const sendViewItemListEvent = ({
  list,
  type,
  avaiableFilters,
  pagination,
  columns,
  pathname,
  search_id,
}: ViewItemListObject) => {
  const items = !isEmpty(list)
    ? list.map(
        (
          {
            name,
            id,
            details: {
              categories = [],
              brand,
              rate,
              reviews,
              modelColor,
              styleCode,
            },
            price = 0,
            oldPrice = 0,
            installment,
          }: Product | SnkrsGridProduct,
          index,
        ) => ({
          index: index,
          interest_free_installments_count: installment?.quantity || 0,
          interest_free_installments_value: installment?.price || 0,
          item_brand: brand || undefined,
          item_category: categories ? categories[0] : undefined,
          from_price: oldPrice || price,
          item_id: getCleanStyleCode(styleCode),
          item_scooby_id: id || undefined,
          item_list_name: getItemlistType(pathname),
          item_name: name,
          item_type: type,
          item_variant: modelColor,
          price,
          quantity: 1,
          rating_count: reviews || 0,
          rating_value: Number(rate) || 0,
        }),
      )
    : [];
  const itemList = !isEmpty(list)
    ? {
        type: getItemlistType(pathname),
        id: window.location.pathname,
        format: getFormat(columns, pagination?.pageMaxSize),
        items_cta: undefined,
        items_order: getItemsOrder(),
        avaiable_filters: avaiableFilters || [],
        selected_filters: getSelectedFilters(),
        algorithm: undefined,
        slot_index: 0,
        search_term: getSearchTerm(),
        pagination: {
          pages_count: pagination?.last || 1,
          current_page: pagination?.current || 1,
          page_max_size: pagination?.pageMaxSize || 0,
          page_size: pagination?.pageSize || 0,
          page_items: isEmpty(list) ? [] : list.map(({ id = '' }) => id),
        },
      }
    : [];

  trackingEvent({ ecommerce: null, item_list: null });
  trackingEvent({
    event: 'view_item_list',
    event_type: 'item_view',
    search_id: search_id,
    ecommerce: {
      item_list_id: getItemlistType(pathname),
      item_list_name: getItemlistType(pathname),
      items,
    },
    item_list: itemList,
  });
};

export type SnkrsProductObject = {
  id: string;
  name: string;
  price: number;
  rate: string;
  totalReview: number;
  categories: string[];
  modelColor: string;
  brand: string;
  details?: Details;
};

export type SelectItemObject = {
  product: Product | SnkrsProductObject;
  index: number;
  type: SNEAKER_FLAG;
  avaiableFilters?: Facet[];
  pagination?: {
    current?: number;
    last?: number;
    pageMaxSize?: number;
    pageSize?: number;
    pageItems?: Pick<Product, 'id'>[] | SnkrsProductObject[];
  };
  columns?: number;
  pathname: string;
  search_id?: string;
};

export const sendSelectItemEvent = ({
  product,
  index,
  type,
  avaiableFilters,
  pagination,
  columns,
  pathname,
  search_id,
}: SelectItemObject) => {
  const MAX_PRODUCTS_PER_PAGE = 40;
  const price = product?.price || 0;

  trackingEvent({ ecommerce: null, item_list: null });
  trackingEvent({
    event: 'select_item',
    event_type: 'click',
    search_id: search_id,
    ecommerce: {
      items: [
        {
          item_name: product?.name,
          item_id: getCleanStyleCode(product?.details?.styleCode),
          item_scooby_id: product?.id,
          quantity: 1,
          item_category:
            'categories' in product
              ? product?.categories[0]
              : product?.details?.categories[0],
          from_price:
            'oldPrice' in product ? product?.oldPrice : product?.price || price,
          price,
          item_type: type,
          item_brand:
            'brand' in product ? product?.brand : product?.details?.brand,
          item_list_name: getItemlistType(pathname),
          item_list_id: window.location.pathname,
          item_variant:
            'modelColor' in product
              ? product?.modelColor
              : product?.details?.modelColor,
          rating_value:
            'rate' in product
              ? Number(product?.rate)
              : Number(product?.details?.rate) || 0,
          rating_count:
            'totalReview' in product
              ? product?.totalReview
              : product?.details?.totalReview || 0,
          index,
          discount_percentage: 0,
          interest_free_installments_count: 0,
          interest_free_installments_value: 0,
        },
      ],
    },
    item_list: {
      type: getItemlistType(pathname),
      id: window.location.pathname,
      format: getFormat(columns, MAX_PRODUCTS_PER_PAGE),
      items_order: getItemsOrder(),
      avaiable_filters:
        (avaiableFilters &&
          avaiableFilters.map((item: Facet) => {
            return item.attribute;
          })) ||
        [],
      selected_filters: getSelectedFilters(),
      algorithm: undefined,
      slot_index: 0,
      search_term: getSearchTerm(),
      pagination: {
        pages_count: pagination?.last || 1,
        current_page: pagination?.current || 1,
        page_max_size: MAX_PRODUCTS_PER_PAGE,
        page_size: pagination?.pageSize || 0,
        page_items: pagination?.pageItems?.map(({ id }) => id) || [],
      },
    },
  });
};

export type SelectSearchProps = {
  selectedFilters?: { keys: string[]; values: string[] };
  isUserAction?: boolean;
  isHotsite?: boolean;
  searchedTerm?: string;
  algorithm?: string;
  sorting: string;
  page?: number;
  path: string;
  resultsCount?: number;
  search_id?: string;
};

export const sendSelectSearch = ({
  selectedFilters: selected_filters,
  isHotsite,
  searchedTerm,
  sorting,
  page = 1,
  path,
  resultsCount = 0,
  search_id = '',
  algorithm = 'scoreByRanking',
}: SelectSearchProps) => {
  const type = searchedTerm
    ? 'search_results'
    : isHotsite
    ? 'hotsite_results'
    : 'list_results';
  const term = searchedTerm ? searchedTerm : path.split('?')[0];

  trackingEvent({
    event: 'select_search',
    type,
    term,
    selected_filters,
    algorithm,
    sorting,
    page,
    results_count: resultsCount,
    search_id: search_id,
  });
};
