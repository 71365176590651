import { getCoreRoutes } from '@/common/config/routes';
import MenuAccountMobile from '@/common/patterns/Menu/MenuAccountMobile';
import {
  StyledDrawer,
  StyledDrawerItem,
  StyledLink,
} from './DrawerMobileHeader.styled';

const coreRoutes = getCoreRoutes();

const DrawerMobileHeader = () => {
  return (
    <StyledDrawer>
      <MenuAccountMobile />
      <StyledDrawerItem>
        <StyledLink
          href={coreRoutes.home}
          passHref
          type="default"
          text="Voltar para nike.com.br"
        />
      </StyledDrawerItem>
      <StyledDrawerItem>
        <StyledLink
          href={coreRoutes.attendance}
          passHref
          type="default"
          text="Ajuda"
        />
      </StyledDrawerItem>
    </StyledDrawer>
  );
};

export default DrawerMobileHeader;
