import { useRouter } from 'next/router';
import CartIconButton from '@/components/CartIconButton';
import { cartPath } from '@/helpers/path';
import GuestCartIconButton from '@/modules/guestCheckout/common/components/CartIconButton';
import { useCheckoutPlataformToggle } from '@/modules/guestCheckout/services/checkoutToggle/checkoutToggle';

const SidebarCart = () => {
  const { push: routerPush } = useRouter();

  const { isCheckoutPlatformActive } = useCheckoutPlataformToggle();

  const handleClick = () => {
    routerPush(cartPath);
  };

  return (
    <>
      {isCheckoutPlatformActive ? (
        <GuestCartIconButton onClick={handleClick} />
      ) : (
        <CartIconButton onClick={handleClick} />
      )}
    </>
  );
};

export default SidebarCart;
