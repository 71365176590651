import { Components, Events, TrackingErrors } from '@/common/types/tracking';
import { trackingEvent } from '@/common/utils/tracking/tracking';
import { CHECKOUT_PLATFORM_LABEL } from '../types';

export const sendErrorEvent = ({
  component,
  microComponent,
  originEvent,
  errorMessage,
  errorCode,
  extraDetail,
}: TrackingErrors) => {
  trackingEvent({
    event: 'throw_error',
    component,
    micro_component: microComponent || null,
    origin_event: originEvent,
    error_message: errorMessage,
    extra_detail: {
      errorCode: extraDetail?.errorCode,
      error_message_user: extraDetail?.errorMessageUser,
      error_message_system: extraDetail?.errorMessageSystem,
    },
    error_code: errorCode,
    label: CHECKOUT_PLATFORM_LABEL,
  });
};

type ZeroTokenEvent = {
  component: Components;
  originEvent: Events;
  isAuthenticated: boolean;
};

export const sendZeroTokenEvent = ({
  component,
  originEvent,
  isAuthenticated,
}: ZeroTokenEvent) => {
  sendErrorEvent({
    component,
    errorMessage: 'token 00000000-0000-0000-0000-000000000000',
    originEvent,
    errorCode: undefined,
    extraDetail: {
      errorMessageSystem: '',
      errorMessageUser: isAuthenticated ? 'authenticated' : 'not authenticated',
    },
  });
};
