import { getCoreRoutes } from '@/common/config/routes';

const SEARCH_TERM = '/busca?';
const PRODUCT = '.html';

const coreRoutes = getCoreRoutes();

const routes = {
  [coreRoutes.navigation]: 'nav',
  [coreRoutes.search]: 'search',
  [SEARCH_TERM]: 'search',
  [coreRoutes.staticContent]: 'sc',
  [coreRoutes.hotsite]: 'hotsite',
  [PRODUCT]: 'product',
  [coreRoutes.cart]: 'cart',
  [coreRoutes.myAccount]: 'account',
  [coreRoutes.order]: 'account',
  [coreRoutes.changeRegistrationData]: 'account',
  [coreRoutes.exchangeVoucherExtract]: 'account',
  [coreRoutes.login]: 'sign_in',
  [coreRoutes.signUp]: 'sign_up',
  [coreRoutes.payment]: 'payment_info',
  [coreRoutes.paymentConfirmation]: 'purchase',
  [coreRoutes.checkout]: 'begin_checkout',
  [coreRoutes.favorites]: 'favorites',
  [coreRoutes.writeReview]: 'write_review',
};

export const getUrlType = (url: string) => {
  if (url === coreRoutes.home) {
    return 'home';
  }

  const filteredList = Object.entries(routes)
    .filter(([key]) => url.includes(key))
    .flatMap((item) => item)
    .filter((item) => item);
  const filteredRoute = filteredList.length > 0 ? filteredList[1] : 'generic';
  return filteredRoute;
};
