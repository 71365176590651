import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { CONTAINER_DEFAULT_MAX_WIDTH } from '@/common/constants/constants';
import { StyledContainer, Wrapper } from './Container.styled';

interface ContainerProps extends ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode;
  maxWidth?: string;
  fullHeight?: boolean;
  fullWidth?: boolean;
  as?: React.ElementType;
  className?: string;
}

const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (
    {
      children,
      maxWidth = CONTAINER_DEFAULT_MAX_WIDTH,
      fullHeight = false,
      fullWidth = false,
      as = 'div',
      className,
    },
    ref,
  ) => (
    <StyledContainer
      fullHeight={fullHeight}
      as={as}
      className={className}
      ref={ref}
      data-testid="container"
    >
      <Wrapper maxWidth={maxWidth} className={className} fullWidth={fullWidth}>
        {children}
      </Wrapper>
    </StyledContainer>
  ),
);

Container.displayName = 'Container';

export default Container;
