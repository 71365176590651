import { sendEvent } from '@/helpers/analytics';

const UI_FOOTER_SOCIAL_MEDIA = 'UI - Footer - Social Media';
const UI_FOOTER_CONTRACTS = 'UI - Footer - Contratos';
const UI_FOOTER_NEWSLETTER = 'UI - Footer - Newsletter';

export const sendSocialMediaEvent = (socialMediaName: string) => {
  sendEvent({
    category: UI_FOOTER_SOCIAL_MEDIA,
    action: 'Click - Social Media',
    label: `Clicou para ir para a rede social ${socialMediaName}`,
  });
};

export const sendContractEvent = (contract: string) => {
  sendEvent({
    category: UI_FOOTER_CONTRACTS,
    action: `Click - ${contract}`,
    label: `Clicou para ir para ${contract}`,
  });
};

export const sendNewsLetterEvent = () => {
  sendEvent({
    category: UI_FOOTER_NEWSLETTER,
    action: `Click - Newsletter`,
    label: `Clicou em Cadastre-se para receber novidades`,
  });
};
