import { parseCookies } from 'nookies';
import { getCoreConfig } from '@/common/config/config';
import { getCorePaths } from '@/common/config/paths';

const sizebayTrackerBasePath =
  getCoreConfig().publicRuntimeConfig.sizebay.tracking;

const {
  sizebay: { trackAddToCart, trackCompleteOrder },
} = getCorePaths();

const SIZEBAY_SESSION_ID = parseCookies()['SIZEBAY_SESSION_ID_V4'];

/**
 * Returns the add to cart tracker url
 */
export const getTrackingAddToCartUrl = () => {
  if (SIZEBAY_SESSION_ID) {
    return `${sizebayTrackerBasePath}${trackAddToCart}?sid=${SIZEBAY_SESSION_ID}`;
  }
};

/**
 * Returns the complete order tracker url
 */
export const getTrackingCompleteOrdertUrl = () => {
  if (SIZEBAY_SESSION_ID) {
    return `${sizebayTrackerBasePath}${trackCompleteOrder}?sid=${SIZEBAY_SESSION_ID}`;
  }
};
