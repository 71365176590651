import { media } from '@sbf/fairplay-utils';
import styled, { css } from 'styled-components';

export const PaymentsWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spaces[500]};

    ${media.greaterThan('desktop')} {
      margin-top: ${theme.spaces[500]};
    }
  `}
`;

export const PaymentsGrid = styled.div`
  ${({ theme }) => css`
    width: 200rem;
    display: grid;
    grid-gap: ${theme.spaces[200]};
    grid-template-columns: repeat(auto-fit, 43rem);
    justify-content: start;
  `}
`;

// TODO: the gradient must be a token
export const PaymentMethod = styled.div`
  ${({ theme }) => css`
    width: 43rem;
    height: 32rem;
    background: linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
    border-radius: ${theme.borderRadius.small};
    display: grid;
    place-content: center;
  `}
`;
