import { useRouter } from 'next/router';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getCoreRoutes } from '@/common/config/routes';

export const LIST_TYPE_LIST = 'list';
export const LIST_TYPE_GRID = 'grid';

type ListLayout = {
  feed: ListTypeValue;
  stock: ListTypeValue;
  calendar: 'grid';
};

type SnkrsGridPages = 'feed' | 'stock' | 'calendar';

type ListTypeValue = typeof LIST_TYPE_LIST | typeof LIST_TYPE_GRID;

type SnkrsContextData = {
  listTypeStatus: boolean;
  enableListTypeStatus: () => void;
  disableListTypeStatus: () => void;
  getGridLayout: () => ListTypeValue;
  toggleGridLayout: () => void;
  page: SnkrsGridPages;
};

export type SnkrsContextProviderProps = {
  children: ReactNode;
  disableListType?: boolean;
};

const initialLayout: ListLayout = {
  feed: LIST_TYPE_GRID,
  stock: LIST_TYPE_LIST,
  calendar: LIST_TYPE_GRID,
};

const getPage = (pathname: string): SnkrsGridPages => {
  const { snkrsStock, snkrsCalendar } = getCoreRoutes();
  switch (pathname) {
    case snkrsStock:
      return 'stock';
    case snkrsCalendar:
      return 'calendar';
    default:
      return 'feed';
  }
};

export const SnkrsContext = createContext({} as SnkrsContextData);

export function SnkrsContextProvider({
  disableListType = false,
  children,
}: SnkrsContextProviderProps) {
  const { pathname } = useRouter();
  const [page, setPage] = useState(getPage(pathname));
  const [gridLayout, setGridLayout] = useState(initialLayout);
  const [listTypeStatus, setListTypeStatus] = useState(!disableListType);

  const enableListTypeStatus = () => setListTypeStatus(true);
  const disableListTypeStatus = () => setListTypeStatus(false);

  const toggleGridLayout = () => {
    const layout =
      gridLayout[page] === LIST_TYPE_LIST ? LIST_TYPE_GRID : LIST_TYPE_LIST;

    setGridLayout({ ...gridLayout, [page]: layout });
  };

  const getGridLayout = () => gridLayout[page];

  useEffect(() => {
    setPage(getPage(pathname));
  }, [pathname]);

  useEffect(() => {
    setListTypeStatus(!disableListType);
  }, [disableListType]);

  return (
    <SnkrsContext.Provider
      value={{
        listTypeStatus,
        enableListTypeStatus,
        disableListTypeStatus,
        getGridLayout,
        toggleGridLayout,
        page,
      }}
    >
      {children}
    </SnkrsContext.Provider>
  );
}

export const useSnkrsContext = () => {
  return useContext(SnkrsContext);
};
