import { isClientSide } from '../../environment/environment';

export const extractReferenceID = (errorMessage: string) => {
  if (!isClientSide()) {
    return '';
  }

  const referenceIdHTML = new DOMParser().parseFromString(
    errorMessage,
    'text/html',
  );
  const referenceId = referenceIdHTML.querySelector('p')?.textContent ?? '';

  return referenceId;
};
