/* eslint-disable import/no-unresolved */
import { createGlobalStyle } from 'styled-components';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
import swiperCSS from 'swiper/css';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
import swiperScrollbarCSS from 'swiper/css/scrollbar';

const GlobalStyle = createGlobalStyle`
  ${swiperCSS}
  ${swiperScrollbarCSS}

  html {
    --rem-base: 1;
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    //TO DO: Radix dialog issue. Remove this line when fixed by the DS team. https://github.com/radix-ui/primitives/issues/2122
    pointer-events: inherit !important;
  }

`;

export default GlobalStyle;
