import { AxiosResponse } from 'axios';
import { useSession } from 'next-auth/react';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { getCorePaths } from '@/common/config/paths';
import {
  clientCheckout,
  getSalesChannel,
} from '@/common/services/api/clientCheckout/clientCheckout';
import { type ClientError } from '@/common/services/api/types';
import { isProdEnv } from '@/common/utils/environment/environment';
import { trackingExceptionError } from '@/common/utils/tracking/tracking';
import { sendErrorEvent } from '@/modules/checkout/tracking/error/error';
import {
  getCreateCartErrorEventPayload,
  getCreateEmptyCartErrorEventPayload,
  getIdentificationErrorEventPayload,
  getRevalidateErrorEventPayload,
  getUpdateCartErrorEventPayload,
  getUpdateCheckoutErrorEventPayload,
} from '@/modules/checkout/tracking/error/transformers';
import { useCartContext } from '@/modules/guestCheckout/context/CartContext';
import { getCacheQuery } from '@/modules/guestCheckout/services/cart/cart.type';
import { useCheckoutPlataformToggle } from '@/modules/guestCheckout/services/checkoutToggle/checkoutToggle';
import {
  CART_NOT_FOUND_ERROR_CODE,
  handleResponse,
} from '@/modules/guestCheckout/transformers/cartError';
import {
  CartDataRequestApi,
  CartDataResponseApi,
} from '@/modules/guestCheckout/types/cart';
import {
  CheckoutClientError,
  CheckoutErrorResponse,
} from '@/modules/guestCheckout/types/checkoutErrorResponse';

const { guestCheckout } = getCorePaths();

enum COOKIES {
  CART_UUID = 'cartUuid',
  DEVICE_ID = 'deviceId',
}

export const deleteCartUuid = () => {
  destroyCookie(null, COOKIES.CART_UUID, {
    path: '/',
    secure: isProdEnv,
  });
};

const setCartUuid = (cartUuid: string) => {
  if (cartUuid) {
    setCookie(null, COOKIES.CART_UUID, cartUuid, {
      path: '/',
      secure: isProdEnv,
    });
  }
  return cartUuid;
};

const setDeviceId = (deviceId: string) => {
  if (deviceId) {
    setCookie(null, COOKIES.DEVICE_ID, deviceId, {
      path: '/',
      secure: isProdEnv,
    });
  }
  return deviceId;
};

const getConfigData = (data: CartDataRequestApi) => {
  const deviceId = getDeviceId();
  const uuid = getCartUUid();

  return {
    ...data,
    ...(!deviceId ? { deviceId: setDeviceId(uuidv4()) } : { deviceId }),
    ...(uuid ? { uuid } : {}),
  };
};

export const getCartUUid = () => parseCookies()[COOKIES.CART_UUID];
export const getDeviceId = () => parseCookies()[COOKIES.DEVICE_ID];

const setUuids = (
  response: AxiosResponse<CartDataResponseApi, unknown>,
  isAuthenticated?: boolean,
) => {
  if (response.data.deviceId && !getDeviceId()) {
    setDeviceId(response.data.deviceId);
  }

  // This condition is necessary to avoid setting the cartUuid in the cookie when the user is authenticated
  // The cartUuid its just necessary for guest users
  if (response.data.uuid !== getCartUUid() && !isAuthenticated) {
    setCartUuid(response.data.uuid);
  }
};

export const createCart = async (
  data: CartDataRequestApi,
  isAuthenticated?: boolean,
) => {
  try {
    const transformerData = getConfigData(data);
    // Before to create we ahve to sure that the cartUuid is deleted
    if (isAuthenticated) {
      deleteCartUuid();
    }

    const response = await clientCheckout.patch<CartDataResponseApi>(
      guestCheckout.cart,
      {
        ...transformerData,
        businessUnit: 'nike',
        // TODO: we have to identify the device element
        salesChannelId: getSalesChannel(),
        // If you want to test the sandbox, just uncomment this line below
        // sandbox: true,
      },
    );

    setUuids(response, isAuthenticated);

    return response;
  } catch (e) {
    throw handleResponse(e);
  }
};

export const cart = async (cartUuid: string) => {
  try {
    const response = await clientCheckout.get<CartDataResponseApi>(
      `${guestCheckout.cart}/${cartUuid}`,
      {
        params: {
          noAuth: true,
        },
      },
    );
    return response;
  } catch (e) {
    throw handleResponse(e);
  }
};

export const cartAuth = async () => {
  try {
    const response = await clientCheckout.get<CartDataResponseApi>(
      `${guestCheckout.cart}`,
    );

    return response;
  } catch (e) {
    if (e.response?.data?.code === CART_NOT_FOUND_ERROR_CODE) {
      try {
        await createCart({});
      } catch (e) {
        if (e?.response?.data) {
          sendErrorEvent(getCreateEmptyCartErrorEventPayload(e));
        }
      }
    }
    throw handleResponse(e);
  }
};

export const updateCart = async (
  data: Partial<CartDataRequestApi>,
  isAuthenticated?: boolean,
) => {
  try {
    const response = await clientCheckout.patch<CartDataResponseApi>(
      guestCheckout.cart,
      {
        ...data,
        uuid: getCartUUid(),
      },
    );

    setUuids(response, isAuthenticated);

    return response;
  } catch (e) {
    throw handleResponse(e);
  }
};

export const useAddToCartMutation = () => {
  const queryClient = useQueryClient();
  const { status } = useSession();

  const isAuthenticated = status === 'authenticated';
  const cacheQuery = getCacheQuery(isAuthenticated);
  const { isCheckoutPlatformActive } = useCheckoutPlataformToggle();
  return useMutation<
    Partial<CartDataResponseApi>,
    ClientError<CheckoutClientError>,
    CartDataRequestApi
  >(
    async (data: CartDataRequestApi) => {
      const response = await createCart(data, isAuthenticated);

      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          [
            cacheQuery,
            isAuthenticated,
            getCartUUid(),
            isCheckoutPlatformActive,
          ],
          data,
        );
      },
      onError: (e) => {
        queryClient.refetchQueries({
          queryKey: [
            cacheQuery,
            isAuthenticated,
            getCartUUid(),
            isCheckoutPlatformActive,
          ],
        });
        sendErrorEvent(getCreateCartErrorEventPayload(e));
      },
    },
  );
};

export const useUpdateCartMutation = (
  page: 'identification' | 'cart' | 'checkout',
) => {
  const queryClient = useQueryClient();
  const { status } = useSession();
  const cartUuid = parseCookies()[COOKIES.CART_UUID];
  const { cleanErrorCartData } = useCartContext();

  const isAuthenticated = status === 'authenticated';
  const cacheQuery = getCacheQuery(isAuthenticated);
  const { isCheckoutPlatformActive } = useCheckoutPlataformToggle();
  return useMutation<
    CartDataResponseApi,
    ClientError<CheckoutClientError>,
    Partial<CartDataRequestApi>
  >(
    [queryClient],
    async (data: Partial<CartDataRequestApi>) => {
      const response = await updateCart(data, isAuthenticated);

      return response.data;
    },
    {
      onSuccess: (data) => {
        // If the cart is empty, we have to clean the error data
        // So the user can see the error message again
        // This will assure that the cart is empty and without errors
        if (data?.items?.length === 0 || !data?.items) {
          cleanErrorCartData();
        }

        queryClient.setQueryData(
          [
            cacheQuery,
            isAuthenticated,
            getCartUUid(),
            isCheckoutPlatformActive,
          ],
          data,
        );
      },
      onError: (e) => {
        queryClient.refetchQueries({
          queryKey: [
            cacheQuery,
            isAuthenticated,
            cartUuid,
            isCheckoutPlatformActive,
          ],
        });

        if (page === 'cart') {
          sendErrorEvent(getUpdateCartErrorEventPayload(e));
        }
        if (page === 'identification') {
          sendErrorEvent(getIdentificationErrorEventPayload(e));
        }
        if (page === 'checkout') {
          sendErrorEvent(getUpdateCheckoutErrorEventPayload(e));
        }
      },
    },
  );
};

export const useRevalidateCartMutation = () => {
  const queryClient = useQueryClient();
  const { status } = useSession();
  const cartUuid = parseCookies()[COOKIES.CART_UUID];

  const { cleanErrorCartData, setErrorCartData } = useCartContext();
  const isAuthenticated = status === 'authenticated';
  const { isCheckoutPlatformActive } = useCheckoutPlataformToggle();
  const cacheQuery = getCacheQuery(isAuthenticated);

  return useMutation<
    CartDataResponseApi,
    ClientError<CheckoutClientError>,
    Partial<CartDataRequestApi>
  >(
    [queryClient],
    async (data: Partial<CartDataRequestApi>) => {
      const response = await createCart(data, isAuthenticated);

      return response.data;
    },
    {
      onSuccess: (data) => {
        cleanErrorCartData();
        queryClient.setQueryData(
          [
            cacheQuery,
            isAuthenticated,
            getCartUUid(),
            isCheckoutPlatformActive,
          ],
          data,
        );
      },
      onError: (e) => {
        if (e?.response?.data?.detail) {
          setErrorCartData(e.response.data.detail);
        }
        sendErrorEvent(getRevalidateErrorEventPayload(e));
        queryClient.refetchQueries({
          queryKey: [
            cacheQuery,
            isAuthenticated,
            cartUuid,
            isCheckoutPlatformActive,
          ],
        });

        trackingExceptionError({
          error: e,
          flow: 'request-errors',
          checkpoint: 'revalidate-cart',
          namespace: 'guest-checkout()',
        });
      },
    },
  );
};

export const useCart = () => {
  const cartUuid = parseCookies()[COOKIES.CART_UUID];
  const { status } = useSession();
  const isAuthenticated = status === 'authenticated';
  const { isCheckoutPlatformActive } = useCheckoutPlataformToggle();

  const cacheQuery = getCacheQuery(isAuthenticated);
  return useQuery<
    CartDataResponseApi | undefined,
    ClientError<CheckoutErrorResponse>
  >(
    [cacheQuery, isAuthenticated, cartUuid, isCheckoutPlatformActive],
    async () => {
      if (!isCheckoutPlatformActive) {
        return;
      }
      const response = isAuthenticated
        ? await cartAuth()
        : await cart(cartUuid);
      return response.data;
    },
    {
      enabled:
        Boolean(isCheckoutPlatformActive) &&
        Boolean(isAuthenticated || (cartUuid && !isAuthenticated)),
      refetchOnMount: false,
      // THIS WILL REFETCH THE QUERY WHEN THE WINDOW IS FOCUSED
      refetchOnWindowFocus: true,
    },
  );
};
