import axios from 'axios';
import * as AxiosLogger from 'axios-logger';
import { getCoreConfig } from '@/common/config/config';
import { handlePreconditionRequiredError } from '@/common/services/api/client/errors/errors';
import { createHttpAgentConfig } from '@/common/services/api/client/httpAgent/httpAgent';
import {
  isProdReleaseEnv,
  isServerSide,
} from '@/common/utils/environment/environment';
import errLogObject from '@/logging/log-format';
import { getLogger } from '@/logging/log-utils';
import { getClientHeaders } from '../client/headers/headers';
import { type ClientError } from '../types';
const { publicRuntimeConfig, serverRuntimeConfig } = getCoreConfig();

const bffAgentConfig = {
  timeout: 20000,
};

const httpAgentConfig = createHttpAgentConfig(bffAgentConfig);

export const baseURL = isServerSide()
  ? serverRuntimeConfig.kubeInternal.bff
  : publicRuntimeConfig.publicApi.bff;

export const clientBFF = axios.create({
  baseURL,
  ...httpAgentConfig,
});

if (isProdReleaseEnv) {
  clientBFF.defaults.withCredentials = true;
}

clientBFF.interceptors.request.use(async function (config) {
  if (isServerSide()) {
    return config;
  }
  const coreHeaders = await getClientHeaders();
  const headers = {
    ...config.headers,
    ...coreHeaders,
  };
  config.headers = headers;
  return config;
});

clientBFF.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: ClientError) {
    handlePreconditionRequiredError(error);
    if (isServerSide()) {
      const errorLogger = getLogger('*');
      errorLogger.error(errLogObject(error));
    }

    return AxiosLogger.errorLogger(error);
  },
);
