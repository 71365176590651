import { isServerSide } from '@/common/utils/environment/environment';

type EventGtag = {
  action: string;
  category: string;
  label: string;
  value?: number;
  nonInteraction?: boolean;
};

export const sendEventGtag = ({
  action,
  category,
  label,
  value = 0,
  nonInteraction = false,
}: EventGtag): void => {
  if (isServerSide() || !window.gtag) {
    return;
  }

  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
    non_interaction: nonInteraction,
  });
};
