import { Button } from '@sbf/fairplay-core';
import { NikeFeed, NikeGrid } from '@sbf/fairplay-icons';
import { useSnkrsContext } from '@/domains/snkrs/common/context/SnkrsContext/SnkrsContext';
import { StyledBox } from './GridButton.styled';

const GridButton = () => {
  const { getGridLayout, toggleGridLayout, listTypeStatus } = useSnkrsContext();

  return (
    <StyledBox border={listTypeStatus} data-testid="icon-grid">
      {listTypeStatus && (
        <Button.Icon
          onClick={() => toggleGridLayout()}
          shape="square"
          icon={getGridLayout() === 'list' ? <NikeFeed /> : <NikeGrid />}
          /*TODO: definir texto para leitor de tela*/
          title="Grid layout"
        />
      )}
    </StyledBox>
  );
};

export default GridButton;
