import { media } from '@sbf/fairplay-utils';
import styled, { css } from 'styled-components';

type ContentProps = {
  border: boolean;
};

export const StyledBox = styled.div<ContentProps>`
  ${({ theme, border }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: auto;

    ${media.lessThan('desktop')} {
      border-top: ${border
        ? `${theme.borderWidth.hairline} solid
        ${theme.colors.neutral[300]}`
        : 'none'};
    }

    ${media.greaterThan('desktop')} {
      justify-content: flex-end;
      border: ${theme.borderWidth.none};

      &:last-child {
        gap: ${theme.spaces[500]};
      }
    }
  `}
`;
