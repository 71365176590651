import { Link, Typography } from '@sbf/fairplay-nike';
import { media } from '@sbf/fairplay-utils';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: fixed;
    bottom: ${theme.spaces[300]};
    left: 50%;
    transform: translateX(-50%);
    z-index: ${theme.zIndex[500]};
    background: ${theme.colors.neutral[100]};
    display: flex;
    width: calc(100% - ${theme.spaces[500]});
    max-width: 1024rem;
    padding: ${theme.spaces[300]};
    box-sizing: border-box;
    box-shadow: ${theme.shadow.medium};
    border-radius: ${theme.borderRadius.large};
    align-items: center;
    justify-content: space-between;
  `}
`;

export const TextContainer = styled.div`
  ${({ theme }) => css`
    padding-right: ${theme.spaces[400]};
  `}
`;

export const Title = styled(Typography).attrs({
  variant: 'heading',
  size: 'h4',
})`
  ${({ theme }) => css`
    margin-bottom: ${theme.spaces[200]};
  `}
`;

export const MessageMobile = styled(Typography).attrs({
  variant: 'paragraph',
  size: 'tiny',
})`
  display: inline;
`;

export const MessageDesk = styled(Typography).attrs({
  variant: 'paragraph',
  size: 'small',
})`
  display: inline;
`;

export const Anchor = styled(Link)`
  ${({ theme }) => css`
    display: inline;
    font-size: ${theme.fontSizes.tiny};

    ${media.greaterThan('desktop')} {
      font-size: ${theme.fontSizes.small};
    }
  `}
`;
