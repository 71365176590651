import {
  Filter,
  NikeMenuHamburguer as HamburgerMenu,
} from '@sbf/fairplay-icons';
import { Button, Drawer, SearchBar } from '@sbf/fairplay-nike';
import { useRouter } from 'next/router';
import { ChangeEvent, KeyboardEvent, useState } from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';
import {
  DesktopView,
  MobileView,
} from '@/common/components/Breakpoints/Breakpoints';
import Logo from '@/common/components/Logo/Logo';
import { getCoreRoutes } from '@/common/config/routes';
import DrawerContentMobile from '@/common/patterns/Header/components/DrawerContentMobile/DrawerContentMobile';
import HeaderButtonIcon from '@/common/patterns/Header/components/HeaderButtonIcon/HeaderButtonIcon';
import Menu from '@/common/patterns/Menu/Menu';
import { MenuContextProvider } from '@/common/patterns/Menu/menuContext';
import { isKey } from '@/common/utils/dom/keyboard/keyboard';
import isEmpty from '@/common/utils/funcs/isEmpty/isEmpty';
import SidebarCart from '@/components/SidebarCart';
import { useTrackingContext } from '@/contexts/TrackingContext';
import { useWebsiteUIContext } from '@/contexts/WebsiteUIContext';
import DrawerMobileHeader from '@/domains/snkrs/common/patterns/Header/DrawerMobileHeader/DrawerMobileHeader';
import GridButton from '@/domains/snkrs/common/patterns/Header/GridButton/GridButton';
import MenuSnkrs from '@/domains/snkrs/common/patterns/Header/Menu/Menu';
import {
  SelectContentObject,
  sendSelectContentEvents,
} from '@/helpers/analytics';
import { sendEventDoSearch } from '@/modules/grid/screens/Grid/trackingEvents';
import {
  BoxSearchMobile,
  HeaderLogoWrapper,
  MobileTopContent,
  RightContent,
  SearchBarContainer,
  MainHeader as StyledMainHeader,
} from '../../Header.styled';
import { HeaderLayoutProps } from '../../Header.types';
import CloseButton from '../CloseButton/CloseButton';
import FavoriteButton from '../FavoriteButton/FavoriteButton';

const coreRoutes = getCoreRoutes();

const MenuWithHydrationOnDemand = withHydrationOnDemand({
  on: [
    ['mouseenter', () => document],
    ['keydown', () => document],
    ['touchstart', () => document],
  ],
})(Menu);

const MainHeader = ({ layout, searchFilters, labels }: HeaderLayoutProps) => {
  const { setOpen, setSearchValue, searchValue } = useWebsiteUIContext();
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const { handleEvents } = useTrackingContext();
  const router = useRouter();
  const isHome = router.pathname === '/';
  const isSnkrsHome = router.pathname === '/snkrs';

  const isSnkrsLayout = layout === 'snkrs';
  const isAssistedSaleLayout = layout === 'assistedSale';
  const isDefaultLayout = layout === 'default' || isAssistedSaleLayout;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    const isKeyEnter = isKey('ENTER', event);
    if (isKeyEnter) {
      event.preventDefault();
      event.currentTarget?.blur();
    }
  };

  const handleSubmit = () => {
    if (isEmpty(searchValue)) {
      return;
    }

    sendEventDoSearch(searchValue);
    router.push(`/busca?term=${searchValue}`);
  };

  const openDrawer = () => setShowDrawer(true);
  const closeDrawer = () => setShowDrawer(false);

  return (
    <>
      <MobileView>
        <StyledMainHeader>
          <MobileTopContent layout={isDefaultLayout ? 'default' : 'snkrs'}>
            {isDefaultLayout && (
              <HeaderLogoWrapper as={isHome ? 'h1' : 'span'}>
                <Logo
                  src="/images/brands/logo.svg"
                  href={coreRoutes.home}
                  width="39rem"
                  height="14rem"
                  alt="Nike"
                  title="Ir para a página principal"
                  imgTitle="Nike"
                  onClick={() => {
                    handleEvents<SelectContentObject>(sendSelectContentEvents, {
                      component: 'header',
                      microComponent: 'top_bar',
                      anchorText: 'logo Nike',
                      title: 'Ir para a página principal',
                      url: 'https://www.nike.com.br/',
                      contentType: 'navigation_link',
                    });
                  }}
                />
              </HeaderLogoWrapper>
            )}

            {isSnkrsLayout && (
              <HeaderLogoWrapper as={isSnkrsHome ? 'h1' : 'span'}>
                <Logo
                  href={coreRoutes.snkrs}
                  title="Ir para a página principal sneakers"
                  src="/images/brands/snkrs-red.svg"
                  alt="Sneakers"
                  width="34.27rem"
                  height="22.78rem"
                  onClick={() => {
                    handleEvents<SelectContentObject>(sendSelectContentEvents, {
                      component: 'header',
                      microComponent: 'top_bar',
                      anchorText: 'logo sneakers',
                      title: 'Ir para a página principal sneakers',
                      url: 'https://www.nike.com.br/snkrs',
                      contentType: 'navigation_link',
                    });
                  }}
                />
              </HeaderLogoWrapper>
            )}
            <RightContent>
              <FavoriteButton />
              <SidebarCart />
              <HeaderButtonIcon
                data-testid={'menu-button'}
                icon={<HamburgerMenu />}
                title="Abrir menu"
                onClick={openDrawer}
              />
            </RightContent>
          </MobileTopContent>
          {isDefaultLayout && (
            <BoxSearchMobile>
              <SearchBar
                onSubmit={handleSubmit}
                value={searchValue}
                onChange={handleChange}
                onKeyUp={handleKeyUp}
              />
              {searchFilters && (
                <Button.Icon
                  title="abrir filtros"
                  shape="rounded"
                  icon={<Filter />}
                  onClick={() => setOpen('searchFilters')}
                />
              )}
            </BoxSearchMobile>
          )}
          {isSnkrsLayout && (
            <>
              <MenuSnkrs />
              <GridButton />
            </>
          )}
          <Drawer anchor="right" isOpen={showDrawer} onClose={closeDrawer}>
            <MenuContextProvider labels={labels}>
              <CloseButton onClick={closeDrawer} />
              <DrawerContentMobile>
                {isDefaultLayout && (
                  <MenuWithHydrationOnDemand onClick={closeDrawer} />
                )}
                {isSnkrsLayout && <DrawerMobileHeader />}
              </DrawerContentMobile>
            </MenuContextProvider>
          </Drawer>
        </StyledMainHeader>
      </MobileView>
      <DesktopView shouldRenderOnServer>
        <StyledMainHeader>
          {isDefaultLayout && (
            <HeaderLogoWrapper as={isHome ? 'h1' : 'span'}>
              <Logo
                src="/images/brands/logo.svg"
                href={coreRoutes.home}
                width="64rem"
                height="23rem"
                alt="Nike"
                title="Ir para a página principal"
                imgTitle="Nike"
                onClick={() => {
                  handleEvents<SelectContentObject>(sendSelectContentEvents, {
                    component: 'header',
                    microComponent: 'top_bar',
                    anchorText: 'logo Nike',
                    title: 'Ir para a página principal',
                    url: 'https://www.nike.com.br/',
                    contentType: 'navigation_link',
                  });
                }}
              />
            </HeaderLogoWrapper>
          )}

          {isSnkrsLayout && (
            <HeaderLogoWrapper as={isSnkrsHome ? 'h1' : 'span'}>
              <Logo
                href={coreRoutes.snkrs}
                title="Ir para a página principal sneakers"
                src="/images/brands/snkrs-red.svg"
                alt="Sneakers"
                width="57.11rem"
                height="37.96rem"
                onClick={() => {
                  handleEvents<SelectContentObject>(sendSelectContentEvents, {
                    component: 'header',
                    microComponent: 'top_bar',
                    anchorText: 'logo sneakers',
                    title: 'Ir para a página principal sneakers',
                    url: 'https://www.nike.com.br/snkrs',
                    contentType: 'navigation_link',
                  });
                }}
              />
            </HeaderLogoWrapper>
          )}
          <MenuContextProvider labels={labels}>
            {isDefaultLayout && <MenuWithHydrationOnDemand />}{' '}
            {isSnkrsLayout && <MenuSnkrs />}
          </MenuContextProvider>
          <RightContent>
            {isDefaultLayout && (
              <SearchBarContainer>
                <SearchBar
                  onSubmit={handleSubmit}
                  value={searchValue}
                  onChange={handleChange}
                  onKeyUp={handleKeyUp}
                />
              </SearchBarContainer>
            )}
            {isSnkrsLayout && <GridButton />}
            <FavoriteButton />
            <SidebarCart />
          </RightContent>
        </StyledMainHeader>
      </DesktopView>
    </>
  );
};

export default MainHeader;
