import { NikeBox } from '@sbf/fairplay-icons';
import { getCoreRoutes } from '@/common/config/routes';
import useUnleash from '@/common/hooks/useUnleash/useUnleash';
import { useTrackingContext } from '@/contexts/TrackingContext';
import {
  SelectContentObject,
  sendSelectContentEvents,
} from '@/helpers/analytics';
import { checkGuestCheckoutFlag } from '@/modules/guestCheckout/utils/guestCheckoutEnabled';
import {
  SecondaryItem,
  SecondaryItemLink,
  SecondaryListItems,
  SecondaryMenuContainer,
} from './Menu.styled';

interface SecondaryMenuProps {
  onClick?: () => void;
}

const MenuSecondaryMobile: React.FC<SecondaryMenuProps> = ({ onClick }) => {
  const { handleEvents, cleanSearchIdList } = useTrackingContext();
  const coreRoutes = getCoreRoutes();
  const isOrderDetailsGuestCheckoutActive = useUnleash(
    'nike-front.order-details-guest-checkout',
  );
  const isGuestCheckoutReady = checkGuestCheckoutFlag();
  return (
    <SecondaryMenuContainer role="menuitem">
      <SecondaryListItems>
        {isOrderDetailsGuestCheckoutActive && isGuestCheckoutReady && (
          <SecondaryItem>
            <SecondaryItemLink
              href={coreRoutes.trackOrder}
              passHref
              onClick={() => {
                onClick?.();
                cleanSearchIdList();
                handleEvents<SelectContentObject>(sendSelectContentEvents, {
                  component: 'header',
                  microComponent: 'main_header_content',
                  anchorText: 'acompanhe seu pedido',
                  title: '',
                  url: coreRoutes.trackOrder,
                  contentType: 'navigation_link',
                });
              }}
            >
              <NikeBox />
              <p>Acompanhe seu pedido</p>
            </SecondaryItemLink>
          </SecondaryItem>
        )}
      </SecondaryListItems>
    </SecondaryMenuContainer>
  );
};

export default MenuSecondaryMobile;
