import {
  LayoutContextProvider,
  SnackBar,
  ThemeProvider,
} from '@sbf/fairplay-nike';
import { captureException, withScope } from '@sentry/nextjs';
import type { NextPageWithLayout } from 'next';
import { SessionProvider } from 'next-auth/react';
import type {
  AppContext,
  AppInitialProps,
  AppProps as NextAppProps,
} from 'next/app';
import App from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { ReactNode, useState } from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { DehydratedState, Hydrate } from 'react-query/hydration';
import AppSeo from '@/common/components/AppSeo/AppSeo';
import {
  MediaContextProvider,
  mediaStyles,
} from '@/common/components/Breakpoints/Breakpoints';
import GlobalStyle from '@/common/components/GlobalStyle/GlobalStyle';
import GtmScripts from '@/common/components/GtmScripts/GtmScripts';
import Preconnect from '@/common/components/Preconnect/Preconnect';
import OnePixelScripts from '@/common/components/Security/ThirdParty/OnePixel/OnePixel';
import { getCoreConfig } from '@/common/config/config';
import { experimentConfig, testOptions } from '@/common/config/experiments';
import { getSeoConfig } from '@/common/config/seo';
import MenuDataProvider from '@/common/patterns/Menu/provider/MenuDataProvider';
import { getMenuData } from '@/common/patterns/Menu/services/menuService';
import { Menu } from '@/common/patterns/Menu/types';
import { getPitchBarData } from '@/common/patterns/PitchBar/PitchBar.service';
import { PitchBarContent } from '@/common/patterns/PitchBar/PitchBar.types';
import { UnleashProvider } from '@/common/providers/UnleashProvider';
import { retryRequestByConditionalQueries } from '@/common/services/api/client/retry/retry';
import { composer } from '@/common/utils/composer';
import { isProdEnv } from '@/common/utils/environment/environment';
import {
  getExperimentCookie,
  getExperimentEnv,
} from '@/common/utils/experiment/experiment';
import { RouteLoader } from '@/components/Loader';
import { TrackingContextProvider } from '@/contexts/TrackingContext';
import { WebsiteUIContextProvider } from '@/contexts/WebsiteUIContext';
import MainLayout from '@/layout/MainLayout/MainLayout';
import CheckoutProvider from '@/modules/checkout/components/CheckoutProvider/CheckoutProvider';
import { GridContextProvider } from '@/modules/grid/context/GridContext/GridContext';
import { CheckoutGuestContext } from '@/modules/guestCheckout/context/CartContext';
import BannerCookie from '@/modules/myAccount/components/Registration/BannerCookie/BannerCookie';
import RegistrationModal from '@/modules/myAccount/components/Registration/RegistrationModal/RegistrationModal';
import { useInsider } from '@/modules/thirdparty/recommendation/Insider/hooks/useInsider/useInsider';
import PageView from '@/modules/tracking/PageView';

const SnackBarWithHydrationOnDemand = withHydrationOnDemand({
  on: [
    ['mouseenter', () => document],
    ['keydown', () => document],
    ['touchstart', () => document],
    ['scroll', () => document],
  ],
})(SnackBar) as React.FC;

const BannerCookieWithHydrationOnDemand = withHydrationOnDemand({
  on: [
    ['mouseenter', () => document],
    ['keydown', () => document],
    ['touchstart', () => document],
    ['scroll', () => document],
  ],
})(BannerCookie) as React.FC;

const MParticle = dynamic(() => import('@/modules/tracking/MParticle'));

const ExperimentProvider = dynamic(() =>
  import('@grupo-sbf/experiment-react').then(
    ({ ExperimentProvider }) => ExperimentProvider,
  ),
);

const preconnectUrls = [
  '//static.nike.com.br',
  '//www.googletagmanager.com',
  '//www.google-analytics.com',
  '//connect.facebook.net',
  '//imgnike-a.akamaihd.net',
];

type MyAppPropsWithLayout = NextAppProps & {
  dehydratedState: DehydratedState;
  Component: NextPageWithLayout;
  menuData: Menu;
  pitchBarData: PitchBarContent;
};

type ComponentWithChildren = (props: { children: ReactNode }) => JSX.Element;

const ThemeProviderWithFontLoader: ComponentWithChildren = ({ children }) => (
  <ThemeProvider useFontLoader={false}>{children}</ThemeProvider>
);

const AppProvider = composer(
  ThemeProviderWithFontLoader,
  MediaContextProvider,
  LayoutContextProvider,
  CheckoutProvider,
  WebsiteUIContextProvider,
  UnleashProvider,
  TrackingContextProvider,
  GridContextProvider,
  CheckoutGuestContext,
);

const {
  publicRuntimeConfig: { gaID, gtmID },
} = getCoreConfig();

export default function MyApp({
  Component,
  pageProps,
  menuData,
  pitchBarData,
}: MyAppPropsWithLayout) {
  const { query, asPath } = useRouter();
  const isWebview = query?.source === 'app';
  useInsider(asPath);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        mutationCache: new MutationCache({
          onError: (err, _variables, _context, mutation) => {
            withScope((scope) => {
              scope.setContext('mutation', {
                mutationId: mutation.mutationId,
                variables: mutation.state.variables,
              });
              if (mutation.options.mutationKey) {
                scope.setFingerprint(
                  // Duplicate to prevent modification
                  Array.from(mutation.options.mutationKey) as string[],
                );
              }
              captureException(err);
            });
          },
        }),
        queryCache: new QueryCache({
          onError: (err, query) => {
            withScope((scope) => {
              scope.setContext('query', { queryHash: query.queryHash });
              scope.setFingerprint([query.queryHash.replaceAll(/[0-9]/g, '0')]);
              captureException(err);
            });
          },
        }),
        defaultOptions: {
          mutations: {
            ...retryRequestByConditionalQueries(),
          },
          queries: {
            refetchOnWindowFocus: false,
            ...retryRequestByConditionalQueries(),
          },
        },
      }),
  );

  const mainLayoutProps = Component.mainLayoutProps;

  const getLayout =
    Component.getLayout ||
    ((page) => (
      <MainLayout
        {...mainLayoutProps}
        preview={pageProps?.preview}
        pitchBarData={pitchBarData}
      >
        {page}
      </MainLayout>
    ));

  const componentLayout = getLayout(<Component {...pageProps} />);

  const { seoData } = getSeoConfig();

  const experimentUserId = getExperimentCookie();
  const experimentEnv = getExperimentEnv();

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, maximum-scale=1, minimum-scale=1, initial-scale=1, user-scalable=no, shrink-to-fit=no"
        />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{ __html: mediaStyles }}
        />
        <Preconnect urlsList={preconnectUrls} />
      </Head>
      <Script
        async
        src="https://nikebr.api.useinsider.com/ins.js?id=10008305"
      />
      <SessionProvider session={pageProps.session}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <ExperimentProvider
              env={experimentEnv}
              userId={experimentUserId}
              config={experimentConfig}
              testOptions={testOptions}
            >
              <AppProvider>
                <MenuDataProvider menuData={menuData}>
                  <MParticle />
                  <PageView />
                  <OnePixelScripts />
                  {isProdEnv && <GtmScripts gaID={gaID} gtmID={gtmID} />}
                  {/* https://techdocs.akamai.com/bot-manager/docs/set-up-inline-telemetry */}
                  <input type="hidden" name="bm-telemetry" />
                  <AppSeo seoData={seoData} />
                  <GlobalStyle />
                  <SnackBarWithHydrationOnDemand />
                  {!isWebview && <RegistrationModal />}
                  {componentLayout}
                  {!isWebview && <BannerCookieWithHydrationOnDemand />}
                  <RouteLoader />
                </MenuDataProvider>
              </AppProvider>
            </ExperimentProvider>
          </Hydrate>
        </QueryClientProvider>
      </SessionProvider>
    </>
  );
}

type MenuData = { menuData: Menu };
type PitchBarData = { pitchBarData: PitchBarContent };

MyApp.getInitialProps = async (
  context: AppContext,
): Promise<PitchBarData & MenuData & AppInitialProps> => {
  const ctx = await App.getInitialProps(context);
  const [menuData, pitchBarData] = await Promise.all([
    getMenuData(),
    getPitchBarData(),
  ]);

  return { ...ctx, menuData, pitchBarData };
};
