import { TrackingErrors } from '@/common/types/tracking';
import { trackingEvent } from '@/common/utils/tracking/tracking';

export const sendErrorsTrackingEvent = ({
  component,
  microComponent,
  originEvent,
  errorMessage,
  errorCode,
  extraDetail,
}: TrackingErrors) => {
  trackingEvent({
    event: 'throw_error',
    component: component,
    micro_component: microComponent || null,
    origin_event: originEvent,
    error_message: errorMessage,
    extra_detail: {
      error_message_user: extraDetail?.errorMessageUser,
      error_message_system: extraDetail?.errorMessageSystem,
    },
    error_code: errorCode,
  });
};
