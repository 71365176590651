import { PitchBarContent } from './PitchBar.types';

export const pitchbarFallbackData: PitchBarContent = [
  {
    highlight: 'DEVOLUÇÃO GRÁTIS',
    text: 'Confira os detalhes da nossa política de devoluções.',
    cta: 'Veja aqui.',
    href: 'https://atendimento.nike.com.br/hc/pt-br/articles/5336676401683-Qual-%C3%A9-a-pol%C3%ADtica-para-a-devolu%C3%A7%C3%A3o-de-um-pedido-realizado-em-Nike-com-br-',
    icon: '/images/pitchbar/free-exchange.png',
    startDate: '',
    endDate: '',
  },
];

export const pitchbarContentMock: PitchBarContent = [
  {
    startDate: '2024-07-12T13:40:00.000Z',
    endDate: '2024-08-13T02:55:00.000Z',
    highlight: 'Vença com o Air',
    text: 'Tecnologia desenvolvida para os melhores atletas',
    cta: 'Ver Produtos',
    href: '/hotsite/venca-com-air',
    icon: 'https://imgnike-a.akamaihd.net/strapi/nike/Pitch_Bar_50x50px_opt_08_f01ee362f7/Pitch_Bar_50x50px_opt_08_f01ee362f7.png',
  },
  {
    startDate: '2024-06-24T03:00:00.000Z',
    endDate: '2024-07-01T11:25:00.000Z',
    highlight: 'NIKE PROMO',
    text: 'Está chegando. Cadastre-se para receber as novidades',
    cta: 'aqui.',
    href: '/sc/nike-promo-cadastro',
    icon: 'https://imgnike-a.akamaihd.net/strapi/nike/discount_bf_82cb3176b4/discount_bf_82cb3176b4.png',
  },
  {
    startDate: '2024-07-01T11:30:00.000Z',
    endDate: '2024-07-15T03:00:00.000Z',
    highlight: 'NIKE PROMO',
    text: 'começou! Aproveite ofertas imperdíveis. ',
    cta: 'Saiba Mais',
    href: '/sc/nike-promo',
    icon: 'https://imgnike-a.akamaihd.net/strapi/nike/discount_bf_82cb3176b4/discount_bf_82cb3176b4.png',
  },
  {
    startDate: '2024-06-19T13:10:00.000Z',
    endDate: '2024-07-19T03:00:00.000Z',
    highlight: 'Novo Pegasus 41',
    text: 'Não desperdice sua energia.',
    cta: 'Ver Tênis ',
    href: '/sc/pegasus41',
    icon: 'https://imgnike-a.akamaihd.net/strapi/nike/Pitch_Bar_50x50px_opt_02_b410898e86/Pitch_Bar_50x50px_opt_02_b410898e86.png',
  },
  {
    startDate: '2024-06-25T18:55:00.000Z',
    endDate: '2024-07-14T03:00:00.000Z',
    highlight: 'NIKE SÃO PAULO RUN ',
    text: 'Confira todos os detalhes da prova',
    cta: 'Aqui. ',
    href: '/sc/nike-sao-paulo-run',
    icon: 'https://imgnike-a.akamaihd.net/strapi/nike/alert_aaddb45f01/alert_aaddb45f01.png',
  },
  {
    startDate: '2023-12-01T03:00:00.000Z',
    endDate: '2024-07-31T03:00:00.000Z',
    highlight: 'NIKE APP ',
    text: 'O mundo Nike, do seu jeito. ',
    cta: 'Baixe agora',
    href: '/sc/nike-app',
    icon: 'https://imgnike-a.akamaihd.net/strapi/nike/Oecone_Nike_App_Pitchbar_OPT_01_Copia_Copia_c14c251a87/Oecone_Nike_App_Pitchbar_OPT_01_Copia_Copia_c14c251a87.png',
  },
  {
    startDate: '2024-03-21T03:00:00.000Z',
    endDate: '2024-07-31T03:00:00.000Z',
    highlight: 'NOVAS CAMISAS',
    text: 'da Seleção Brasileira.',
    cta: 'Confira',
    href: '/sc/futebol-selecao-brasileira',
    icon: 'https://imgnike-a.akamaihd.net/strapi/nike/2_1_1781f30a05/2_1_1781f30a05.png',
  },
  {
    startDate: '2024-06-25T19:00:00.000Z',
    endDate: '2025-01-01T02:55:00.000Z',
    highlight: 'QUIZ TÊNIS DE CORRIDA',
    text: 'Encontre o seu tênis ideal.',
    cta: 'Saiba Mais.',
    href: '/sc/encontre-seu-tenis-quiz',
    icon: 'https://imgnike-a.akamaihd.net/strapi/nike/icon_release_shoes_b57187fa62/icon_release_shoes_b57187fa62.png',
  },
  {
    startDate: '2023-05-16T03:00:00.000Z',
    endDate: '2025-01-01T02:55:00.000Z',
    highlight: 'CARTÃO PRESENTE',
    text: 'para presentes de última hora.',
    cta: 'Saiba Mais.',
    href: '/sc/cartao-presente',
    icon: 'https://imgnike-a.akamaihd.net/strapi/nike/gift_card_99aa6b2828/gift_card_99aa6b2828.png',
  },
];
