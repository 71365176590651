import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { getStorageItem, removeStorageItem } from '@/helpers/localStorage';
import { SELLER_KEY } from '../constants';

const useSessionSeller = () => {
  const queryClient = useQueryClient();

  const hasSessionStore = getStorageItem(SELLER_KEY) !== null;

  const signOutSessionStore = useCallback(() => {
    if (hasSessionStore) {
      queryClient.invalidateQueries('seller');
      removeStorageItem(SELLER_KEY);
    }
  }, [hasSessionStore, queryClient]);

  return { hasSessionStore, signOutSessionStore };
};

export default useSessionSeller;
