import { media } from '@sbf/fairplay-utils';
import styled, { css } from 'styled-components';

type WrapperProps = {
  maxWidth?: string;
  fullWidth: boolean;
};

type StyledContainerProps = {
  as?: React.ElementType;
  className?: string;
  fullHeight?: boolean;
};

export const StyledContainer = styled.div<StyledContainerProps>`
  ${({ fullHeight }) => css`
    display: flex;
    justify-content: center;
    height: auto;
    min-height: ${fullHeight ? '100vh' : 'auto'};
  `}
`;

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, maxWidth, fullWidth }) => css`
    width: 100%;
    max-width: ${maxWidth};
    box-sizing: border-box;
    padding: ${theme.spaces[0]}
      ${fullWidth ? theme.spaces[0] : theme.spaces[300]};

    ${media.greaterThan('desktop')} {
      padding: ${theme.spaces[0]}
        ${fullWidth ? theme.spaces[0] : theme.spaces[600]};
    }
  `}
`;
