import { getCoreConfig } from '@/common/config/config';
import { isProdReleaseEnv } from '../utils/environment/environment';

const {
  publicRuntimeConfig: { pitchbarCdnPath },
} = getCoreConfig();

const PITCHBAR_PATH = isProdReleaseEnv
  ? `${pitchbarCdnPath.basePath}/web/prd/pitchbar/pitchbar.json`
  : `${pitchbarCdnPath.basePath}/web/dev/pitchbar/pitchbar.json`;

export const getPitchbarConfig = () => ({
  path: PITCHBAR_PATH,
});
