import { type ClientError } from '@/common/services/api/types';
import { Components, Events, MicroComponents } from '@/common/types/tracking';
import { ERRORS } from '@/modules/guestCheckout/transformers/cartError';
import { CheckoutClientError } from '@/modules/guestCheckout/types/checkoutErrorResponse';
import { AsyncData } from '@/modules/guestCheckout/types/placeOrder';

export const getErrorEventBase = ({
  response,
}: ClientError<CheckoutClientError>) => ({
  errorCode: Number(response?.status),
  errorMessage: response?.data.message,
  extraDetail: {
    errorCode: response?.data.code,
    errorMessageSystem: response?.data.message,
    errorMessageUser:
      ERRORS[response?.data.code || 'DEFAULT']?.message ??
      ERRORS['DEFAULT'].message,
  },
});

export const getCreateEmptyCartErrorEventPayload = (
  error: ClientError<CheckoutClientError>,
) => ({
  component: Components.Request,
  originEvent: Events.CreateEmptyCart,
  ...getErrorEventBase(error),
});

export const getFreightErrorEventPayoad = (
  error: ClientError<CheckoutClientError>,
) => ({
  component: Components.IdentificationPage,
  microComponent: MicroComponents.FreightCalculatorComponent,
  originEvent: Events.FreightCalculate,
  ...getErrorEventBase(error),
});

export const getPlaceOrderErrorEventPayoad = (
  error: ClientError<CheckoutClientError>,
) => ({
  component: Components.PaymentPage,
  microComponent: MicroComponents.PlaceOrder,
  originEvent: Events.PlaceOrder,
  ...getErrorEventBase(error),
});

export const getOrderConfirmationErrorEventPayload = (
  error: AsyncData,
  message: string,
) => ({
  component: Components.OrderPage,
  microComponent: MicroComponents.PlaceOrder,
  originEvent: Events.PlaceOrder,
  errorCode: 0,
  errorMessage: error.status,
  extraDetail: {
    errorCode: error.cause?.code,
    errorMessageSystem: error.cause?.message,
    errorMessageUser: message,
  },
});

export const getOrderStatusErrorEventPayload = (
  error: ClientError<CheckoutClientError>,
) => ({
  component: Components.OrderPage,
  microComponent: MicroComponents.ViewOrder,
  originEvent: Events.CompleteOrder,
  ...getErrorEventBase(error),
});

export const getCreateCartErrorEventPayload = (
  error: ClientError<CheckoutClientError>,
) => ({
  component: Components.ProductDetailsPage,
  microComponent: MicroComponents.AddToCartButtonCTA,
  originEvent: Events.AddToCart,
  ...getErrorEventBase(error),
});

export const getUpdateCartErrorEventPayload = (
  error: ClientError<CheckoutClientError>,
) => ({
  component: Components.Cart,
  microComponent: MicroComponents.AddToCartButtonCTA,
  originEvent: Events.AddToCart,
  ...getErrorEventBase(error),
});

export const getUpdateCheckoutErrorEventPayload = (
  error: ClientError<CheckoutClientError>,
) => ({
  component: Components.CheckoutPage,
  microComponent: MicroComponents.PaymentStepComponent,
  originEvent: Events.CheckoutGenericErrors,
  ...getErrorEventBase(error),
});

export const getIdentificationErrorEventPayload = (
  error: ClientError<CheckoutClientError>,
) => ({
  component: Components.IdentificationPage,
  microComponent: MicroComponents.ConfirmAddress,
  originEvent: Events.ChangeAddress,
  ...getErrorEventBase(error),
});
export const getRevalidateErrorEventPayload = (
  error: ClientError<CheckoutClientError>,
) => ({
  component: Components.Cart,
  originEvent: Events.RevalidateCart,
  ...getErrorEventBase(error),
});
