import { getSession } from 'next-auth/react';
import { parseCookies } from 'nookies';
import { getCoreConfig } from '@/common/config/config';
import { SALES_CHANNEL } from '@/common/constants/constants';
import { isClientSide } from '@/common/utils/environment/environment';
import { getStorageItem } from '@/common/utils/localStorage/localStorage';
import { ACCESS_TOKEN_COOKIE, EXPIRATION_COOKIE } from '@/hooks/useAuthSession';
import { getAssistedSaleHeaders } from '@/modules/assistedSale/utils/getAssistedSaleHeaders';

// Threashold to consider the token expired 1 minute before the actual expiration
const MILLISECONDS_THREASHOLD = 60000;

export const fetchAccessTokenFromSession = async () => {
  const session = await getSession();
  return session?.accessToken || '';
};

export const getAccessToken = async () => {
  try {
    const token = parseCookies()[ACCESS_TOKEN_COOKIE];
    const expiration = parseCookies()[EXPIRATION_COOKIE];
    if (token && Date.now() < parseInt(expiration) - MILLISECONDS_THREASHOLD) {
      return token;
    } else {
      throw new Error('Token expired or invalid');
    }
  } catch {
    return await fetchAccessTokenFromSession();
  }
};

export const clearEmptyHeaders = (headers: Record<string, string | number>) => {
  return Object.keys(headers).reduce((newHeaders, key) => {
    if (headers[key] !== '') {
      newHeaders[key] = headers[key];
    }
    return newHeaders;
  }, {} as Record<string, string | number>);
};

/**
 * Get some headers info which are must be included in some
 * requests to identify the user in the cart flow
 *
 * When run at the server-side will always returns mobile sales channel
 * and authorization. The client-token was stored in local storage and
 * server can't be able to access this information
 *
 * @example
 * import { getClientHeaders } from '@/common/services/api/client/header';
 *
 * // returns { 'x-cv-id': 7, 'x-client-token': 'abcd', Authorization: 'abcd' }
 * getClientHeaders();
 */
export const getClientHeaders = async () => {
  let cvId = SALES_CHANNEL.Mobile;

  /**
   * When run at server-side we can't identify the user's screen size
   */
  const isDesktop =
    isClientSide() && window.matchMedia('(min-width: 1024px)').matches;

  if (isDesktop) {
    cvId = SALES_CHANNEL.Desktop;
  }

  const userToken = getStorageItem<string>('x-client-token');
  const accessToken = userToken ? await getAccessToken() : '';
  const assistedSaleHeaders = getAssistedSaleHeaders();

  const coreTokens = {
    'x-cv-id': cvId,
  };

  const requestHeaders = userToken
    ? {
        'x-client-token': userToken,
        Authorization: `Bearer ${accessToken}`,
        ...coreTokens,
      }
    : coreTokens;

  return { ...clearEmptyHeaders(requestHeaders), ...assistedSaleHeaders };
};

/**
 * Get some headers info which are must be included in some
 * requests to identify the user in the cart flow
 *
 * When run at the server-side will always returns mobile sales channel
 * and authorization. The client-token was stored in local storage and
 * server can't be able to access this information
 *
 * @example
 * import { getClientHeaders } from '@/common/services/api/client/header';
 *
 * // returns { 'x-cv-id': 7, 'x-client-token': 'abcd', Authorization: 'abcd' }
 * getClientHeaders();
 */
export const getClientScoobyHeaders = async () => {
  let cvId = SALES_CHANNEL.Mobile;

  /**
   * When run at server-side we can't identify the user's screen size
   */
  const isDesktop =
    isClientSide() && window.matchMedia('(min-width: 1024px)').matches;

  if (isDesktop) {
    cvId = SALES_CHANNEL.Desktop;
  }

  const userToken = getStorageItem<string>('x-client-token');
  const { publicRuntimeConfig } = getCoreConfig();
  const { authorization = '' } = publicRuntimeConfig.temporaryTokens;
  const accessToken = userToken ? await getAccessToken() : '';
  const assistedSaleHeaders = getAssistedSaleHeaders();

  const coreTokens = {
    Authorization: authorization,
    'x-cv-id': cvId,
  };

  const userAuthToken =
    userToken && !authorization
      ? { Authorization: `Bearer ${accessToken}` }
      : {};

  const requestHeaders = userToken
    ? {
        ...coreTokens,
        ...userAuthToken,
        'x-client-token': userToken,
      }
    : coreTokens;

  return { ...clearEmptyHeaders(requestHeaders), ...assistedSaleHeaders };
};
