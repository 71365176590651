import { InMemoryStorageProvider } from '@unleash/proxy-client-react';
import { isProdEnv, isServerSide } from '../utils/environment/environment';

const config = isServerSide()
  ? {
      fetch,
      storageProvider: new InMemoryStorageProvider(),
    }
  : {};

export const UNLEASH_CONFIG = {
  url: process.env.NEXT_PUBLIC_UNLEASH_PROXY_URL,
  clientKey: process.env.NEXT_PUBLIC_UNLEASH_PROXY_KEY,
  disableMetrics: true,
  refreshInterval: 900,
  appName: 'nike-front',
  environment: isProdEnv ? 'production' : 'development',
  ...config,
};
