import { MouseEvent } from 'react';
import { getCoreRoutes } from '@/common/config/routes';
import { useMenu } from '@/common/patterns/Menu/menuContext';
import { useTrackingContext } from '@/contexts/TrackingContext';
import {
  SelectContentObject,
  sendSelectContentEvents,
} from '@/helpers/analytics';
import { MenuItem } from '../SubHeader/SubHeader.styled';
import { sendClickEvent } from './trackingEvents';

interface MenuItemsProps {
  handleSignOut: (event: MouseEvent) => void;
}

const coreRoutes = getCoreRoutes();

const MenuItems = ({ handleSignOut }: MenuItemsProps) => {
  const { labels } = useMenu();

  const { handleEvents } = useTrackingContext();
  return (
    <>
      <MenuItem
        href="/minha-conta"
        onClick={(event: MouseEvent) => {
          sendClickEvent(event);
          handleEvents<SelectContentObject>(sendSelectContentEvents, {
            component: 'header',
            microComponent: 'top_bar',
            anchorText: labels?.myAccountLabel || 'minha conta',
            title: '',
            url: '/minha-conta',
            contentType: 'navigation_link',
          });
        }}
        type="quiet"
        size="medium"
        text={labels ? labels.myAccountLabel : 'Minha conta'}
      />
      <MenuItem
        href="/meus-pedidos"
        onClick={(event: MouseEvent) => {
          sendClickEvent(event);
          handleEvents<SelectContentObject>(sendSelectContentEvents, {
            component: 'header',
            microComponent: 'top_bar',
            anchorText: labels?.myOrdersLabel || 'meus pedidos',
            title: '',
            url: '/meus-pedidos',
            contentType: 'navigation_link',
          });
        }}
        type="quiet"
        size="medium"
        text={labels ? labels.myOrdersLabel : 'Meus pedidos'}
      />
      <MenuItem
        href={coreRoutes.favorites}
        onClick={(event: MouseEvent) => {
          sendClickEvent(event);
          handleEvents<SelectContentObject>(sendSelectContentEvents, {
            component: 'header',
            microComponent: 'top_bar',
            anchorText: labels?.myFavorites || 'meus favoritos',
            title: '',
            url: coreRoutes.favorites,
            contentType: 'navigation_link',
          });
        }}
        type="quiet"
        size="medium"
        text={labels ? labels.myFavorites : 'Meus favoritos'}
      />
      <MenuItem
        onClick={(event: MouseEvent) => {
          handleSignOut(event);
          handleEvents<SelectContentObject>(sendSelectContentEvents, {
            component: 'header',
            microComponent: 'top_bar',
            anchorText: 'sair',
            title: '',
            url: 'https://www.nike.com.br/',
            contentType: 'navigation_link',
          });
        }}
        type="quiet"
        size="medium"
        text="Sair"
      />
    </>
  );
};

export default MenuItems;
