import { ArrowRight } from '@sbf/fairplay-icons';
import { Link as Anchor } from '@sbf/fairplay-nike';
import Link from 'next/link';
import { memo, useEffect } from 'react';
import { useInView as useInViewFromIntersectionObserver } from 'react-intersection-observer';
import { useTrackingContext } from '@/contexts/TrackingContext';
import {
  SelectContentObject,
  sendSelectContentEvents,
} from '@/helpers/analytics';
import { useInView } from '@/hooks/useInView';
import {
  Categories,
  CategoryButton,
  CategoryContentContainer,
  CategoryMenu,
  CategoryPanel,
  CategoryTitle,
  Item,
  LinkMobileMenu,
  ListItems,
  NavMenu,
  SeeMoreItem,
  SubCategoryContent,
  SubCategoryList,
} from './Menu.styled';
import MenuAccountMobile from './MenuAccountMobile';
import MenuSecondaryMobile from './MenuSecondaryMobile';
import { useMenu } from './menuContext';
import { MenuItem } from './types';
import { isItemActive } from './utils/isItemActive/isItemActive';

type MenuProps = {
  onClick?: () => void;
  content: MenuItem[];
};

const MenuMobile = ({ onClick, content }: MenuProps) => {
  const { categoryStack, pushCategoryStack, clearStack } = useMenu();
  const { handleEvents, cleanSearchIdList } = useTrackingContext();

  const isSubCategoryOpened = (stackPosition: number, stackTitle: string) =>
    categoryStack.length > stackPosition &&
    categoryStack[stackPosition] === stackTitle;

  const [ref, inView] = useInView<HTMLDivElement>(
    useInViewFromIntersectionObserver,
  )({
    intersectionOptions: {
      triggerOnce: false,
      threshold: 0,
      initialInView: true,
    },
  });

  useEffect(() => {
    if (!inView) {
      clearStack();
    }
  }, [clearStack, inView]);

  return (
    <NavMenu>
      <Categories role="menubar" data-testid="menubar" ref={ref}>
        <MenuAccountMobile />
        {content.filter(isItemActive).map((category) => (
          <CategoryMenu key={category.title} role="menuitem">
            <CategoryButton
              data-testid="menu-item-mobile"
              onClick={() => {
                pushCategoryStack(category.title);
                cleanSearchIdList();
                handleEvents<SelectContentObject>(sendSelectContentEvents, {
                  component: 'header',
                  microComponent: 'main_header_content',
                  anchorText: category.title,
                  url: category.link,
                  contentType: 'navigation_link',
                });
              }}
            >
              {category.title}
              <ArrowRight size="medium" />
            </CategoryButton>
            <CategoryPanel
              data-testid={`category-panel-${category.title}`}
              aria-expanded={isSubCategoryOpened(0, category.title)}
            >
              <LinkMobileMenu href={category.link} passHref>
                <CategoryTitle
                  onClick={() => {
                    onClick?.();
                    cleanSearchIdList();
                    handleEvents<SelectContentObject>(sendSelectContentEvents, {
                      component: 'header',
                      microComponent: 'main_header_content',
                      anchorText: category.title,
                      title: category.title,
                      url: category.link,
                      contentType: 'navigation_link',
                    });
                  }}
                >
                  {category.title}
                </CategoryTitle>
              </LinkMobileMenu>
              <CategoryContentContainer>
                <SubCategoryContent role="menu">
                  {(category.title.includes('Masculino') ||
                    category.title.includes('Feminino')) && (
                    <SeeMoreItem role="menuitem" key={category.title}>
                      <Link href={category.link} passHref>
                        <Anchor
                          text="Ver mais"
                          size="small"
                          type="brand"
                          onClick={onClick}
                        />
                      </Link>
                    </SeeMoreItem>
                  )}

                  {category.subMenu?.filter(isItemActive).map((subMenuItem) => (
                    <SubCategoryList role="menuitem" key={subMenuItem.title}>
                      <CategoryButton
                        onClick={() => pushCategoryStack(subMenuItem.title)}
                      >
                        {subMenuItem.title}
                        <ArrowRight size="medium" />
                      </CategoryButton>
                      <CategoryPanel
                        data-testid={`subCategory-panel-${category.title}-${subMenuItem.title}`}
                        aria-expanded={isSubCategoryOpened(
                          1,
                          subMenuItem.title,
                        )}
                      >
                        <LinkMobileMenu href={subMenuItem.link} passHref>
                          <CategoryTitle
                            onClick={() => {
                              onClick?.();
                              cleanSearchIdList();
                              handleEvents<SelectContentObject>(
                                sendSelectContentEvents,
                                {
                                  component: 'header',
                                  microComponent: 'main_header_content',
                                  anchorText: subMenuItem.title,
                                  title: subMenuItem.title,
                                  url: subMenuItem.link,
                                  contentType: 'navigation_link',
                                },
                              );
                            }}
                          >
                            {subMenuItem.title}
                          </CategoryTitle>
                        </LinkMobileMenu>
                        <CategoryContentContainer>
                          <ListItems role="menu">
                            {subMenuItem.subMenu
                              ?.filter(isItemActive)
                              .map((item) => (
                                <Item
                                  role="menuitem"
                                  key={`${item.title}${item.link}`}
                                >
                                  <Link
                                    href={item.link}
                                    passHref
                                    legacyBehavior
                                  >
                                    <Anchor
                                      text={item.title}
                                      size="small"
                                      type="brand"
                                      onClick={() => {
                                        onClick?.();
                                        cleanSearchIdList();
                                        handleEvents<SelectContentObject>(
                                          sendSelectContentEvents,
                                          {
                                            component: 'header',
                                            microComponent:
                                              'main_header_content',
                                            anchorText: item.title,
                                            title: item.title + '_submenu',
                                            url: item.link,
                                            contentType: 'navigation_link',
                                          },
                                        );
                                      }}
                                    />
                                  </Link>
                                </Item>
                              ))}
                          </ListItems>
                        </CategoryContentContainer>
                      </CategoryPanel>
                    </SubCategoryList>
                  ))}
                </SubCategoryContent>
              </CategoryContentContainer>
            </CategoryPanel>
          </CategoryMenu>
        ))}
        <MenuSecondaryMobile onClick={() => onClick?.()} />
      </Categories>
    </NavMenu>
  );
};

export default memo(MenuMobile);
