import { Typography } from '@sbf/fairplay-core';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useTrackingContext } from '@/contexts/TrackingContext';
import { AuthEventType, handleSignOut } from '@/helpers/auth';
import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from '@/helpers/localStorage';
import { useSellerQuery } from '@/modules/assistedSale/services/LoginSeller/loginSeller';
import useSessionSeller from '@/modules/assistedSale/utils/useSessionSeller';
import { Container, Content, LogoutButton } from './TopHeader.styled';

const TopHeader = () => {
  const { data } = useSellerQuery();
  const router = useRouter();
  const { signOutSessionStore, hasSessionStore } = useSessionSeller();
  const queryClient = useQueryClient();
  const { setUserData } = useTrackingContext();
  const orderConfirmation = queryClient.getQueryData('orderConfirmation');

  const handleLogout = useCallback(() => {
    signOutSessionStore();
    const authEvent: AuthEventType = {
      action: 'Click - Header Sair Vendedor',
      label: 'Encerramento da sessão do cliente ao deslogar vendedor',
    };
    handleSignOut(authEvent);
    setUserData();
    router.push('/login-vendedor');
  }, [signOutSessionStore, setUserData, router]);

  const handleStart = useCallback(
    (url: string) => {
      const confirmedOrder = getStorageItem('assistedSaleConfirmOrder');

      if (url == '/' && confirmedOrder) {
        removeStorageItem('assistedSaleConfirmOrder');
        handleSignOut();
        setUserData();
      }
    },
    [setUserData],
  );

  useEffect(() => {
    if (hasSessionStore) {
      router.events.on('routeChangeComplete', handleStart);
      return () => {
        router.events.off('routeChangeComplete', handleStart);
      };
    }
  }, [router, hasSessionStore, handleStart]);

  useEffect(() => {
    if (hasSessionStore && orderConfirmation) {
      setStorageItem('assistedSaleConfirmOrder', 'true');
    }
  }, [hasSessionStore, orderConfirmation]);

  const prefixStore = useMemo(() => {
    const prefix = data?.description.split(' ')[0];
    return prefix;
  }, [data?.description]);

  if (!data) {
    return <></>;
  }

  return (
    <Container>
      <Content>
        <Typography variant="paragraph" size="tiny">
          {prefixStore} | {data?.name}
        </Typography>
        <LogoutButton type="button" onClick={handleLogout}>
          Sair
        </LogoutButton>
      </Content>
    </Container>
  );
};

export default TopHeader;
