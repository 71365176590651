import { useSession } from 'next-auth/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getCorePaths } from '@/common/config/paths';
import { client } from '@/common/services/api/client/client';
import { type ClientError } from '@/common/services/api/types';
import { Components, Events } from '@/common/types/tracking';
import {
  getStorageItem,
  setStorageItem,
} from '@/common/utils/localStorage/localStorage';
import { generateTelemetryHeader } from '@/helpers/akamai/akamai';
import { useCheckout } from '@/modules/checkout/components/CheckoutProvider/CheckoutProvider';
import { CartGiftWrapProps } from '@/modules/checkout/components/SummaryCart/SummaryCart.types';
import { CheckoutErrorResponse } from '@/modules/checkout/types/checkoutErrorResponse';
import { sendAnalyticsEvents } from '@/modules/checkout/utils/trackingEvents';
import { sendTrackToSizebay } from '@/services/sizebay/sizebay';
import { sendZeroTokenEvent } from '../../tracking/error/error';
import {
  AddItemResponse,
  Cart,
  ItemInfoRequestBody,
  ItemsInfoRequestBody,
  ItemsInfoSyncRequestBody,
  ProductId,
} from './cart.types';

const { checkout } = getCorePaths();

const RETRY_CONFIG =
  process.env.NODE_ENV === 'test'
    ? {}
    : {
        retryOnMount: true,
        retry: 1,
      };

export const getCart = () => {
  return client.get<Cart>(`${checkout.cart}`, {
    params: {
      ice: '1',
    },
    headers: generateTelemetryHeader(true),
  });
};

export const addToCart = (item: ItemInfoRequestBody) => {
  return client.post<AddItemResponse>(checkout.cart, item, {
    headers: generateTelemetryHeader(true),
  });
};

export const syncCart = (items: ItemsInfoSyncRequestBody) => {
  return client.post<AddItemResponse>(checkout.syncCart, items, {
    headers: generateTelemetryHeader(true),
  });
};

export const addManyToCart = (items: ItemsInfoRequestBody) => {
  return client.post<AddItemResponse>(checkout.addCart, items.items);
};

export const removeFromCart = (item: ProductId) => {
  return client.put<AddItemResponse>(
    `${checkout.cart}/${item.productId}`,
    item,
  );
};

export const useCart = () => {
  const userToken = getStorageItem<string>('x-client-token');
  const { setCheckoutStepValues } = useCheckout();

  const context = useQuery<
    Cart | undefined,
    ClientError<CheckoutErrorResponse>
  >(
    ['cart', userToken],
    async () => {
      setCheckoutStepValues({
        isCartAPILoading: true,
      });
      const response = await getCart();
      sendAnalyticsEvents(response.data);

      // I have no clue why sometimes the onSettled is not firing every time when getting data from the API.
      // So, we have to put it here also.

      setCheckoutStepValues({
        isCartAPILoading: false,
      });
      return response.data;
    },
    {
      refetchOnMount: false,
      enabled: Boolean(userToken),
      ...RETRY_CONFIG,
      onSettled: () => {
        setCheckoutStepValues({
          isCartAPILoading: false,
        });
      },
    },
  );

  return context;
};

export const useAddToCartMutation = () => {
  const queryClient = useQueryClient();
  const { status } = useSession();

  return useMutation<
    AddItemResponse,
    ClientError<CheckoutErrorResponse>,
    ItemInfoRequestBody,
    () => void
  >(
    async (item: ItemInfoRequestBody) => {
      const response = await addToCart(item);
      if (item.href) {
        sendTrackToSizebay([item.href]);
      }
      return response.data;
    },
    {
      onSuccess: (response) => {
        const hasFaultyToken =
          response.tokenClienteAnonimo ===
          '00000000-0000-0000-0000-000000000000';
        const isAuthenticated = status === 'authenticated';

        if (hasFaultyToken) {
          sendZeroTokenEvent({
            component: Components.Request,
            originEvent: Events.AddToCart,
            isAuthenticated,
          });
        }

        if (!isAuthenticated) {
          setStorageItem('x-client-token', response.tokenClienteAnonimo);
        }

        queryClient.invalidateQueries('cart');

        queryClient.removeQueries('payments', { inactive: true });

        queryClient.removeQueries('checkoutDelivery', { inactive: true });
      },
    },
  );
};

export const useSyncCartMutation = () => {
  const queryClient = useQueryClient();
  const { status } = useSession();

  return useMutation<
    AddItemResponse,
    ClientError<CheckoutErrorResponse>,
    ItemsInfoSyncRequestBody,
    () => void
  >(
    async (items: ItemsInfoSyncRequestBody) => {
      const response = await syncCart(items);

      return response.data;
    },
    {
      onSuccess: (response) => {
        const hasFaultyToken =
          response.tokenClienteAnonimo ===
          '00000000-0000-0000-0000-000000000000';
        const isAuthenticated = status === 'authenticated';

        if (hasFaultyToken) {
          sendZeroTokenEvent({
            component: Components.Request,
            originEvent: Events.SyncCart,
            isAuthenticated,
          });
        }

        if (!isAuthenticated) {
          setStorageItem('x-client-token', response.tokenClienteAnonimo);
        }

        queryClient.invalidateQueries('cart');

        queryClient.removeQueries('payments', { inactive: true });

        queryClient.removeQueries('checkoutDelivery', { inactive: true });
      },
    },
  );
};

export const useRemoveFromCartMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    AddItemResponse,
    ClientError<CheckoutErrorResponse>,
    ProductId,
    () => void
  >(
    async (item: ProductId) => {
      const response = await removeFromCart(item);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('cart');

        queryClient.removeQueries('payments', { inactive: true });

        queryClient.removeQueries('checkoutDelivery', { inactive: true });
      },
    },
  );
};

const CART_GIFT_WRAP = getCorePaths().checkout.giftWrap;
export const handleGiftWrap = (item: CartGiftWrapProps) => {
  return client.post(`${CART_GIFT_WRAP}`, item);
};

export const useGiftWrapMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (item: CartGiftWrapProps) => {
      await handleGiftWrap(item);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('cart');
      },
    },
  );
};
