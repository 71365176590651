import { GetRegistrationResponse } from '@/modules/myAccount/components/Registration/Registration.types';

export const verifyAccountData = (data: GetRegistrationResponse) => {
  const missingData: string[] = [];

  if (data.cpf) {
    const naturalPersonData = {
      nome: data.nome || '',
      sobrenome: data.sobrenome || '',
      dataDeNascimento: data.dataDeNascimento || '',
      logradouro: data.endereco?.logradouro || '',
      numero: data.endereco?.numero || '',
      bairro: data.endereco?.bairro || '',
      cidade: data.endereco?.cidade || '',
      cep: data.endereco?.cep || '',
      estado: data.endereco?.estado || '',
      telefone:
        (data.endereco?.telefoneAdicional?.ddd &&
          data.endereco?.telefoneAdicional?.numero) ||
        (data.endereco?.telefoneFixo?.ddd &&
          data.endereco?.telefoneFixo?.numero) ||
        '',
    };

    Object.entries(naturalPersonData).forEach((value) => {
      if (!value[1]) {
        missingData.push(value[0]);
      }
    });
  }
  if (data.cnpj) {
    const juridicalPersonData = {
      nome: data.nome || '',
      sobrenome: data.sobrenome || '',
      razaoSocial: data.razaoSocial || '',
      logradouro: data.endereco?.logradouro || '',
      numero: data.endereco?.numero || '',
      bairro: data.endereco?.bairro || '',
      cidade: data.endereco?.cidade || '',
      cep: data.endereco?.cep || '',
      estado: data.endereco?.estado || '',
      telefone:
        (data.endereco?.telefoneAdicional?.ddd &&
          data.endereco?.telefoneAdicional?.numero) ||
        (data.endereco?.telefoneFixo?.ddd &&
          data.endereco?.telefoneFixo?.numero) ||
        '',
    };
    Object.entries(juridicalPersonData).forEach((value) => {
      if (!value[1]) {
        missingData.push(value[0]);
      }
    });
  }

  if (!data.cpf && !data.cnpj) {
    missingData.push('nova conta');
  }
  return missingData;
};

export const checkMinimumChars = (value: string | undefined) => {
  if (value) {
    return value.length < 2 ? false : true;
  }
  return true;
};

export const wichPersonType = (data: GetRegistrationResponse) => {
  let personType = '';
  if (data.cpf) {
    personType = 'pessoa física';
  }
  if (data.cnpj) {
    personType = 'pessoa jurídica';
  }
  return personType;
};
