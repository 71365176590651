import { useLayout } from '@sbf/fairplay-core';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { removeQueryParams } from '@/common/utils/urls/urls';
import {
  TrackingUtmObject,
  useTrackingContext,
} from '@/contexts/TrackingContext';
import { PageViewObject, sendPageViewEvent } from '@/helpers/analytics';
import { getStorageItem } from '@/helpers/localStorage';
import { useAuthSession } from '@/hooks/useAuthSession';

const PageView = () => {
  const { pathname, asPath } = useRouter();
  const { userData, utmValues, getUtmValues, setUtmValues, handleEvents } =
    useTrackingContext();
  const { isMobile } = useLayout();
  const { isAuthenticated } = useAuthSession();

  const anonymousId: string | null = isAuthenticated
    ? ''
    : getStorageItem('x-client-token');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callPageView = () => {
    if (userData) {
      handleEvents<PageViewObject>(sendPageViewEvent, {
        url: asPath,
        referrer: document.referrer,
        user: userData,
        isMobile,
        utmValues,
        anonymousId,
      });
    }
  };

  useDeepCompareEffect(() => {
    callPageView();
  }, [userData, handleEvents]);

  useEffect(() => {
    callPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, removeQueryParams(asPath)]);

  useEffect(() => {
    if (!userData) {
      const urlQuery = new URLSearchParams(window.location.search);
      const urlHash = new URLSearchParams(
        window.location.hash.replace('#', ''),
      );

      const urlParams = {
        ...Object.fromEntries(urlQuery),
        ...Object.entries(Object.fromEntries(urlHash)).reduce(
          (params, [key, value]) => ({
            ...params,
            [key.replace('u_', '')]: value,
          }),
          {},
        ),
      };

      const storageParams = getUtmValues();
      const params = (
        Object.keys(urlParams).length &&
        Object.keys(urlParams).find(
          (key) => key.includes('utm') || key.includes('clid'),
        )
          ? urlParams
          : storageParams
      ) as TrackingUtmObject;

      setUtmValues(params);
    }
  }, [setUtmValues, getUtmValues, userData]);

  return <></>;
};

export default PageView;
