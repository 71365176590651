import { Alert } from '@sbf/fairplay-icons';
import { Button, Link, Typography } from '@sbf/fairplay-nike';
import styled, { css } from 'styled-components';
import { MarginProps } from '../Registration.types';

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    text-align: center;
    padding-bottom: ${theme.spaces[500]};
  `}
`;

export const PersonRow = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: ${theme.spaces[400]} ${theme.spaces[0]};
    gap: ${theme.spaces[500]};
  `}
`;

export const MarginTitle = styled(Typography)`
  ${({ theme }) => css`
    margin: ${theme.spaces[400]} ${theme.spaces[0]};
  `}
`;

export const MarginRadio = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.spaces[500]};
  `}
`;

export const MarginCheckbox = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.spaces[300]};
  `}
`;

export const MarginFavorites = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spaces[400]};
  `}
`;

export const GenderPreferenceRow = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: ${theme.spaces[200]} 0;
    gap: ${theme.spaces[700]};
  `}
`;

export const ButtonMargin = styled(Button.Fill)`
  ${({ theme }) => css`
    width: 100%;
    margin: ${theme.spaces[500]} 0 ${theme.spaces[200]} 0;
  `}
`;

export const ButtonHelpCenter = styled(Button.Outline)`
  ${({ theme }) => css`
    border: none;
    text-decoration: underline;
    padding: ${theme.spaces[0]};
  `}
`;

export const Grid = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-gap: ${theme.spaces[300]};
    margin-bottom: ${theme.spaces[300]};
    text-align: left;
  `}
`;

export const ColumnCenter = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-gap: ${theme.spaces[200]};
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    text-align: left;
  `}
`;

export const ColumnStart = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-gap: ${theme.spaces[200]};
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    text-align: left;
  `}
`;

export const LinkStyled = styled(Link)<MarginProps>`
  ${({ theme, marginBottom }) => css`
    margin-top: ${theme.spaces[400]};
    margin-bottom: ${marginBottom ? theme.spaces[400] : theme.spaces[0]};
  `}
`;

export const CheckboxMargin = styled.div<MarginProps>`
  ${({ theme, marginBottom }) => css`
    margin-bottom: ${marginBottom ? theme.spaces[400] : theme.spaces[0]};
  `}
`;

export const HelpCenterLink = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.negative[300]};
    font-size: ${theme.fontSizes.tiny};
    font-weight: ${theme.fontWeights.medium};
  `}
`;

export const LoginStyled = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.negative[300]};
    font-weight: ${theme.fontWeights.bold};
  `}
`;

export const PoliciesContainer = styled.div`
  ${({ theme }) => css`
    border-top: solid ${theme.colors.neutral[300]};
    border-bottom: solid ${theme.colors.neutral[300]};
    padding-bottom: ${theme.spaces[300]};
    margin-top: ${theme.spaces[500]};
    justify-content: space-between;
  `}
`;
export const PoliciesContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: ${theme.spaces[400]};
    align-items: flex-start;
  `}
`;

export const PoliciesParagraph = styled(Typography).attrs({
  variant: 'paragraph',
})`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.regular};
    font-size: ${theme.fontSizes.small};
  `}
`;

export const PoliciesErrorParagraph = styled(Typography).attrs({
  variant: 'paragraph',
})`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.regular};
    font-size: ${theme.fontSizes.small};
    color: ${theme.colors.negative[300]};
    display: flex;
    gap: ${theme.spaces[100]};
    align-items: center;
  `}
`;

export const PoliciesBoldParagraph = styled(Typography).attrs({
  variant: 'paragraph',
})`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.medium};
    font-size: ${theme.fontSizes.small};
  `}
`;

export const PoliciesErrorIcon = styled(Alert)`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.medium};
    color: ${theme.colors.negative[200]};
  `}
`;

export const PoliciesText = styled.div`
  ${({ theme }) => css`
    text-align: left;
    margin-left: ${theme.spaces[200]};
  `}
`;

export const PoliciesLink = styled(Link)`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small};
  `}
`;
