import { getPitchbarConfig } from '@/common/config/pitchbar';
import cachedAxios from '@/common/services/cachedAxios/cachedAxios';
import { trackingExceptionError } from '@/common/utils/tracking/tracking';
import { pitchbarFallbackData } from './PitchBar.mock';
import { PitchBarContent } from './PitchBar.types';

const { path } = getPitchbarConfig();

export const getPitchBarData = async () => {
  try {
    const result = await cachedAxios.get<PitchBarContent>(path);
    const filteredData = filterPitchBarData(result.data);

    return filteredData.length > 0 ? filteredData : pitchbarFallbackData;
  } catch (error) {
    error.message = 'Failed to fetch PitchBar data.';
    trackingExceptionError({
      error,
      namespace: 'pitchbar',
      flow: '',
      checkpoint: '',
    });

    return pitchbarFallbackData;
  }
};

export const filterPitchBarData = (data: PitchBarContent) => {
  const currentDate = new Date();

  return data.filter((item) => {
    const startDate = new Date(item.startDate);
    const endDate = new Date(item.endDate);

    return currentDate >= startDate && currentDate <= endDate;
  });
};
