const HOME_ROUTE = '/';

//  LINKS ATTENDANCE
const ATTENDANCE_HOME = 'https://atendimento.nike.com.br/hc/pt-br';
const ATTENDANCE_EXCHANGE_POLICY =
  'https://atendimento.nike.com.br/hc/pt-br/articles/5336676401683-Pol%C3%ADtica-de-devolu%C3%A7%C3%A3o-para-compras-no-site';
const GENERAL_QUESTIONS =
  'https://atendimento.nike.com.br/hc/pt-br/sections/5331142558099-D%C3%BAvidas-Gerais';
const FIND_YOUR_SIZE =
  'https://atendimento.nike.com.br/hc/pt-br/sections/5331161233171-Encontre-seu-Tamanho';
const ATTENDANCE_DELIVERIES =
  'https://atendimento.nike.com.br/hc/pt-br/sections/5330991677203-Entrega';
const ATTENDANCE_MEMBERS_ADVANTAGE =
  'https://atendimento.nike.com.br/hc/pt-br/articles/5341770027411-O-que-%C3%A9-ser-um-membro-Nike-e-quais-as-vantagens-';
const ATTENDANCE_REQUESTS =
  'https://atendimento.nike.com.br/hc/pt-br/sections/5331072633491-Pedidos';
const EXCHANGES_AND_RETURNS =
  'https://atendimento.nike.com.br/hc/pt-br/sections/5330983405075-Trocas-e-devolu%C3%A7%C3%B5es';
const ATTENDANCE_PAYMENTS =
  'https://atendimento.nike.com.br/hc/pt-br/sections/5331065761427-Pagamentos';
const ATTENDANCE_PRODUCTS =
  'https://atendimento.nike.com.br/hc/pt-br/sections/5331044797075-Produtos';
const ATTENDANCE_CORPORATE =
  'https://atendimento.nike.com.br/hc/pt-br/sections/5331067989267-Corporativo';
const ATTENDANCE_ROUTE = 'https://atendimento.nike.com.br/hc/pt-br';
const ATTENDANCE_PRIVACY_POLICY =
  'https://atendimento.nike.com.br/hc/pt-br/articles/5342267459731-Pol%C3%ADtica-de-Privacidade';
const ATTENDANCE_TERMS_OF_USE =
  'https://atendimento.nike.com.br/hc/pt-br/articles/5342261426451-Termos-de-Servi%C3%A7o';

const FISIA_BEARER_PORTAL =
  'https://portaldotitular.fisia.com.br/titular_fisia';

const NIKE_INC_TOS =
  'https://agreementservice.svs.nike.com/rest/agreement?agreementType=termsOfUse&country=BR&language=pt&mobileStatus=false&requestType=redirect&uxId=com.nike.commerce.nikedotcom.brazil.oauth.web';

const CART_ROUTE = '/carrinho';
const CHECKOUT_ROUTE = '/checkout';
const PAYMENT_ROUTE = `${CHECKOUT_ROUTE}/pagamento`;
const PAYMENT_CONFIRMATION_ROUTE = `${CHECKOUT_ROUTE}/confirmacao`;

const LOGIN_ROUTE = '/login';

const ORDER_ROUTE = '/meus-pedidos';
const TRACK_ORDER_ROUTE = '/acompanhar-pedido';

const MY_ACCOUNT_ROUTE = '/minha-conta';
const CHANGE_REGISTRATION_DATA_ROUTE = `${MY_ACCOUNT_ROUTE}/alterar-dados-cadastrais`;
const EXCHANGE_VOUCHER_EXTRACT_ROUTE = `${MY_ACCOUNT_ROUTE}/extrato-vale-troca`;

const ATHLETE_VOUCHER_EXTRACT_ROUTE = `${MY_ACCOUNT_ROUTE}/extrato-voucher-atleta`;

const EXCHANGE_ROUTE = 'https://autoatendimento.nike.com.br';

const EXCHANGE_APP_ROUTE =
  'nike://browser?url=https://atendimento.nike.com.br/hc/pt-br';

const EXCHANGE_SELECT_PRODUCT_ROUTE =
  'https://autoatendimento.nike.com.br/devolucao-de-produto';

const NIKE_VESTE_A_GARRA = 'https://vesteagarra.nike.com.br';

const SELLER_LOGIN_ROUTE = '/login-vendedor';

const SEARCH_CEP_ROUTE =
  'https://buscacepinter.correios.com.br/app/endereco/index.php';

const NIKE_PROFILE_ROUTE = 'https://www.nike.com/pt/member/settings';

const NIKE_COMMUNICATION_PREFERENCE =
  'https://www.nike.com/pt/member/settings/communication-preferences';

const SNKRS_ROUTE = '/snkrs';

const SNKRS_STOCK_ROUTE = '/snkrs/estoque';

const SNKRS_CALENDAR_ROUTE = '/snkrs/calendario';

const GEO_SELECTION_LANGUAGE_ROUTE = 'https://www.nike.com/?geoselection=true';

const INSTAGRAM_ROUTE = 'https://www.instagram.com/nike/';

const YOUTUBE_ROUTE = 'https://www.youtube.com/user/nike';

const FACEBOOK_ROUTE = 'https://www.facebook.com/nike';

const SUSTAINABILITY_ROUTE = '/sc/sustentabilidade';

const PURPOSE_ROUTE = '/sc/proposito';

const JOIN_US_ROUTE = 'https://bit.ly/3QBF14o';

const PRIVACY_POLICY_ROUTE = '/politica-privacidade';

const COOKIES_POLICY_ROUTE = '/politica-cookies';

const TERMS_OF_SERVICE_ROUTE = '/termos-de-uso';

const NAVIGATION = '/nav';

const SEARCH = '/busca';

const STATIC_CONTENT = '/sc';

const HOTSITE = '/hotsite';

const SIGN_UP = '/signup';

const PRODUCTS = '/products';

const GIFT_CARD = 'https://bit.ly/3FeZCbR';

const FIND_STORE = '/sc/encontre-uma-loja';

const FAVORITES_ROUTE = '/favoritos';

const WRITE_REVIEW = '/escreva-uma-avaliacao';

const ABOUT_NIKE_INC = 'https://about.nike.com/en/impact';

const ABOUT_SBF_GROUP = 'https://ri.gruposbf.com.br/';

const ORDER_DELAYED_FORM = 'https://nike-brasil.typeform.com/to/sePN6LN8';

const SITE_MAP = '/mapa-do-site';

const CORINTHIANS = '/sc/corinthians-nike';

const JOURNAL = '/journal';

const REDIRECT_ORDER_MESSAGE_CORINTHIANS =
  'https://www.mantocorinthians.com.br/account#/orders';

const SOCIALCOMMERCE_PRE_REGISTER =
  'https://socialcommerce.gruposbf.com.br/pre-cadastro/nike';

const HUB = '/hub';

/**
This function is used to direct the route to somewhere. For example, footer links.
 */

export const getCoreRoutes = () => ({
  home: HOME_ROUTE,
  attendance: ATTENDANCE_ROUTE,
  cart: CART_ROUTE,
  checkout: CHECKOUT_ROUTE,
  payment: PAYMENT_ROUTE,
  paymentConfirmation: PAYMENT_CONFIRMATION_ROUTE,
  login: LOGIN_ROUTE,
  order: ORDER_ROUTE,
  trackOrder: TRACK_ORDER_ROUTE,
  myAccount: MY_ACCOUNT_ROUTE,
  changeRegistrationData: CHANGE_REGISTRATION_DATA_ROUTE,
  athleteVoucherExtract: ATHLETE_VOUCHER_EXTRACT_ROUTE,
  exchangeVoucherExtract: EXCHANGE_VOUCHER_EXTRACT_ROUTE,
  exchange: EXCHANGE_ROUTE,
  exchangeNikeApp: EXCHANGE_APP_ROUTE,
  exchangeSelectProduct: EXCHANGE_SELECT_PRODUCT_ROUTE,
  sellerLogin: SELLER_LOGIN_ROUTE,
  searchCep: SEARCH_CEP_ROUTE,
  nikeProfile: NIKE_PROFILE_ROUTE,
  snkrs: SNKRS_ROUTE,
  snkrsStock: SNKRS_STOCK_ROUTE,
  snkrsCalendar: SNKRS_CALENDAR_ROUTE,
  geoSelectionLanguage: GEO_SELECTION_LANGUAGE_ROUTE,
  instagram: INSTAGRAM_ROUTE,
  youTube: YOUTUBE_ROUTE,
  facebook: FACEBOOK_ROUTE,
  sustainability: SUSTAINABILITY_ROUTE,
  purpose: PURPOSE_ROUTE,
  aboutNikeInc: ABOUT_NIKE_INC,
  aboutSBFGroup: ABOUT_SBF_GROUP,
  privacyPolicy: PRIVACY_POLICY_ROUTE,
  cookiesPolicy: COOKIES_POLICY_ROUTE,
  termsOfService: TERMS_OF_SERVICE_ROUTE,
  navigation: NAVIGATION,
  search: SEARCH,
  staticContent: STATIC_CONTENT,
  hotsite: HOTSITE,
  signUp: SIGN_UP,
  joinUs: JOIN_US_ROUTE,
  generalQuestions: GENERAL_QUESTIONS,
  findYourSize: FIND_YOUR_SIZE,
  attendanceHome: ATTENDANCE_HOME,
  attendanceExchangePolicy: ATTENDANCE_EXCHANGE_POLICY,
  attendanceDeliveries: ATTENDANCE_DELIVERIES,
  attendanceMembersAdvantage: ATTENDANCE_MEMBERS_ADVANTAGE,
  attendanceRequests: ATTENDANCE_REQUESTS,
  attendancePrivacyPolicy: ATTENDANCE_PRIVACY_POLICY,
  attendanceTermsOfUse: ATTENDANCE_TERMS_OF_USE,
  exchangesAndReturns: EXCHANGES_AND_RETURNS,
  attendancePayments: ATTENDANCE_PAYMENTS,
  attendanceProducts: ATTENDANCE_PRODUCTS,
  attendanceCorporate: ATTENDANCE_CORPORATE,
  communicationPreference: NIKE_COMMUNICATION_PREFERENCE,
  nikeVesteAGarra: NIKE_VESTE_A_GARRA,
  products: PRODUCTS,
  giftCard: GIFT_CARD,
  findStore: FIND_STORE,
  favorites: FAVORITES_ROUTE,
  writeReview: WRITE_REVIEW,
  orderDelayedForm: ORDER_DELAYED_FORM,
  siteMap: SITE_MAP,
  redirectOrderMessageCorinthians: REDIRECT_ORDER_MESSAGE_CORINTHIANS,
  socialcommercePreRegister: SOCIALCOMMERCE_PRE_REGISTER,
  nikeIncTOS: NIKE_INC_TOS,
  fisiaBearerPortal: FISIA_BEARER_PORTAL,
  journal: JOURNAL,
  corinthians: CORINTHIANS,
  hub: HUB,
});
