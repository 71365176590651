import { ThrottleOptions, isThrottleOptions } from './types';

export const throttle = (
  callback: () => void,
  options: ThrottleOptions | number,
) => {
  let delay: number;
  const leading = (options as ThrottleOptions).leading || false;
  const trailing = (options as ThrottleOptions).trailing || false;

  let timeoutID: NodeJS.Timeout | undefined;
  let cancelled: boolean;
  let lastExec = leading ? 0 : Date.now();

  if (isThrottleOptions(options)) {
    delay = options.delay;
  } else {
    delay = options;
  }

  const clearExistingTimeout = () => {
    if (timeoutID) {
      clearTimeout(timeoutID);
      timeoutID = undefined;
    }
  };

  const cancel = () => {
    clearExistingTimeout();
    cancelled = true;
  };

  const trailingExec = (exec: () => void) => {
    if (trailing) {
      clearExistingTimeout();
      if (!timeoutID) {
        timeoutID = setTimeout(exec, delay);
      }
    }
  };

  const wrapper = (...args: []) => {
    if (cancelled) {
      return;
    }

    const exec = () => {
      lastExec = Date.now();
      callback.apply(this, args);
    };

    trailingExec(exec);

    const runTime = Date.now() - lastExec;

    if (runTime > delay) {
      exec();
    }
  };

  wrapper.cancel = cancel;
  return wrapper;
};
