export const PAYMENT_TABS_VALUES = {
  BO: 'bank_slip',
  GC: 'gift_card',
  VT: 'exchange_voucher',
  VA: 'athlete_voucher',
  PI: 'pix',
  CC: 'credit_card',
  LP1: 'payment_link',
  PP: 'physical_payment',
} as {
  [key: string]: string;
};

export enum PAYMENT_TYPE {
  BO = 'BO',
  VT = 'VT',
  VA = 'VA',
  GC = 'GC',
  PI = 'PI',
  LP1 = 'LP1',
  PP = 'PP',
}
