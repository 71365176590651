import { type ClientError } from '@/common/services/api/types';
import { STATUS_CODE } from '@/common/utils/http/statusCode/statusCode';
import { extractReferenceID } from '@/common/utils/referenceID/extractReferenceID/extractReferenceID';
import {
  trackingExceptionError,
  trackingMessageError,
} from '@/common/utils/tracking/tracking';
import { deleteCartUuid } from '@/modules/guestCheckout/services/cart/cart';
import {
  CheckoutClientError,
  CheckoutErrorResponse,
} from '@/modules/guestCheckout/types/checkoutErrorResponse';
// Please review the messages: https://github.com/grupo-sbf/checkout/wiki/Payload-errors---V1

export const DEFAULT_ERROR_MESSAGE = 'Algo inesperado aconteceu';
export const GIFT_CARD_NUMBER_ERROR_CODE = 'CHK067';
export const GIFT_CARD_PIN_ERROR_CODE = 'CHK068';
export const GITT_CARD_PAYMENT = 'CHK065';
export const CART_NOT_FOUND_ERROR_CODE = 'CHK009';
export const ATHLETE_VOUCHER_INSUFFICIENT_BALANCE =
  'Saldo do voucher insuficiente. Selecione outro meio de pagamento.';
export const PAYMENT_FAILED = 'CHK059';
export const PAYMENT_FAILED_CREDIT = 'CHK085';

export const ERRORS = {
  FRONT001: {
    message: 'Pedido não encontrado',
  },
  // CHK001: {
  //   message: 'Algo inesperado aconteceu! Tente novamente :)',
  // },
  CHK002: {
    message:
      'Produto fora de estoque. Ative o Avise-me para ser notificado quando o produto estiver disponível.',
  },
  CHK003: {
    message:
      'Cupom indisponível. Verifique se o código do cupom foi preenchido corretamente.',
  },
  CHK004: {
    message:
      'Cupom inválido. Verifique se o código do cupom foi preenchido corretamente.',
  },
  CHK005: {
    message: 'Cupom já aplicado. Verifique o desconto no resumo.',
  },
  // CHK006: {
  //   message: 'Item não encontrado na simulação anterior',
  // },
  // CHK007: {
  //   message: 'Credencial fornecida nos headers são inválidas',
  // },
  // CHK008: {
  //   message: 'Credencial fornecida não possui acesso ao carrinho',
  // },
  CHK009: {
    message: 'Carrinho não encontrado',
    handler: () => {
      deleteCartUuid();
    },
  },
  // CHK010: {
  //   message: 'Request realizada com propriedade faltando',
  // },
  // CHK011: {
  //   message: 'Valor enviado na request é desconhecido',
  // },
  // CHK012: {
  //   message: 'Quantidade enviada é inválida com a simulação anterior',
  // },
  CHK013: {
    message: 'Embalagem para presente indisponível para este produto.',
  },
  CHK014: {
    message: 'Personalização indisponível para este produto.',
  },
  CHK015: {
    message:
      'Falha ao realizar a simulação, considera problemas com gift card ou vouchers aplicados',
  },
  CHK016: {
    message: 'Atualização de frete necessária. Por favor, refaça o cálculo.',
  },
  CHK017: {
    message:
      'Adicione pelo menos um produto no carrinho para calcular o frete.',
  },
  // CHK018: {
  //   message:
  //     'Algum dos itens enviados ficou sem centro de distribuição após o cálculo do frete',
  // },
  // CHK019: {
  //   message:
  //     'O cliente enviou um id de item do carrinho que não está contido no carrinho atual',
  // },
  // CHK020: {
  //   message: 'Reserva não encontrada para atualização',
  // },
  // CHK021: {
  //   message: 'Produto não lançado',
  // },
  // CHK022: {
  //   message:
  //     'Mensagem padrão que não possui mapeamento definido para erros de estoque',
  // },
  CHK023: {
    message: DEFAULT_ERROR_MESSAGE,
  },
  CHK024: {
    getMessage: ({ detail }) => {
      const deliveryTypes = detail?.DeliveryTypes === 'cannot be blank';
      return deliveryTypes
        ? `Ocorreu um erro no endereço. Volte para a etapa de Identificação e selecione o endereço novamente.`
        : DEFAULT_ERROR_MESSAGE;
    },
    message: DEFAULT_ERROR_MESSAGE,
  },
  // CHK025: {
  //   message:
  //     'Algum campo da simulação de place order é inválido segundo as validações estáticas feitas pelo sistema',
  // },
  // CHK026: {
  //   message:
  //     'O cliente enviou um id de voucher do carrinho que não está contido no carrinho atual',
  // },
  // CHK027: {
  //   message:
  //     'O cliente enviou um id de cupom do carrinho que não está contido no carrinho atual',
  // },
  // CHK028: {
  //   message: 'Credencial fornecida não possui acesso ao place order',
  // },
  // CHK029: {
  //   message: 'Place order não encontrado',
  // },
  CHK030: {
    message:
      'Erro ao validar o CPF. Verifique se o número e formato está correto e tente novamente. ',
  },
  CHK031: {
    message: 'O cliente é menor de idade',
  },
  // CHK032: {
  //   message: 'Token para guest client inválido',
  // },
  // CHK033: {
  //   message: 'Token sem permissão de acesso ao recurso',
  // },
  // CHK034: {
  //   message: 'Propriedade enviada na requisição com formato inválido',
  // },
  // CHK035: {
  //   message:
  //     'Mensagem padrão que não possui mapeamento definido para erros de pagamento',
  // },
  CHK036: {
    message:
      'Pagamento já realizado. Verifique o status da sua compra em Meus Pedidos.',
  },
  // CHK037: {
  //   message: 'Os SKUs da entrega não são os mesmos do tipo selecionado',
  // },
  CHK038: {
    message:
      'CEP inválido. Verifique se o número e formato está correto e tente novamente. ',
  },
  CHK039: {
    message:
      'CEP inválido. Informe o número correto ou outro CEP para continuar a compra. ',
  },
  // CHK040: {
  //   message: 'O tipo da entrega não está disponível para a simulação',
  // },
  // CHK041: {
  //   message:
  //     'O período de entrega selecionado não está disponível para a simulação',
  // },
  // CHK042: {
  //   message:
  //     'O método de pagamento selecionado não está disponível para a simulação',
  // },
  CHK043: {
    message: 'Selecione o tipo de entrega para continuar a compra.',
  },
  // CHK044: {
  //   message: 'O parcelamento não está disponível na simulação',
  // },
  // CHK045: {
  //   message: 'Mensagem de erro interno para pedido',
  // },
  // CHK046: {
  //   message: 'O id do tipo de está inválido',
  // },
  // CHK047: {
  //   message: 'Mensagem de erro interno na checagem do pedido gerado',
  // },
  // CHK048: {
  //   message:
  //     'Ocorreu alguma mudança na quebra de entregas após o cliente enviar um place order',
  // },
  // CHK049: {
  //   message:
  //     'Ocorreu alguma mudança no preço de um item após o cliente enviar um place order',
  // },
  // CHK050: {
  //   message:
  //     'Ocorreu alguma mudança no preço de frete do tipo de entrega selecionado após o cliente enviar um place order',
  // },
  // CHK051: {
  //   message:
  //     'O valor a pagar do pedido mudou após o cliente enviar um place order',
  // },
  // CHK052: {
  //   message:
  //     'O valor total dos items do pedido mudou após o cliente enviar um place order',
  // },
  // CHK053: {
  //   message: 'O desconto do pedido mudou após o cliente enviar um place order',
  // },
  // CHK054: {
  //   message:
  //     'O valor total do frete mudou após o cliente enviar um place order',
  // },
  // CHK055: {
  //   message:
  //     'O saldo de vale trocas mudou após o cliente enviar um place order',
  // },
  CHK056: {
    message: 'Ocorreu um erro ao adicionar ou remover o cartão presente.',
  },
  // CHK057: {
  //   message:
  //     'O prazo de entrega para a modalidade selecionada mudou após o cliente enviar um place order',
  // },
  CHK058: {
    message:
      'Para finalizar a compra com cupom, entre ou crie uma conta Nike. ',
  },
  CHK059: {
    message:
      'Erro ao finalizar pagamento. Verifique se as informações estão corretas e tente novamente.',
  },
  CHK060: {
    message: 'Erro ao finalizar o pedido. Tente novamente.',
  },
  CHK061: {
    message: 'Erro ao finalizar o pedido. Tente novamente.',
  },
  // CHK062: {
  //   message:
  //     'Ocorre quando os SKUs dos deliveries não correspondem ao SKUs retornados da API de estoque',
  // },
  CHK063: {
    message: 'Erro ao finalizar o pedido. Tente novamente.',
  },
  // CHK064: {
  //   message:
  //     'Ocorre quando a resposta do serviço de cálculo de frete e quebra de entregas não consegue encontrar um meio de realizar a entrega de algum dos itens no endereço informado',
  // },
  CHK065: {
    message:
      'Erro ao validar o cartão presente. Verifique a data de validade e se o número e PIN estão corretos.',
  },
  CHK066: {
    message: 'Erro ao validar o cartão presente. Tente novamente.',
  },
  CHK067: {
    message: 'Número inválido.',
  },
  CHK068: {
    message: 'PIN inválido.',
  },
  CHK069: {
    message:
      'O cartão presente não tem saldo suficiente. Selecione outro meio de pagamento para finalizar a compra.',
  },
  CHK070: {
    message: 'Selecione apenas 1 cartão presente para continuar a compra.',
  },
  CHK071: {
    getMessage: ({ detail }) => {
      const purchaseLimit = detail?.purchaseLimit;
      return `Erro ao adicionar ao carrinho. Este produto tem um limite de ${purchaseLimit} ite${
        purchaseLimit === '1' ? 'm' : 'ms'
      } por CPF`;
    },
    message:
      'Erro ao adicionar ao carrinho. Este produto tem um limite de itens por CPF',
  },
  CHK072: {
    getMessage: ({ detail }) => {
      const purchaseLimit = detail?.purchaseLimit;
      return `Erro ao adicionar ao carrinho. Este produto tem um limite de ${purchaseLimit} ite${
        purchaseLimit === '1' ? 'm' : 'ms'
      } por CPF`;
    },
    message:
      'Erro ao adicionar ao carrinho. Este produto tem um limite de itens por CPF',
  },
  // CHK073: {
  //   message:
  //     'Retornado quando o usuário está tentando comprar um item que ainda não foi lançado',
  // },
  // CHK074: {
  //   message:
  //     'Retornado quando o usuário envia um meio de pagamento no place order, mas esse meio de pagamento não está disponível por algum motivo',
  // },
  // CHK075: {
  //   message:
  //     'Retornado quando os últimos meios de pagamento que o usuário viu mudaram no momento que ele decide fazer um place order',
  // },
  CHK076: {
    message:
      'Não é possível realizar a entrega neste endereço. Informe outro para continuar a compra. ',
  },
  // CHK077: {
  //   message:
  //     'Retornado quando o usuário guest envia um item exclusivo para compra',
  // },
  // CHK078: {
  //   message:
  //     'Retornado quando o item do carrinho ou pedido está com algum bloqueio (temporário ou permanente)',
  // },
  CHK079: {
    message:
      'Erro ao finalizar a compra. Entre em contato com o SAC para mais informações.',
  },
  // CHK080: {
  //   message:
  //     'Retornado quando algum dos itens está com cadastro incorreto de preço negativo',
  // },
  // CHK081: {
  //   message:
  //     'Retornado quando algum dos itens está com preço menor que o preço de custo e essa situação não é permitida pelo cadastro dele',
  // },
  CHK082: {
    getMessage: ({ detail }) => {
      return `Não é possível adicionar mais itens deste produto ao carrinho. Máximo de ${detail?.maxItemQuantity} unidades por compra.`;
    },
    message:
      'Não é possível adicionar mais itens deste produto ao carrinho. Máximo de unidades por compra atingido.',
  },
  CHK083: {
    getMessage: ({ detail }) => {
      return `Não é possível adicionar mais itens ao carrinho. Máximo de ${detail?.maxItemLength} unidades por compra.`;
    },
    message: `Não é possível adicionar mais itens ao carrinho. Máximo de unidades por compra atingido.`,
  },
  // CHK084: {
  //   message:
  //     'Retornado quando um usuário guest tenta aplicar um voucher ao pedido',
  // },
  CHK085: {
    message:
      'Erro ao finalizar o pagamento. Verifique se os dados do seu cartão estão corretos e tente novamente.',
  },
  // CHK086: {
  //   message: 'Retornado quando uma empresa tenta fechar um pedido como guest',
  // },
  // CHK087: {
  //   message:
  //     'Retornado quando o mapeamento do canal de vendas não é encontrado',
  // },
  CHK088: {
    message: 'Selecione um meio de pagamento para continuar',
  },
  CHK089: {
    message: 'Ocorreu um erro ao adicionar ou remover o cartão presente.',
  },
  // CHK090: {
  //   message: 'Retornado quando há um desconto e nenhum cupom foi informado',
  // },
  // CHK091: {
  //   message:
  //     'Retornado quando há um saldo de vale trocas/voucher e nenhum foi informado',
  // },
  // CHK092: {
  //   message:
  //     'Retornado quando há um cupom de desconto aplicado e a regra comercial de cupom está desligada',
  // },
  // CHK093: {
  //   message:
  //     'Retornado quando o cálculo de frete retorna um centro de distribuição inválido',
  // },
  // CHK094: {
  //   message:
  //     'Retornado quando por algum motivo o uso de voucher está bloqueado e o cliente tenta utilizar um voucher',
  // },
  // CHK095: {
  //   message:
  //     'Retornado quando ocorre algum erro inesperado na api de omnichannel',
  // },
  // CHK096: {
  //   message:
  //     'Retornado quando ocorre algum erro/validação de dado na api de omnichannel',
  // },
  CHK097: {
    message: 'Ocorreu um erro ao adicionar ou remover o cartão presente.',
  },
  // CHK098: {
  //   message:
  //     'Retornado quando a simulação não possuir saldo devedor, impossibilitando a aplicação do voucher',
  // },
  CHK099: {
    message:
      'Erro ao utilizar o vale-troca. Ele pode ser utilizado somente em compras enviadas para o mesmo endereço do pedido original. Fale com o SAC para mais informações.',
  },
  CHK100: {
    message:
      'Erro ao finalizar o pagamento. O valor da compra deve ser igual ou menor que o valor do voucher de atleta.',
  },
  CHK112: {
    message:
      'Cupom inválido. Verifique se o código do cupom foi preenchido corretamente.',
  },
  // CHK999: {
  //   message:
  //     'Retornado quando o cálculo de frete muda de CD normal para CD Omnichannel e o place order veio para a nova plataforma de checkout, nesse caso vamos processar o pedido pelo legado',
  // },
  DEFAULT: {
    message: 'Algo inesperado aconteceu! Tente novamente.',
  },
} as {
  [key: string]: {
    getMessage?: (error: CheckoutErrorResponse) => string;
    message: string;
    handler?: () => void;
  };
};

export const handleError = (error: CheckoutErrorResponse | undefined) => {
  if (!error) {
    return;
  }
  const hasError = ERRORS[error.code];
  if (hasError) {
    if (hasError?.handler) {
      hasError.handler();
    }
    if (hasError?.getMessage) {
      return hasError.getMessage(error);
    }
    return `${hasError.message} (${error.code})`;
  }
};

export const getResponseError = (error: CheckoutErrorResponse | undefined) =>
  error ? ERRORS[error.code] : null;

export const handleResponse = (e: ClientError<CheckoutClientError>) => {
  const errorDetailCode = e?.response?.data?.detail?.items?.find(
    (item) => item.code,
  );

  const message = errorDetailCode
    ? handleError(errorDetailCode)
    : handleError(e?.response?.data);

  const statusCode = Number(e?.response?.status || 0);

  if (e.response?.data?.code) {
    e.response.data.format = {
      message:
        message || `${DEFAULT_ERROR_MESSAGE} (${e.response?.data?.code})`,
    };
    trackingMessageError({
      flow: 'request-errors',
      message: `Request errors: ${e.response?.data?.code}`,
      extraDetails: {
        messageUser: e.response?.data?.format?.message,
        error: JSON.stringify(e.response?.data),
      },
      checkpoint: 'app',
      namespace: 'guest-checkout()',
    });
  } else if (STATUS_CODE.FORBIDDEN === statusCode && e.response) {
    const errorMessage = String(e?.response?.data);
    const referenceID = extractReferenceID(errorMessage);

    e.response.data = {
      format: {
        message: `${DEFAULT_ERROR_MESSAGE} (${referenceID})`,
      },
      message: referenceID,
      code: String(STATUS_CODE.FORBIDDEN),
    };

    return Promise.reject(e);
  } else {
    trackingExceptionError({
      error: e,
      flow: 'request-errors',
      checkpoint: 'app',
      namespace: 'guest-checkout()',
    });
  }

  // TODO: when the code doesn't come up, it's because something unexpected has happened systemically.

  return e;
};
