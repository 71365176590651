import styled, { css } from 'styled-components';

export const ButtonWrapper = styled.button`
  ${({ theme }) => css`
    line-height: ${theme.lineHeights.tight};
    cursor: pointer;
    outline: none;
    border: ${theme.borderWidth.none};
    background: ${theme.colors.neutral[100]};
    position: relative;
    height: 32rem;
    width: 32rem;
    color: ${theme.colors.primary};

    :hover {
      opacity: ${theme.opacity[700]};
      background-color: ${theme.colors.neutral[300]};
      border-radius: ${theme.borderRadius.circular};
    }

    &.focus-visible {
      box-shadow: ${theme.shadow.focus};
    }
  `}
`;
