import { trackingEvent } from '@/common/utils/tracking/tracking';

const BANNER_CATEGORY = 'UI - Banner aceite do uso de cookie';

export const sendClickButtonEvent = () => {
  trackingEvent({
    event: 'click_banner_cookie_accept',
    category: BANNER_CATEGORY,
    action: 'Click - Concordar com o uso de cookie',
    label: 'Clicou para concordar com o uso de cookie no site',
  });
};

export const sendViewBannerEvent = () => {
  trackingEvent({
    event: 'view_banner_cookie',
    category: BANNER_CATEGORY,
    action: 'Visualização do banner de aceite do uso de cookie',
    label: 'Visualizou o banner de aceite do uso de cookie',
    nonInteraction: true,
  });
};

export const sendPrivacyPolicyEvent = () => {
  trackingEvent({
    event: 'click_banner_cookie_privacy',
    category: BANNER_CATEGORY,
    action: 'Click - Política de Privacidade',
    label: 'Clicou no link Política de Privacidade',
  });
};
