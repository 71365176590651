import { isProdEnv } from '@/common/utils/environment/environment';

export type HomePathsKeyType = 'desktop' | 'mobile' | 'preview';
export type HomePathsType = Record<HomePathsKeyType, string>;

export type ProductPathsKeyType = 'base';
export type ProductPathsType = Record<ProductPathsKeyType, string>;

export type IdentityPathsKeyType = 'logout' | 'ssoLogin' | 'callbackSsoLogin';
export type IdentityPathsType = Record<IdentityPathsKeyType, string>;

export type StaticPagesPathsKeyType = 'page' | 'urls';
export type StaticPagesPathsType = Record<StaticPagesPathsKeyType, string>;

export type CheckoutPathsKeyType =
  | 'address'
  | 'cart'
  | 'addCart'
  | 'syncCart'
  | 'cartShippingFreight'
  | 'giftWrap'
  | 'completeOrder'
  | 'completeOrderDetails'
  | 'completeOrderPaymentLink'
  | 'coupon'
  | 'exchangeVoucher'
  | 'exchangeVoucherExtract'
  | 'exchangeVoucherAthleteExtract'
  | 'exchangeVoucherAthleteEligible'
  | 'exchangeVoucherCheckout'
  | 'payments'
  | 'giftCard'
  | 'removeGiftCard'
  | 'shippingModality'
  | 'shippingFreight'
  | 'exchangeVoucherAthleteCheckout'
  | 'migrateCart'
  | 'myCards';

export type GuestCheckoutPathsKeyType =
  | 'cart'
  | 'placeOrder'
  | 'payments'
  | 'order';

export type CheckoutPathsType = Record<CheckoutPathsKeyType, string>;

export type CustomersPathsKeyType =
  | 'registration'
  | 'registrationPut'
  | 'registrationPutBFFPhysical'
  | 'registrationPutBFFJuridical'
  | 'myAccountInfoBFF'
  | 'cpf'
  | 'cnpj'
  | 'zipCode'
  | 'address'
  | 'orderDetails'
  | 'myOrders'
  | 'myCards'
  | 'myCardsDelete'
  | 'orderDetailsTracking'
  | 'documentChecker'
  | 'keycloakToken'
  | 'serverSideKeycloakToken'
  | 'customerAuth'
  | 'customerAccountAuth'
  | 'myOrdersGuest';

export type CustomersPathsType = Record<CustomersPathsKeyType, string>;
export type GuestCheckoutPathsType = Record<GuestCheckoutPathsKeyType, string>;

export type PersonalizationPathsKeyType = 'addToCart' | 'iFrameRoute';
export type PersonalizationPathsType = Record<
  PersonalizationPathsKeyType,
  string
>;

export type SizebayPathsKeyType = 'trackAddToCart' | 'trackCompleteOrder';
export type SizebayPathsType = Record<SizebayPathsKeyType, string>;

export type FavoritesPathsType = string;

export type JournalPathsKeyType =
  | 'home'
  | 'content'
  | 'contentUrls'
  | 'author'
  | 'authorUrls';
export type JournalPathsType = Record<JournalPathsKeyType, string>;

export type HubPathsKeyType = 'home' | 'content' | 'contentUrls';
export type HubPathsType = Record<HubPathsKeyType, string>;

type CorePathsType = {
  home: HomePathsType;
  product: ProductPathsType;
  identity: IdentityPathsType;
  staticPages: StaticPagesPathsType;
  customers: CustomersPathsType;
  checkout: CheckoutPathsType;
  personalization: PersonalizationPathsType;
  guestCheckout: GuestCheckoutPathsType;
  sizebay: SizebayPathsType;
  favorites: FavoritesPathsType;
  journal: JournalPathsType;
  hub: HubPathsType;
  exclusiveFirstAppLink: string;
};

const HOME_API_DESKTOP_PATH = '/v3/homes/desktop';
const HOME_API_MOBILE_PATH = '/v3/homes/mobile';
const HOME_PREVIEW_API_PATH = '/v3/homes/simulacao';

const PRODUCT_API_PATH = '/products';
const APP_EXCLUSIVE_PDP_LINK = 'https://click.nike.com.br/Q0y9/appexclusive';

const IDENTITY_LOGOUT_API_PATH = '/v4.2/clientes/logout';
const IDENTITY_SSO_LOGIN_API_PATH = '/v5.1/clientes-sso/login-com-sso';
const IDENTITY_SSO_CALLBACK_LOGIN_API_PATH =
  '/v5.1/clientes-sso/callback-login';

const CUSTOMERS_REGISTRATION_API_PATH = '/v4.1/clientes';
const CUSTOMERS_REGISTRATION_PUT_API_PATH = '/v3/clientes';
const CUSTOMERS_REGISTRATIONBFF_PF_PUT_API_PATH = '/account/register/physical';
const CUSTOMERS_REGISTRATIONBFF_PJ_PUT_API_PATH = '/account/register/juridical';
const MY_ACCOUNT_INFO_BFF = '/myaccount/info';
const CUSTOMERS_CHECK_CPF_API_PATH = '/v2/validacao/cpf';
const CUSTOMERS_CHECK_CNPJ_API_PATH = '/v2/validacao/cnpj';
const CUSTOMERS_CHECK_ZIP_CODE_API_PATH = '/v2/consultacep';
const CUSTOMERS_CHECK_ADDRESS_API_PATH = '/v4.2/endereco';
const CUSTOMERS_ORDER_DETAILS_API_PATH = '/v5.0/clientes/meus-pedidos/detalhe';
const CUSTOMERS_ORDER_DETAILS_TRACKING_API_PATH =
  '/v5.0/clientes/meus-pedidos/detalhe/tracking';
const CUSTOMERS_MYORDERS_API_PATH = '/v5.0/clientes/minha-conta/meus-pedidos';
const CUSTOMERS_MYCARDS_API_PATH = '/v2.2/cartoes';
const CUSTOMERS_MYCARDS_DELETE_API_PATH = '/v2.1/cartoes';
const CUSTOMERS_DOCUMENT_CHECKER_API_PATH = '/v1/check';
const CUSTOMERS_KEYCLOAK_TOKEN =
  '/auth/realms/service-now/protocol/openid-connect/token';
const SERVER_SIDE_REGISTRATION_KEYCLOAK_PATH = '/api/keycloak-token';
const CART_API_PATH = '/v5.0/carrinhos';
const CART_ADD_API_PATH = '/v5.0/carrinhos/adicionar';
const CART_SYNC = '/v5.0/carrinhos/sincronizar';
const CART_SHIPPING_MODALITY_API_PATH = '/v4/checkout/entregas';
const CART_GIFT_WRAP = '/v5.0/carrinhos/habilitarpresente';
const SHIPPING_FREIGTH_API_PATH = '/v5.1/frete';
const COMPLETE_ORDER_PATH = '/v4.1/checkout/processamentopedido';
const COMPLETE_ORDER_PAYMENT_LINK_PATH = '/v5.0/checkout/processamentopedido';
const COMPLETE_ORDER_DETAILS_PATH = '/v5/pedidos';
const COMPLETE_ORDER_DETAILS_GUEST_PATH =
  '/v5.0/clientes/meus-pedidos/detalhe/guestcheckout';
const COUPON_URL = '/v5.0/carrinhos/promocodes';
const EXCHANGE_VOUCHER_API_PATH = '/v5.0/carrinhos/valetrocas';
const EXCHANGE_VOUCHER_ATHLETE_ELIGIBLE_PATH =
  '/exchange-voucher/api/ExchangeVoucher/voucher/Eligible';
const EXCHANGE_VOUCHER_ATHLETE_EXTRACT_PATH =
  '/exchange-voucher/api/ExchangeVoucher/voucher';
const EXCHANGE_VOUCHER_EXTRACT_PATH = `/exchange-voucher/api/ExchangeVoucher`;
const EXCHANGE_VOUCHER_CHECKOUT_API_PATH = `/exchange-voucher/api/ExchangeVoucherCheckout`;
const PAYMENTS_API_PATH =
  '/v5.0/checkout/pagamentos?habilitarCompraRapida=true';
const GIFT_CARD_API_PATH = '/v5.0/carrinhos/aplicar-gift-card';
const REMOVE_GIFT_CARD_API_PATH = '/v5.0/carrinhos/remover-gift-card';
const MIGRATE_CARD = '/v5.0/checkout/migrar-carrinho';

const STATIC_PAGES_API_PATH = '/v5.0/paginas-estaticas';
const STATIC_PAGES_URLS_API_PATH = '/v5.0/paginas-estaticas/urls';
const EXCHANGE_VOUCHER_ATHLETE_CHECKOUT_API_PATH = `${EXCHANGE_VOUCHER_CHECKOUT_API_PATH}/voucher`;

// Autoatendimento
const CUSTOMER_SERVICE_AUTH = '/customerservice-api/api/auth';
const CUSTOMER_SERVICE_ACCOUNT_AUTH =
  '/customerservice-api/api/auth/nike-account';

// Personalization
const CUSTOM_PRODUCTS_ADD_TO_CART = '/v5.0/carrinhos/add-custom-item-fisia';
const PERSONALIZATION_IFRAME_ROUTE = '/frontend/v2';

// TODO: replace this for the bff web route when its done
const CHECKOUT_MY_CARDS = '/mobile/payments/credit-cards';

const getEnvPaths = (env: 'dev' | 'prod'): GuestCheckoutType => {
  const envUrls: EnvPathsType = {
    prod: {
      guestCheckout: {
        cart: '/v1/shopping-carts',
        placeOrder: '/v1/place-orders',
        payments: '/customers',
        order: '/v5.0/clientes/meus-pedidos/detalhe/guestcheckout',
      },
    },
    dev: {
      guestCheckout: {
        cart: '/v1/shopping-carts',
        placeOrder: '/v1/place-orders',
        payments: '/customers',
        order: '/v5.0/clientes/meus-pedidos/detalhe/guestcheckout',
      },
    },
  };
  return envUrls[env];
};

type GuestCheckoutType = {
  guestCheckout: GuestCheckoutPathsType;
};

type EnvPathsType = {
  prod: GuestCheckoutType;
  dev: GuestCheckoutType;
};

const ENV_TYPE = isProdEnv ? 'prod' : 'dev';
// GuestCheckout PROD/DEV
const GUEST_CART_API_PATH = getEnvPaths(ENV_TYPE).guestCheckout.cart;
const GUEST_PLACE_ORDER_API_PATH =
  getEnvPaths(ENV_TYPE).guestCheckout.placeOrder;
const GUEST_PAYMENTS_API_PATH = getEnvPaths(ENV_TYPE).guestCheckout.payments;
const GUEST_ORDER_API_PATH = getEnvPaths(ENV_TYPE).guestCheckout.order;

// Freight
const CART_SHIPPING_FREIGHT_API_PATH = '/v5.0/carrinhos/tiposentrega?ia=1';

// Address

const ADDRESS_CHECKOUT = '/account/addresses';

// Sizebay
const SIZEBAY_TRACK_PRODUCT_ADD_TO_CART = '/cart';
const SIZEBAY_TRACK_COMPLETED_ORDER = '/ordered';

// Favorites
const FAVORITES_API_PATH = '/favorites';

// Journal
const JOURNAL_API_PATH_HOME = '/marvin/journal/home';
const JOURNAL_API_PATH_CONTENT = '/marvin/journal/content';
const JOURNAL_API_PATH_CONTENT_URLS = '/marvin/journal/urls';
const JOURNAL_API_PATH_AUTHOR = '/marvin/journal/author';
const JOURNAL_API_PATH_AUTHOR_URLS = '/marvin/journal/author/urls';

// Purpose Hub
const HUB_API_PATH_HOME = '/marvin/hub/home';
const HUB_API_PATH_CONTENT = '/marvin/hub/content';
const HUB_API_PATH_CONTENT_URLS = '/marvin/hub/urls';

/**
This function is used for API paths (API url's parts).
It's necessary to separate into contexts, as well as “customer” and “checkout”, which are already created.
If necessary, create a new context following the pattern.
 */

export const getCorePaths = (): CorePathsType => ({
  home: {
    desktop: HOME_API_DESKTOP_PATH,
    mobile: HOME_API_MOBILE_PATH,
    preview: HOME_PREVIEW_API_PATH,
  },
  product: {
    base: PRODUCT_API_PATH,
  },
  staticPages: {
    page: STATIC_PAGES_API_PATH,
    urls: STATIC_PAGES_URLS_API_PATH,
  },
  identity: {
    logout: IDENTITY_LOGOUT_API_PATH,
    ssoLogin: IDENTITY_SSO_LOGIN_API_PATH,
    callbackSsoLogin: IDENTITY_SSO_CALLBACK_LOGIN_API_PATH,
  },
  customers: {
    registration: CUSTOMERS_REGISTRATION_API_PATH,
    registrationPut: CUSTOMERS_REGISTRATION_PUT_API_PATH,
    registrationPutBFFPhysical: CUSTOMERS_REGISTRATIONBFF_PF_PUT_API_PATH,
    registrationPutBFFJuridical: CUSTOMERS_REGISTRATIONBFF_PJ_PUT_API_PATH,
    myAccountInfoBFF: MY_ACCOUNT_INFO_BFF,
    cpf: CUSTOMERS_CHECK_CPF_API_PATH,
    cnpj: CUSTOMERS_CHECK_CNPJ_API_PATH,
    zipCode: CUSTOMERS_CHECK_ZIP_CODE_API_PATH,
    address: CUSTOMERS_CHECK_ADDRESS_API_PATH,
    orderDetails: CUSTOMERS_ORDER_DETAILS_API_PATH,
    orderDetailsTracking: CUSTOMERS_ORDER_DETAILS_TRACKING_API_PATH,
    myOrders: CUSTOMERS_MYORDERS_API_PATH,
    myCards: CUSTOMERS_MYCARDS_API_PATH,
    myCardsDelete: CUSTOMERS_MYCARDS_DELETE_API_PATH,
    myOrdersGuest: COMPLETE_ORDER_DETAILS_GUEST_PATH,
    documentChecker: CUSTOMERS_DOCUMENT_CHECKER_API_PATH,
    keycloakToken: CUSTOMERS_KEYCLOAK_TOKEN,
    serverSideKeycloakToken: SERVER_SIDE_REGISTRATION_KEYCLOAK_PATH,
    customerAuth: CUSTOMER_SERVICE_AUTH,
    customerAccountAuth: CUSTOMER_SERVICE_ACCOUNT_AUTH,
  },
  checkout: {
    address: ADDRESS_CHECKOUT,
    cart: CART_API_PATH,
    cartShippingFreight: CART_SHIPPING_FREIGHT_API_PATH,
    addCart: CART_ADD_API_PATH,
    syncCart: CART_SYNC,
    giftWrap: CART_GIFT_WRAP,
    completeOrder: COMPLETE_ORDER_PATH,
    completeOrderDetails: COMPLETE_ORDER_DETAILS_PATH,
    completeOrderPaymentLink: COMPLETE_ORDER_PAYMENT_LINK_PATH,
    coupon: COUPON_URL,
    exchangeVoucher: EXCHANGE_VOUCHER_API_PATH,
    exchangeVoucherAthleteEligible: EXCHANGE_VOUCHER_ATHLETE_ELIGIBLE_PATH,
    exchangeVoucherAthleteExtract: EXCHANGE_VOUCHER_ATHLETE_EXTRACT_PATH,
    exchangeVoucherExtract: EXCHANGE_VOUCHER_EXTRACT_PATH,
    exchangeVoucherCheckout: EXCHANGE_VOUCHER_CHECKOUT_API_PATH,
    payments: PAYMENTS_API_PATH,
    giftCard: GIFT_CARD_API_PATH,
    removeGiftCard: REMOVE_GIFT_CARD_API_PATH,
    shippingModality: CART_SHIPPING_MODALITY_API_PATH,
    shippingFreight: SHIPPING_FREIGTH_API_PATH,
    exchangeVoucherAthleteCheckout: EXCHANGE_VOUCHER_ATHLETE_CHECKOUT_API_PATH,
    migrateCart: MIGRATE_CARD,
    myCards: CHECKOUT_MY_CARDS,
  },
  guestCheckout: {
    cart: GUEST_CART_API_PATH,
    placeOrder: GUEST_PLACE_ORDER_API_PATH,
    payments: GUEST_PAYMENTS_API_PATH,
    order: GUEST_ORDER_API_PATH,
  },
  personalization: {
    addToCart: CUSTOM_PRODUCTS_ADD_TO_CART,
    iFrameRoute: PERSONALIZATION_IFRAME_ROUTE,
  },
  sizebay: {
    trackAddToCart: SIZEBAY_TRACK_PRODUCT_ADD_TO_CART,
    trackCompleteOrder: SIZEBAY_TRACK_COMPLETED_ORDER,
  },
  favorites: FAVORITES_API_PATH,
  journal: {
    home: JOURNAL_API_PATH_HOME,
    content: JOURNAL_API_PATH_CONTENT,
    contentUrls: JOURNAL_API_PATH_CONTENT_URLS,
    author: JOURNAL_API_PATH_AUTHOR,
    authorUrls: JOURNAL_API_PATH_AUTHOR_URLS,
  },
  hub: {
    home: HUB_API_PATH_HOME,
    content: HUB_API_PATH_CONTENT,
    contentUrls: HUB_API_PATH_CONTENT_URLS,
  },
  exclusiveFirstAppLink: APP_EXCLUSIVE_PDP_LINK,
});
