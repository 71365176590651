import { isBefore, isFuture } from 'date-fns';
import { AllProductTrackingEvent } from '@/modules/checkout/utils/trackingEvents.types';

export enum TYPE_PRODUCT {
  EXCLUSIVE_APP = 'exclusivo_app',
  FIRST_APP = 'lancamento_antecipado_app',
  LOW_STOCK = 'ultimas_unidades',
}

export const getProductTag = (product: AllProductTrackingEvent) => {
  const isAppExclusive = product?.releasesByChannel
    ? product?.releasesByChannel.length === 1 &&
      product?.releasesByChannel?.find(({ channel }) => channel === 'App')
    : false;

  const appReleaseDate = product?.releasesByChannel
    ? product?.releasesByChannel?.find(({ channel }) => channel === 'App')
        ?.releaseDate
    : '';

  const webReleaseDate = product?.releasesByChannel
    ? product?.releasesByChannel?.find(({ channel }) => channel === 'Site')
        ?.releaseDate
    : '';

  const isAppFirst =
    appReleaseDate &&
    webReleaseDate &&
    isBefore(new Date(appReleaseDate || ''), new Date(webReleaseDate || '')) &&
    isFuture(new Date(webReleaseDate || ''));

  const tagProduct = isAppFirst
    ? TYPE_PRODUCT.FIRST_APP
    : isAppExclusive
    ? TYPE_PRODUCT.EXCLUSIVE_APP
    : product?.hasLowStock
    ? TYPE_PRODUCT.LOW_STOCK
    : undefined;

  return tagProduct;
};
