/**
 * The sale channel origin
 * @readonly
 * @enum {number}
 * @property {number} Desktop origin.
 * @property {number} Mobile origin.
 */
export enum SALES_CHANNEL {
  Desktop = 1,
  Mobile = 7,
  AssistedSale = 29,
}

export const SELLER_KEY = 'assistedSaleStore';

export const CONTAINER_DEFAULT_MAX_WIDTH = '1440rem';
export const MAIN_CONTENT_MAX_WIDTH = '1440rem';
export const HEADER_MAX_WIDTH = '1920rem';
