import { CHECKOUT_PLATFORM } from '@/common/config/toggles';
import useEmailFeatureToogle from '@/common/hooks/useEmailFeatureToogle/useEmailFeatureToogle';
import { isAssistedSaleSession } from '@/modules/assistedSale/utils/getAssistedSaleHeaders';

export const useCheckoutPlataformToggle = () => {
  const [checkoutPlatformToggle, isCheckoutPlatformFlagReady] =
    useEmailFeatureToogle(CHECKOUT_PLATFORM);
  const isAssistedSale = isAssistedSaleSession();

  return {
    isCheckoutPlatformActive: checkoutPlatformToggle && !isAssistedSale,
    isCheckoutPlatformFlagReady: isCheckoutPlatformFlagReady || isAssistedSale,
  };
};
