import Agent from 'agentkeepalive';

/**
 * Agent config
 */
const defaultAgentConfig = {
  keepAlive: true,
  keepAliveMsecs: 2000,
  maxFreeSockets: 128,
  socketActiveTTL: 300000,
  timeout: 60000,
  freeSocketTimeout: 30000,
};

export const createHttpAgentConfig = (opts: Agent.HttpsOptions = {}) => {
  const config = { ...defaultAgentConfig, ...opts };

  const httpsAgentConfig = new Agent.HttpsAgent(config);
  const httpAgentConfig = new Agent(config);

  return {
    httpsAgent: httpsAgentConfig,
    httpAgent: httpAgentConfig,
    timeout: 50000,
  };
};
