import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { newMenuMock } from '../mock/newMenuMock';
import { Menu } from '../types';
import { validateMenu } from '../utils/validateMenu/validateMenu';

const MenuDataContext = createContext({ menuData: newMenuMock.content });

const MenuDataProvider = ({
  children,
  menuData: initialMenuData,
}: PropsWithChildren<{ menuData: Menu }>) => {
  const [menuData] = useState(validateMenu(initialMenuData));

  return (
    <MenuDataContext.Provider value={{ menuData: menuData.content }}>
      {children}
    </MenuDataContext.Provider>
  );
};

export const useMenuData = () => {
  const { menuData } = useContext(MenuDataContext);
  return menuData;
};

export default MenuDataProvider;
