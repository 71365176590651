import { HeartOutline } from '@sbf/fairplay-icons';
import { useRouter } from 'next/router';
import { getCoreRoutes } from '@/common/config/routes';
import { useNewCallbackLogin } from '@/common/hooks/useNewCallbackLogin/useNewCallbackLogin';
import { useTrackingContext } from '@/contexts/TrackingContext';
import {
  SelectContentObject,
  sendSelectContentEvents,
} from '@/helpers/analytics';
import { AuthEventType, handleSignIn } from '@/helpers/auth';
import { useAuthSession } from '@/hooks/useAuthSession';
import { ButtonWrapper } from './FavoriteButton.styled';
const authEvent: AuthEventType = {
  action: 'Login Favoritos',
  label: 'Clicou no botão Favoritar',
};

const coreRoutes = getCoreRoutes();

const FavoriteButton = () => {
  const isNewCallbackLogin = useNewCallbackLogin();
  const { session } = useAuthSession();
  const { handleEvents } = useTrackingContext();

  const router = useRouter();
  const onClick = () => {
    if (session) {
      handleEvents<SelectContentObject>(sendSelectContentEvents, {
        component: 'header',
        microComponent: 'favorites_button',
        anchorText: 'Ir para favoritos',
        title: '',
        url: coreRoutes.favorites,
        contentType: 'navigation_link',
      });

      return router.push(coreRoutes.favorites);
    }
    return handleSignIn(authEvent, isNewCallbackLogin);
  };
  return (
    <ButtonWrapper>
      <HeartOutline
        data-testid="favorite-button"
        titleAccess="Ir para favoritos"
        onClick={onClick}
      />
    </ButtonWrapper>
  );
};

export default FavoriteButton;
