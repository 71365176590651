import { AxiosResponse } from 'axios';
import { type ClientError } from '@/common/services/api/types';
import { Components, Events } from '@/common/types/tracking';
import { isClientSide } from '@/common/utils/environment/environment';
import { STATUS_CODE } from '@/common/utils/http/statusCode/statusCode';
import { extractReferenceID } from '@/common/utils/referenceID/extractReferenceID/extractReferenceID';
import {
  trackingEvent,
  trackingExceptionError,
} from '@/common/utils/tracking/tracking';
import { AuthEventType, handleSignOut } from '@/helpers/auth';
import { sendErrorsTrackingEvent } from '@/helpers/trackingErrors/trackingErrors';
import { CheckoutErrorResponse } from '@/modules/checkout/types/checkoutErrorResponse';

export const handleUnauthorizedError = (error: ClientError) => {
  if (error?.response?.status === STATUS_CODE.UNAUTHORIZED) {
    const authEventUnauthorizedLoggedOut: AuthEventType = {
      action: 'API - Error - 401',
      label: 'Recebeu um status unauthorized e foi deslogado',
    };
    handleSignOut(authEventUnauthorizedLoggedOut);
  }
};
export const trackerCommonError = (
  error: ClientError<CheckoutErrorResponse>,
) => {
  const statusCode = Number(error?.response?.status || 0);
  const messageError = error.response?.data?.message as string;

  if (statusCode >= 500) {
    error.name = 'Request Error: ' + error.response?.status;
    error.message = messageError || error.message;

    trackingExceptionError({
      error,
      flow: 'request_flow',
      code: error?.response?.data?.code || null,
      extraDetails: {
        message: error?.response?.data,
        request: error?.request?.requestData,
      },
      checkpoint: 'server_error',
      namespace: 'scooby()',
    });
  } else if (statusCode >= 400 && statusCode < 500 && statusCode !== 403) {
    error.name = 'Request Error: ' + error.response?.status;
    error.message = messageError || error.message;

    trackingExceptionError({
      error,
      flow: 'request_flow',
      code: error?.response?.data?.code || null,
      extraDetails: {
        message: error?.response?.data,
        request: error?.request?.requestData,
      },
      checkpoint: 'client_error()',
      namespace: 'scooby()',
    });
  } else if (statusCode === 403) {
    error.name = 'Request Error: ' + error.response?.status;
    trackingExceptionError({
      error,
      flow: 'request_flow',
      extraDetails: {
        message: error?.response?.data,
        request: error?.request?.requestData,
      },
      checkpoint: 'akamai_error',
      namespace: 'akamai()',
    });
  } else {
    error.name = 'Request Error: ' + error.response?.status;
    error.message = messageError || error.message;

    trackingExceptionError({
      error,
      flow: 'request_flow',
      code: error?.response?.data?.code || null,
      extraDetails: {
        message: error?.response?.data,
        request: error?.request?.requestData,
      },
      checkpoint: 'client_error',
      namespace: 'browser()',
    });
  }
};

type ResponseScooby = {
  mensagensCarrinhoTodos: Array<string> | unknown;
  mensagensCarrinhoStatusSucesso: boolean | unknown;
};

export const handleEventsErrorFromSuccess = (
  response: AxiosResponse<ResponseScooby>,
) => {
  // I know that this is an anti-pattern, but we have to track a key from any response
  const messageFromScooby = response.data?.mensagensCarrinhoTodos || [];
  if (
    messageFromScooby &&
    Array.isArray(messageFromScooby) &&
    messageFromScooby.length
  ) {
    sendErrorsTrackingEvent({
      component: Components.Request,
      originEvent: Events.ResponseRequest,
      errorMessage: messageFromScooby.join(),
      errorCode: response?.status,
    });
  }
};

export const handlePreconditionRequiredError = (error: ClientError) => {
  if (
    Number(error?.response?.status) === STATUS_CODE.PRECONDITION_REQUIRED &&
    isClientSide()
  ) {
    const akamaiChallenger = window.ak_chlge;
    const response = error.response;
    const url = response?.request?.responseURL || '';
    const enableChallenger = akamaiChallenger && url;
    const origin = window.location.pathname;

    if (enableChallenger) {
      akamaiChallenger.showChallenge(response, url);

      trackingEvent({
        event: 'error',
        event_type: 'challenge',
        category: 'error 428',
        action: `set captcha challenge`,
        label: `challenge suspected bots with a CAPTCHA puzzle to let only humans through`,
        origin,
      });
      return Promise.reject(response);
    }
  }
};

export const clientResponseErrors = (
  error: ClientError<CheckoutErrorResponse>,
) => {
  const statusCode = Number(error?.response?.status || 0);
  handleUnauthorizedError(error);
  trackerCommonError(error);

  if (STATUS_CODE.FORBIDDEN === statusCode && error.response) {
    const errorMessage = String(error?.response?.data);
    const referenceID = extractReferenceID(errorMessage);

    const updatedData = {
      referenceID: referenceID,
      message: '',
    };

    error.response.data = updatedData;

    return Promise.reject(error);
  }

  return Promise.reject(error);
};
